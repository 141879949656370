// @flow
import React from 'react';
import Styles from './recommended.module.scss';
import {
  PURPOSES_INFO,
  STACKS_INFO,
  SPECIAL_FEATURES_INFO,
  SPECIAL_PURPOSES_INFO,
  FEATURES_INFO
} from '../../../utils/constants';

type PublisherProps = {
  publisherPurposeConsentIds: Array<any>,
  publisherSpecialFeaturesIds: Array<any>,
  publisherPurposeLegitimateInterestIds: Array<any>,
  stacks: Array<any>,
  features: Array<any>,
  specialPurposes: Array<any>,
}

type Props = {
  list: Array<PublisherProps>;
  stackList: PublisherProps;
  purposesData: Record<String, any>;
}

const RecommendedList = ({ list, stackList, purposesData }: Props) => {
  const {
    specialFeatures: specialFeaturesData,
    specialPurposes: specialPurposesData,
    purposes: purposesList,
    features: featuresList
  } = purposesData;
  const gvlPurposes = purposesList || PURPOSES_INFO;
  const publisherProps = list ? list[0] : stackList;

  return (
    <div className={Styles.recommended}>
      <ul>
        {publisherProps.publisherPurposeConsentIds && publisherProps.publisherPurposeConsentIds.includes(1) && (
          <li>{`Purpose ${gvlPurposes[0].id}: ${gvlPurposes[0].name}`}</li>
        )}
        {publisherProps.publisherSpecialFeaturesIds && publisherProps.publisherSpecialFeaturesIds.map((item) => {
          const specialFeature = (
            specialFeaturesData || SPECIAL_FEATURES_INFO
          ).find(featureId => featureId.id === item);
          const isStack1 = publisherProps.stacks.includes(1);
          if (isStack1) {
            return null;
          }
          return (
            <li
              data-testid={`specialFeature.${specialFeature.id}`}
              key={item}
            >
              {`Special Feature ${specialFeature.id}: ${specialFeature.name}`}
            </li>
          );
        })}
        {publisherProps.stacks && publisherProps.stacks.map((stackItem) => {
          const stack = STACKS_INFO.find(stackInfoItem => stackInfoItem.id === stackItem);
          return (
            <li key={stackItem}>
              {`Stack ${stack.id}: ${stack.name}`}
              <ul>
                {stack.purposes.map((item) => {
                  const purposeInfo = gvlPurposes.find(purposeItem => purposeItem.id === item);
                  return <li key={purposeInfo.id}>{`Purpose ${purposeInfo.id}: ${purposeInfo.name}`}</li>;
                })}
                {stack.specialFeatures.map((item) => {
                  const featureInfo = SPECIAL_FEATURES_INFO.find(featureItem => featureItem.id === item);
                  return <li key={featureInfo.id}>{`Special Feature ${featureInfo.id}: ${featureInfo.name}`}</li>;
                })}
              </ul>
            </li>
          );
        })}
        {publisherProps.publisherPurposeConsentIds && publisherProps.publisherPurposeConsentIds.map((item) => {
          const purposesObj = gvlPurposes.find(purposeItem => purposeItem.id === item);

          if (gvlPurposes.id !== 1 && purposesObj) {
            return <li key={purposesObj.id}>{`Purpose ${purposesObj.id}: ${purposesObj.name}`}</li>;
          }
          return null;
        })}
        {publisherProps.publisherPurposeLegitimateInterestIds
          && publisherProps.publisherPurposeLegitimateInterestIds.map((item) => {
            const purposesObj = gvlPurposes.find(purposeItem => purposeItem.id === item);
            if (purposesObj) {
              return <li key={purposesObj.id}>{`Legitimate Purpose ${purposesObj.id}: ${purposesObj.name}`}</li>;
            }
            return null;
          })}
        {'specialPurposes'
        in publisherProps && publisherProps.specialPurposes && publisherProps.specialPurposes.map((item) => {
          const specialPurpose = (
            specialPurposesData || SPECIAL_PURPOSES_INFO
          ).find(purposeItem => purposeItem.id === item);
          return specialPurpose && (
            <li
              data-testid={`specialPurpose.${specialPurpose.id}`}
              key={specialPurpose.id}
            >
              {`Special Purpose ${specialPurpose.id}: ${specialPurpose.name}`}
            </li>
          );
        })}
        {'features'
        in publisherProps && publisherProps.features && publisherProps.features.map((item) => {
          const feature = (featuresList || FEATURES_INFO).find(featureItem => featureItem.id === item);
          return feature && <li key={feature.id}>{`Feature ${feature.id}: ${feature.name}`}</li>;
        })}
      </ul>
    </div>
  );
};

export default RecommendedList;
