import { QueryFunctionContext } from '@tanstack/react-query';
import getAxiosInstance from '../axiosClient';

// @flow
const requestBanner = getAxiosInstance(process.env.REACT_APP_CHOICE_2_OLD_VERSION_API_URL);

export async function getOldVersionProperties(context: QueryFunctionContext): Promise<any> {
  const [, pCode] = context.queryKey;

  const data = await requestBanner.get(`/${pCode}.csv`);
  return data;
}
