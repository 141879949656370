import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMSPAPurposes } from '../../../store/duck/mspa';

function useMSPAPurposes() {
  const purposesData = useSelector(state => state.mspaPurposes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!purposesData.pending && purposesData.purposes.length === 0
        && purposesData.error.status === 200) dispatch(fetchMSPAPurposes());
  }, [purposesData]);

  return purposesData.purposes;
}

export default useMSPAPurposes;
