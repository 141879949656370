// @flow
import React from 'react';
import Form from 'antd/es/form';
import Card from 'antd/es/card';
import Checkbox from 'antd/es/checkbox';
import Input from 'antd/es/input';
import Tooltip from 'antd/es/tooltip';
import { HEADER_REGULATION_TEXT } from '../../../../utils/constants';
import SITETOOLTIPS from '../../../../utils/toolTipsMessages';
import InfoIconTooltip from '../../../ui/infoIconTooltip';
import Select from '../../../ui/select';
import { getTranslationsForLang } from '../../../../utils/language';

function getSoftOptInNotice(isSoftOptIn) {
  if (isSoftOptIn) {
    return 'Please note that in order to enable soft opt-in, the persistent consent link must always be on.';
  }
  return '';
}

type Props = {
  language: string;
  isSoftOptIn: Boolean;
  validations: Object;
}

function CustomizePersistentConsent({
  language,
  isSoftOptIn,
  validations
}: Props) {
  const getPersistentConsentLink = [
    { value: 'BOTTOM_RIGHT', label: 'BOTTOM RIGHT' },
    { value: 'BOTTOM_LEFT', label: 'BOTTOM LEFT' },
    { value: 'TOP_RIGHT', label: 'TOP RIGHT' },
    { value: 'TOP_LEFT', label: 'TOP LEFT' }
  ];
  const { Option } = Select;

  return (
    <Card
      title="Customize Persistent Consent Link"
      extra={HEADER_REGULATION_TEXT.GDPR}
    >
      <Form.Item styles={{ display: 'flex', marginBottom: 0 }}>
        <Form.Item
          name="uxPersistentConsentButton"
          style={{ marginBottom: '0', display: 'inline-block' }}
          valuePropName="checked"
          help={getSoftOptInNotice(isSoftOptIn)}
        >
          <Checkbox
            disabled={isSoftOptIn}
          >
            Display persistent consent link
          </Checkbox>
        </Form.Item>
        <Tooltip
          title={SITETOOLTIPS.persistentConsentLink}
          placement="right"
        >
          <span><InfoIconTooltip style={{ marginTop: '9' }} /></span>
        </Tooltip>
      </Form.Item>
      <Form.Item
        name="consentLinkLabel"
        label="PERSISTENT CONSENT LINK LABEL"
        rules={validations.textInput}
      >
        <Input
          placeholder={
            getTranslationsForLang(language).mockPurposesCopies.consentLinkLabel
          }
          disabled={isSoftOptIn}
        />
      </Form.Item>
      <Form.Item
        label="Persistent consent link location"
        name="uxPersistentConsentLinkLocation"
      >
        <Select required>
          {getPersistentConsentLink.map(opt => (
            <Option key={opt.value} value={opt.value}>{opt.label}</Option>
          ))}
        </Select>
      </Form.Item>
    </Card>
  );
}

export default CustomizePersistentConsent;
