// @flow
import { faTimes } from '@fortawesome/fontawesome-pro-light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AntModal, { ModalProps } from 'antd/es/modal';
import React from 'react';
import './styles.scss';

const Modal = ({ closeIcon = <FontAwesomeIcon icon={faTimes} />, ...props }: ModalProps) => (
  <AntModal closeIcon={closeIcon} {...props} />
);

export default Modal;
