/* eslint-disable max-len, no-multi-str, no-tabs */
const TRANSLATIONS = {
  // Slovenian
  sl: {
    agree: 'STRINJAM SE',
    saveAndExit: 'SHRANI IN ZAPRI',
    disagree: 'NE STRINJAM SE',
    objectAll: 'UGOVARJAM VSEM',
    mockPurposesCopies: {
      headline: 'Cenimo vašo zasebnost',
      body: 'You can set your consent preferences and determine how you want your data to be used based on the purposes below. You may set your preferences for us independently from those of third-party partners. Each purpose has a description so that you know how we and partners use your data.',
      accept: 'Accept all',
      vendorLink: 'See Vendors',
      saveExit: 'Save & Exit',
      cancel: 'Cancel',
      consentLinkLabel: 'Privacy'
    },
    mockHeadlinesCopies: {
      headline: 'Cenimo vašo zasebnost',
      accept: 'I accept',
      reject: 'I do not accept',
      showPurposes: 'Show Purposes',
    },
    coreUiLabels: {
      initScreenTitle: 'Cenimo vašo zasebnost',
      agreeButton: 'STRINJAM SE',
      initScreenRejectButton: 'NE STRINJAM SE',
      initScreenSettingsButton: 'VEČ MOŽNOSTI',
      summaryScreenBodyNoRejectService: [
        'Mi in naši partnerji shranjujemo in/ali dostopamo do informacij v napravi, na primer piškotkov, in obdelujemo osebne podatke, kot so enolični identifikatorji in standardne informacije, ki jih naprava pošilja za individualno prilagojene oglase in vsebine, merjenje oglasov in vsebin ter vpoglede v ciljne skupine kakor tudi za razvoj in izboljšanje izdelkov.',
        ' Z vašim dovoljenjem lahko mi in naši partnerji uporabljamo natančne geolokacijske podatke in identifikacijo z iskanjem naprave. Lahko kliknete, da se strinjate z obdelavo, ki jo mi in naši partnerji izvajamo, kot je opisano zgoraj. Lahko tudi dostopate do podrobnejših informacij in spremenite prednostne nastavitve, preden podate privolitev ali jo zavrnete. Vaša privolitev v določeno obdelavo vaših osebnih podatkov morda ne bo potrebna, vendar imate pravico do ugovora taki obdelavi. Vaše prednostne nastavitve bodo uporabljene samo za to spletno mesto. Prednostne nastavitve lahko kadar koli spremenite, če se vrnete na to stran ali preverite našo politiko zasebnosti.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Mi in naši partnerji shranjujemo in/ali dostopamo do informacij v napravi, na primer piškotkov, in obdelujemo osebne podatke, kot so enolični identifikatorji in standardne informacije, ki jih naprava pošilja za individualno prilagojene oglase in vsebine, merjenje oglasov in vsebin ter vpoglede v ciljne skupine kakor tudi za razvoj in izboljšanje izdelkov.',
        ' Z vašim dovoljenjem lahko mi in naši partnerji uporabljamo natančne geolokacijske podatke in identifikacijo z iskanjem naprave. Lahko kliknete, da se strinjate z obdelavo, ki jo mi in naši partnerji izvajamo, kot je opisano zgoraj. Lahko tudi dostopate do podrobnejših informacij in spremenite prednostne nastavitve, preden podate privolitev ali jo zavrnete. Vaša privolitev v določeno obdelavo vaših osebnih podatkov morda ne bo potrebna, vendar imate pravico do ugovora taki obdelavi. Vaše prednostne nastavitve bodo uporabljene po vsem spletu. Prednostne nastavitve lahko kadar koli spremenite, če se vrnete na to stran ali preverite našo politiko zasebnosti.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Mi in naši partnerji shranjujemo in/ali dostopamo do informacij v napravi, na primer piškotkov, in obdelujemo osebne podatke, kot so enolični identifikatorji in standardne informacije, ki jih naprava pošilja za individualno prilagojene oglase in vsebine, merjenje oglasov in vsebin ter vpoglede v ciljne skupine kakor tudi za razvoj in izboljšanje izdelkov.',
        ' Z vašim dovoljenjem lahko mi in naši partnerji uporabljamo natančne geolokacijske podatke in identifikacijo z iskanjem naprave. Lahko kliknete, da se strinjate z obdelavo, ki jo mi in naši partnerji izvajamo, kot je opisano zgoraj. Lahko tudi dostopate do podrobnejših informacij in spremenite prednostne nastavitve, preden podate privolitev ali jo zavrnete. Vaša privolitev v določeno obdelavo vaših osebnih podatkov morda ne bo potrebna, vendar imate pravico do ugovora taki obdelavi. Vaše prednostne nastavitve bodo uporabljene v skupini spletnih mest [povezanih prek hiperpovezav z domeno, kjer so navedene vse nastavitve za to konfiguracijo skupin].Prednostne nastavitve lahko kadar koli spremenite, če se vrnete na to stran ali preverite našo politiko zasebnosti.'
      ],
      summaryScreenBodyRejectService: [
        'Mi in naši partnerji shranjujemo in/ali dostopamo do informacij v napravi, na primer piškotkov, in obdelujemo osebne podatke, kot so enolični identifikatorji in standardne informacije, ki jih naprava pošilja za individualno prilagojene oglase in vsebine, merjenje oglasov in vsebin ter vpoglede v ciljne skupine kakor tudi za razvoj in izboljšanje izdelkov.',
        ' Z vašim dovoljenjem lahko mi in naši partnerji uporabljamo natančne geolokacijske podatke in identifikacijo z iskanjem naprave. Lahko kliknete, da se strinjate z obdelavo, ki jo mi in naši partnerji izvajamo, kot je opisano zgoraj. Lahko tudi kliknete, da privolitve ne želite podati, ali dostopate do podrobnejših informacij in spremenite prednostne nastavitve, preden podate privolitev. Vaša privolitev v določeno obdelavo vaših osebnih podatkov morda ne bo potrebna, vendar imate pravico do ugovora taki obdelavi. Vaše prednostne nastavitve bodo uporabljene samo za to spletno mesto. Prednostne nastavitve lahko kadar koli spremenite, če se vrnete na to stran ali preverite našo politiko zasebnosti.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Mi in naši partnerji shranjujemo in/ali dostopamo do informacij v napravi, na primer piškotkov, in obdelujemo osebne podatke, kot so enolični identifikatorji in standardne informacije, ki jih naprava pošilja za individualno prilagojene oglase in vsebine, merjenje oglasov in vsebin ter vpoglede v ciljne skupine kakor tudi za razvoj in izboljšanje izdelkov.',
        ' Z vašim dovoljenjem lahko mi in naši partnerji uporabljamo natančne geolokacijske podatke in identifikacijo z iskanjem naprave.Lahko kliknete, da se strinjate z obdelavo, ki jo mi in naši partnerji izvajamo, kot je opisano zgoraj. Lahko tudi kliknete, da privolitve ne želite podati, ali dostopate do podrobnejših informacij in spremenite prednostne nastavitve, preden podate privolitev. Vaša privolitev v določeno obdelavo vaših osebnih podatkov morda ne bo potrebna, vendar imate pravico do ugovora taki obdelavi. Vaše prednostne nastavitve bodo uporabljene po vsem spletu.Prednostne nastavitve lahko kadar koli spremenite, če se vrnete na to stran ali preverite našo politiko zasebnosti.'
      ],
      summaryScreenBodyRejectGroup: [
        'Mi in naši partnerji shranjujemo in/ali dostopamo do informacij v napravi, na primer piškotkov, in obdelujemo osebne podatke, kot so enolični identifikatorji in standardne informacije, ki jih naprava pošilja za individualno prilagojene oglase in vsebine, merjenje oglasov in vsebin ter vpoglede v ciljne skupine kakor tudi za razvoj in izboljšanje izdelkov.',
        ' Z vašim dovoljenjem lahko mi in naši partnerji uporabljamo natančne geolokacijske podatke in identifikacijo z iskanjem naprave. Lahko kliknete, da se strinjate z obdelavo, ki jo mi in naši partnerji izvajamo, kot je opisano zgoraj. Lahko tudi kliknete, da privolitve ne želite podati, ali dostopate do podrobnejših informacij in spremenite prednostne nastavitve, preden podate privolitev. Vaša privolitev v določeno obdelavo vaših osebnih podatkov morda ne bo potrebna, vendar imate pravico do ugovora taki obdelavi. Vaše prednostne nastavitve bodo uporabljene v skupini spletnih mest [povezave do domene, kjer so navedene vse nastavitve za to konfiguracijo skupin]. Prednostne nastavitve lahko kadar koli spremenite, če se vrnete na to stran ali preverite našo politiko zasebnosti.'
      ],
      initScreenBodyGlobal: 'Mi in naši partnerji shranjujemo ali dostopamo do informacij v napravah, na primer piškotkov, in obdelujemo osebne podatke, kot so enolični identifikatorji in standardne informacije, ki jih naprava pošilja za namene, opisane v nadaljevanju. Lahko kliknete, da se strinjate z obdelavo, ki jo mi in naši partnerji izvajamo za te namene. Lahko tudi kliknete, da privolitve ne želite podati ali dostopati do podrobnejših informacij, da bi spremenili prednostne nastavitve, preden podate privolitev. Vaše prednostne nastavitve bodo uporabljene po vsem spletu. Vaša privolitev v določeno obdelavo vaših osebnih podatkov morda ne bo potrebna, vendar imate pravico do ugovora taki obdelavi. Prednostne nastavitve lahko kadar koli spremenite, če se vrnete na to stran ali preverite našo politiko zasebnosti.',
      initScreenBodyService: 'Mi in naši partnerji shranjujemo ali dostopamo do informacij v napravah, na primer piškotkov, in obdelujemo osebne podatke, kot so enolični identifikatorji in standardne informacije, ki jih naprava pošilja za namene, opisane v nadaljevanju. Lahko kliknete, da se strinjate z obdelavo, ki jo mi in naši partnerji izvajamo za te namene. Lahko tudi kliknete, da ne želite podati privolitve, ali dostopate do podrobnejših informacij in spremenite prednostne nastavitve, preden podate privolitev. Vaše prednostne nastavitve bodo uporabljene samo za to spletno mesto. Vaša privolitev v določeno obdelavo vaših osebnih podatkov morda ne bo potrebna, vendar imate pravico do ugovora taki obdelavi. Prednostne nastavitve lahko kadar koli spremenite, če se vrnete na to stran ali preverite našo politiko zasebnosti.',
      initScreenBodyGroup: 'Mi in naši partnerji shranjujemo ali dostopamo do informacij v napravah, na primer piškotkov, in obdelujemo osebne podatke, kot so enolični identifikatorji in standardne informacije, ki jih naprava pošilja za namene, opisane v nadaljevanju. Lahko kliknete, da se strinjate z obdelavo, ki jo mi in naši partnerji izvajamo za te namene. Lahko tudi kliknete, da ne želite podati privolitve, ali dostopate do podrobnejših informacij in spremenite prednostne nastavitve, preden podate privolitev. Vaše prednostne nastavitve bodo uporabljene v skupini spletnih mest [povezave do domene, kjer so navedene vse nastavitve za to konfiguracijo skupin]. Vaša privolitev v določeno obdelavo vaših osebnih podatkov morda ne bo potrebna, vendar imate pravico do ugovora taki obdelavi. Prednostne nastavitve lahko kadar koli spremenite, če se vrnete na to stran ali preverite našo politiko zasebnosti.',
      specialPurposesAndFeatures: 'Posebni nameni in funkcije',
      saveAndExitButton: 'SHRANI IN ZAPRI',
      purposeScreenVendorLink: 'PARTNERJI',
      legitimateInterestLink: 'ZAKONITI INTERES ',
      specialPurposesLabel: 'Posebni nameni',
      specialFeaturesLabel: 'Posebne funkcije',
      featuresLabel: 'Funkcije',
      back: 'Nazaj',
      onLabel: 'VKL.',
      offLabel: 'IZKL.',
      multiLabel: 'MULTI',
      legalDescription: 'Pravni opis',
      showPartners: 'Pokaži partnerje',
      hidePartners: 'Skrij partnerje',
      vendorScreenBody: 'V nadaljevanju lahko preverite in izberite prednostne nastavitve privolitve za vsakega partnerja. Za več informacij razširite vsak seznam partnerjev, da se lahko laže odločite. Nekatere osebne podatke obdelujemo brez vaše privolitve, vendar imate pravico do ugovora.',
      privacyPolicyLabel: 'Politika zasebnosti',
      descriptionLabel: 'Opis ponudnikov',
      legitimateScreenBody: 'V nadaljevanju lahko vsak partner preveri in ugovarja obdelavi osebnih podatkov brez privolitve na podlagi zakonitega interesa za vsak namen. Za več informacij razširite vsak namen in seznam partnerjev, da se lahko laže odločite. Če želite ugovarjati posebnemu namenu za zagotavljanje varnosti, preprečevanje goljufij, razhroščevanje in tehnično zagotavljanje oglasov ali vsebin, kliknite povezavo do partnerjeve politike zasebnosti.',
      legitimateInterestPurposesLabel: 'Namen/-i z zakonitimi interesi',
      legitimateInterestVendorLabel: 'Ponudniki z zakonitimi interesi',
      legitimateScreenObject: 'UGOVARJAM',
      legitimateScreenObjected: 'UGOVARJANO',
      legitimateScreenAccept: 'ODSTRANI UGOVOR',
      objectAllButton: 'UGOVARJAM VSEM',
      persistentConsentLinkLabel: 'Zasebnost',
      nonIabVendorsNotice: 'Ponudniki, ki ne sodelujejo v okviru evropske mreže IAB za preglednost in privolitev (IAB Europe Transparency and Consent Framework) in ne spoštujejo njenih politik ali tehničnih specifikacij',
      googlePartners: 'Google Partners',
      purposesLabel: 'Namen (privolitev)'
    },
    premiumUiLabels: {
      linksTitle: 'Dodatne povezave',
      nonIabVendorsLabel: 'Ponudniki, ki niso v mreži IAB'
    }
  },

  // Maltese
  mt: {
    agree: 'NAQBEL',
    saveAndExit: 'ISSEJVJA U OĦROĠ',
    disagree: 'MA NAQBILX',
    objectAll: 'OĠĠEZZJONA GĦAL KOLLOX',
    mockPurposesCopies: {
      headline: 'Il-privatezza tiegħek hija importanti għalina',
      body: 'You can set your consent preferences and determine how you want your data to be used based on the purposes below. You may set your preferences for us independently from those of third-party partners. Each purpose has a description so that you know how we and partners use your data.',
      accept: 'Accept all',
      vendorLink: 'See Vendors',
      saveExit: 'Save & Exit',
      cancel: 'Cancel',
      consentLinkLabel: 'Privacy'
    },
    mockHeadlinesCopies: {
      headline: 'Il-privatezza tiegħek hija importanti għalina',
      accept: 'I accept',
      reject: 'I do not accept',
      showPurposes: 'Show Purposes',
    },
    coreUiLabels: {
      initScreenTitle: 'Il-privatezza tiegħek hija importanti għalina',
      agreeButton: 'NAQBEL',
      initScreenRejectButton: 'MA NAQBILX',
      initScreenSettingsButton: 'AKTAR GĦAŻLIET',
      summaryScreenBodyNoRejectService: [
        'Aħna u l-imsieħba tagħna naħżnu u/jew naċċessaw l-informazzjoni fuq apparat, bħal cookies u nipproċessaw data personali, bħal identifikaturi uniċi u informazzjoni standard mibgħuta minn apparat għal reklami u kontenut ippersonalizzati, kejl tar-reklami u l-kontenut, u għarfien dwar l-udjenza, kif ukoll biex niżviluppaw u ntejbu l-prodotti.',
        ' Bil-permess tiegħek aħna u l-imsieħba tagħna nistgħu nużaw data dwar il-ġeolokalizzazzjoni preċiża u identifikazzjoni permezz tal-iskennjar tal-apparat. Tista’ tikklikkja biex tagħti l-kunsens tiegħek għall-ipproċessar minna u mill-imsieħba tagħna kif deskritt hawn fuq. Inkella tista’ taċċessa informazzjoni aktar iddettaljata u tibdel il-preferenzi tiegħek qabel ma tagħti l-kunsens tiegħek jew biex tirrifjuta li tagħti l-kunsens. Jekk jogħġbok innota li xi pproċessar tad-data personali tiegħek jista’ ma jirrikjedix il-kunsens tiegħek, iżda għandek id-dritt li toġġezzjona għal tali pproċessar. Il-preferenzi tiegħek se japplikaw għal dan is-sit web biss. Tista’ tibdel il-preferenzi tiegħek fi kwalunkwe ħin billi tirritorna f’dan is-sit jew iżżur il-politika tal-privatezza tagħna.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Aħna u l-imsieħba tagħna naħżnu u/jew naċċessaw l-informazzjoni fuq apparat, bħal cookies u nipproċessaw data personali, bħal identifikaturi uniċi u informazzjoni standard mibgħuta minn apparat għal reklami u kontenut ippersonalizzati, kejl tar-reklami u l-kontenut, u għarfien dwar l-udjenza, kif ukoll biex niżviluppaw u ntejbu l-prodotti.',
        ' Bil-permess tiegħek aħna u l-imsieħba tagħna nistgħu nużaw data dwar il-ġeolokalizzazzjoni preċiża u identifikazzjoni permezz tal-iskennjar tal-apparat. Tista’ tikklikkja biex tagħti l-kunsens tiegħek għall-ipproċessar minna u mill-imsieħba tagħna kif deskritt hawn fuq. Inkella tista’ taċċessa informazzjoni aktar iddettaljata u tibdel il-preferenzi tiegħek qabel ma tagħti l-kunsens tiegħek jew biex tirrifjuta li tagħti l-kunsens. Jekk jogħġbok innota li xi pproċessar tad-data personali tiegħek jista’ ma jirrikjedix il-kunsens tiegħek, iżda għandek id-dritt li toġġezzjona għal tali pproċessar. Il-preferenzi tiegħek se japplikaw fuq il-web kollu. Tista’ tibdel il-preferenzi tiegħek fi kwalunkwe ħin billi tirritorna f’dan is-sit jew iżżur il-politika tal-privatezza tagħna.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Aħna u l-imsieħba tagħna naħżnu u/jew naċċessaw l-informazzjoni fuq apparat, bħal cookies u nipproċessaw data personali, bħal identifikaturi uniċi u informazzjoni standard mibgħuta minn apparat għal reklami u kontenut ippersonalizzati, kejl tar-reklami u l-kontenut, u għarfien dwar l-udjenza, kif ukoll biex niżviluppaw u ntejbu l-prodotti.',
        ' Bil-permess tiegħek aħna u l-imsieħba tagħna nistgħu nużaw data dwar il-ġeolokalizzazzjoni preċiża u identifikazzjoni permezz tal-iskennjar tal-apparat. Tista’ tikklikkja biex tagħti l-kunsens tiegħek għall-ipproċessar minna u mill-imsieħba tagħna kif deskritt hawn fuq. Inkella tista’ taċċessa informazzjoni aktar iddettaljata u tibdel il-preferenzi tiegħek qabel ma tagħti l-kunsens tiegħek jew biex tirrifjuta li tagħti l-kunsens. Jekk jogħġbok innota li xi pproċessar tad-data personali tiegħek jista’ ma jirrikjedix il-kunsens tiegħek, iżda għandek id-dritt li toġġezzjona għal tali pproċessar. Il-preferenzi tiegħek se japplikaw għal grupp ta’ siti web [iperkonnessi mad-dominju fejn il-proprjetajiet kollha huma elenkati għal din il-konfigurazzjoni tal-grupp]. Tista’ tibdel il-preferenzi tiegħek fi kwalunkwe ħin billi tirritorna f’dan is-sit jew iżżur il-politika tal-privatezza tagħna.'
      ],
      summaryScreenBodyRejectService: [
        'Aħna u l-imsieħba tagħna naħżnu u/jew naċċessaw l-informazzjoni fuq apparat, bħal cookies u nipproċessaw data personali, bħal identifikaturi uniċi u informazzjoni standard mibgħuta minn apparat għal reklami u kontenut ippersonalizzati, kejl tar-reklami u l-kontenut, u għarfien dwar l-udjenza, kif ukoll biex niżviluppaw u ntejbu l-prodotti.',
        " Bil-permess tiegħek aħna u l-imsieħba tagħna nistgħu nużaw data dwar il-ġeolokalizzazzjoni preċiża u identifikazzjoni permezz tal-iskennjar tal-apparat. Tista’ tikklikkja biex tagħti l-kunsens tiegħek għall-ipproċessar minna u mill-imsieħba tagħna kif deskritt hawn fuq. Inkella tista' tikklikkja biex tirrifjuta li tagħti l-kunsens tiegħek jew taċċessa informazzjoni aktar iddettaljata u tibdel il-preferenzi tiegħek qabel ma tagħti l-kunsens. Jekk jogħġbok innota li xi pproċessar tad-data personali tiegħek jista’ ma jirrikjedix il-kunsens tiegħek, iżda għandek id-dritt li toġġezzjona għal tali ipproċessar. Il-preferenzi tiegħek se japplikaw għal dan is-sit web biss. Tista’ tibdel il-preferenzi tiegħek fi kwalunkwe ħin billi tirritorna f’dan is-sit jew iżżur il-politika tal-privatezza tagħna."
      ],
      summaryScreenBodyRejectGlobal: [
        'Aħna u l-imsieħba tagħna naħżnu u/jew naċċessaw l-informazzjoni fuq apparat, bħal cookies u nipproċessaw data personali, bħal identifikaturi uniċi u informazzjoni standard mibgħuta minn apparat għal reklami u kontenut ippersonalizzati, kejl tar-reklami u l-kontenut, u għarfien dwar l-udjenza, kif ukoll biex niżviluppaw u ntejbu l-prodotti.',
        " Bil-permess tiegħek aħna u l-imsieħba tagħna nistgħu nużaw data dwar il-ġeolokalizzazzjoni preċiża u identifikazzjoni permezz tal-iskennjar tal-apparat. Tista’ tikklikkja biex tagħti l-kunsens tiegħek għall-ipproċessar minna u mill-imsieħba tagħna kif deskritt hawn fuq. Inkella tista' tikklikkja biex tirrifjuta li tagħti l-kunsens tiegħek jew li taċċessa informazzjoni aktar iddettaljata u tibdel il-preferenzi tiegħek qabel ma tagħti l-kunsens. Jekk jogħġbok innota li xi pproċessar tad-data personali tiegħek jista’ ma jirrikjedix il-kunsens tiegħek, iżda għandek id-dritt li toġġezzjona għal tali pproċessar. Il-preferenzi tiegħek se japplikaw fuq il-web kollu. Inti tista’ tibdel il-preferenzi tiegħek fi kwalunkwe ħin billi tirritorna f’dan is-sit jew iżżur il-politika ta’ privatezza tagħna."
      ],
      summaryScreenBodyRejectGroup: [
        'Aħna u l-imsieħba tagħna naħżnu u/jew naċċessaw l-informazzjoni fuq apparat, bħal cookies u nipproċessaw data personali, bħal identifikaturi uniċi u informazzjoni standard mibgħuta minn apparat għal reklami u kontenut ippersonalizzati, kejl tar-reklami u l-kontenut, u għarfien dwar l-udjenza, kif ukoll biex niżviluppaw u ntejbu l-prodotti.',
        " Bil-permess tiegħek aħna u l-imsieħba tagħna nistgħu nużaw data dwar il-ġeolokalizzazzjoni preċiża u identifikazzjoni permezz tal-iskennjar tal-apparat. Tista’ tikklikkja biex tagħti l-kunsens tiegħek għall-ipproċessar minna u mill-imsieħba tagħna kif deskritt hawn fuq. Inkella tista' tikklikkja biex tirrifjuta li tagħti l-kunsens tiegħek jew taċċessa informazzjoni aktar iddettaljata u tibdel il-preferenzi tiegħek qabel ma tagħti l-kunsens. Jekk jogħġbok innota li xi pproċessar tad-data personali tiegħek jista’ ma jirrikjedix il-kunsens tiegħek, iżda għandek id-dritt li toġġezzjona għal tali pproċessar. Il-preferenzi tiegħek se japplikaw għal grupp ta’ siti web [links għad-dominju fejn il-proprjetajiet kollha huma elenkati għal din il-konfigurazzjoni tal-grupp]. Tista’ tibdel il-preferenzi tiegħek fi kwalunkwe ħin billi tirritorna f’dan is-sit jew iżżur l-istqarrija ta’ privatezza tagħna."
      ],
      initScreenBodyGlobal: "Aħna u l-imsieħba tagħna naħżnu jew naċċessaw informazzjoni fuq apparati, bħal cookies u nipproċessaw data personali, bħal identifikaturi uniċi u informazzjoni standard mibgħuta minn apparat għall-finijiet deskritti hawn taħt. Tista’ tikklikkja biex tagħti l-kunsens tiegħek għall-ipproċessar minna u mill-imsieħba tagħna għal tali skopijiet. Inkella, tista' tikklikkja biex tirrifjuta li tagħti l-kunsens tiegħek, jew taċċessa informazzjoni aktar iddettaljata biex tibdel il-preferenzi tiegħek qabel ma tagħti l-kunsens. Il-preferenzi tiegħek se japplikaw fuq il-web kollu. Jekk jogħġbok innota li xi pproċessar tad-data personali tiegħek jista’ ma jirrikjedix il-kunsens tiegħek, iżda għandek id-dritt li toġġezzjona għal dan l-ipproċessar. Tista’ tibdel il-preferenzi tiegħek fi kwalunkwe ħin billi tirritorna f’dan is-sit jew iżżur il-politika tal-privatezza tagħna.",
      initScreenBodyService: "Aħna u l-imsieħba tagħna naħżnu jew naċċessaw informazzjoni fuq apparati, bħal cookies u nipproċessaw data personali, bħal identifikaturi uniċi u informazzjoni standard mibgħuta minn apparat għall-finijiet deskritti hawn taħt. Tista’ tikklikkja biex tagħti l-kunsens tiegħek għall-ipproċessar minna u mill-imsieħba tagħna għal tali skopijiet. Inkella, tista' tikklikkja biex tirrifjuta li tagħti l-kunsens tiegħek, jew taċċessa informazzjoni aktar iddettaljata u tibdel il-preferenzi tiegħek qabel ma tagħti l-kunsens. Il-preferenzi tiegħek se japplikaw għal dan is-sit web biss. Jekk jogħġbok innota li xi pproċessar tad-data personali tiegħek jista’ ma jirrikjedix il-kunsens tiegħek, iżda għandek id-dritt li toġġezzjona għal tali pproċessar. Tista’ tibdel il-preferenzi tiegħek fi kwalunkwe ħin billi tirritorna f’dan is-sit jew iżżur il-politika tal-privatezza tagħna.",
      initScreenBodyGroup: "Aħna u l-imsieħba tagħna naħżnu jew naċċessaw informazzjoni fuq apparati, bħal cookies u nipproċessaw data personali, bħal identifikaturi uniċi u informazzjoni standard mibgħuta minn apparat għall-finijiet deskritti hawn taħt. Tista’ tikklikkja biex tagħti l-kunsens tiegħek għall-ipproċessar minna u mill-imsieħba tagħna għal tali skopijiet. Inkella, tista' tikklikkja biex tirrifjuta li tagħti l-kunsens tiegħek, jew taċċessa informazzjoni aktar iddettaljata u tibdel il-preferenzi tiegħek qabel ma tagħti l-kunsens. Il-preferenzi tiegħek se japplikaw għal grupp ta' siti web [links għad-dominju fejn il-proprjetajiet kollha huma elenkati għal din il-konfigurazzjoni tal-grupp]. Jekk jogħġbok innota li xi pproċessar tad-data personali tiegħek jista’ ma jirrikjedix il-kunsens tiegħek, iżda għandek id-dritt li toġġezzjona għal tali pproċessar. Tista’ tibdel il-preferenzi tiegħek fi kwalunkwe ħin billi tirritorna f’dan is-sit jew iżżur l-istqarrija ta’ privatezza tagħna.",
      specialPurposesAndFeatures: 'Għanijiet u Karatteristiċi Speċjali',
      saveAndExitButton: 'ISSEJVJA U OĦROĠ',
      purposeScreenVendorLink: 'IMSIEĦBA',
      legitimateInterestLink: 'INTERESS LEĠITTIMU ',
      specialPurposesLabel: 'Għanijiet Speċjali',
      specialFeaturesLabel: 'Karatteristiċi Speċjali',
      featuresLabel: 'Karatteristiċi',
      back: 'Lura',
      onLabel: 'MIXGĦUL',
      offLabel: 'MITFI',
      multiLabel: 'MULTI',
      legalDescription: 'Deskrizzjoni Legali',
      showPartners: 'Uri l-Imsieħba',
      hidePartners: 'Aħbi l-Imsieħba',
      vendorScreenBody: 'Irrevedi u ssettja l-preferenzi tal-kunsens tiegħek għal kull imsieħeb hawn taħt. Espandi kull punt tal-lista tal-imsieħeb għal aktar informazzjoni biex jgħinuk tagħmel l-għażla tiegħek. Xi data personali tiġi pproċessata mingħajr il-kunsens tiegħek, iżda għandek id-dritt li toġġezzjona.',
      privacyPolicyLabel: 'Politika tal-Privatezza',
      descriptionLabel: 'Deskrizzjoni tal-Bejjiegħ',
      legitimateScreenBody: 'Irrevedi u oġġezzjona għall-ipproċessar ta’ data personali mingħajr il-kunsens tiegħek fuq il-bażi ta’ interess leġittimu għal kull għan u minn kull imsieħeb hawn taħt. Espandi kull għan jew punt tal-lista tal-imsieħba għal aktar informazzjoni biex jgħinuk tagħmel l-għażla tiegħek. Biex toġġezzjona għall-għanijiet speċjali li tiġi żgurata s-sigurtà, tiġi evitata l-frodi, u d-debugging, u għat-twassil tekniku ta’ reklami jew kontenut, ikklikkja fuq link tal-politika tal-privatezza tal-imsieħeb.',
      legitimateInterestPurposesLabel: 'Għan(ijiet) ta’ Interess Leġittimu',
      legitimateInterestVendorLabel: 'Bejjiegħa ta’ Interess Leġittimu',
      legitimateScreenObject: 'OĠĠEZZJONI',
      legitimateScreenObjected: 'OĠĠEZZJONAT',
      legitimateScreenAccept: 'NEĦĦI L-OĠĠEZZJONI',
      objectAllButton: 'OĠĠEZZJONA GĦAL KOLLOX',
      persistentConsentLinkLabel: 'Privatezza',
      nonIabVendorsNotice: 'Bejjiegħa li ma jipparteċipawx fil-Qafas ta’ Trasparenza u Kunsens ta’ IAB Europe u ma jikkonformawx mal-politiki jew l-ispeċifikazzjonijiet tekniċi tagħha',
      googlePartners: "Imsieħba ta' Google",
      purposesLabel: 'Skopijiet (Kunsens)'
    },
    premiumUiLabels: {
      linksTitle: 'Links Addizzjonali',
      nonIabVendorsLabel: 'Bejjiegħa mhux tal-IAB'
    }
  },

  // Lithuanian
  lt: {
    agree: 'SUTINKU',
    saveAndExit: 'ĮRAŠYTI IR IŠEITI',
    disagree: 'NESUTINKU',
    objectAll: 'NESUTIKTI SU VISKUO',
    mockPurposesCopies: {
      headline: 'Gerbiame jūsų privatumą',
      body: 'You can set your consent preferences and determine how you want your data to be used based on the purposes below. You may set your preferences for us independently from those of third-party partners. Each purpose has a description so that you know how we and partners use your data.',
      accept: 'Accept all',
      vendorLink: 'See Vendors',
      saveExit: 'Save & Exit',
      cancel: 'Cancel',
      consentLinkLabel: 'Privacy'
    },
    mockHeadlinesCopies: {
      headline: 'Gerbiame jūsų privatumą',
      accept: 'I accept',
      reject: 'I do not accept',
      showPurposes: 'Show Purposes',
    },
    coreUiLabels: {
      initScreenTitle: 'Gerbiame jūsų privatumą',
      agreeButton: 'SUTINKU',
      initScreenRejectButton: 'NESUTINKU',
      initScreenSettingsButton: 'DAUGIAU PASIRINKIMŲ',
      summaryScreenBodyNoRejectService: [
        'Mes ir mūsų partneriai saugo ir (arba) pasiekia įrenginyje esančią informaciją, tokią kaip slapukai, ir tvarko asmens duomenis, tokius kaip unikalūs identifikatoriai ir standartinė įrenginio siunčiama informacija, kad galėtų rodyti pagal asmeninius poreikius pritaikytą reklamą ir turinį, vertinti reklamą ir turinį bei gauti įžvalgų apie auditoriją, taip pat tobulinti ir gerinti savo produktų kokybę.',
        ' Gavę jūsų sutikimą mes ir mūsų partneriai gali naudoti tikslius geografinės padėties ir identifikavimo duomenis nuskaitę įrenginį. Galite spustelėti, kad sutinkate, jog mes ir mūsų partneriai tvarkytų jūsų asmens duomenis, kaip aprašyta anksčiau. Arba, priešingai, galite gauti išsamesnės informacijos ir pakeisti savo nuostatas prieš sutikdami arba atsisakyti sutikti. Atkreipkite dėmesį, kad tvarkant kai kuriuos jūsų asmens duomenis jūsų sutikimas nėra būtinas, tačiau turite teisę nesutikti, kad duomenys būtų tvarkomi. Jūsų nuostatos bus taikomos tik šiai svetainei. Galite pakeisti savo nuostatas bet kuriuo metu grįžę į šią svetainę arba apsilankę puslapyje, kuriame pateikiama mūsų privatumo politika.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Mes ir mūsų partneriai saugo ir (arba) pasiekia įrenginyje esančią informaciją, tokią kaip slapukai, ir tvarko asmens duomenis, tokius kaip unikalūs identifikatoriai ir standartinė įrenginio siunčiama informacija, kad galėtų rodyti pagal asmeninius poreikius pritaikytą reklamą ir turinį, vertinti reklamą ir turinį bei gauti įžvalgų apie auditoriją, taip pat tobulinti ir gerinti savo produktų kokybę.',
        ' Gavę jūsų sutikimą mes ir mūsų partneriai gali naudoti tikslius geografinės padėties ir identifikavimo duomenis nuskaitę įrenginį. Galite spustelėti, kad sutinkate, jog mes ir mūsų partneriai tvarkytų jūsų asmens duomenis, kaip aprašyta anksčiau. Arba, priešingai, galite gauti išsamesnės informacijos ir pakeisti savo nuostatas prieš sutikdami arba atsisakyti sutikti. Atkreipkite dėmesį, kad tvarkant kai kuriuos jūsų asmens duomenis jūsų sutikimas nėra būtinas, tačiau turite teisę nesutikti, kad duomenys būtų tvarkomi. Jūsų nuostatos bus taikomos visame žiniatinklyje. Galite pakeisti savo nuostatas bet kuriuo metu grįžę į šią svetainę arba apsilankę puslapyje, kuriame pateikiama mūsų privatumo politika.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Mes ir mūsų partneriai saugo ir (arba) pasiekia įrenginyje esančią informaciją, tokią kaip slapukai, ir tvarko asmens duomenis, tokius kaip unikalūs identifikatoriai ir standartinė įrenginio siunčiama informacija, kad galėtų rodyti pagal asmeninius poreikius pritaikytą reklamą ir turinį, vertinti reklamą ir turinį bei gauti įžvalgų apie auditoriją, taip pat tobulinti ir gerinti savo produktų kokybę.',
        ' Gavę jūsų sutikimą mes ir mūsų partneriai gali naudoti tikslius geografinės padėties ir identifikavimo duomenis nuskaitę įrenginį. Galite spustelėti, kad sutinkate, jog mes ir mūsų partneriai tvarkytų jūsų asmens duomenis, kaip aprašyta anksčiau. Arba, priešingai, galite gauti išsamesnės informacijos ir pakeisti savo nuostatas prieš sutikdami arba atsisakyti sutikti. Atkreipkite dėmesį, kad tvarkant kai kuriuos jūsų asmens duomenis jūsų sutikimas nėra būtinas, tačiau turite teisę nesutikti, kad duomenys būtų tvarkomi. Jūsų nuostatos bus taikomos svetainių grupei [saitas į sritį, kurioje visos šios grupės konfigūracijos savybės yra išvardytos]. Galite pakeisti savo nuostatas bet kuriuo metu grįžę į šią svetainę arba apsilankę puslapyje, kuriame pateikiama mūsų privatumo politika.'
      ],
      summaryScreenBodyRejectService: [
        'Mes ir mūsų partneriai saugo ir (arba) pasiekia įrenginyje esančią informaciją, tokią kaip slapukai, ir tvarko asmens duomenis, tokius kaip unikalūs identifikatoriai ir standartinė įrenginio siunčiama informacija, kad galėtų rodyti pagal asmeninius poreikius pritaikytą reklamą ir turinį, vertinti reklamą ir turinį bei gauti įžvalgų apie auditoriją, taip pat tobulinti ir gerinti savo produktų kokybę.',
        ' Gavę jūsų sutikimą mes ir mūsų partneriai gali naudoti tikslius geografinės padėties ir identifikavimo duomenis nuskaitę įrenginį. Galite spustelėti, kad sutinkate, jog mes ir mūsų partneriai tvarkytų jūsų asmens duomenis, kaip aprašyta anksčiau. Arba, priešingai, galite spustelėti, kad nesutinkate, arba gauti išsamesnės informacijos ir pakeisti savo nuostatas prieš sutikdami. Atkreipkite dėmesį, kad tvarkant kai kuriuos jūsų asmens duomenis jūsų sutikimas nėra būtinas, tačiau turite teisę nesutikti, kad duomenys būtų tvarkomi. Jūsų nuostatos bus taikomos tik šiai svetainei. Galite pakeisti savo nuostatas bet kuriuo metu grįžę į šią svetainę arba apsilankę puslapyje, kuriame pateikiama mūsų privatumo politika.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Mes ir mūsų partneriai saugo ir (arba) pasiekia įrenginyje esančią informaciją, tokią kaip slapukai, ir tvarko asmens duomenis, tokius kaip unikalūs identifikatoriai ir standartinė įrenginio siunčiama informacija, kad galėtų rodyti pagal asmeninius poreikius pritaikytą reklamą ir turinį, vertinti reklamą ir turinį bei gauti įžvalgų apie auditoriją, taip pat tobulinti ir gerinti savo produktų kokybę.',
        ' Gavę jūsų sutikimą mes ir mūsų partneriai gali naudoti tikslius geografinės padėties ir identifikavimo duomenis nuskaitę įrenginį. Galite spustelėti, kad sutinkate, jog mes ir mūsų partneriai tvarkytų jūsų asmens duomenis, kaip aprašyta anksčiau. Arba, priešingai, galite spustelėti, kad nesutinkate, arba gauti išsamesnės informacijos ir pakeisti savo nuostatas prieš sutikdami. Atkreipkite dėmesį, kad tvarkant kai kuriuos jūsų asmens duomenis jūsų sutikimas nėra būtinas, tačiau turite teisę nesutikti, kad duomenys būtų tvarkomi. Jūsų nuostatos bus taikomos visame žiniatinklyje. Galite pakeisti savo nuostatas bet kuriuo metu grįžę į šią svetainę arba apsilankę puslapyje, kuriame pateikiama mūsų privatumo politika.'
      ],
      summaryScreenBodyRejectGroup: [
        'Mes ir mūsų partneriai saugo ir (arba) pasiekia įrenginyje esančią informaciją, tokią kaip slapukai, ir tvarko asmens duomenis, tokius kaip unikalūs identifikatoriai ir standartinė įrenginio siunčiama informacija, kad galėtų rodyti pagal asmeninius poreikius pritaikytą reklamą ir turinį, vertinti reklamą ir turinį bei gauti įžvalgų apie auditoriją, taip pat tobulinti ir gerinti savo produktų kokybę.',
        ' Gavę jūsų sutikimą mes ir mūsų partneriai gali naudoti tikslius geografinės padėties ir identifikavimo duomenis nuskaitę įrenginį. Galite spustelėti, kad sutinkate, jog mes ir mūsų partneriai tvarkytų jūsų asmens duomenis, kaip aprašyta anksčiau. Arba, priešingai, galite spustelėti, kad nesutinkate, arba gauti išsamesnės informacijos ir pakeisti savo nuostatas prieš sutikdami. Atkreipkite dėmesį, kad tvarkant kai kuriuos jūsų asmens duomenis jūsų sutikimas nėra būtinas, tačiau turite teisę nesutikti, kad duomenys būtų tvarkomi. Jūsų nuostatos bus taikomos svetainių grupei [nuorodos į sritį, kurioje visos šios grupės konfigūracijos savybės yra išvardytos]. Galite pakeisti savo nuostatas bet kuriuo metu grįžę į šią svetainę arba apsilankę puslapyje, kuriame pateikiama mūsų privatumo politika.'
      ],
      initScreenBodyGlobal: 'Mes ir mūsų partneriai saugo ir (arba) pasiekia įrenginyje esančią informaciją, tokią kaip slapukai, ir apdoroja asmens duomenis, tokius kaip unikalūs identifikatoriai ir standartinė įrenginio siunčiama informacija, skirta toliau nurodytiems tikslams. Galite spustelėti, kad sutinkate, jog mes ir mūsų partneriai tvarkytų jūsų asmens duomenis šiais tikslais. Arba, priešingai, galite spustelėti, kad nesutinkate, arba gauti išsamesnės informacijos, kad galėtumėte pakeisti savo nuostatas prieš sutikdami. Jūsų nuostatos bus taikomos visame žiniatinklyje. Atkreipkite dėmesį, kad tvarkant kai kuriuos jūsų asmens duomenis jūsų sutikimas nėra būtinas, tačiau turite teisę nesutikti, kad duomenys būtų tvarkomi. Galite pakeisti savo nuostatas bet kuriuo metu grįžę į šią svetainę arba apsilankę puslapyje, kuriame pateikiama mūsų privatumo politika.',
      initScreenBodyService: 'Mes ir mūsų partneriai saugo ir (arba) pasiekia įrenginyje esančią informaciją, tokią kaip slapukai, ir apdoroja asmens duomenis, tokius kaip unikalūs identifikatoriai ir standartinė įrenginio siunčiama informacija, skirta toliau nurodytiems tikslams. Galite spustelėti, kad sutinkate, jog mes ir mūsų partneriai tvarkytų jūsų asmens duomenis šiais tikslais. Arba, priešingai, galite spustelėti, kad nesutinkate, arba gauti išsamesnės informacijos ir pakeisti savo nuostatas prieš sutikdami. Jūsų nuostatos bus taikomos tik šiai interneto svetainei. Atkreipkite dėmesį, kad tvarkant kai kuriuos jūsų asmens duomenis jūsų sutikimas nėra būtinas, tačiau turite teisę nesutikti, kad duomenys būtų tvarkomi. Galite pakeisti savo nuostatas bet kuriuo metu grįžę į šią svetainę arba apsilankę puslapyje, kuriame pateikiama mūsų privatumo politika.',
      initScreenBodyGroup: 'Mes ir mūsų partneriai saugo ir (arba) pasiekia įrenginyje esančią informaciją, tokią kaip slapukai, ir apdoroja asmens duomenis, tokius kaip unikalūs identifikatoriai ir standartinė įrenginio siunčiama informacija, skirta toliau nurodytiems tikslams. Galite spustelėti, kad sutinkate, jog mes ir mūsų partneriai tvarkytų jūsų asmens duomenis šiais tikslais. Arba, priešingai, galite spustelėti, kad nesutinkate, arba gauti išsamesnės informacijos ir pakeisti savo nuostatas prieš sutikdami. Jūsų nuostatos bus taikomos interneto svetainių grupei [nuorodos į sritį, kurioje visos šios grupės konfigūracijos savybės yra išvardytos]. Atkreipkite dėmesį, kad tvarkant kai kuriuos jūsų asmens duomenis jūsų sutikimas nėra būtinas, tačiau turite teisę nesutikti, kad duomenys būtų tvarkomi. Galite pakeisti savo nuostatas bet kuriuo metu grįžę į šią svetainę arba apsilankę puslapyje, kuriame pateikiama mūsų privatumo politika.',
      specialPurposesAndFeatures: 'Konkretūs tikslai ir funkcijos',
      saveAndExitButton: 'ĮRAŠYTI IR IŠEITI',
      purposeScreenVendorLink: 'PARTNERIAI',
      legitimateInterestLink: 'TEISĖTAS INTERESAS ',
      specialPurposesLabel: 'Specialieji tikslai',
      specialFeaturesLabel: 'Specialiosios funkcijos',
      featuresLabel: 'Funkcijos',
      back: 'Atgal',
      onLabel: 'ĮJUNGTI',
      offLabel: 'IŠJUNGTI',
      multiLabel: 'DAUGIAFUNKCIS',
      legalDescription: 'Teisinis aprašas',
      showPartners: 'Rodyti partnerius',
      hidePartners: 'Slėpti partnerius',
      vendorScreenBody: 'Peržiūrėkite ir nustatykite savo sutikimo nuostatas kiekvienam partneriui toliau. Išskleiskite kiekvieną partnerių sąrašo elementą, kad gautumėte daugiau informacijos ir galėtumėte pasirinkti. Kai kurie asmens duomenys gali būti tvarkomi be jūsų sutikimo, bet jūs turite teisę nesutikti.',
      privacyPolicyLabel: 'Privatumo politika',
      descriptionLabel: 'Tiekėjo aprašas',
      legitimateScreenBody: 'Peržiūrėkite ir nesutikite, kad asmens duomenys būtų tvarkomi be jūsų sutikimo remdamiesi teisėtu interesu kiekvienam tikslui ir kiekvieno toliau pateikiamo partnerio interesu. Išskleiskite kiekvieną tikslą arba partnerių sąrašo elementą, kad gautumėte daugiau informacijos ir galėtumėte pasirinkti. Norėdami nesutikti su konkrečiais tikslais, skirtais saugumui užtikrinti, išvengti sukčiavimo ir klaidų taisymo bei techniškai pateikti skelbimus ar turinį, spustelėkite nuorodą į partnerio privatumo politiką.',
      legitimateInterestPurposesLabel: 'Teisėto intereso tikslas (-ai)',
      legitimateInterestVendorLabel: 'Teisėto intereso tiekėjai',
      legitimateScreenObject: 'NESUTIKTI (vertimo užuomina: veiksmažodis „nesutikti“)',
      legitimateScreenObjected: 'NESUTINKAMA',
      legitimateScreenAccept: 'PAŠALINTI NESUTIKIMĄ',
      objectAllButton: 'NESUTIKTI SU VISKUO',
      persistentConsentLinkLabel: 'Privatumas',
      nonIabVendorsNotice: 'Tiekėjai, nedalyvaujantys „IAB Europe“ skaidrumo ir sutikimo sistemoje ir nesilaikantys jų politikos arba techninių specifikacijų',
      googlePartners: '„Google“ partneriai',
      purposesLabel: 'Tikslai (sutikimas)'
    },
    premiumUiLabels: {
      linksTitle: 'Papildomos nuorodos',
      nonIabVendorsLabel: 'Ne IAB tiekėjai'
    }
  },

  // Latvian
  lv: {
    agree: 'PIEKRĪTU',
    saveAndExit: 'SAGLABĀT UN IZIET',
    disagree: 'NEPIEKRĪTU',
    objectAll: 'IEBILST PRET VISU',
    mockPurposesCopies: {
      headline: 'Mēs novērtējam jūsu privātumu',
      body: 'You can set your consent preferences and determine how you want your data to be used based on the purposes below. You may set your preferences for us independently from those of third-party partners. Each purpose has a description so that you know how we and partners use your data.',
      accept: 'Accept all',
      vendorLink: 'See Vendors',
      saveExit: 'Save & Exit',
      cancel: 'Cancel',
      consentLinkLabel: 'Privacy'
    },
    mockHeadlinesCopies: {
      headline: 'Mēs novērtējam jūsu privātumu',
      accept: 'I accept',
      reject: 'I do not accept',
      showPurposes: 'Show Purposes',
    },
    coreUiLabels: {
      initScreenTitle: 'Mēs novērtējam jūsu privātumu',
      agreeButton: 'PIEKRĪTU',
      initScreenRejectButton: 'NEPIEKRĪTU',
      initScreenSettingsButton: 'PAPILDU OPCIJAS',
      summaryScreenBodyNoRejectService: [
        'Mēs un mūsu partneri glabājam informāciju un/vai piekļūstam tai ierīcē, piemēram, sīkfailiem, kā arī apstrādājam personas datus, piemēram, unikālos identifikatorus un standarta informāciju, ko ierīce nosūta personalizētu reklāmu un satura ģenerēšanai, reklāmu un satura mērījumiem, auditorijas datu apkopošanai, kā arī produktu izstrādei un uzlabošanai.',
        ' Ar jūsu atļauju mēs un mūsu partneri varam izmantot precīzus ģeogrāfiskās atrašanās vietas datus un identifikāciju, veicot ierīces skenēšanu. Jūs varat noklikšķināt, lai sniegtu piekrišanu mūsu un mūsu partneru veiktajai apstrādei, kā aprakstīts iepriekš. Jūs varat arī piekļūt detalizētākai informācijai un mainīt savas preferences, pirms sniedzat piekrišanu vai to liedzat. Lūdzu, ņemiet vērā, ka noteiktai jūsu personas datu apstrādei var nebūt nepieciešama jūsu piekrišana, bet jums ir tiesības iebilst pret šādu apstrādi. Jūsu preferences tiks piemērotas tikai šajā tīmekļa vietnē. Jūs varat jebkurā brīdī mainīt savas preferences, atgriežoties šajā vietnē vai apmeklējot mūsu privātuma politiku.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Mēs un mūsu partneri glabājam informāciju un/vai piekļūstam tai ierīcē, piemēram, sīkfailiem, kā arī apstrādājam personas datus, piemēram, unikālos identifikatorus un standarta informāciju, ko ierīce nosūta personalizētu reklāmu un satura ģenerēšanai, reklāmu un satura mērījumiem, auditorijas datu apkopošanai, kā arī produktu izstrādei un uzlabošanai. ',
        'Ar jūsu atļauju mēs un mūsu partneri varam izmantot precīzus ģeogrāfiskās atrašanās vietas datus un identifikāciju, veicot ierīces skenēšanu. Jūs varat noklikšķināt, lai sniegtu piekrišanu mūsu un mūsu partneru veiktajai apstrādei, kā aprakstīts iepriekš. Jūs varat arī piekļūt detalizētākai informācijai un mainīt savas preferences, pirms sniedzat piekrišanu vai to liedzat. Lūdzu, ņemiet vērā, ka noteiktai jūsu personas datu apstrādei var nebūt nepieciešama jūsu piekrišana, bet jums ir tiesības iebilst pret šādu apstrādi. Jūsu preferences tiks piemērotas visā tīmeklī. Jūs varat jebkurā brīdī mainīt savas preferences, atgriežoties šajā vietnē vai apmeklējot mūsu privātuma politiku.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Mēs un mūsu partneri glabājam informāciju un/vai piekļūstam tai ierīcē, piemēram, sīkfailiem, kā arī apstrādājam personas datus, piemēram, unikālos identifikatorus un standarta informāciju, ko ierīce nosūta personalizētu reklāmu un satura ģenerēšanai, reklāmu un satura mērījumiem, auditorijas datu apkopošanai, kā arī produktu izstrādei un uzlabošanai.',
        ' Ar jūsu atļauju mēs un mūsu partneri varam izmantot precīzus ģeogrāfiskās atrašanās vietas datus un identifikāciju, veicot ierīces skenēšanu. Jūs varat noklikšķināt, lai sniegtu piekrišanu mūsu un mūsu partneru veiktajai apstrādei, kā aprakstīts iepriekš. Jūs varat arī piekļūt detalizētākai informācijai un mainīt savas preferences, pirms sniedzat piekrišanu vai to liedzat. Lūdzu, ņemiet vērā, ka noteiktai jūsu personas datu apstrādei var nebūt nepieciešama jūsu piekrišana, bet jums ir tiesības iebilst pret šādu apstrādi. Jūsu preferences tiks piemērotas tīmekļa vietņu grupā [hipersaite uz domēnu, kurā ir norādīti visi rekvizīti šai grupas konfigurācijai]. Jūs varat jebkurā brīdī mainīt savas preferences, atgriežoties šajā vietnē vai apmeklējot mūsu privātuma politiku.'
      ],
      summaryScreenBodyRejectService: [
        'Mēs un mūsu partneri glabājam informāciju un/vai piekļūstam tai ierīcē, piemēram, sīkfailiem, kā arī apstrādājam personas datus, piemēram, unikālos identifikatorus un standarta informāciju, ko ierīce nosūta personalizētu reklāmu un satura ģenerēšanai, reklāmu un satura mērījumiem, auditorijas datu apkopošanai, kā arī produktu izstrādei un uzlabošanai.',
        ' Ar jūsu atļauju mēs un mūsu partneri varam izmantot precīzus ģeogrāfiskās atrašanās vietas datus un identifikāciju, veicot ierīces skenēšanu. Jūs varat noklikšķināt, lai sniegtu piekrišanu mūsu un mūsu partneru veiktajai apstrādei, kā aprakstīts iepriekš. Jūs varat arī noklikšķināt, lai liegtu piekrišanu vai piekļūtu detalizētākai informācijai un pirms piekrišanas sniegšanas mainītu savas preferences. Lūdzu, ņemiet vērā, ka noteiktai jūsu personas datu apstrādei var nebūt nepieciešama jūsu piekrišana, bet jums ir tiesības iebilst pret šādu apstrādi. Jūsu preferences tiks piemērotas tikai šajā tīmekļa vietnē. Jūs varat jebkurā brīdī mainīt savas preferences, atgriežoties šajā vietnē vai apmeklējot mūsu privātuma politiku.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Mēs un mūsu partneri glabājam informāciju un/vai piekļūstam tai ierīcē, piemēram, sīkfailiem, kā arī apstrādājam personas datus, piemēram, unikālos identifikatorus un standarta informāciju, ko ierīce nosūta personalizētu reklāmu un satura ģenerēšanai, reklāmu un satura mērījumiem, auditorijas datu apkopošanai, kā arī produktu izstrādei un uzlabošanai.',
        ' Ar jūsu atļauju mēs un mūsu partneri varam izmantot precīzus ģeogrāfiskās atrašanās vietas datus un identifikāciju, veicot ierīces skenēšanu. Jūs varat noklikšķināt, lai sniegtu piekrišanu mūsu un mūsu partneru veiktajai apstrādei, kā aprakstīts iepriekš. Jūs varat arī noklikšķināt, lai liegtu piekrišanu vai piekļūtu detalizētākai informācijai un pirms piekrišanas sniegšanas mainītu savas preferences. Lūdzu, ņemiet vērā, ka noteiktai jūsu personas datu apstrādei var nebūt nepieciešama jūsu piekrišana, bet jums ir tiesības iebilst pret šādu apstrādi. Jūsu preferences tiks piemērotas visā tīmeklī. Jūs varat jebkurā brīdī mainīt savas preferences, atgriežoties šajā vietnē vai apmeklējot mūsu privātuma politiku.'
      ],
      summaryScreenBodyRejectGroup: [
        'Mēs un mūsu partneri glabājam informāciju un/vai piekļūstam tai ierīcē, piemēram, sīkfailiem, kā arī apstrādājam personas datus, piemēram, unikālos identifikatorus un standarta informāciju, ko ierīce nosūta personalizētu reklāmu un satura ģenerēšanai, reklāmu un satura mērījumiem, auditorijas datu apkopošanai, kā arī produktu izstrādei un uzlabošanai.',
        ' Ar jūsu atļauju mēs un mūsu partneri varam izmantot precīzus ģeogrāfiskās atrašanās vietas datus un identifikāciju, veicot ierīces skenēšanu. Jūs varat noklikšķināt, lai sniegtu piekrišanu mūsu un mūsu partneru veiktajai apstrādei, kā aprakstīts iepriekš. Jūs varat arī noklikšķināt, lai liegtu piekrišanu vai piekļūtu detalizētākai informācijai un pirms piekrišanas sniegšanas mainītu savas preferences. Lūdzu, ņemiet vērā, ka noteiktai jūsu personas datu apstrādei var nebūt nepieciešama jūsu piekrišana, bet jums ir tiesības iebilst pret šādu apstrādi. Jūsu preferences tiks piemērotas tīmekļa vietņu grupā [saites uz domēnu, kurā ir norādīti visi rekvizīti šai grupas konfigurācijai]. Jūs varat jebkurā brīdī mainīt savas preferences, atgriežoties šajā vietnē vai apmeklējot mūsu privātuma politiku.'
      ],
      initScreenBodyGlobal: 'Mēs un mūsu partneri glabājam informāciju vai piekļūstam tai ierīcēs, piemēram, sīkfailiem, un apstrādājam personas datus, piemēram, unikālos identifikatorus un standarta informāciju, ko ierīce nosūtījusi iepriekš aprakstītajiem nolūkiem. Jūs varat noklikšķināt, lai sniegtu piekrišanu mūsu un mūsu partneru veiktajai apstrādei šādiem nolūkiem. Jūs varat arī noklikšķināt, lai liegtu piekrišanu vai piekļūtu detalizētākai informācijai, kā arī lai mainītu savas preferences, pirms sniegt piekrišanu. Jūsu preferences tiks piemērotas visā tīmeklī. Lūdzu, ņemiet vērā, ka noteiktai jūsu personas datu apstrādei var nebūt nepieciešama jūsu piekrišana, bet jums ir tiesības iebilst pret šādu apstrādi. Jūs varat jebkurā brīdī mainīt savas preferences, atgriežoties šajā vietnē vai apmeklējot mūsu privātuma politiku.',
      initScreenBodyService: 'Mēs un mūsu partneri glabājam informāciju vai piekļūstam tai ierīcēs, piemēram, sīkfailiem, un apstrādājam personas datus, piemēram, unikālos identifikatorus un standarta informāciju, ko ierīce nosūtījusi iepriekš aprakstītajiem nolūkiem. Jūs varat noklikšķināt, lai sniegtu piekrišanu mūsu un mūsu partneru veiktajai apstrādei šādiem nolūkiem. Jūs varat arī noklikšķināt, lai liegtu piekrišanu vai piekļūtu detalizētākai informācijai, kā arī lai mainītu savas preferences, pirms sniegt piekrišanu. Jūsu preferences tiks piemērotas tikai šajā tīmekļa vietnē. Lūdzu, ņemiet vērā, ka noteiktai jūsu personas datu apstrādei var nebūt nepieciešama jūsu piekrišana, bet jums ir tiesības iebilst pret šādu apstrādi. Jūs varat jebkurā brīdī mainīt savas preferences, atgriežoties šajā vietnē vai apmeklējot mūsu privātuma politiku.',
      initScreenBodyGroup: 'Mēs un mūsu partneri glabājam informāciju vai piekļūstam tai ierīcēs, piemēram, sīkfailiem, un apstrādājam personas datus, piemēram, unikālos identifikatorus un standarta informāciju, ko ierīce nosūtījusi iepriekš aprakstītajiem nolūkiem. Jūs varat noklikšķināt, lai sniegtu piekrišanu mūsu un mūsu partneru veiktajai apstrādei šādiem nolūkiem. Jūs varat arī noklikšķināt, lai liegtu piekrišanu vai piekļūtu detalizētākai informācijai, kā arī lai mainītu savas preferences, pirms sniegt piekrišanu. Jūsu preferences tiks piemērotas tīmekļa vietņu grupā [saites uz domēnu, kurā ir norādīti visi rekvizīti šai grupas konfigurācijai]. Lūdzu, ņemiet vērā, ka noteiktai jūsu personas datu apstrādei var nebūt nepieciešama jūsu piekrišana, bet jums ir tiesības iebilst pret šādu apstrādi. Jūs varat jebkurā brīdī mainīt savas preferences, atgriežoties šajā vietnē vai apmeklējot mūsu privātuma politiku.',
      specialPurposesAndFeatures: 'Īpaši nolūki un funkcijas',
      saveAndExitButton: 'SAGLABĀT UN IZIET',
      purposeScreenVendorLink: 'PARTNERI',
      legitimateInterestLink: 'LIKUMISKĀ INTERESE ',
      specialPurposesLabel: 'Īpašie nolūki',
      specialFeaturesLabel: 'Īpašās funkcijas',
      featuresLabel: 'Funkcijas',
      back: 'Atpakaļ',
      onLabel: 'IESLĒGTS',
      offLabel: 'IZSLĒGTS',
      multiLabel: 'VAIRĀKI',
      legalDescription: 'Juridiskais apraksts',
      showPartners: 'Rādīt partnerus',
      hidePartners: 'Slēpt partnerus',
      vendorScreenBody: 'Pārskatiet un iestatiet savas piekrišanas preferences katram partnerim. Izvērsiet katru partnera saraksta vienumu, lai iegūtu papildinformāciju, kas palīdzēs veikt izvēli. Noteikti personas dati tiek apstrādāti bez jūsu piekrišanas, bet jums ir tiesības iebilst pret to.',
      privacyPolicyLabel: 'Privātuma politika',
      descriptionLabel: 'Piegādātāja apraksts',
      legitimateScreenBody: 'Pārskatiet un iebilstiet pret personas datu apstrādi bez jūsu piekrišanas, pamatojoties uz katra nolūka likumisko interesi, kā arī katram attiecīgajam partnerim. Izvērsiet katru nolūku vai partnera saraksta vienumu, lai iegūtu papildinformāciju, kas palīdzēs veikt izvēli. Lai iebilstu pret īpašajiem nolūkiem attiecībā uz drošības garantēšanu, krāpšanas novēršanu, atkļūdošanu un reklāmu vai satura tehnisko nodrošināšanu, noklikšķiniet uz partnera privātuma politikas saites.',
      legitimateInterestPurposesLabel: 'Likumiskās intereses nolūks(-i)',
      legitimateInterestVendorLabel: 'Likumiskās intereses piegādātāji',
      legitimateScreenObject: 'IEBILST',
      legitimateScreenObjected: 'IEBILSTS',
      legitimateScreenAccept: 'NOŅEMT IEBILDUMU',
      objectAllButton: 'IEBILST PRET VISU',
      persistentConsentLinkLabel: 'Privātums',
      nonIabVendorsNotice: 'Piegādātāji, kuri nepiedalās “IAB Europe” pārredzamības un piekrišanas ietvarā, kā arī neatbilst tā politikai vai tehniskajām specifikācijām',
      googlePartners: 'Google partneri',
      purposesLabel: 'Mērķi (piekrišana)'
    },
    premiumUiLabels: {
      linksTitle: 'Papildu saites',
      nonIabVendorsLabel: 'IAB nepiederoši piegādātāji'
    }
  },
  // Estonian
  et: {
    agree: 'NÕUSTUN',
    saveAndExit: 'SALVESTA JA VÄLJU',
    disagree: 'EI NÕUSTU',
    objectAll: 'KEELDU KÕIGEST',
    mockPurposesCopies: {
      headline: 'Me hindame teie privaatsust',
      body: 'You can set your consent preferences and determine how you want your data to be used based on the purposes below. You may set your preferences for us independently from those of third-party partners. Each purpose has a description so that you know how we and partners use your data.',
      accept: 'Accept all',
      vendorLink: 'See Vendors',
      saveExit: 'Save & Exit',
      cancel: 'Cancel',
      consentLinkLabel: 'Privacy'
    },
    mockHeadlinesCopies: {
      headline: 'Me hindame teie privaatsust',
      accept: 'I accept',
      reject: 'I do not accept',
      showPurposes: 'Show Purposes',
    },
    coreUiLabels: {
      initScreenTitle: 'Me hindame teie privaatsust',
      agreeButton: 'NÕUSTUN',
      initScreenRejectButton: 'EI NÕUSTU',
      initScreenSettingsButton: 'ROHKEM VÕIMALUSI',
      summaryScreenBodyNoRejectService: [
        'Meie ja meie partnerid salvestame seadmes olevat teavet ja/või pääseme juurde seadmes olevale teabele, nagu küpsised, ja töötleme isikuandmeid, nagu kordumatud identifikaatorid ja seadme poolt isikupärastatud reklaamide ja sisu, reklaami ja sisu mõõtmise ning vaatajaskonna analüüside jaoks, samuti toodete arendamiseks ja täiustamiseks saadetud standardne teave.',
        ' Teie loal võime meie ja meie partnerid kasutada täpseid geograafilise asukoha andmeid ja identifitseerimist seadme skannimise teel. Saate klõpsata meie ja meie partnerite eespool kirjeldatud andmetöötlusega nõustumiseks. Teise võimalusena pääsete ligi üksikasjalikumale teabele ja saate muuta oma eelistusi enne nõusoleku andmist või saate sellest keelduda. Pange tähele, et teatud isikuandmete töötlemine ei pruugi eeldada teie nõusolekut, kuid teil on õigus sellisest töötlemisest keelduda. Teie eelistused kehtivad ainult sellel veebisaidil. Saate igal ajal oma eelistuste muutmiseks pöörduda tagasi sellele saidile või külastada meie privaatsuspoliitika lehte.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Meie ja meie partnerid salvestame seadmes olevat teavet ja/või pääseme juurde seadmes olevale teabele, nagu küpsised, ja töötleme isikuandmeid, nagu kordumatud identifikaatorid ja seadme poolt isikupärastatud reklaamide ja sisu, reklaami ja sisu mõõtmise ning vaatajaskonna analüüside jaoks, samuti toodete arendamiseks ja täiustamiseks saadetud standardne teave. ',
        'Teie loal võime meie ja meie partnerid kasutada täpseid geograafilise asukoha andmeid ja identifitseerimist seadme skannimise teel. Saate klõpsata meie ja meie partnerite eespool kirjeldatud andmetöötlusega nõustumiseks. Teise võimalusena pääsete ligi üksikasjalikumale teabele ja saate muuta oma eelistusi enne nõusoleku andmist või saate sellest keelduda. Pange tähele, et teatud isikuandmete töötlemine ei pruugi eeldada teie nõusolekut, kuid teil on õigus sellisest töötlemisest keelduda. Teie eelistused kehtivad kogu veebis. Saate igal ajal oma eelistuste muutmiseks pöörduda tagasi sellele saidile või külastada meie privaatsuspoliitika lehte.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Meie ja meie partnerid salvestame seadmes olevat teavet ja/või pääseme juurde seadmes olevale teabele, nagu küpsised, ja töötleme isikuandmeid, nagu kordumatud identifikaatorid ja seadme poolt isikupärastatud reklaamide ja sisu, reklaami ja sisu mõõtmise ning vaatajaskonna analüüside jaoks, samuti toodete arendamiseks ja täiustamiseks saadetud standardne teave.',
        ' Teie loal võime meie ja meie partnerid kasutada täpseid geograafilise asukoha andmeid ja identifitseerimist seadme skannimise teel. Saate klõpsata meie ja meie partnerite eespool kirjeldatud andmetöötlusega nõustumiseks. Teise võimalusena pääsete ligi üksikasjalikumale teabele ja saate muuta oma eelistusi enne nõusoleku andmist või saate sellest keelduda. Pange tähele, et teatud isikuandmete töötlemine ei pruugi eeldada teie nõusolekut, kuid teil on õigus sellisest töötlemisest keelduda. Teie eelistused kehtivad veebisaitide rühmale [hüperlink domeenile, kus on loetletud kõik selle rühma konfiguratsiooni atribuudid]. Saate igal ajal oma eelistuste muutmiseks pöörduda tagasi sellele saidile või külastada meie privaatsuspoliitika lehte.'
      ],
      summaryScreenBodyRejectService: [
        'Meie ja meie partnerid salvestame seadmes olevat teavet ja/või pääseme juurde seadmes olevale teabele, nagu küpsised, ja töötleme isikuandmeid, nagu kordumatud identifikaatorid ja seadme poolt isikupärastatud reklaamide ja sisu, reklaami ja sisu mõõtmise ning vaatajaskonna analüüside jaoks, samuti toodete arendamiseks ja täiustamiseks saadetud standardne teave.',
        ' Teie loal võime meie ja meie partnerid kasutada täpseid geograafilise asukoha andmeid ja identifitseerimist seadme skannimise teel. Saate klõpsata meie ja meie partnerite eespool kirjeldatud andmetöötlusega nõustumiseks. Teise võimalusena saate klõpsata nõusolekust keeldumiseks või saada üksikasjalikumat teavet ja muuta oma eelistusi enne nõusoleku andmist. Pange tähele, et teatud isikuandmete töötlemine ei pruugi eeldada teie nõusolekut, kuid teil on õigus sellisest töötlemisest keelduda. Teie eelistused kehtivad ainult sellel veebisaidil. Saate igal ajal oma eelistuste muutmiseks pöörduda tagasi sellele saidile või külastada meie privaatsuspoliitika lehte.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Meie ja meie partnerid salvestame seadmes olevat teavet ja/või pääseme juurde seadmes olevale teabele, nagu küpsised, ja töötleme isikuandmeid, nagu kordumatud identifikaatorid ja seadme poolt isikupärastatud reklaamide ja sisu, reklaami ja sisu mõõtmise ning vaatajaskonna analüüside jaoks, samuti toodete arendamiseks ja täiustamiseks saadetud standardne teave.',
        ' Teie loal võime meie ja meie partnerid kasutada täpseid geograafilise asukoha andmeid ja identifitseerimist seadme skannimise teel. Saate klõpsata meie ja meie partnerite eespool kirjeldatud andmetöötlusega nõustumiseks. Teise võimalusena saate klõpsata nõusolekust keeldumiseks või saada üksikasjalikumat teavet ja muuta oma eelistusi enne nõusoleku andmist. Pange tähele, et teatud isikuandmete töötlemine ei pruugi eeldada teie nõusolekut, kuid teil on õigus sellisest töötlemisest keelduda. Teie eelistused kehtivad kogu veebis. Saate igal ajal oma eelistuste muutmiseks pöörduda tagasi sellele saidile või külastada meie privaatsuspoliitika lehte.'
      ],
      summaryScreenBodyRejectGroup: [
        'Meie ja meie partnerid salvestame seadmes olevat teavet ja/või pääseme juurde seadmes olevale teabele, nagu küpsised, ja töötleme isikuandmeid, nagu kordumatud identifikaatorid ja seadme poolt isikupärastatud reklaamide ja sisu, reklaami ja sisu mõõtmise ning vaatajaskonna analüüside jaoks, samuti toodete arendamiseks ja täiustamiseks saadetud standardne teave. ',
        'Teie loal võime meie ja meie partnerid kasutada täpseid geograafilise asukoha andmeid ja identifitseerimist seadme skannimise teel. Saate klõpsata meie ja meie partnerite eespool kirjeldatud andmetöötlusega nõustumiseks. Teise võimalusena saate klõpsata nõusolekust keeldumiseks või saada üksikasjalikumat teavet ja muuta oma eelistusi enne nõusoleku andmist. Pange tähele, et teatud isikuandmete töötlemine ei pruugi eeldada teie nõusolekut, kuid teil on õigus sellisest töötlemisest keelduda. Teie eelistused kehtivad veebisaitide rühmale [lingid domeenile, kus on loetletud kõik selle rühma konfiguratsiooni atribuudid]. Saate igal ajal oma eelistuste muutmiseks pöörduda tagasi sellele saidile või külastada meie privaatsuspoliitika lehte.'
      ],
      initScreenBodyGlobal: 'Meie ja meie partnerid salvestame seadmetes olevat teavet või pääseme juurde seadmete teabele, nagu küpsised, ja töötleme isikuandmeid, nagu kordumatud identifikaatorid ja seadme saadetud standardteave, allpool kirjeldatud eesmärkidel. Saate klõpsata meie ja meie partnerite sellise eesmärgiga andmetöötlusega nõustumiseks. Teise võimalusena saate klõpsata nõusolekust keeldumiseks või saada üksikasjalikumat teavet, et muuta oma eelistusi enne nõusoleku andmist. Teie eelistused kehtivad kogu veebis. Pange tähele, et teatud isikuandmete töötlemine ei pruugi eeldada teie nõusolekut, kuid teil on õigus sellisest töötlemisest keelduda. Saate igal ajal oma eelistuste muutmiseks pöörduda tagasi sellele saidile või külastada meie privaatsuspoliitika lehte.',
      initScreenBodyService: 'Meie ja meie partnerid salvestame seadmetes olevat teavet või pääseme juurde seadmete teabele, nagu küpsised, ja töötleme isikuandmeid, nagu kordumatud identifikaatorid ja seadme saadetud standardteave, allpool kirjeldatud eesmärkidel. Saate klõpsata meie ja meie partnerite sellise eesmärgiga andmetöötlusega nõustumiseks. Teise võimalusena saate klõpsata nõusolekust keeldumiseks või saada üksikasjalikumat teavet ja muuta oma eelistusi enne nõusoleku andmist. Teie eelistused kehtivad ainult sellel veebisaidil. Pange tähele, et teatud isikuandmete töötlemine ei pruugi eeldada teie nõusolekut, kuid teil on õigus sellisest töötlemisest keelduda. Saate igal ajal oma eelistuste muutmiseks pöörduda tagasi sellele saidile või külastada meie privaatsuspoliitika lehte.',
      initScreenBodyGroup: 'Meie ja meie partnerid salvestame seadmetes olevat teavet või pääseme juurde seadmete teabele, nagu küpsised, ja töötleme isikuandmeid, nagu kordumatud identifikaatorid ja seadme saadetud standardteave, allpool kirjeldatud eesmärkidel. Saate klõpsata meie ja meie partnerite sellise eesmärgiga andmetöötlusega nõustumiseks. Teise võimalusena saate klõpsata nõusolekust keeldumiseks või saada üksikasjalikumat teavet ja muuta oma eelistusi enne nõusoleku andmist. Teie eelistused kehtivad veebisaitide rühmale [lingid domeenile, kus on loetletud kõik selle rühma konfiguratsiooni atribuudid]. Pange tähele, et teatud isikuandmete töötlemine ei pruugi eeldada teie nõusolekut, kuid teil on õigus sellisest töötlemisest keelduda. Saate igal ajal oma eelistuste muutmiseks pöörduda tagasi sellele saidile või külastada meie privaatsuspoliitika lehte.',
      specialPurposesAndFeatures: 'Eriotstarbed ja -funktsioonid',
      saveAndExitButton: 'SALVESTA JA VÄLJU',
      purposeScreenVendorLink: 'PARTNERID',
      legitimateInterestLink: 'ÕIGUSTATUD HUVI ',
      specialPurposesLabel: 'Eriotstarbed',
      specialFeaturesLabel: 'Erifunktsioonid',
      featuresLabel: 'Funktsioonid',
      back: 'Tagasi',
      onLabel: 'SEES',
      offLabel: 'VÄLJAS',
      multiLabel: 'MITU',
      legalDescription: 'Õiguslik määratlus',
      showPartners: 'Kuva partnerid',
      hidePartners: 'Peida partnerid',
      vendorScreenBody: 'Vaadake üle ja määrake oma nõusoleku eelistused iga alltoodud partneri kohta. Laiendage lisateabe saamiseks iga partneri loendiüksust, et teil oleks lihtsam valikut teha. Mõningaid isikuandmeid töödeldakse ilma teie nõusolekuta, kuid teil on õigus sellest keelduda.',
      privacyPolicyLabel: 'Privaatsuspoliitika',
      descriptionLabel: 'Müüja kirjeldus',
      legitimateScreenBody: 'Vaadake läbi isikuandmete töötlus ilma teie nõusolekuta ja keelduge sellest, lähtudes õigustatud huvist iga alltoodud eesmärgi ja partneri kohta. Laiendage lisateabe saamiseks iga eesmärgi või partneri loendiüksust, et teil oleks lihtsam valikut teha. Turvalisuse tagamise, pettuste ennetamise ja vigade kõrvaldamise ning reklaamide või sisu tehnilise edastamise erieesmärkidel teostatavast töötlusest keeldumiseks klõpsake partneri privaatsuspoliitika linki.',
      legitimateInterestPurposesLabel: 'Õigustatud huvi eesmärk',
      legitimateInterestVendorLabel: 'Õigustatud huvi müüjad',
      legitimateScreenObject: 'KEELDU',
      legitimateScreenObjected: 'KEELDUTUD',
      legitimateScreenAccept: 'EEMALDADA KEELDUMINE',
      objectAllButton: 'KEELDU KÕIGEST',
      persistentConsentLinkLabel: 'Privaatsus',
      nonIabVendorsNotice: "Müüjad, kes ei osale IAB Europe'i Läbipaistvuse ja Nõusoleku Raamistikus ega järgi selle poliitikaid ega tehnilisi kirjeldusi",
      googlePartners: "Google'i partnerid",
      purposesLabel: 'Eesmärgid (nõusolek)'
    },
    premiumUiLabels: {
      linksTitle: 'Täiendavad lingid',
      nonIabVendorsLabel: 'Müüjad, kes ei kuulu IAB-sse'
    }
  },

  // Czech
  cs: {
    agree: 'SOUHLASÍM',
    saveAndExit: 'ULOŽIT A ODEJÍT',
    disagree: 'NESOUHLASÍM',
    objectAll: 'VZNÉST NÁMITKU PROTI VŠEM',
    mockPurposesCopies: {
      headline: 'Vážíme si vašeho soukromí',
      body: 'You can set your consent preferences and determine how you want your data to be used based on the purposes below. You may set your preferences for us independently from those of third-party partners. Each purpose has a description so that you know how we and partners use your data.',
      accept: 'Accept all',
      vendorLink: 'See Vendors',
      saveExit: 'Save & Exit',
      cancel: 'Cancel',
      consentLinkLabel: 'Privacy'
    },
    mockHeadlinesCopies: {
      headline: 'Vážíme si vašeho soukromí',
      accept: 'I accept',
      reject: 'I do not accept',
      showPurposes: 'Show Purposes',
    },
    coreUiLabels: {
      initScreenTitle: 'Vážíme si vašeho soukromí',
      agreeButton: 'SOUHLASÍM',
      initScreenRejectButton: 'NESOUHLASÍM',
      initScreenSettingsButton: 'VÍCE MOŽNOSTÍ',
      summaryScreenBodyNoRejectService: [
        'My a naši partneři ukládáme a/nebo přistupujeme k informacím v zařízení, jako jsou soubory cookie, a zpracováváme osobní údaje, jako jsou jedinečné identifikátory a standardní informace, které zařízení odesílá za účelem personalizace reklam a obsahu, měření reklamy a obsahu a získání poznatků o okruzích publika, jakož i pro účely zlepšení produktů.',
        ' S vaším souhlasem můžeme my a naši partneři používat přesné údaje o geografické poloze a identifikaci prostřednictvím skenování zařízení. Souhlas se zpracováním popsaným výše můžete nám či našim partnerům udělit klepnutím na tlačítko. Případně si můžete zobrazit podrobnější informace a změnit své předvolby ještě předtím, než udělíte nebo odmítnete udělit svůj souhlas. Upozorňujeme, že některé zpracování vašich osobních údajů souhlas nevyžaduje, máte však právo proti takovému zpracování vznést námitku. Vaše předvolby platí pouze pro tuto webovou stránku. Své předvolby můžete kdykoli změnit při další návštěvě této webové stránky nebo v našich zásadách ochrany osobních údajů.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'My a naši partneři ukládáme a/nebo přistupujeme k informacím v zařízení, jako jsou soubory cookie, a zpracováváme osobní údaje, jako jsou jedinečné identifikátory a standardní informace, které zařízení odesílá za účelem personalizace reklam a obsahu, měření reklamy a obsahu a získání poznatků o okruzích publika, jakož i pro účely zlepšení produktů.',
        ' S vaším souhlasem můžeme my a naši partneři používat přesné údaje o geografické poloze a identifikaci prostřednictvím skenování zařízení. Souhlas se zpracováním popsaným výše můžete nám či našim partnerům udělit klepnutím na tlačítko. Případně si můžete zobrazit podrobnější informace a změnit své předvolby ještě předtím, než udělíte nebo odmítnete udělit svůj souhlas. Upozorňujeme, že některé zpracování vašich osobních údajů souhlas nevyžaduje, máte však právo proti takovému zpracování vznést námitku. Vaše předvolby platí v rámci celé webové stránky. Své předvolby můžete kdykoli změnit při další návštěvě této webové stránky nebo v našich zásadách ochrany osobních údajů.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'My a naši partneři ukládáme a/nebo přistupujeme k informacím v zařízení, jako jsou soubory cookie, a zpracováváme osobní údaje, jako jsou jedinečné identifikátory a standardní informace, které zařízení odesílá za účelem personalizace reklam a obsahu, měření reklamy a obsahu a získání poznatků o okruzích publika, jakož i pro účely zlepšení produktů.',
        ' S vaším souhlasem můžeme my a naši partneři používat přesné údaje o geografické poloze a identifikaci prostřednictvím skenování zařízení. Souhlas se zpracováním popsaným výše můžete nám či našim partnerům udělit klepnutím na tlačítko. Případně si můžete zobrazit podrobnější informace a změnit své předvolby ještě předtím, než udělíte nebo odmítnete udělit svůj souhlas. Upozorňujeme, že některé zpracování vašich osobních údajů souhlas nevyžaduje, máte však právo proti takovému zpracování vznést námitku. Vaše předvolby budou platit pro skupinu webových stránek [odkaz na doménu, kde jsou uvedeny všechny vlastnosti pro tuto konfiguraci skupiny]. Své předvolby můžete kdykoli změnit při další návštěvě této webové stránky nebo v našich zásadách ochrany osobních údajů.'
      ],
      summaryScreenBodyRejectService: [
        'My a naši partneři ukládáme a/nebo přistupujeme k informacím v zařízení, jako jsou soubory cookie, a zpracováváme osobní údaje, jako jsou jedinečné identifikátory a standardní informace, které zařízení odesílá za účelem personalizace reklam a obsahu, měření reklamy a obsahu a získání poznatků o okruzích publika, jakož i pro účely zlepšení produktů.',
        ' S vaším souhlasem můžeme my a naši partneři používat přesné údaje o geografické poloze a identifikaci prostřednictvím skenování zařízení. Souhlas se zpracováním popsaným výše můžete nám či našim partnerům udělit klepnutím na tlačítko. Případně můžete souhlas klepnutím na tlačítko odmítnout, nebo si před udělením souhlasu můžete zobrazit podrobnější informace a změnit své předvolby. Upozorňujeme, že některé zpracování vašich osobních údajů souhlas nevyžaduje, máte však právo proti takovému zpracování vznést námitku. Vaše předvolby platí pouze pro tuto webovou stránku. Své předvolby můžete kdykoli změnit při další návštěvě této webové stránky nebo v našich zásadách ochrany osobních údajů.'
      ],
      summaryScreenBodyRejectGlobal: [
        'My a naši partneři ukládáme a/nebo přistupujeme k informacím v zařízení, jako jsou soubory cookie, a zpracováváme osobní údaje, jako jsou jedinečné identifikátory a standardní informace, které zařízení odesílá za účelem personalizace reklam a obsahu, měření reklamy a obsahu a získání poznatků o okruzích publika, jakož i pro účely zlepšení produktů.',
        ' S vaším souhlasem můžeme my a naši partneři používat přesné údaje o geografické poloze a identifikaci prostřednictvím skenování zařízení. Souhlas se zpracováním popsaným výše můžete nám či našim partnerům udělit klepnutím na tlačítko. Případně můžete souhlas klepnutím na tlačítko odmítnout, nebo si před udělením souhlasu můžete zobrazit podrobnější informace a změnit své předvolby. Upozorňujeme, že některé zpracování vašich osobních údajů souhlas nevyžaduje, máte však právo proti takovému zpracování vznést námitku. Vaše předvolby platí v rámci celé webové stránky. Své předvolby můžete kdykoli změnit při další návštěvě této webové stránky nebo v našich zásadách ochrany osobních údajů.'
      ],
      summaryScreenBodyRejectGroup: [
        'My a naši partneři ukládáme a/nebo přistupujeme k informacím v zařízení, jako jsou soubory cookie, a zpracováváme osobní údaje, jako jsou jedinečné identifikátory a standardní informace, které zařízení odesílá za účelem personalizace reklam a obsahu, měření reklamy a obsahu a získání poznatků o okruzích publika, jakož i pro účely zlepšení produktů.',
        ' S vaším souhlasem můžeme my a naši partneři používat přesné údaje o geografické poloze a identifikaci prostřednictvím skenování zařízení. Souhlas se zpracováním popsaným výše můžete nám či našim partnerům udělit klepnutím na tlačítko. Případně můžete souhlas klepnutím na tlačítko odmítnout, nebo si před udělením souhlasu můžete zobrazit podrobnější informace a změnit své předvolby. Upozorňujeme, že některé zpracování vašich osobních údajů souhlas nevyžaduje, máte však právo proti takovému zpracování vznést námitku. Vaše předvolby budou platit pro skupinu webových stránek [odkazy na doménu, kde jsou uvedeny všechny vlastnosti pro tuto konfiguraci skupiny]. Své předvolby můžete kdykoli změnit při další návštěvě této webové stránky nebo v našich zásadách ochrany osobních údajů.'
      ],
      initScreenBodyGlobal: 'My a naši partneři ukládáme nebo přistupujeme k informacím v zařízeních, jako jsou soubory cookie, a zpracováváme osobní údaje, jako jsou jedinečné identifikátory a standardní informace, které zařízení odesílá pro účely uvedené níže. Souhlas se zpracováním pro takové účely můžete nám či našim partnerům udělit klepnutím na tlačítko. Případně můžete souhlas klepnutím na tlačítko odmítnout, nebo si před udělením souhlasu můžete zobrazit podrobnější informace, abyste si změnili předvolby. Vaše předvolby platí v rámci celé webové stránky. Upozorňujeme, že některé zpracování vašich osobních údajů souhlas nevyžaduje, máte však právo proti takovému zpracování vznést námitku. Své předvolby můžete kdykoli změnit při další návštěvě této webové stránky nebo v našich zásadách ochrany osobních údajů.',
      initScreenBodyService: 'My a naši partneři ukládáme nebo přistupujeme k informacím v zařízeních, jako jsou soubory cookie, a zpracováváme osobní údaje, jako jsou jedinečné identifikátory a standardní informace, které zařízení odesílá pro účely uvedené níže. Souhlas se zpracováním pro takové účely můžete nám či našim partnerům udělit klepnutím na tlačítko. Případně můžete souhlas klepnutím na tlačítko odmítnout, nebo si před udělením souhlasu můžete zobrazit podrobnější informace a změnit své předvolby. Vaše předvolby platí pouze pro tuto webovou stránku. Upozorňujeme, že některé zpracování vašich osobních údajů souhlas nevyžaduje, máte však právo proti takovému zpracování vznést námitku. Své předvolby můžete kdykoli změnit při další návštěvě této webové stránky nebo v našich zásadách ochrany osobních údajů.',
      initScreenBodyGroup: 'My a naši partneři ukládáme nebo přistupujeme k informacím v zařízeních, jako jsou soubory cookie, a zpracováváme osobní údaje, jako jsou jedinečné identifikátory a standardní informace, které zařízení odesílá pro účely uvedené níže. Souhlas se zpracováním pro takové účely můžete nám či našim partnerům udělit klepnutím na tlačítko. Případně můžete souhlas klepnutím na tlačítko odmítnout, nebo si před udělením souhlasu můžete zobrazit podrobnější informace a změnit své předvolby. Vaše předvolby budou platit pro skupinu webových stránek [odkazy na doménu, kde jsou uvedeny všechny vlastnosti pro tuto konfiguraci skupiny]. Upozorňujeme, že některé zpracování vašich osobních údajů souhlas nevyžaduje, máte však právo proti takovému zpracování vznést námitku. Své předvolby můžete kdykoli změnit při další návštěvě této webové stránky nebo v našich zásadách ochrany osobních údajů.',
      specialPurposesAndFeatures: 'Zvláštní účely a vlastnosti',
      saveAndExitButton: 'ULOŽIT A ODEJÍT',
      purposeScreenVendorLink: 'PARTNEŘI',
      legitimateInterestLink: 'OPRÁVNĚNÝ ZÁJEM ',
      specialPurposesLabel: 'Zvláštní účely',
      specialFeaturesLabel: 'Zvláštní vlastnosti',
      featuresLabel: 'Vlastnosti',
      back: 'Zpět',
      onLabel: 'ZAPNOUT',
      offLabel: 'VYPNOUT',
      multiLabel: 'MULTI',
      legalDescription: 'Právní popis',
      showPartners: 'Zobrazit partnery',
      hidePartners: 'Skrýt partnery',
      vendorScreenBody: 'Níže zkontrolujte a nastavte své předvolby souhlasu pro každého partnera. Rozhodování vám usnadní, když rozbalíte jednotlivé položky seznamu partnerů s dalšími informacemi. Některé osobní údaje jsou zpracovávány bez vašeho souhlasu, máte však právo proti tomu vznést námitku.',
      privacyPolicyLabel: 'Zásady ochrany osobních údajů',
      descriptionLabel: 'Popis prodejce',
      legitimateScreenBody: 'Níže můžete zkontrolovat a vznést námitku ke zpracování osobních údajů bez vašeho souhlasu na základě oprávněného zájmu pro každý účel a podle každého partnera. Rozhodování vám usnadní, když rozbalíte jednotlivé položky seznamu účelů nebo partnerů s dalšími informacemi. Chcete-li vznést námitku proti zvláštním účelům, tedy zajištění bezpečnosti, předcházení podvodům a odstraňování chyb a technickému doručování (zobrazení) reklamy nebo obsahu, klepněte na odkaz partnerových zásad ochrany osobních údajů.',
      legitimateInterestPurposesLabel: 'Účely oprávněného zájmu',
      legitimateInterestVendorLabel: 'Prodejci s oprávněným zájmem',
      legitimateScreenObject: 'VZNÉST NÁMITKU',
      legitimateScreenObjected: 'VZNESENA NÁMITKA',
      legitimateScreenAccept: 'ODSTRANIT NÁMITKU',
      objectAllButton: 'VZNÉST NÁMITKU PROTI VŠEM',
      persistentConsentLinkLabel: 'Ochrana osobních údajů',
      nonIabVendorsNotice: 'Prodejci, kteří nejsou součástí Transparency and Consent Framework organizace IAB Europe a nedodržují její zásady ani technické specifikace',
      googlePartners: 'Google Partner',
      purposesLabel: 'Účely (souhlas)'
    },
    premiumUiLabels: {
      linksTitle: 'Další odkazy',
      nonIabVendorsLabel: 'Prodejci mimo IAB'
    }
  },

  // Croatian
  hr: {
    agree: 'SLAŽEM SE',
    saveAndExit: 'SPREMI I IZAĐI',
    disagree: 'NE SLAŽEM SE',
    objectAll: 'ULOŽI PRIGOVOR NA SVE',
    mockPurposesCopies: {
      headline: 'Poštujemo vašu privatnost',
      body: 'You can set your consent preferences and determine how you want your data to be used based on the purposes below. You may set your preferences for us independently from those of third-party partners. Each purpose has a description so that you know how we and partners use your data.',
      accept: 'Accept all',
      vendorLink: 'See Vendors',
      saveExit: 'Save & Exit',
      cancel: 'Cancel',
      consentLinkLabel: 'Privacy'
    },
    mockHeadlinesCopies: {
      headline: 'Poštujemo vašu privatnost',
      accept: 'I accept',
      reject: 'I do not accept',
      showPurposes: 'Show Purposes',
    },
    coreUiLabels: {
      initScreenTitle: 'Poštujemo vašu privatnost',
      agreeButton: 'SLAŽEM SE',
      initScreenRejectButton: 'NE SLAŽEM SE',
      initScreenSettingsButton: 'VIŠE MOGUĆNOSTI',
      summaryScreenBodyNoRejectService: [
        'Mi i naši partneri pohranjujemo i/ili pristupamo informacijama na uređaju, kao što su kolačići i obrađujemo osobne podatke, kao što su jedinstveni identifikatori i standardne informacije koje se šalju uređajem radi personaliziranih oglasa i sadržaja, mjerenja performansi oglasa i sadržaja te uvida u publiku, kao i za razvoj i poboljšanje proizvoda.',
        ' Uz vaše dopuštenje, mi i naši partneri možemo upotrebljavati precizne podatke o geolokaciji i identifikaciji skeniranjem uređaja. Možete kliknuti za davanje privole nama i našim partnerima za obradu, u skladu s gornjim opisom. U suprotnom, možete pristupiti detaljnijim informacijama i promijeniti svoje preferencije prije davanja privole ili odbiti davanje privole. Imajte na umu da za neke obrade vaših osobnih podataka možda neće trebati vaša privola, no imate pravo na prigovor na takvu obradu. Vaše se preferencije primjenjuju samo na ovo web-mjesto. Možete promijeniti svoje preferencije u bilo kojem trenutku povratkom na ovo web-mjesto ili pogledajte naš pravilnik o privatnosti.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Mi i naši partneri pohranjujemo i/ili pristupamo informacijama na uređaju, kao što su kolačići i obrađujemo osobne podatke, kao što su jedinstveni identifikatori i standardne informacije koje se šalju uređajem radi personaliziranih oglasa i sadržaja, mjerenja performansi oglasa i sadržaja te uvida u publiku, kao i za razvoj i poboljšanje proizvoda.',
        ' Uz vaše dopuštenje, mi i naši partneri možemo upotrebljavati precizne podatke o geolokaciji i identifikaciji skeniranjem uređaja. Možete kliknuti za davanje privole nama i našim partnerima za obradu, u skladu s gornjim opisom. U suprotnom, možete pristupiti detaljnijim informacijama i promijeniti svoje preferencije prije davanja privole ili odbiti davanje privole. Imajte na umu da za neke obrade vaših osobnih podataka možda neće trebati vaša privola, no imate pravo na prigovor na takvu obradu. Vaše se preferencije primjenjuju na čitavom internetu. Možete promijeniti svoje preferencije u bilo kojem trenutku povratkom na ovo web-mjesto ili pogledajte naš pravilnik o privatnosti.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Mi i naši partneri pohranjujemo i/ili pristupamo informacijama na uređaju, kao što su kolačići i obrađujemo osobne podatke, kao što su jedinstveni identifikatori i standardne informacije koje se šalju uređajem radi personaliziranih oglasa i sadržaja, mjerenja performansi oglasa i sadržaja te uvida u publiku, kao i za razvoj i poboljšanje proizvoda.',
        ' Uz vaše dopuštenje, mi i naši partneri možemo upotrebljavati precizne podatke o geolokaciji i identifikaciji skeniranjem uređaja. Možete kliknuti za davanje privole nama i našim partnerima za obradu, u skladu s gornjim opisom. U suprotnom, možete pristupiti detaljnijim informacijama i promijeniti svoje preferencije prije davanja privole ili odbiti davanje privole. Imajte na umu da za neke obrade vaših osobnih podataka možda neće trebati vaša privola, no imate pravo na prigovor na takvu obradu. Vaše će se preferencije primjenjivati na grupu web-mjesta [hiperveza za domenu gdje su navedene sve značajke za ovu grupnu konfiguraciju].Možete promijeniti svoje preferencije u bilo kojem trenutku povratkom na ovo web-mjesto ili pogledajte naš pravilnik o privatnosti.'
      ],
      summaryScreenBodyRejectService: [
        'Mi i naši partneri pohranjujemo i/ili pristupamo informacijama na uređaju, kao što su kolačići i obrađujemo osobne podatke, kao što su jedinstveni identifikatori i standardne informacije koje se šalju uređajem radi personaliziranih oglasa i sadržaja, mjerenja performansi oglasa i sadržaja te uvida u publiku, kao i za razvoj i poboljšanje proizvoda.',
        ' Uz vaše dopuštenje, mi i naši partneri možemo upotrebljavati precizne podatke o geolokaciji i identifikaciji skeniranjem uređaja. Možete kliknuti za davanje privole nama i našim partnerima za obradu, u skladu s gornjim opisom. U suprotnom, možete kliknuti za odbijanje davanja privole ili pristupiti detaljnijim informacijama i promijeniti svoje preferencije prije davanja privole. Imajte na umu da za neke obrade vaših osobnih podataka možda neće trebati vaša privola, no imate pravo na prigovor na takvu obradu. Vaše se preferencije primjenjuju samo na ovo web-mjesto. Možete promijeniti svoje preferencije u bilo kojem trenutku povratkom na ovo web-mjesto ili pogledajte naš pravilnik o privatnosti.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Mi i naši partneri pohranjujemo i/ili pristupamo informacijama na uređaju, kao što su kolačići i obrađujemo osobne podatke, kao što su jedinstveni identifikatori i standardne informacije koje se šalju uređajem radi personaliziranih oglasa i sadržaja, mjerenja performansi oglasa i sadržaja te uvida u publiku, kao i za razvoj i poboljšanje proizvoda.',
        ' Uz vaše dopuštenje, mi i naši partneri možemo upotrebljavati precizne podatke o geolokaciji i identifikaciji skeniranjem uređaja. Možete kliknuti za davanje privole nama i našim partnerima za obradu, u skladu s gornjim opisom. U suprotnom, možete kliknuti za odbijanje davanja privole ili pristupiti detaljnijim informacijama i promijeniti svoje preferencije prije davanja privole. Imajte na umu da za neke obrade vaših osobnih podataka možda neće trebati vaša privola, no imate pravo na prigovor na takvu obradu. Vaše se preferencije primjenjuju na čitavom internetu. Možete promijeniti svoje preferencije u bilo kojem trenutku povratkom na ovo web-mjesto ili pogledajte naš pravilnik o privatnosti.'
      ],
      summaryScreenBodyRejectGroup: [
        'Mi i naši partneri pohranjujemo i/ili pristupamo informacijama na uređaju, kao što su kolačići i obrađujemo osobne podatke, kao što su jedinstveni identifikatori i standardne informacije koje se šalju uređajem radi personaliziranih oglasa i sadržaja, mjerenja performansi oglasa i sadržaja te uvida u publiku, kao i za razvoj i poboljšanje proizvoda.',
        ' Uz vaše dopuštenje, mi i naši partneri možemo upotrebljavati precizne podatke o geolokaciji i identifikaciji skeniranjem uređaja. Možete kliknuti za davanje privole nama i našim partnerima za obradu, u skladu s gornjim opisom. U suprotnom, možete kliknuti za odbijanje davanja privole ili pristupiti detaljnijim informacijama i promijeniti svoje preferencije prije davanja privole. Imajte na umu da za neke obrade vaših osobnih podataka možda neće trebati vaša privola, no imate pravo na prigovor na takvu obradu. Vaše će se preferencije primjenjivati na grupu web-mjesta [poveznica za domenu gdje su navedene sve značajke za ovu grupnu konfiguraciju]. Možete promijeniti svoje preferencije u bilo kojem trenutku povratkom na ovo web-mjesto ili pogledajte naš pravilnik o privatnosti.'
      ],
      initScreenBodyGlobal: 'Mi i naši partneri pohranjujemo ili pristupamo informacijama na uređaju, kao što su kolačići i obrađujemo osobne podatke, kao što su jedinstveni identifikatori i standardne informacije koje se šalju uređajem u svrhe opisane u nastavku. Možete kliknuti za davanje privole nama i našim partnerima za obradu u takve svrhe. U suprotnom, možete kliknuti za odbijanje davanja privole ili pristupiti detaljnijim informacijama radi promjene svojih preferencija prije davanja privole. Vaše se preferencije primjenjuju na čitavom internetu. Imajte na umu da za neke obrade vaših osobnih podataka možda neće trebati vaša privola, no imate pravo na prigovor na takvu obradu. Možete promijeniti svoje preferencije u bilo kojem trenutku povratkom na ovo web-mjesto ili pogledajte naš pravilnik o privatnosti.',
      initScreenBodyService: 'Mi i naši partneri pohranjujemo ili pristupamo informacijama na uređaju, kao što su kolačići i obrađujemo osobne podatke, kao što su jedinstveni identifikatori i standardne informacije koje se šalju uređajem u svrhe opisane u nastavku. Možete kliknuti za davanje privole nama i našim partnerima za obradu u takve svrhe. U suprotnom, možete kliknuti za odbijanje davanja privole ili pristupiti detaljnijim informacijama i promijeniti svoje preferencije prije davanja privole. Vaše se preferencije primjenjuju samo na ovo web-mjesto. Imajte na umu da za neke obrade vaših osobnih podataka možda neće trebati vaša privola, no imate pravo na prigovor na takvu obradu. Možete promijeniti svoje preferencije u bilo kojem trenutku povratkom na ovo web-mjesto ili pogledajte naš pravilnik o privatnosti.',
      initScreenBodyGroup: 'Mi i naši partneri pohranjujemo ili pristupamo informacijama na uređaju, kao što su kolačići i obrađujemo osobne podatke, kao što su jedinstveni identifikatori i standardne informacije koje se šalju uređajem u svrhe opisane u nastavku. Možete kliknuti za davanje privole nama i našim partnerima za obradu u takve svrhe. U suprotnom, možete kliknuti za odbijanje davanja privole ili pristupiti detaljnijim informacijama i promijeniti svoje preferencije prije davanja privole. Vaše će se preferencije primjenjivati na grupu web-mjesta [poveznica za domenu gdje su navedene sve značajke za ovu grupnu konfiguraciju]. Imajte na umu da za neke obrade vaših osobnih podataka možda neće trebati vaša privola, no imate pravo na prigovor na takvu obradu. Možete promijeniti svoje preferencije u bilo kojem trenutku povratkom na ovo web-mjesto ili pogledajte naš pravilnik o privatnosti.',
      specialPurposesAndFeatures: 'Posebne svrhe i značajke',
      saveAndExitButton: 'SPREMI I IZAĐI',
      purposeScreenVendorLink: 'PARTNERI',
      legitimateInterestLink: 'LEGITIMNI INTERES ',
      specialPurposesLabel: 'Posebne svrhe',
      specialFeaturesLabel: 'Posebne značajke',
      featuresLabel: 'Značajke',
      back: 'Natrag',
      onLabel: 'UKLJUČENO',
      offLabel: 'ISKLJUČENO',
      multiLabel: 'MULTI',
      legalDescription: 'Pravni opis',
      showPartners: 'Prikaži partnere',
      hidePartners: 'Sakrij partnere',
      vendorScreenBody: 'Pregledajte i postavite svoje preferencije u vezi s privolom za svakog partnera u nastavku. Proširite svaku stavku popisa partnera za više informacija koje mogu biti od pomoći pri odabiru. Neki se osobni podaci obrađuju bez vaše privole, no imate pravo na prigovor.',
      privacyPolicyLabel: 'Pravilnik o privatnosti',
      descriptionLabel: 'Opis dobavljača',
      legitimateScreenBody: 'Pregledajte i uložite prigovor na obradu osobnih podataka bez vaše privole na temelju legitimnog interesa za svaku svrhu i za svakog partnera u nastavku. Proširite svaku svrhu ili stavku popisa partnera za više informacija koje mogu biti od pomoći pri odabiru. Za ulaganje prigovora za posebne svrhe jamčenja sigurnosti, sprječavanja prijevare, uklanjanja pogrešaka i tehničku isporuku oglasa ili sadržaja, kliknite na poveznicu za pravilnik o privatnosti partnera.',
      legitimateInterestPurposesLabel: 'Svrha/e s legitimnim interesom',
      legitimateInterestVendorLabel: 'Dobavljači s legitimnim interesom',
      legitimateScreenObject: 'ULOŽI PRIGOVOR',
      legitimateScreenObjected: 'ULOŽEN PRIGOVOR',
      legitimateScreenAccept: 'UKLONI PRIGOVOR',
      objectAllButton: 'ULOŽI PRIGOVOR NA SVE',
      persistentConsentLinkLabel: 'Privatnost',
      nonIabVendorsNotice: 'Dobavljači koji ne sudjeluju u Okviru za transparentnost i privolu udruženja IAB Europe i ne pridržavaju se njegovih pravilnika ili tehničkih specifikacija',
      googlePartners: 'Partneri društva Google',
      purposesLabel: 'Svrhe (pristanak)'
    },
    premiumUiLabels: {
      linksTitle: 'Dodatne poveznice',
      nonIabVendorsLabel: 'Dobavljači koji nisu vezani uz IAB'
    }
  },

  // Bulgarian
  bg: {
    agree: 'ПРИЕМАМ',
    saveAndExit: 'ЗАПАЗВАНЕ И ИЗХОД',
    disagree: 'НЕ ПРИЕМАМ',
    objectAll: 'ВЪЗРАЗЯВАНЕ СРЕЩУ ВСИЧКИ',
    mockPurposesCopies: {
      headline: 'Ценим вашата поверителност',
      body: 'You can set your consent preferences and determine how you want your data to be used based on the purposes below. You may set your preferences for us independently from those of third-party partners. Each purpose has a description so that you know how we and partners use your data.',
      accept: 'Accept all',
      vendorLink: 'See Vendors',
      saveExit: 'Save & Exit',
      cancel: 'Cancel',
      consentLinkLabel: 'Privacy'
    },
    mockHeadlinesCopies: {
      headline: 'Ценим вашата поверителност',
      accept: 'I accept',
      reject: 'I do not accept',
      showPurposes: 'Show Purposes',
    },
    coreUiLabels: {
      initScreenTitle: 'Ценим вашата поверителност',
      agreeButton: 'ПРИЕМАМ',
      initScreenRejectButton: 'НЕ ПРИЕМАМ',
      initScreenSettingsButton: 'ОЩЕ ОПЦИИ',
      summaryScreenBodyNoRejectService: [
        'Ние и нашите партньори съхраняваме и/или осъществяваме достъп до информация на устройството (като например „бисквитки“) и обработваме лични данни (като например уникални идентификатори) и стандартна информация, изпратена от устройството, с цел персонализирани реклами и съдържание, измерване на рекламите и съдържанието и статистика за аудиторията, както и с цел разработване и подобряване на продукти.',
        ' С ваше разрешение ние и партньорите ни може да използваме точни данни за географско позициониране и идентификация чрез сканиране на устройството. Можете да кликнете, за да дадете съгласието си ние и партньорите ни да обработваме данните ви, както е описано по-горе. Друга възможност е да разгледате по-подробна информация и да промените предпочитанията си, преди да дадете съгласието си, или да откажете да дадете съгласието си. Моля, обърнете внимание, че за част от начините на обработване на личните ви данни може да не се изисква съгласието ви, но имате право да възразите срещу обработването им по тези начини. Предпочитанията ви ще са в сила само за този уебсайт. Можете да ги промените по всяко време, като се върнете на него или посетите декларацията ни за поверителност.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Ние и нашите партньори съхраняваме и/или осъществяваме достъп до информация на устройството (като например „бисквитки“) и обработваме лични данни (като например уникални идентификатори) и стандартна информация, изпратена от устройството, с цел персонализирани реклами и съдържание, измерване на рекламите и съдържанието и статистика за аудиторията, както и с цел разработване и подобряване на продукти.',
        ' С ваше разрешение ние и партньорите ни може да използваме точни данни за географско позициониране и идентификация чрез сканиране на устройството. Можете да кликнете, за да дадете съгласието си ние и партньорите ни да обработваме данните ви, както е описано по-горе. Друга възможност е да разгледате по-подробна информация и да промените предпочитанията си, преди да дадете съгласието си, или да откажете да дадете съгласието си. Моля, обърнете внимание, че за част от начините на обработване на личните ви данни може да не се изисква съгласието ви, но имате право да възразите срещу обработването им по тези начини. Предпочитанията ви ще са в сила навсякъде в мрежата. Можете да ги промените по всяко време, като се върнете на този сайт или посетите декларацията ни за поверителност.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Ние и нашите партньори съхраняваме и/или осъществяваме достъп до информация на устройството (като например „бисквитки“) и обработваме лични данни (като например уникални идентификатори) и стандартна информация, изпратена от устройството, с цел персонализирани реклами и съдържание, измерване на рекламите и съдържанието и статистика за аудиторията, както и с цел разработване и подобряване на продукти.',
        ' С ваше разрешение ние и партньорите ни може да използваме точни данни за географско позициониране и идентификация чрез сканиране на устройството. Можете да кликнете, за да дадете съгласието си ние и партньорите ни да обработваме данните ви, както е описано по-горе. Друга възможност е да разгледате по-подробна информация и да промените предпочитанията си, преди да дадете съгласието си, или да откажете да дадете съгласието си. Моля, обърнете внимание, че за част от начините на обработване на личните ви данни може да не се изисква съгласието ви, но имате право да възразите срещу обработването им по тези начини. Предпочитанията ви ще са в сила за група уебсайтове [hyperlinked to domain where all the properties are listed for this group configuration]. Можете да ги промените по всяко време, като се върнете на този сайт или посетите декларацията ни за поверителност.'
      ],
      summaryScreenBodyRejectService: [
        'Ние и нашите партньори съхраняваме и/или осъществяваме достъп до информация на устройството (като например „бисквитки“) и обработваме лични данни (като например уникални идентификатори) и стандартна информация, изпратена от устройството, с цел персонализирани реклами и съдържание, измерване на рекламите и съдържанието и статистика за аудиторията, както и с цел разработване и подобряване на продукти.',
        ' С ваше разрешение ние и партньорите ни може да използваме точни данни за географско позициониране и идентификация чрез сканиране на устройството. Можете да кликнете, за да дадете съгласието си ние и партньорите ни да обработваме данните ви, както е описано по-горе. Друга възможност е да кликнете, за да откажете да дадете съгласието си, или да разгледате по-подробна информация и да промените предпочитанията си, преди да дадете съгласието си. Моля, обърнете внимание, че за част от начините на обработване на личните ви данни може да не се изисква съгласието ви, но имате право да възразите срещу обработването им по тези начини. Предпочитанията ви ще са в сила само за този уебсайт. Можете да ги промените по всяко време, като се върнете на него или посетите декларацията ни за поверителност.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Ние и нашите партньори съхраняваме и/или осъществяваме достъп до информация на устройството (като например „бисквитки“) и обработваме лични данни (като например уникални идентификатори) и стандартна информация, изпратена от устройството, с цел персонализирани реклами и съдържание, измерване на рекламите и съдържанието и статистика за аудиторията, както и с цел разработване и подобряване на продукти.',
        ' С ваше разрешение ние и партньорите ни може да използваме точни данни за географско позициониране и идентификация чрез сканиране на устройството. Можете да кликнете, за да дадете съгласието си ние и партньорите ни да обработваме данните ви, както е описано по-горе. Друга възможност е да кликнете, за да откажете да дадете съгласието си, или да разгледате по-подробна информация и да промените предпочитанията си, преди да дадете съгласието си. Моля, обърнете внимание, че за част от начините на обработване на личните ви данни може да не се изисква съгласието ви, но имате право да възразите срещу обработването им по тези начини. Предпочитанията ви ще са в сила навсякъде в мрежата. Можете да ги промените по всяко време, като се върнете на този сайт или посетите декларацията ни за поверителност.'
      ],
      summaryScreenBodyRejectGroup: [
        'Ние и нашите партньори съхраняваме и/или осъществяваме достъп до информация на устройството (като например „бисквитки“) и обработваме лични данни (като например уникални идентификатори) и стандартна информация, изпратена от устройството, с цел персонализирани реклами и съдържание, измерване на рекламите и съдържанието и статистика за аудиторията, както и с цел разработване и подобряване на продукти.',
        ' С ваше разрешение ние и партньорите ни може да използваме точни данни за геогарфско позициониране и идентификация чрез сканиране на устройството. Можете да кликнете, за да дадете съгласието си ние и партньорите ни да обработваме данните ви, както е описано по-горе. Друга възможност е да кликнете, за да откажете да дадете съгласието си, или да разгледате по-подробна информация и да промените предпочитанията си, преди да дадете съгласието си. Моля, обърнете внимание, че за част от начините на обработване на личните ви данни може да не се изисква съгласието ви, но имате право да възразите срещу обработването им по тези начини. Предпочитанията ви ще са в сила за група уебсайтове [links to domain where all the properties are listed for this group configuration]. Можете да ги промените по всяко време, като се върнете на този сайт или посетите декларацията ни за поверителност.'
      ],
      initScreenBodyGlobal: 'Ние и нашите партньори съхраняваме или осъществяваме достъп до информация на устройства (като например „бисквитки“) и обработваме лични данни (като например уникални идентификатори) и стандартна информация, изпратена от устройството, за долуописаните цели. Можете да кликнете, за да дадете съгласието си ние и партньорите ни да обработваме данните ви за тези цели. Друга възможност е да кликнете, за да откажете да дадете съгласието си, или да разгледате по-подробна информация, за да промените предпочитанията си, преди да дадете съгласието си. Предпочитанията ви ще са в сила навсякъде в мрежата. Моля, обърнете внимание, че за част от начините на обработване на личните ви данни може да не се изисква съгласието ви, но имате право да възразите срещу обработването им по тези начини. Можете да промените предпочитанията си по всяко време, като се върнете на този сайт или посетите декларацията ни за поверителност.',
      initScreenBodyService: 'Ние и нашите партньори съхраняваме или осъществяваме достъп до информация на устройства (като например „бисквитки“) и обработваме лични данни (като например уникални идентификатори) и стандартна информация, изпратена от устройството, за долуописаните цели. Можете да кликнете, за да дадете съгласието си ние и партньорите ни да обработваме данните ви за тези цели. Друга възможност е да кликнете, за да откажете да дадете съгласието си, или да разгледате по-подробна информация и да промените предпочитанията си, преди да дадете съгласието си. Предпочитанията ви ще са в сила само за този уебсайт. Моля, обърнете внимание, че за част от начините на обработване на личните ви данни може да не се изисква съгласието ви, но имате право да възразите срещу обработването им по тези начини. Можете да промените предпочитанията си по всяко време, като се върнете на този сайт или посетите декларацията ни за поверителност.',
      initScreenBodyGroup: 'Ние и нашите партньори съхраняваме или осъществяваме достъп до информация на устройства (като например „бисквитки“) и обработваме лични данни (като например уникални идентификатори) и стандартна информация, изпратена от устройството, за долуописаните цели. Можете да кликнете, за да дадете съгласието си ние и партньорите ни да обработваме данните ви за тези цели. Друга възможност е да кликнете, за да откажете да дадете съгласието си, или да разгледате по-подробна информация и да промените предпочитанията си, преди да дадете съгласието си. Предпочитанията ви ще са в сила за група уебсайтове [links to domain where all the properties are listed for this group configuration]. Моля, обърнете внимание, че за част от начините на обработване на личните ви данни може да не се изисква съгласието ви, но имате право да възразите срещу обработването им по тези начини. Можете да промените предпочитанията си по всяко време, като се върнете на този сайт или посетите декларацията ни за поверителност.',
      specialPurposesAndFeatures: 'Специални цели и функции',
      saveAndExitButton: 'ЗАПАЗВАНЕ И ИЗХОД',
      purposeScreenVendorLink: 'ПАРТНЬОРИ',
      legitimateInterestLink: 'ЗАКОНЕН ИНТЕРЕС',
      specialPurposesLabel: 'Специални цели',
      specialFeaturesLabel: 'Специални функции',
      featuresLabel: 'Функции',
      back: 'Назад',
      onLabel: 'ВКЛ.',
      offLabel: 'ИЗКЛ.',
      multiLabel: 'МУЛТИ',
      legalDescription: 'Юридическо описание',
      showPartners: 'Показване на партньорите',
      hidePartners: 'Скриване на партньорите',
      vendorScreenBody: 'Прегледайте и задайте предпочитанията си за съгласието за всеки партньор по-долу. Разгънете записа за всеки партньор в списъка, за да получите повече информация, с чиято помощ да вземете решение. Някои лични данни се обработват без вашето съгласие, но имате право да подадете възражение.',
      privacyPolicyLabel: 'Декларация за поверителност',
      descriptionLabel: 'Описание на доставчика',
      legitimateScreenBody: 'Прегледайте и повдигнете възражения срещу обработването на лични данни без вашето съгласие въз основа на законен интерес за всяка цел и от всеки партньор по-долу. Разгънете всички записи за цели или партньори в списъка, за да получите повече информация, с чиято помощ да вземете решение. За да възразите срещу специалните цели, свързани с осигуряване на защита, предотвратяване на измами, отстраняване на грешки и доставяне на реклами или съдържание на базата на технически параметри, кликнете върху връзката към декларацията за поверителност на партньора.',
      legitimateInterestPurposesLabel: 'Цели, свързани със законен интерес',
      legitimateInterestVendorLabel: 'Доставчици със законен интерес',
      legitimateScreenObject: 'ВЪЗРАЗЯВАНЕ',
      legitimateScreenObjected: 'ПОВДИГНАТО ВЪЗРАЖЕНИЕ',
      legitimateScreenAccept: 'ПРЕМАХВАНЕ НА ВЪЗРАЖЕНИЕТО',
      objectAllButton: 'ВЪЗРАЗЯВАНЕ СРЕЩУ ВСИЧКИ',
      persistentConsentLinkLabel: 'Поверителност',
      nonIabVendorsNotice: 'Доставчици, които не участват в рамката на IAB за прозрачност и съгласие за Европа и не спазват правилата или техническите ѝ спецификации',
      googlePartners: 'Партньори на Google',
      purposesLabel: 'Цели (Съгласие)'
    },
    premiumUiLabels: {
      linksTitle: 'Допълнителни връзки',
      nonIabVendorsLabel: 'Доставчици, които не са в списъка на IAB'
    }
  },
  // English
  en: {
    agree: 'AGREE',
    saveAndExit: 'SAVE & EXIT',
    disagree: 'DISAGREE',
    objectAll: 'OBJECT ALL',
    consentOrPaySettings: {
      accept: 'ACCEPT',
      viewPreferencesLabel: 'MANAGE PREFERENCES',
      subscribe: 'SUBSCRIBE',
      login: 'LOGIN',
      privacyBody: `We and our partners store and/or access information on a device, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for personalised advertising and content, advertising and content measurement, audience research and services development. With your permission we and our partners may use precise geolocation data and identification through device scanning.

1. By clicking on I Accept you will agree to your data being used for personalised advertising in exchange for using our site for free.
2. If you click Reject and Pay, you will need to sign up to our subscription service. This subscription will enable you to access the same content, without sharing your personal data for advertising purposes. For more information see our Terms and Conditions.

Both options enable you to access the content and support the work of our team. We have introduced these choices in accordance with data protection regulations.

Please note that some processing of your personal data may not require your consent, but you have a right to object to such processing. You may access more detailed information about the purposes, and tailor your choices, by clicking on More Options. You can change your preferences, view our partners, or withdraw your consent at any time by returning to this site and clicking the Privacy setting link on the webpage.  Your preferences will apply to this website only and will be stored in for months.`,
    },
    mockPurposesCopies: {
      headline: 'We value your privacy',
      body: 'You can set your consent preferences and determine how you want your data to be used based on the purposes below. You may set your preferences for us independently from those of third-party partners. Each purpose has a description so that you know how we and partners use your data.',
      accept: 'Accept all',
      vendorLink: 'See Vendors',
      saveExit: 'Save & Exit',
      cancel: 'Cancel',
      consentLinkLabel: 'Privacy'
    },
    mockHeadlinesCopies: {
      headline: 'We value your privacy',
      accept: 'I accept',
      reject: 'I do not accept',
      showPurposes: 'Show Purposes',
    },
    coreUiLabels: {
      back: 'Back',
      acceptAll: 'Accept all',
      descriptionLabel: 'Vendor Description:',
      featuresLabel: 'Features',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'We and our partners store or access information on devices, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for the purposes described below. You may click to consent to our and our partners’ processing for such purposes. Alternatively, you may click to refuse to consent, or access more detailed information and change your preferences before consenting. Please note that some processing of your personal data may not require your consent, but you have a right to object to such processing. Your preferences will apply to this website only and will be stored in Browser Cookies for 13 months. You can change your preferences or withdraw your consent at any time by returning to this site and clicking the "persistant_label" button at the bottom of the webpage.',
      initScreenBodyGlobal:
        'We and our partners store or access information on devices, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for the purposes described below. You may click to consent to our and our partners’ processing for such purposes. Alternatively, you may click to refuse to consent, or access more detailed information to change your preferences before consenting. Your preferences will apply across the web. Please note that some processing of your personal data may not require your consent, but you have a right to object to such processing.',
      initScreenBodyGroup:
        'We and our partners store or access information on devices, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for the purposes described below. You may click to consent to our and our partners’ processing for such purposes. Alternatively, you may click to refuse to consent, or access more detailed information and change your preferences before consenting. Your preferences will apply to a group of websites. Please note that some processing of your personal data may not require your consent, but you have a right to object to such processing.',
      initScreenRejectButton: 'Disagree',
      initScreenSettingsButton: 'MORE OPTIONS',
      initScreenTitle: 'We value your privacy',
      legalDescription: 'Legal Description',
      legitimateInterestPurposesLabel: 'Legitimate Interest',
      legitimateScreenObjected: 'Objected',
      agreeButton: 'Agree',
      legitimateScreenAccept: 'REMOVE OBJECTION',
      legitimateScreenObject: 'Object',
      objectAllButton: 'Object all',
      offLabel: 'Off',
      offOnLabel: 'Off/On',
      onLabel: 'On',
      multiLabel: 'Multi',
      persistentConsentLinkLabel: 'Privacy',
      privacyPolicyLabel: 'Privacy Policy',
      softOptInText:
        'Review and object to processing of personal data without your consent on the basis of a legitimate interest for each purpose and by each partner below. Expand each purpose or partner list item for more information to help make your choice. To object to the special purposes of ensuring security, preventing fraud, and debugging, and technically delivering ads or content click on a partner\'s privacy policy link.',
      legitimateScreenBody:
        "Review and object to processing of personal data without your consent on the basis of a legitimate interest for each purpose and by each partner below. Expand each purpose or partner list item for more information to help make your choice. To object to the special purposes of ensuring security, preventing fraud, and debugging, and technically delivering ads or content click on a partner's privacy policy link.",
      saveAndExitButton: 'Save & Exit',
      purposeScreenVendorLink: 'Partners',
      purposesLabel: 'Purposes (Consent)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Special Purposes',
      specialFeaturesLabel: 'Special Features',
      summaryScreenBodyNoRejectService: [
        'We and our partners store and/or access information on a device, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for personalised ads and content, ad and content measurement, and audience insights, as well as to develop and improve products. ',
        'With your permission we and our partners may use precise geolocation data and identification through device scanning. You may click to consent to our and our partners’ processing as described above. Alternatively you may access more detailed information and change your preferences before consenting or to refuse consenting. Please note that some processing of your personal data may not require your consent, but you have a right to object to such processing. Your preferences will apply to this website only.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'We and our partners store and/or access information on a device, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for personalised ads and content, ad and content measurement, and audience insights, as well as to develop and improve products. ',
        'With your permission we and our partners may use precise geolocation data and identification through device scanning. You may click to consent to our and our partners’ processing as described above. Alternatively you may access more detailed information and change your preferences before consenting or to refuse consenting. Please note that some processing of your personal data may not require your consent, but you have a right to object to such processing. Your preferences will apply across the web.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'We and our partners store and/or access information on a device, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for personalised ads and content, ad and content measurement, and audience insights, as well as to develop and improve products. ',
        'With your permission we and our partners may use precise geolocation data and identification through device scanning. You may click to consent to our and our partners’ processing as described above. Alternatively you may access more detailed information and change your preferences before consenting or to refuse consenting. Please note that some processing of your personal data may not require your consent, but you have a right to object to such processing. Your preferences will apply to a group of websites.'
      ],
      summaryScreenBodyRejectService: [
        'We and our partners store and/or access information on a device, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for personalised ads and content, ad and content measurement, and audience insights, as well as to develop and improve products. ',
        'With your permission we and our partners may use precise geolocation data and identification through device scanning. You may click to consent to our and our partners’ processing as described above. Alternatively you may click to refuse to consent or access more detailed information and change your preferences before consenting. Please note that some processing of your personal data may not require your consent, but you have a right to object to such processing. Your preferences will apply to this website only.'
      ],
      summaryScreenBodyRejectGlobal: [
        'We and our partners store and/or access information on a device, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for personalised ads and content, ad and content measurement, and audience insights, as well as to develop and improve products. ',
        'With your permission we and our partners may use precise geolocation data and identification through device scanning.You may click to consent to our and our partners’ processing as described above. Alternatively you may click to refuse to consent or access more detailed information and change your preferences before consenting. Please note that some processing of your personal data may not require your consent, but you have a right to object to such processing. Your preferences will apply across the web.'
      ],
      summaryScreenBodyRejectGroup: [
        'We and our partners store and/or access information on a device, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for personalised ads and content, ad and content measurement, and audience insights, as well as to develop and improve products. ',
        'With your permission we and our partners may use precise geolocation data and identification through device scanning. You may click to consent to our and our partners’ processing as described above. Alternatively you may click to refuse to consent or access more detailed information and change your preferences before consenting. Please note that some processing of your personal data may not require your consent, but you have a right to object to such processing. Your preferences will apply to a group of websites.'
      ],
      partners: 'our partners',
      groupOfSitesLabel: 'group of websites',
      vendorScreenBody:
        'Review and set your consent preferences for each partner below. Expand each partner list item for more information to help make your choice. Some personal data is processed without your consent, but you have the right to object.',
      showPartners: 'Show Partners',
      hidePartners: 'Hide Partners'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Non-IAB Vendors',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // French
  fr: {
    agree: "J'ACCEPTE",
    saveAndExit: 'Enregistrer et quitter',
    disagree: 'JE REFUSE',
    objectAll: 'TOUT REFUSER',
    consentOrPaySettings: {
      accept: 'ACCEPTER',
      viewPreferencesLabel: 'GERER LES PREFERENCES',
      subscribe: 'S\'ABONNER',
      login: 'SE CONNECTER',
      privacyBody: `
      Nous et nos partenaires stockons et/ou accédons à des informations stockées sur un appareil, telles que les cookies, et traitons les données personnelles, telles que les identifiants uniques et les informations standards envoyées par chaque appareil pour la publicité et le contenu personnalisés, la mesure de performance de la publicité et du contenu, la recherche d'audience et le développement de services. Avec votre permission, nous et nos partenaires pouvons utiliser des données de géolocalisation précises et une identification par analyse de l'appareil.
1. En cliquant sur J'accepte, vous acceptez que vos données soient utilisées à des fins de publicité personnalisée en échange de l'utilisation gratuite de notre site.
2. Si vous cliquez sur Refuser et payer, vous devrez vous inscrire à notre service d'abonnement. Cet abonnement vous permettra d'accéder au même contenu, sans partager vos données personnelles à des fins publicitaires. Pour plus d'informations, consultez nos Conditions générales.
Les deux options vous permettent d'accéder au contenu et de soutenir le travail de notre équipe. Nous avons mis en place ces choix conformément à la réglementation sur la protection des données.
Veuillez noter que certains traitements de vos données personnelles peuvent ne pas nécessiter votre consentement, mais vous avez le droit de vous y opposer. Vous pouvez accéder à des informations plus détaillées sur les finalités et personnaliser vos choix en cliquant sur Plus d'options. Vous pouvez modifier vos préférences, consulter nos partenaires  ou retirer votre consentement à tout moment en revenant sur ce site et en cliquant sur le lien Paramètres de confidentialité sur la page Web. Vos préférences s'appliqueront uniquement à ce site Web et seront stockées dans pendant mois.
      `
    },
    mockPurposesCopies: {
      headline: 'Le respect de votre vie privée est notre priorité',
      body: 'Vous pouvez définir ci-dessous vos préférences de consentement et déterminer la manière dont vous souhaitez que vos données soient utilisées en fonction des objectifs mentionnés. Vous pouvez définir vos préférences pour notre société, indépendamment de celles de nos partenaires tiers. Chaque objectif est décrit afin que vous sachiez comment nos partenaires et nous-mêmes exploitons vos données.',
      accept: 'Tout Accepter',
      vendorLink: 'Consulter les fournisseurs',
      saveExit: 'Enregistrer et quitter',
      cancel: 'Annuler',
      consentLinkLabel: 'Confidentialité'
    },
    mockHeadlinesCopies: {
      headline: 'Le respect de votre vie privée est notre priorité',
      accept: 'J\'accepte',
      reject: 'JE N’ACCEPTE PAS',
      showPurposes: 'Afficher les objectifs',
    },
    coreUiLabels: {
      back: 'Retour',
      acceptAll: 'TOUT ACCEPTER',
      descriptionLabel: 'Description du fournisseur:',
      featuresLabel: 'Fonctionnalités',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        "Nous et nos partenaires stockons ou accédons à des informations sur les appareils, telles que les cookies, et traitons des données personnelles, telles que des identifiants uniques et des informations standard envoyées par un appareil aux fins décrites ci-dessous. Vous pouvez cliquer pour consentir à notre traitement et à celui de nos partenaires à ces fins. Vous pouvez également cliquer pour refuser de consentir, ou accéder à des informations plus détaillées et modifier vos préférences avant de consentir. Veuillez noter que certains traitements de vos données personnelles peuvent ne pas nécessiter votre consentement, mais vous avez le droit de vous opposer à un tel traitement. Vos préférences s'appliqueront uniquement à ce site Web et seront stockées dans les cookies du navigateur pendant 13 mois. Vous pouvez modifier vos préférences ou retirer votre consentement à tout moment en revenant sur ce site et en cliquant sur le bouton 'gérer les cookies' en bas de la page Web.",
      initScreenBodyGlobal:
        "Nos partenaires et nous-mêmes stockons ou accédons à des informations stockées sur les terminaux, telles que les cookies, et traitons les données personnelles, telles que les identifiants uniques et les informations standards envoyées par le terminal pour les finalités décrites ci-dessous. En cliquant, vous pouvez consentir à ces traitements. Vous pouvez également cliquer pour refuser de donner votre consentement ou accéder à des informations plus détaillées et modifier vos préférences avant de consentir. Vos préférences s'appliqueront à tous les sites web. Veuillez noter que certains traitements de vos données personnelles peuvent ne pas nécessiter votre consentement, mais vous avez le droit de vous y opposer.",
      initScreenBodyGroup:
        "Nos partenaires et nous-mêmes stockons ou accédons à des informations stockées sur les terminaux, telles que les cookies, et traitons les données personnelles, telles que les identifiants uniques et les informations standards envoyées par le terminal pour les finalités décrites ci-dessous. En cliquant, vous pouvez consentir à ces traitements. Vous pouvez également cliquer pour refuser de donner votre consentement ou accéder à des informations plus détaillées et modifier vos préférences avant de consentir. Vos préférences s'appliqueront à un groupe de sites web. Veuillez noter que certains traitements de vos données personnelles peuvent ne pas nécessiter votre consentement, mais vous avez le droit de vous y opposer.",
      initScreenRejectButton: 'JE REFUSE',
      initScreenSettingsButton: "PLUS D'OPTIONS",
      initScreenTitle: 'Le respect de votre vie privée est notre priorité',
      legalDescription: 'Description Juridique',
      legitimateInterestPurposesLabel: 'INTERET LEGITIME',
      legitimateScreenObjected: 'REFUSÉ',
      agreeButton: "J'ACCEPTE",
      legitimateScreenAccept: 'RETIRER SON OBJECTION',
      legitimateScreenObject: 'Object',
      objectAllButton: 'TOUT REFUSER',
      offLabel: 'Désactivé',
      offOnLabel: 'Désactivé/Activé',
      onLabel: 'Activé',
      multiLabel: 'Multi',
      persistentConsentLinkLabel: 'Confidentialité',
      privacyPolicyLabel: 'Politique de confidentialité',
      legitimateScreenBody:
        "Vous pouvez vérifier et vous opposer au traitement des données personnelles qui est effectué sur la base de l’intérêt légitime, sans votre consentement, pour chaque finalité et par chaque partenaire ci-dessous. Utilisez le menu déroulant associé à chaque finalité ou partenaire de la liste pour avoir plus d'informations afin de faire votre choix. Pour vous opposer aux finalités spéciales d’assurer la sécurité, prévenir la fraude et déboguer, et de diffuser techniquement les publicités ou le contenu, cliquez sur le lien vers la politique de confidentialité du partenaire.",
      saveAndExitButton: 'Enregistrer et quitter',
      purposeScreenVendorLink: 'Partenaires',
      purposesLabel: 'Objectifs (consentement)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'Pour refuser ou modifier vos préférences, veuillez cliquer sur le bouton <strong>Confidentialité</strong>.',
      softOptInAlertTitle:
        'Vos choix relatifs au traitement de vos données personnelles ont été pris en compte.',
      specialPurposesLabel: 'Finalités spéciales',
      specialFeaturesLabel: 'Fonctionnalités spéciales',
      summaryScreenBodyNoRejectService: [
        "Nos partenaires et nous-mêmes stockons et/ou accédons à des informations stockées sur un terminal, telles que les cookies, et traitons les données personnelles, telles que les identifiants uniques et les informations standards envoyées par chaque terminal pour diffuser des publicités et du contenu personnalisés, mesurer les performances des publicités et du contenu, obtenir des données d'audience, et développer et améliorer les produits.",
        "Avec votre permission, nos partenaires et nous-mêmes pouvons utiliser des données de géolocalisation précises et d’identification par analyse du terminal. En cliquant, vous pouvez consentir aux traitements décrits précédemment. Vous pouvez également accéder à des informations plus détaillées et modifier vos préférences avant de consentir ou pour refuser de donner votre consentement. Veuillez noter que certains traitements de vos données personnelles peuvent ne pas nécessiter votre consentement, mais vous avez le droit de vous y opposer. Vos préférences ne s'appliqueront qu’à ce site web."
      ],
      summaryScreenBodyNoRejectGlobal: [
        "Nos partenaires et nous-mêmes stockons et/ou accédons à des informations stockées sur un terminal, telles que les cookies, et traitons les données personnelles, telles que les identifiants uniques et les informations standards envoyées par chaque terminal pour diffuser des publicités et du contenu personnalisés, mesurer les performances des publicités et du contenu, obtenir des données d'audience, et développer et améliorer les produits.",
        "Avec votre permission, nos partenaires et nous-mêmes pouvons utiliser des données de géolocalisation précises et d’identification par analyse du terminal. En cliquant, vous pouvez consentir aux traitements décrits précédemment. Vous pouvez également accéder à des informations plus détaillées et modifier vos préférences avant de consentir ou pour refuser de donner votre consentement. Veuillez noter que certains traitements de vos données personnelles peuvent ne pas nécessiter votre consentement, mais vous avez le droit de vous y opposer. Vos préférences s'appliqueront à tous les sites web."
      ],
      summaryScreenBodyNoRejectGroup: [
        "Nos partenaires et nous-mêmes stockons et/ou accédons à des informations stockées sur un terminal, telles que les cookies, et traitons les données personnelles, telles que les identifiants uniques et les informations standards envoyées par chaque terminal pour diffuser des publicités et du contenu personnalisés, mesurer les performances des publicités et du contenu, obtenir des données d'audience, et développer et améliorer les produits.",
        "Avec votre permission, nos partenaires et nous-mêmes pouvons utiliser des données de géolocalisation précises et d’identification par analyse du terminal. En cliquant, vous pouvez consentir aux traitements décrits précédemment. Vous pouvez également accéder à des informations plus détaillées et modifier vos préférences avant de consentir ou pour refuser de donner votre consentement. Veuillez noter que certains traitements de vos données personnelles peuvent ne pas nécessiter votre consentement, mais vous avez le droit de vous y opposer. Vos préférences s'appliqueront à un groupe de sites web."
      ],
      summaryScreenBodyRejectService: [
        "Nos partenaires et nous-mêmes stockons et/ou accédons à des informations stockées sur un terminal, telles que les cookies, et traitons les données personnelles, telles que les identifiants uniques et les informations standards envoyées par chaque terminal pour diffuser des publicités et du contenu personnalisés, mesurer les performances des publicités et du contenu, obtenir des données d'audience, et développer et améliorer les produits.",
        "Avec votre permission, nos partenaires et nous-mêmes pouvons utiliser des données de géolocalisation précises et d’identification par analyse du terminal. En cliquant, vous pouvez consentir aux traitements décrits précédemment. Vous pouvez également refuser de donner votre consentement ou accéder à des informations plus détaillées et modifier vos préférences avant de consentir. Veuillez noter que certains traitements de vos données personnelles peuvent ne pas nécessiter votre consentement, mais vous avez le droit de vous y opposer. Vos préférences ne s'appliqueront qu’à ce site Web."
      ],
      summaryScreenBodyRejectGlobal: [
        "Nos partenaires et nous-mêmes stockons et/ou accédons à des informations stockées sur un terminal, telles que les cookies, et traitons les données personnelles, telles que les identifiants uniques et les informations standards envoyées par chaque terminal pour diffuser des publicités et du contenu personnalisés, mesurer les performances des publicités et du contenu, obtenir des données d'audience, et développer et améliorer les produits.",
        "Avec votre permission, nos partenaires et nous-mêmes pouvons utiliser des données de géolocalisation précises et d’identification par analyse du terminal. En cliquant, vous pouvez consentir aux traitements décrits précédemment. Vous pouvez également refuser de donner votre consentement ou accéder à des informations plus détaillées et modifier vos préférences avant de consentir. Veuillez noter que certains traitements de vos données personnelles peuvent ne pas nécessiter votre consentement, mais vous avez le droit de vous y opposer. Vos préférences s'appliqueront à tous les sites web."
      ],
      summaryScreenBodyRejectGroup: [
        "Nos partenaires et nous-mêmes stockons et/ou accédons à des informations stockées sur un terminal, telles que les cookies, et traitons les données personnelles, telles que les identifiants uniques et les informations standards envoyées par chaque terminal pour diffuser des publicités et du contenu personnalisés, mesurer les performances des publicités et du contenu, obtenir des données d'audience, et développer et améliorer les produits.",
        "Avec votre permission, nos partenaires et nous-mêmes pouvons utiliser des données de géolocalisation précises et d’identification par analyse du terminal. En cliquant, vous pouvez consentir aux traitements décrits précédemment. Vous pouvez également refuser de donner votre consentement ou accéder à des informations plus détaillées et modifier vos préférences avant de consentir. Veuillez noter que certains traitements de vos données personnelles peuvent ne pas nécessiter votre consentement, mais vous avez le droit de vous y opposer. Vos préférences s'appliqueront à un groupe de sites web."
      ],
      partners: 'Nos partenaires',
      groupOfSitesLabel: 'groupe de sites web',
      vendorScreenBody:
        "Vérifiez et configurez vos préférences pour chaque partenaire listé ci-dessous. Utilisez le menu déroulant associé à chacun des partenaires pour avoir plus d'informations afin de faire votre choix. Certaines de vos données personnelles sont traitées sans votre consentement, mais vous avez le droit de vous y opposer.",
      showPartners: 'Montrer les partenaires',
      hidePartners: 'Cacher les partenaires'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Fournisseurs non IAB',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // German
  de: {
    agree: 'ZUSTIMMEN',
    saveAndExit: 'SPEICHERN & VERLASSEN',
    disagree: 'ABLEHNEN',
    objectAll: 'ALLEM WIDERSPRECHEN',
    mockPurposesCopies: {
      headline: 'Wir respektieren Ihre Privatsphäre',
      body: 'Sie können Ihre Einwilligungsvorgaben festlegen und bestimmen, wie Ihre Daten basierend auf den folgenden Zwecken verwendet werden sollen. Sie können Ihre Einstellungen für uns unabhängig von denen von Drittanbietern vornehmen. Jeder Zweck hat eine Beschreibung, damit Sie wissen, wie wir und unsere Partner Ihre Daten verwenden.',
      accept: 'Alle akzeptieren',
      vendorLink: 'Anbieter anzeigen',
      saveExit: 'Speichern & verlassen',
      cancel: 'Abbrechen',
      consentLinkLabel: 'Datenschutz'
    },
    mockHeadlinesCopies: {
      headline: 'Wir respektieren Ihre Privatsphäre',
      accept: 'ICH STIMME ZU',
      reject: 'ICH STIMME NICHT ZU',
      showPurposes: 'Zwecke anzeigen',
    },
    coreUiLabels: {
      back: 'Zurück',
      acceptAll: 'ALLE AKZEPTIEREN',
      descriptionLabel: 'Anbieterbeschreibung:',
      featuresLabel: 'Eigenschaften',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Wir und unsere Partner speichern oder greifen auf Informationen auf Geräten (z. B. Cookies) zu und verarbeiten personenbezogene Daten, z. B. eindeutige Identifikatoren und standardmäßige Informationen, die von Geräten für die nachfolgenden Zwecke gesendet werden. Sie können auf die entsprechende Schaltfläche klicken, um der Datenverarbeitung durch uns und unsere Partner für derartige Zwecke zuzustimmen. Alternativ können Sie auf die entsprechende Schaltfläche klicken, um die Einwilligung abzulehnen und um auf detailliertere Informationen zugreifen, um Ihre Einstellungen vor der Zustimmung ändern. Ihre Einstellungen gelten nur für diese Website. Bitte beachten Sie, dass die Verarbeitung mancher personenbezogenen Daten ohne Ihre Einwilligung stattfinden kann, obwohl Sie das Recht haben, einer solchen Verarbeitung zu widersprechen.',
      initScreenBodyGlobal:
        'Wir und unsere Partner speichern oder greifen auf Informationen auf Geräten (z. B. Cookies) zu und verarbeiten personenbezogene Daten, z. B. eindeutige Identifikatoren und standardmäßige Informationen, die von Geräten für die nachfolgenden Zwecke gesendet werden. Sie können auf die entsprechende Schaltfläche klicken, um der Datenverarbeitung durch uns und unsere Partner für derartige Zwecke zuzustimmen. Alternativ können Sie auf die entsprechende Schaltfläche klicken, um die Einwilligung abzulehnen oder um auf detailliertere Informationen zugreifen, um Ihre Einstellungen vor der Zustimmung ändern. Ihre Einstellungen gelten für das gesamte Internet. Bitte beachten Sie, dass die Verarbeitung mancher personenbezogenen Daten ohne Ihre Einwilligung stattfinden kann, obwohl Sie das Recht haben, einer solchen Verarbeitung zu widersprechen.',
      initScreenBodyGroup:
        'Wir und unsere Partner speichern oder greifen auf Informationen auf Geräten (z. B. Cookies) zu und verarbeiten personenbezogene Daten, z. B. eindeutige Identifikatoren und standardmäßige Informationen, die von Geräten für die nachfolgenden Zwecke gesendet werden. Sie können auf die entsprechende Schaltfläche klicken, um der Datenverarbeitung durch uns und unsere Partner für derartige Zwecke zuzustimmen. Alternativ können Sie auf die entsprechende Schaltfläche klicken, um die Einwilligung abzulehnen und um auf detailliertere Informationen zugreifen, um Ihre Einstellungen vor der Zustimmung ändern. Ihre Einstellungen gelten für eine Gruppe von Websites. Bitte beachten Sie, dass die Verarbeitung mancher personenbezogenen Daten ohne Ihre Einwilligung stattfinden kann, obwohl Sie das Recht haben, einer solchen Verarbeitung zu widersprechen.',
      initScreenRejectButton: 'ABLEHNEN',
      initScreenSettingsButton: 'MEHR OPTIONEN',
      initScreenTitle: 'Wir respektieren Ihre Privatsphäre',
      legalDescription: 'Rechtliche Darstellung',
      legitimateInterestPurposesLabel: 'BERECHTIGTES INTERESSE',
      legitimateScreenObjected: 'WIDERSPROCHEN',
      agreeButton: 'ZUSTIMMEN',
      legitimateScreenAccept: 'WIDERSPRUCH ENTFERNEN',
      legitimateScreenObject: 'Object',
      objectAllButton: 'ALLEM WIDERSPRECHEN',
      offLabel: 'Aus',
      offOnLabel: 'Aus/An',
      onLabel: 'An',
      multiLabel: 'Multi',
      persistentConsentLinkLabel: 'Datenschutz',
      privacyPolicyLabel: 'Datenschutzerklärung',
      legitimateScreenBody:
        'Überprüfen Sie und legen Sie Widerspruch ein für jeden unten aufgeführten Zweck und Partner gegen die Verarbeitung personenbezogener Daten ohne Ihre Einwilligung auf Grundlage eines berechtigten Interesses. Expandieren Sie die jeweiligen Zweck- oder Partner-Listenelemente, um weitere Informationen zu erhalten, die bei der Entscheidung hilfreich sein könnten. Klicken Sie auf den Datenschutzlink des jeweiligen Partners, um den besonderen Zwecken, d. h. der Gewährleistung von Sicherheit, der Betrugsverhinderung, des Debugging sowie der technischen Ausspielung von Werbeanzeigen oder Inhalten zu widersprechen.',
      saveAndExitButton: 'Speichern & verlassen',
      purposeScreenVendorLink: 'PARTNER',
      purposesLabel: 'Zwecke (Zustimmung)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Besondere Zwecke',
      specialFeaturesLabel: 'Besondere Eigenschaften',
      summaryScreenBodyNoRejectService: [
        'Wir und unsere Partner speichern und/oder greifen auf Informationen auf Geräten (z. B. Cookies) zu und verarbeiten personenbezogene Daten, z. B. eindeutige Identifikatoren und standardmäßige Informationen, die von Geräten für personalisierte Anzeigen und Inhalte, Anzeigen- und Inhaltsmessung sowie Erkenntnisse über Zielgruppen gesendet werden und um Produkte zu entwickeln und zu verbessern.',
        'Mit Ihrer Erlaubnis dürfen wir und unsere Partner über Gerätescans genaue Standortdaten und Kenndaten abfragen. Sie können auf die entsprechende Schaltfläche klicken, um der o. a. Datenverarbeitung durch uns und unsere Partner zuzustimmen. Alternativ können Sie auf detailliertere Informationen zugreifen und Ihre Einstellungen ändern, bevor Sie der Verarbeitung zustimmen oder diese ablehnen. Bitte beachten Sie, dass die Verarbeitung mancher personenbezogenen Daten ohne Ihre Einwilligung stattfinden kann, obwohl Sie das Recht haben, einer solchen Verarbeitung zu widersprechen. Ihre Einstellungen gelten lediglich für diese Website.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Wir und unsere Partner speichern und/oder greifen auf Informationen auf Geräten (z. B. Cookies) zu und verarbeiten personenbezogene Daten, z. B. eindeutige Identifikatoren und standardmäßige Informationen, die von Geräten für personalisierte Anzeigen und Inhalte, Anzeigen- und Inhaltsmessung sowie Erkenntnisse über Zielgruppen gesendet werden und um Produkte zu entwickeln und zu verbessern.',
        'Mit Ihrer Erlaubnis dürfen wir und unsere Partner über Gerätescans genaue Standortdaten und Kenndaten abfragen. Sie können auf die entsprechende Schaltfläche klicken, um der o. a. Datenverarbeitung durch uns und unsere Partner zuzustimmen. Alternativ können Sie auf detailliertere Informationen zugreifen und Ihre Einstellungen ändern, bevor Sie der Verarbeitung zustimmen oder diese ablehnen. Bitte beachten Sie, dass die Verarbeitung mancher personenbezogenen Daten ohne Ihre Einwilligung stattfinden kann, obwohl Sie das Recht haben, einer solchen Verarbeitung zu widersprechen. Ihre Einstellungen gelten für das gesamte Internet.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Wir und unsere Partner speichern und/oder greifen auf Informationen auf Geräten (z. B. Cookies) zu und verarbeiten personenbezogene Daten, z. B. eindeutige Identifikatoren und standardmäßige Informationen, die von Geräten für personalisierte Anzeigen und Inhalte, Anzeigen- und Inhaltsmessung sowie Erkenntnisse über Zielgruppen gesendet werden und um Produkte zu entwickeln und zu verbessern.',
        'Mit Ihrer Erlaubnis dürfen wir und unsere Partner über Gerätescans genaue Standortdaten und Kenndaten abfragen. Sie können auf die entsprechende Schaltfläche klicken, um der o. a. Datenverarbeitung durch uns und unsere Partner zuzustimmen. Alternativ können Sie auf detailliertere Informationen zugreifen und Ihre Einstellungen ändern, bevor Sie der Verarbeitung zustimmen oder diese ablehnen. Bitte beachten Sie, dass die Verarbeitung mancher personenbezogenen Daten ohne Ihre Einwilligung stattfinden kann, obwohl Sie das Recht haben, einer solchen Verarbeitung zu widersprechen. Ihre Einstellungen gelten für eine Gruppe von Websites.'
      ],
      summaryScreenBodyRejectService: [
        'Wir und unsere Partner speichern und/oder greifen auf Informationen auf Geräten (z. B. Cookies) zu und verarbeiten personenbezogene Daten, z. B. eindeutige Identifikatoren und standardmäßige Informationen, die von Geräten für personalisierte Anzeigen und Inhalte, Anzeigen- und Inhaltsmessung sowie Erkenntnisse über Zielgruppen gesendet werden und um Produkte zu entwickeln und zu verbessern.',
        'Mit Ihrer Erlaubnis dürfen wir und unsere Partner über Gerätescans genaue Standortdaten und Kenndaten abfragen. Sie können auf die entsprechende Schaltfläche klicken, um der o. a. Datenverarbeitung durch uns und unsere Partner zuzustimmen. Alternativ können Sie auf die entsprechende Schaltfläche klicken, um die Einwilligung abzulehnen oder um auf detailliertere Informationen zugreifen und um Ihre Einstellungen vor der Zustimmung ändern. Bitte beachten Sie, dass die Verarbeitung mancher personenbezogenen Daten ohne Ihre Einwilligung stattfinden kann, obwohl Sie das Recht haben, einer solchen Verarbeitung zu widersprechen. Ihre Einstellungen gelten lediglich für diese Website.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Wir und unsere Partner speichern und/oder greifen auf Informationen auf Geräten (z. B. Cookies) zu und verarbeiten personenbezogene Daten, z. B. eindeutige Identifikatoren und standardmäßige Informationen, die von Geräten für personalisierte Anzeigen und Inhalte, Anzeigen- und Inhaltsmessung sowie Erkenntnisse über Zielgruppen gesendet werden und um Produkte zu entwickeln und zu verbessern.',
        'Mit Ihrer Erlaubnis dürfen wir und unsere Partner über Gerätescans genaue Standortdaten und Kenndaten abfragen. Sie können auf die entsprechende Schaltfläche klicken, um der o. a. Datenverarbeitung durch uns und unsere Partner zuzustimmen. Alternativ können Sie auf die entsprechende Schaltfläche klicken, um die Einwilligung abzulehnen oder um auf detailliertere Informationen zugreifen und um Ihre Einstellungen vor der Zustimmung ändern. Bitte beachten Sie, dass die Verarbeitung mancher personenbezogenen Daten ohne Ihre Einwilligung stattfinden kann, obwohl Sie das Recht haben, einer solchen Verarbeitung zu widersprechen. Ihre Einstellungen gelten für das gesamte Internet.'
      ],
      summaryScreenBodyRejectGroup: [
        'Wir und unsere Partner speichern und/oder greifen auf Informationen auf Geräten (z. B. Cookies) zu und verarbeiten personenbezogene Daten, z. B. eindeutige Identifikatoren und standardmäßige Informationen, die von Geräten für personalisierte Anzeigen und Inhalte, Anzeigen- und Inhaltsmessung sowie Erkenntnisse über Zielgruppen gesendet werden und um Produkte zu entwickeln und zu verbessern.',
        'Mit Ihrer Erlaubnis dürfen wir und unsere Partner über Gerätescans genaue Standortdaten und Kenndaten abfragen. Sie können auf die entsprechende Schaltfläche klicken, um der o. a. Datenverarbeitung durch uns und unsere Partner zuzustimmen. Alternativ können Sie auf die entsprechende Schaltfläche klicken, um die Einwilligung abzulehnen oder um auf detailliertere Informationen zugreifen und um Ihre Einstellungen vor der Zustimmung ändern. Bitte beachten Sie, dass die Verarbeitung mancher personenbezogenen Daten ohne Ihre Einwilligung stattfinden kann, obwohl Sie das Recht haben, einer solchen Verarbeitung zu widersprechen. Ihre Einstellungen gelten für eine Gruppe von Websites.'
      ],
      partners: 'unsere Partner',
      groupOfSitesLabel: 'Gruppe von Websites',
      vendorScreenBody:
        'Überprüfen Sie und legen Sie Einwilligungseinstellungen für jeden unten aufgeführten Partner fest. Expandieren Sie die jeweiligen Partner-Listenelemente, um weitere Informationen zu erhalten, die bei der Entscheidung hilfreich sein könnten. Einige personenbezogene Daten werden ohne Ihre Einwilligung verarbeitet. Sie haben jedoch ein Widerspruchsrecht.',
      showPartners: 'Partner Einblenden',
      hidePartners: 'Partner Ausblenden'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Nicht-IAB-Anbieter',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Italian
  it: {
    agree: 'ACCETTO',
    saveAndExit: 'SALVA ED ESCI',
    disagree: 'NON ACCETTO',
    objectAll: 'RIFIUTA TUTTO',
    mockPurposesCopies: {
      headline: 'Il rispetto della tua privacy è la nostra priorità',
      body: 'Di seguito puoi impostare le preferenze sul consenso e determinare come desideri utilizzare i dati in base alle finalità. Puoi impostare le tue preferenze per noi indipendentemente da quelle dei partner di terze parti. Ogni finalità ha una descrizione in modo che tu sappia come noi e i nostri partner utilizziamo i tuoi dati.',
      accept: 'Accettare tutto ',
      vendorLink: 'Visualizza i fornitori',
      saveExit: 'Salva ed Esci',
      cancel: 'Annullare',
      consentLinkLabel: 'Riservatezza'
    },
    mockHeadlinesCopies: {
      headline: 'Il rispetto della tua privacy è la nostra priorità',
      accept: 'Accetto',
      reject: 'Non Accetto',
      showPurposes: 'Mostra finalità',
    },
    coreUiLabels: {
      back: 'Indietro',
      acceptAll: 'ACCETTA TUTTO',
      descriptionLabel: 'Descrizione del fornitore:',
      featuresLabel: 'Funzionalità',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Noi e i nostri fornitori archiviamo informazioni su un dispositivo (e/o vi accediamo), quali cookie ed elaboriamo i dati personali, quali gli identificativi unici e informazioni generali inviate da un dispositivo per le finalità descritte sotto. Puoi fare clic per consentire a noi e ai nostri fornitori di elabolarli per queste finalità. In alternativa puoi fare clic per negare il consenso o accedere a informazioni più dettagliate e modificare le tue preferenze prima di acconsentire. Le tue preferenze verranno applicate solo a questo sito web. Si rende noto che alcuni trattamenti dei dati personali possono non richiedere il tuo consenso, ma hai il diritto di opporti a tale trattamento.',
      initScreenBodyGlobal:
        'Noi e i nostri fornitori archiviamo informazioni su un dispositivo (e/o vi accediamo), quali cookie ed elaboriamo i dati personali, quali gli identificativi unici e informazioni generali inviate da un dispositivo per le finalità descritte sotto. Puoi fare clic per consentire a noi e ai nostri fornitori di elabolarli per queste finalità. In alternativa puoi fare clic per negare il consenso o accedere a informazioni più dettagliate e modificare le tue preferenze prima di acconsentire. Le tue preferenze verranno applicate all’intero web. Si rende noto che alcuni trattamenti dei dati personali possono non richiedere il tuo consenso, ma hai il diritto di opporti a tale trattamento.',
      initScreenBodyGroup:
        'Noi e i nostri fornitori archiviamo informazioni su un dispositivo (e/o vi accediamo), quali cookie ed elaboriamo i dati personali, quali gli identificativi unici e informazioni generali inviate da un dispositivo per le finalità descritte sotto. Puoi fare clic per consentire a noi e ai nostri fornitori di elabolarli per queste finalità. In alternativa puoi fare clic per negare il consenso o accedere a informazioni più dettagliate e modificare le tue preferenze prima di acconsentire. Le tue preferenze si applicheranno ad un gruppo di siti web. Si rende noto che alcuni trattamenti dei dati personali possono non richiedere il tuo consenso, ma hai il diritto di opporti a tale trattamento.',
      initScreenRejectButton: 'NON ACCETTO',
      initScreenSettingsButton: 'PIÙ OPZIONI',
      initScreenTitle: 'Il rispetto della tua privacy è la nostra priorità',
      legalDescription: 'Descrizione Legale',
      legitimateInterestPurposesLabel: 'INTERESSE LEGITTIMO',
      legitimateScreenObjected: 'RIFIUTATO',
      agreeButton: 'ACCETTO',
      legitimateScreenAccept: 'RIMUOVI RIFIUTO',
      legitimateScreenObject: 'Rifiuta',
      objectAllButton: 'RIFIUTA TUTTO',
      offLabel: 'Disattivo',
      offOnLabel: 'Disattivo/Attivo',
      onLabel: 'Attivo',
      multiLabel: 'Multi',
      persistentConsentLinkLabel: 'Riservatezza',
      privacyPolicyLabel: 'Informativa sulla riservatezza',
      legitimateScreenBody:
        "Rivedi e rifiuta il trattamento dei dati personali, elaborati senza il tuo consenso sulla base di un interesse legittimo, per ogni finalità e da ogni partner elencati di seguito. Espandi ogni finalità o voce dell'elenco partner per avere accesso a ulteriori informazioni e aiutarti nella scelta. Per opporti alle finalità speciali e garantire la sicurezza, prevenire frodi e debug o inviare tecnicamente annunci o contenuti, fai clic sul link relativo all'informativa sulla riservatezza di ogni partner.",
      saveAndExitButton: 'Salva ed Esci',
      purposeScreenVendorLink: 'FORNITORI',
      purposesLabel: 'Finalità (consenso)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Finalità Speciali',
      specialFeaturesLabel: 'Caratteristiche Speciali',
      summaryScreenBodyNoRejectService: [
        'Noi e i nostri fornitori archiviamo informazioni quali cookie su un dispositivo (e/o vi accediamo) ed elaboriamo i dati personali, quali gli identificativi unici e informazioni generali inviate da un dispositivo, per annunci e contenuti personalizzati, per misurare le prestazioni di annunci e contenuti, ricavare informazioni sul pubblico e anche per sviluppare e migliorare i prodotti.',
        'Con la tua autorizzazione noi e i nostri fornitori possiamo utilizzare dati di geolocalizzazione precisi e identificazione attraverso la scansione del dispositivo. Puoi fare clic per acconsentire all’elaborazione effettuata da noi e dai nostri fornitori come indicato sopra. In alternativa puoi accedere a informazioni più dettagliate e modificare le tue preferenze prima di acconsentire o di negare il consenso. Si noti che alcuni trattamenti dei dati personali potrebbero non richiedere il tuo consenso, ma hai il diritto di opporti a tale trattamento. Le tue preferenze si applicheranno solo a questo sito web.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Noi e i nostri fornitori archiviamo informazioni quali cookie (e/o vi accediamo) su un dispositivo ed elaboriamo i dati personali, quali gli identificativi unici e informazioni generali inviate da un dispositivo, per annunci e contenuti personalizzati, per misurare le prestazioni di annunci e contenuti, ricavare informazioni sul pubblico e anche per sviluppare e migliorare i prodotti.',
        'Con la tua autorizzazione noi e i nostri fornitori possiamo utilizzare dati di geolocalizzazione precisi e identificazione attraverso la scansione del dispositivo. Puoi fare clic per consentire a noi e ai nostri fornitori l’elaborazione per le finalità sopra descritte. In alternativa puoi accedere a informazioni più dettagliate e modificare le tue preferenze prima di acconsentire o per negare il consenso. Si rende noto che alcuni trattamenti dei dati personali potrebbero non richiedere il tuo consenso, ma hai il diritto di opporti a tale trattamento. Le tue preferenze si applicheranno a tutto il web.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Noi e i nostri fornitori archiviamo informazioni quali cookie (e/o vi accediamo) su un dispositivo ed elaboriamo i dati personali, quali gli identificativi unici e informazioni generali inviate da un dispositivo, per annunci e contenuti personalizzati, per misurare le prestazioni di annunci e contenuti, ricavare informazioni sul pubblico e anche per sviluppare e migliorare i prodotti.',
        'Con la tua autorizzazione noi e i nostri fornitori possiamo utilizzare dati di geolocalizzazione precisi e identificazione attraverso la scansione del dispositivo. Puoi fare clic per consentire a noi e ai nostri fornitori l’elaborazione per le finalità sopra descritte. In alternativa puoi accedere a informazioni più dettagliate e modificare le tue preferenze prima di acconsentire o per negare il consenso. Si rende noto che alcuni trattamenti dei dati personali possono non richiedere il tuo consenso, ma hai il diritto di opporti a tale trattamento. Le tue preferenze si applicheranno a un gruppo di siti web.'
      ],
      summaryScreenBodyRejectService: [
        'Noi e i nostri fornitori archiviamo informazioni quali cookie (e/o vi accediamo) su un dispositivo ed elaboriamo i dati personali, quali gli identificativi unici e informazioni generali inviate da un dispositivo, per annunci e contenuti personalizzati, per misurare le prestazioni di annunci e contenuti, ricavare informazioni sul pubblico e anche per sviluppare e migliorare i prodotti.',
        'Con la tua autorizzazione noi e i nostri fornitori possiamo utilizzare dati di geolocalizzazione precisi e identificazione attraverso la scansione del dispositivo. Puoi fare clic per consentire a noi e ai nostri fornitori l’elaborazione per le finalità sopra descritte. In alternativa puoi fare clic per negare il consenso o accedere a informazioni più dettagliate e modificare le tue preferenze prima di acconsentire. Si rende noto che alcuni trattamenti dei dati personali possono non richiedere il tuo consenso, ma hai il diritto di opporti a tale trattamento. Le tue preferenze si applicheranno solo a questo sito web.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Noi e i nostri fornitori archiviamo informazioni su un dispositivo (e/o vi accediamo), quali cookie ed elaboriamo i dati personali, quali gli identificativi unici e informazioni generali inviate da un dispositivo, per personalizzare gli annunci e i contenuti, misurare le prestazioni di annunci e contenuti, ricavare informazioni sul pubblico e anche per sviluppare e migliorare i prodotti.',
        'Con la tua autorizzazione noi e i nostri fornitori possiammo utilizzare dati precisi di geolocalizzazione e identificazione tramite la scansione del dispositivo. Puoi fare clic per consentire a noi e ai nostri fornitori l’elaborazione per le finalità sopra descritte. In alternativa puoi fare clic per negare il consenso o accedere a informazioni più dettagliate e modificare le tue preferenze prima di acconsentire. Si rende noto che alcuni trattamenti dei dati personali possono non richiedere il tuo consenso, ma hai il diritto di opporti a tale trattamento. Le tue preferenze si applicheranno a tutto il web.'
      ],
      summaryScreenBodyRejectGroup: [
        'Noi e i nostri fornitori archiviamo informazioni su un dispositivo (e/o vi accediamo), quali cookie ed elaboriamo i dati personali, quali gli identificativi unici e informazioni generali inviate da un dispositivo, per personalizzare gli annunci e i contenuti, misurare le prestazioni di annunci e contenuti, ricavare informazioni sul pubblico e anche per sviluppare e migliorare i prodotti.',
        'Con la tua autorizzazione noi e i nostri fornitori possiamo utilizzare dati precisi di geolocalizzazione e identificazione tramite la scansione del dispositivo. Puoi fare clic per consentire a noi e ai nostri fornitori l’elaborazione per le finalità sopra descritte. In alternativa puoi fare clic per negare il consenso o accedere a informazioni più dettagliate e modificare le tue preferenze prima di acconsentire. Si rende noto che alcuni trattamenti dei dati personali possono non richiedere il tuo consenso, ma hai il diritto di opporti a tale trattamento. Le tue preferenze si applicheranno a un gruppo di siti web.'
      ],
      partners: 'nostri fornitori',
      groupOfSitesLabel: 'gruppo di siti web',
      vendorScreenBody:
        "Rivedi e imposta le tue preferenze di consenso per ognuno dei partner di seguito. Per aiutarti nella scelta, espandi ogni elemento dell'elenco dei partner per visualizzare ulteriori informazioni. Alcuni dati personali vengono elaborati senza il tuo consenso, ma hai il diritto di opporti.",
      showPartners: 'Mostra fornitori',
      hidePartners: 'Nascondi fornitori'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Fornitori non IAB',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Spanish
  es: {
    agree: 'ACEPTO',
    saveAndExit: 'GUARDAR Y SALIR',
    disagree: 'NO ACEPTO',
    objectAll: 'RECHAZAR TODO',
    mockPurposesCopies: {
      headline: 'Tu privacidad es importante para nosotros',
      body: 'Puede establecer sus preferencias de consentimiento y determinar cómo desea que se utilicen sus datos según los fines que se detallan a continuación. Puede establecer sus preferencias con respecto a nosotros independientemente de las de los socios externos. Cada objetivo tiene una descripción para que sepa cómo nosotros y los socios usamos sus datos.',
      accept: 'Aceptar todo',
      vendorLink: 'Ver vendedores',
      saveExit: 'Guardar y salir',
      cancel: 'Cancelar',
      consentLinkLabel: 'Privacidad'
    },
    mockHeadlinesCopies: {
      headline: 'Tu privacidad es importante para nosotros',
      accept: 'Acepto',
      reject: 'No acepto',
      showPurposes: 'Mostrar objetivos',
    },
    coreUiLabels: {
      back: 'Volver',
      acceptAll: 'ACEPTAR TODO',
      descriptionLabel: 'Descripción del Proveedor:',
      featuresLabel: 'Caracteristicas',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Nosotros y nuestros socios almacenamos o accedemos a información en dispositivos, tales como cookies, y procesamos datos personales, tales como identificadores únicos e información estándar enviada por un dispositivo, para los propósitos descritos a continuación. Puede hacer clic para otorgarnos su consentimiento a nosotros y a nuestros socios para dichos propósitos. De forma alternativa, puede hacer clic para denegar su consentimiento o acceder a información más detallada y cambiar sus preferencias antes de otorgar su consentimiento. Sus preferencias se aplicarán solo a este sitio web. Tenga en cuenta que algún procesamiento de sus datos personales puede no requerir de su consentimiento, pero usted tiene el derecho de rechazar tal procesamiento.',
      initScreenBodyGlobal:
        'Nosotros y nuestros socios almacenamos o accedemos a información en dispositivos, tales como cookies, y procesamos datos personales, tales como identificadores únicos e información estándar enviada por un dispositivo, para los propósitos descritos a continuación. Puede hacer clic para otorgarnos su consentimiento a nosotros y a nuestros socios para dichos propósitos. De forma alternativa, puede hacer clic para denegar su consentimiento o acceder a información más detallada y cambiar sus preferencias antes de otorgar su consentimiento. Sus preferencias se aplicarán a toda la web. Tenga en cuenta que algún procesamiento de sus datos personales puede no requerir de su consentimiento, pero usted tiene el derecho de rechazar tal procesamiento.',
      initScreenBodyGroup:
        'Nosotros y nuestros socios almacenamos o accedemos a información en dispositivos, tales como cookies, y procesamos datos personales, tales como identificadores únicos e información estándar enviada por un dispositivo, para los propósitos descritos a continuación. Puede hacer clic para otorgarnos su consentimiento a nosotros y a nuestros socios para dichos propósitos. De forma alternativa, puede hacer clic para denegar su consentimiento o acceder a información más detallada y cambiar sus preferencias antes de otorgar su consentimiento. Sus preferencias se aplicarán a un grupo de sitios web. Tenga en cuenta que algún procesamiento de sus datos personales puede no requerir de su consentimiento, pero usted tiene el derecho de rechazar tal procesamiento.',
      initScreenRejectButton: 'NO ACEPTO',
      initScreenSettingsButton: 'MÁS OPCIONES',
      initScreenTitle: 'Su privacidad es importante para nosotros',
      legalDescription: 'Descripción Legal',
      legitimateInterestPurposesLabel: 'INTERÉS LEGÍTIMO',
      legitimateScreenObjected: 'RECHAZADO',
      agreeButton: 'ACEPTO',
      legitimateScreenAccept: 'DESHACER RECHAZO',
      legitimateScreenObject: 'Object',
      objectAllButton: 'RECHAZAR TODO',
      offLabel: 'Desactivar',
      offOnLabel: 'Desactivar/Activar',
      onLabel: 'Activar',
      multiLabel: 'Multi',
      persistentConsentLinkLabel: 'Privacidad',
      privacyPolicyLabel: 'Política de Privacidad',
      legitimateScreenBody:
        'Evalúe y rechace el procesamiento de datos personales sin su consentimiento basándose en un interés legítimo para cada uno de los siguientes propósitos y socios. Expanda cada elemento de la lista de propósitos o de la lista de socios para consultar más información que le ayude a tomar su decisión. Para rechazar los propósitos especiales de garantizar la seguridad, evitar fraudes, depurar errores y, técnicamente, ofrecer anuncios o contenido, haga clic en el enlace de la política de privacidad de un socio.',
      saveAndExitButton: 'Guardar y salir',
      purposeScreenVendorLink: 'SOCIOS',
      purposesLabel: 'Objetivos (consentimiento)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Propositos Especiales',
      specialFeaturesLabel: 'Caracteristicas Especiales',
      summaryScreenBodyNoRejectService: [
        'Nosotros y nuestros socios almacenamos o accedemos a información en un dispositivo, tales como cookies, y procesamos datos personales, tales como identificadores únicos e información estándar enviada por un dispositivo, para anuncios y contenido personalizados, medición de anuncios y del contenido e información sobre el público, así como para desarrollar y mejorar productos.',
        'Con su permiso, nosotros y nuestros socios podemos utilizar datos de localización geográfica precisa e identificación mediante las características de dispositivos. Puede hacer clic para otorgarnos su consentimiento a nosotros y a nuestros socios para que llevemos a cabo el procesamiento previamente descrito. De forma alternativa, puede acceder a información más detallada y cambiar sus preferencias antes de otorgar o negar su consentimiento. Tenga en cuenta que algún procesamiento de sus datos personales puede no requerir de su consentimiento, pero usted tiene el derecho de rechazar tal procesamiento. Sus preferencias se aplicarán solo para este sitio web.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Nosotros y nuestros socios almacenamos o accedemos a información en un dispositivo, tales como cookies, y procesamos datos personales, tales como identificadores únicos e información estándar enviada por un dispositivo, para anuncios y contenido personalizados, medición de anuncios y del contenido e información sobre el público, así como para desarrollar y mejorar productos.',
        'Con su permiso, nosotros y nuestros socios podemos utilizar datos de localización geográfica precisa e identificación mediante las características de dispositivos. Puede hacer clic para otorgarnos su consentimiento a nosotros y a nuestros socios para que llevemos a cabo el procesamiento previamente descrito. De forma alternativa, puede acceder a información más detallada y cambiar sus preferencias antes de otorgar o negar su consentimiento. Tenga en cuenta que algún procesamiento de sus datos personales puede no requerir de su consentimiento, pero usted tiene el derecho de rechazar tal procesamiento. Sus preferencias se aplicarán en toda la web.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Nosotros y nuestros socios almacenamos o accedemos a información en un dispositivo, tales como cookies, y procesamos datos personales, tales como identificadores únicos e información estándar enviada por un dispositivo, para anuncios y contenido personalizados, medición de anuncios y del contenido e información sobre el público, así como para desarrollar y mejorar productos.',
        'Con su permiso, nosotros y nuestros socios podemos utilizar datos de localización geográfica precisa e identificación mediante las características de dispositivos. Puede hacer clic para otorgarnos su consentimiento a nosotros y a nuestros socios para que llevemos a cabo el procesamiento previamente descrito. De forma alternativa, puede acceder a información más detallada y cambiar sus preferencias antes de otorgar o negar su consentimiento. Tenga en cuenta que algún procesamiento de sus datos personales puede no requerir de su consentimiento, pero usted tiene el derecho de rechazar tal procesamiento. Sus preferencias se aplicarán a un grupo de sitios web.'
      ],
      summaryScreenBodyRejectService: [
        'Nosotros y nuestros socios almacenamos o accedemos a información en un dispositivo, tales como cookies, y procesamos datos personales, tales como identificadores únicos e información estándar enviada por un dispositivo, para anuncios y contenido personalizados, medición de anuncios y del contenido e información sobre el público, así como para desarrollar y mejorar productos.',
        'Con su permiso, nosotros y nuestros socios podemos utilizar datos de localización geográfica precisa e identificación mediante las características de dispositivos. Puede hacer clic para otorgarnos su consentimiento a nosotros y a nuestros socios para que llevemos a cabo el procesamiento previamente descrito. De forma alternativa, puede hacer clic para denegar su consentimiento o acceder a información más detallada y cambiar sus preferencias antes de otorgar su consentimiento. Tenga en cuenta que algún procesamiento de sus datos personales puede no requerir de su consentimiento, pero usted tiene el derecho de rechazar tal procesamiento. Sus preferencias se aplicarán solo a este sitio web.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Nosotros y nuestros socios almacenamos o accedemos a información en un dispositivo, tales como cookies, y procesamos datos personales, tales como identificadores únicos e información estándar enviada por un dispositivo, para anuncios y contenido personalizados, medición de anuncios y del contenido e información sobre el público, así como para desarrollar y mejorar productos.',
        'Con su permiso, nosotros y nuestros socios podemos utilizar datos de localización geográfica precisa e identificación mediante las características de dispositivos. Puede hacer clic para otorgarnos su consentimiento a nosotros y a nuestros socios para que llevemos a cabo el procesamiento previamente descrito. De forma alternativa, puede hacer clic para denegar su consentimiento o acceder a información más detallada y cambiar sus preferencias antes de otorgar su consentimiento. Tenga en cuenta que algún procesamiento de sus datos personales puede no requerir de su consentimiento, pero usted tiene el derecho de rechazar tal procesamiento. Sus preferencias se aplicarán a toda la web.'
      ],
      summaryScreenBodyRejectGroup: [
        'Nosotros y nuestros socios almacenamos o accedemos a información en un dispositivo, tales como cookies, y procesamos datos personales, tales como identificadores únicos e información estándar enviada por un dispositivo, para anuncios y contenido personalizados, medición de anuncios y del contenido e información sobre el público, así como para desarrollar y mejorar productos.',
        'Con su permiso, nosotros y nuestros socios podemos utilizar datos de localización geográfica precisa e identificación mediante las características de dispositivos. Puede hacer clic para otorgarnos su consentimiento a nosotros y a nuestros socios para que llevemos a cabo el procesamiento previamente descrito. De forma alternativa, puede hacer clic para denegar su consentimiento o acceder a información más detallada y cambiar sus preferencias antes de otorgar su consentimiento. Tenga en cuenta que algún procesamiento de sus datos personales puede no requerir de su consentimiento, pero usted tiene el derecho de rechazar tal procesamiento. Sus preferencias se aplicarán a un grupo de sitios web.'
      ],
      partners: 'nuestros socios',
      groupOfSitesLabel: 'grupo de sitios web',
      vendorScreenBody:
        'Evalúe y seleccione sus preferencias de consentimiento para cada uno de los siguientes socios. Expanda cada elemento de la lista de socios para consultar más información que le ayude a tomar su decisión. Algunos datos personales se procesan sin su consentimiento, pero usted tiene el derecho a rechazar tal procesamiento.',
      showPartners: 'Mostrar Socios',
      hidePartners: 'Ocultar Socios'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Proveedores que no pertenecen a IAB',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Danish
  da: {
    agree: 'ENIG',
    saveAndExit: 'GEM OG LUK',
    disagree: 'UENIG',
    objectAll: 'GØR INDSIGELSE MOD ALT',
    mockPurposesCopies: {
      headline: 'Vi respekterer privatlivets fred',
      body: 'Du kan vælge dine samtykkepræferencer og bestemme, hvordan dine data skal behandles på basis af formålene nedenunder. Du kan vælge dine præferencer i forhold til os uafhængigt af dine præferencer for tredjemandspartnere. Til hvert formål foreligger der en beskrivelse, så du ved, hvordan vi og vores partnere bruger dine data.',
      accept: 'HYVÄKSY KAIKKI',
      vendorLink: 'Se Forhandlere',
      saveExit: 'Gem og luk',
      cancel: 'Annuller',
      consentLinkLabel: 'Privatlivets fred'
    },
    mockHeadlinesCopies: {
      headline: 'Vi respekterer privatlivets fred',
      accept: 'JEG SAMTYKKER',
      reject: 'JEG SAMTYKKER IKKE',
      showPurposes: 'Vis formål',
    },
    coreUiLabels: {
      back: 'Tilbage',
      acceptAll: 'ACCEPTER ALLE',
      descriptionLabel: 'Forhandlerbeskrivelse:',
      featuresLabel: 'Funktioner',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Vi og vores partnere lagrer og/eller får adgang til oplysninger såsom cookies på en enhed, og behandler personoplysninger, såsom entydige identifikatorer og standardoplysninger, som bliver sendt af en enhed til nedenfor beskrevne formål. Du kan klikke for at godkende vores og vores partneres behandling til sådanne formål. Alternativt kan du klikke for at nægte at godkende eller få adgang til mere detaljerede oplysninger for at ændre dine præferencer, før du samtykker. Dine præferencer vil kun gælde for denne hjemmeside. Bemærk, at en vis behandling af dine personoplysninger muligvis ikke kræver dit samtykke, men du har ret til at gøre indsigelse mod sådan behandling.',
      initScreenBodyGlobal:
        'Vi og vores partnere lagrer og/eller får adgang til oplysninger såsom cookies på en enhed, og behandler personoplysninger, såsom entydige identifikatorer og standardoplysninger, som bliver sendt af en enhed til nedenfor beskrevne formål. Du kan klikke for at godkende vores og vores partneres behandling til sådanne formål. Alternativt kan du klikke for at nægte at godkende eller få adgang til mere detaljerede oplysninger for at ændre dine præferencer, før du samtykker. Dine præferencer vil gælde overalt på nettet. Bemærk, at nogle former for behandling af dine personoplysninger muligvis ikke kræver dit samtykke, men du har ret til at gøre indsigelse mod sådan behandling.',
      initScreenBodyGroup:
        'Vi og vores partnere lagrer og/eller får adgang til oplysninger såsom cookies på en enhed, og behandler personoplysninger, såsom entydige identifikatorer og standardoplysninger, som bliver sendt af en enhed til nedenfor beskrevne formål. Du kan klikke for at godkende vores og vores partneres behandling til sådanne formål. Alternativt kan du klikke for at nægte at godkende eller få adgang til mere detaljerede oplysninger for at ændre dine præferencer, før du samtykker. Dine præferencer gælder for en gruppe websteder. Bemærk, at en vis behandling af dine personoplysninger muligvis ikke kræver dit samtykke, men du har ret til at gøre indsigelse mod sådan behandling.',
      initScreenRejectButton: 'UENIG',
      initScreenSettingsButton: 'FLERE MULIGHEDER',
      initScreenTitle: 'Vi respekterer privatlivets fred',
      legalDescription: 'Juridisk beskrivelse',
      legitimateInterestPurposesLabel: 'LEGITIME INTERESSER',
      legitimateScreenObjected: 'GJORT INDSIGELSE',
      agreeButton: 'ENIG',
      legitimateScreenAccept: 'FJERN INDSIGELSE',
      legitimateScreenObject: 'Object',
      objectAllButton: 'GØR INDSIGELSE MOD ALT',
      offLabel: 'Fra',
      offOnLabel: 'Fra/Til',
      onLabel: 'Til',
      multiLabel: 'Multi',
      persistentConsentLinkLabel: 'Privatlivets fred',
      privacyPolicyLabel: 'Politik for beskyttelse af personoplysninger',
      legitimateScreenBody:
        'Gennemgå og modsæt dig behandling af personoplysninger uden dit samtykke, på grundlag af en legitim interesse for hvert formål og for hver partner nedenfor. Udvid hvert formål eller partnerlisteemne for at få flere oplysninger, så du kan træffe dit valg. For at gøre indsigelse mod de specielle formål, som er at sørge for sikkerhed, forhindre svig og fejlsøgning og teknisk levere annoncer eller indhold, skal du klikke på en partners link til fortrolighedspolitik.',
      saveAndExitButton: 'Gem og luk',
      purposeScreenVendorLink: 'PARTNERE',
      purposesLabel: 'Formål (samtykke)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Særlige formål',
      specialFeaturesLabel: 'Særlige funktioner',
      summaryScreenBodyNoRejectService: [
        'Vi og vores partnere lagrer og/eller får adgang til oplysninger såsom cookies på en enhed, og behandler personoplysninger såsom entydige identifikatorer og standardoplysninger, som bliver sendt af en enhed for personaliserede annoncer og indhold, måling af annoncer og indhold og publikumsindsigt samt at udvikle og forbedre produkter.',
        'Med din tilladelse kan vi og vores partnere bruge nøjagtige geolokaliseringsdata og identificering gennem enhedsscanning. Du kan klikke her for at acceptere vores og vores partneres behandling, som beskrevet ovenfor. Alternativt kan du få adgang til mere detaljerede oplysninger og ændre dine præferencer, før du samtykker eller nægter samtykke. Bemærk, at nogle former for behandling af dine personoplysninger muligvis ikke kræver dit samtykke, men du har ret til at gøre indsigelse mod sådan behandling. Dine præferencer gælder kun for dette websted.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Vi og vores partnere lagrer og/eller får adgang til oplysninger såsom cookies på en enhed, og behandler personoplysninger såsom entydige identifikatorer og standardoplysninger, som bliver sendt af en enhed for personaliserede annoncer og indhold, måling af annoncer og indhold og publikumsindsigt samt at udvikle og forbedre produkter.',
        'Med din tilladelse kan vi og vores partnere bruge nøjagtige geolokaliseringsdata og identificering gennem enhedsscanning. Du kan klikke her for at acceptere vores og vores partneres behandling, som beskrevet ovenfor. Alternativt kan du få adgang til mere detaljerede oplysninger og ændre dine præferencer, før du samtykker eller nægter samtykke. Bemærk, at nogle former for behandling af dine personoplysninger muligvis ikke kræver dit samtykke, men du har ret til at gøre indsigelse mod sådan behandling. Dine præferencer gælder for hele internettet.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Vi og vores partnere lagrer og/eller får adgang til oplysninger såsom cookies på en enhed, og behandler personoplysninger såsom entydige identifikatorer og standardoplysninger, som bliver sendt af en enhed for personaliserede annoncer og indhold, måling af annoncer og indhold og publikumsindsigt samt at udvikle og forbedre produkter. Med din tilladelse kan vi og vores partnere bruge nøjagtige geolokaliseringsdata og identificering gennem enhedsscanning.',
        'Du kan klikke her for at acceptere vores og vores partneres behandling, som beskrevet ovenfor. Alternativt kan du få adgang til mere detaljerede oplysninger og ændre dine præferencer, før du samtykker eller nægter samtykke. Bemærk, at nogle former for behandling af dine personoplysninger muligvis ikke kræver dit samtykke, men du har ret til at gøre indsigelse mod sådan behandling. Dine præferencer gælder for en gruppe websteder.'
      ],
      summaryScreenBodyRejectService: [
        'Vi og vores partnere lagrer og/eller får adgang til oplysninger såsom cookies på en enhed, og behandler personoplysninger såsom entydige identifikatorer og standardoplysninger, som bliver sendt af en enhed for personaliserede annoncer og indhold, måling af annoncer og indhold og publikumsindsigt samt at udvikle og forbedre produkter. Med din tilladelse kan vi og vores partnere bruge nøjagtige geolokaliseringsdata og identificering gennem enhedsscanning.',
        'Du kan klikke her for at acceptere vores og vores partneres behandling, som beskrevet ovenfor. Alternativt kan du få adgang til mere detaljerede oplysninger og ændre dine præferencer, før du samtykker eller nægter samtykke. Bemærk, at nogle former for behandling af dine personoplysninger muligvis ikke kræver dit samtykke, men du har ret til at gøre indsigelse mod sådan behandling. Dine præferencer gælder kun for dette websted.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Vi og vores partnere lagrer og/eller får adgang til oplysninger såsom cookies på en enhed, og behandler personoplysninger såsom entydige identifikatorer og standardoplysninger, som bliver sendt af en enhed for personaliserede annoncer og indhold, måling af annoncer og indhold og publikumsindsigt samt at udvikle og forbedre produkter. Med din tilladelse kan vi og vores partnere bruge nøjagtige geolokaliseringsdata og identificering gennem enhedsscanning.',
        'Du kan klikke her for at acceptere vores og vores partneres behandling, som beskrevet ovenfor. Alternativt kan du få adgang til mere detaljerede oplysninger og ændre dine præferencer, før du samtykker eller nægter samtykke. Bemærk, at nogle former for behandling af dine personoplysninger muligvis ikke kræver dit samtykke, men du har ret til at gøre indsigelse mod sådan behandling. Dine præferencer gælder kun for dette websted.'
      ],
      summaryScreenBodyRejectGroup: [
        'Vi og vores partnere lagrer og/eller får adgang til oplysninger såsom cookies på en enhed, og behandler personoplysninger såsom entydige identifikatorer og standardoplysninger, som bliver sendt af en enhed for personaliserede annoncer og indhold, måling af annoncer og indhold og publikumsindsigt samt at udvikle og forbedre produkter. Med din tilladelse kan vi og vores partnere bruge nøjagtige geolokaliseringsdata og identificering gennem enhedsscanning.',
        'Du kan klikke her for at acceptere vores og vores partneres behandling, som beskrevet ovenfor. Alternativt kan du få adgang til mere detaljerede oplysninger og ændre dine præferencer, før du samtykker eller nægter samtykke. Bemærk, at nogle former for behandling af dine personoplysninger muligvis ikke kræver dit samtykke, men du har ret til at gøre indsigelse mod sådan behandling. Dine præferencer gælder for en gruppe websteder.'
      ],
      partners: 'vores partnere',
      groupOfSitesLabel: 'gruppe websteder',
      vendorScreenBody:
        'Gennemgå og indstil dine samtykkepræferencer for hver partner nedenfor. Udvid hvert partnerlisteemne for at få flere oplysninger, så du kan træffe dit valg. Nogle personoplysninger behandles uden dit samtykke, men du har ret til at gøre indsigelse.',
      showPartners: 'Vis partnere',
      hidePartners: 'Skjul partnere'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Ikke-IAB-forhandlere',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Dutch
  nl: {
    agree: 'IK GA AKKOORD',
    saveAndExit: 'Opslaan en afsluiten',
    disagree: 'IK GA NIET AKKOORD',
    objectAll: 'BEZWAAR TEGEN ALLES',
    mockPurposesCopies: {
      headline: 'Wij respecteren uw privacy',
      body: 'U kunt uw toestemmingsvoorkeuren instellen en bepalen hoe u uw gegevens wilt gebruiken op basis van de onderstaande doeleinden. U kunt uw voorkeuren voor ons onafhankelijk van die van externe partners instellen. Elk doeleinde heeft een beschrijving zodat u weet hoe wij en onze partners uw gegevens gebruiken.',
      accept: 'ALLES ACCEPTEREN',
      vendorLink: 'Zie leveranciers',
      saveExit: 'Opslaan en afsluiten',
      cancel: 'Annuleer',
      consentLinkLabel: 'Privacy'
    },
    mockHeadlinesCopies: {
      headline: 'We respecteren uw privacy',
      accept: 'IK GA AKKOORD',
      reject: 'IK GA NIET AKKOORD',
      showPurposes: 'Doeleinden weergeven',
    },
    coreUiLabels: {
      back: 'Terug',
      acceptAll: 'ALLES ACCEPTEREN',
      descriptionLabel: 'Beschrijving leverancier:',
      featuresLabel: 'Kenmerken',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Wij en onze partners passen opslag- en toegangstechnologieën toe op apparaten, zoals cookies, en verwerken persoonlijke gegevens, zoals IP-adressen en standaardinformatie die een apparaat verzendt voor onderstaande doeleinden. Klik hieronder om toestemming te geven voor bovengenoemde verwerking van uw persoonlijke gegevens voor deze doeleinden. U kunt op elk moment van gedachten veranderen en uw toestemmingskeuzes wijzigen door terug te keren naar deze site. Houd er rekening mee dat voor bepaalde verwerkingen van uw persoonlijke gegevens uw toestemming niet nodig is, maar u heeft het recht om bezwaar te maken tegen dergelijke verwerking.',
      initScreenBodyGlobal:
        'Wij en onze partners gebruiken technologieën, zoals cookies, en verwerken persoonlijke gegevens, zoals IP-adressen en standaardinformatie die een apparaat verzendt voor de onderstaande doeleinden. Klik hieronder om toestemming te geven voor bovengenoemde verwerking van uw persoonlijke gegevens voor deze doeleinden. U kunt op elk moment van gedachten veranderen en uw toestemmingskeuzes wijzigen door terug te keren naar deze site. Houd er rekening mee dat voor bepaalde verwerkingen van uw persoonlijke gegevens uw toestemming niet nodig is, maar u heeft het recht om bezwaar te maken tegen dergelijke verwerking.',
      initScreenBodyGroup:
        'Wij en onze partners passen opslag- en toegangstechnologieën toe op apparaten, zoals cookies, en verwerken persoonlijke gegevens, zoals IP-adressen en standaardinformatie die een apparaat verzendt voor onderstaande doeleinden. Klik hieronder om toestemming te geven voor het gebruik van deze technologie en de verwerking van uw persoonlijke gegevens voor deze doeleinden. U kunt ook toestemming weigeren of meer informatie lezen en uw voorkeuren wijzigen alvorens u toestemming geeft. Uw voorkeuren zijn van toepassing op een groep websites. Houd er rekening mee dat voor bepaalde verwerkingen van uw persoonlijke gegevens uw toestemming niet nodig is, maar u heeft het recht om bezwaar te maken tegen dergelijke verwerking.',
      initScreenRejectButton: 'IK GA NIET AKKOORD',
      initScreenSettingsButton: 'MEER OPTIES',
      initScreenTitle: 'We respecteren uw privacy',
      legalDescription: 'Juridische beschrijving',
      legitimateInterestPurposesLabel: 'GERECHTVAARDIGD BELANG',
      legitimateScreenObjected: 'BEZWAAR',
      agreeButton: 'IK GA AKKOORD',
      legitimateScreenAccept: 'VERWIJDER BEZWAAR',
      legitimateScreenObject: 'Object',
      objectAllButton: 'BEZWAAR TEGEN ALLES',
      offLabel: 'Uit',
      offOnLabel: 'Uit/Aan',
      onLabel: 'Aan',
      multiLabel: 'Multi',
      persistentConsentLinkLabel: 'Privacy',
      privacyPolicyLabel: 'Privacybeleid',
      legitimateScreenBody:
        'Bekijk en maak bezwaar tegen de verwerking van persoonsgegevens zonder uw toestemming op basis van een gerechtvaardigd belang voor elk doel en door elke partner hieronder. Vouw de lijst uit voor meer informatie om u te helpen uw keuze te maken. Om bezwaar te maken tegen de speciale doeleinden van het garanderen van veiligheid, het voorkomen van fraude, het oplossen van fouten en het technisch weergeven van advertenties of inhoud, klikt u op de link naar het privacybeleid van een partner.',
      saveAndExitButton: 'Opslaan en afsluiten',
      purposeScreenVendorLink: 'PARTNERS',
      purposesLabel: 'Doeleinden (toestemming)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Speciale doeleinden',
      specialFeaturesLabel: 'Bijzondere kenmerken',
      summaryScreenBodyNoRejectService: [
        'Wij en onze partners passen opslag- en toegangstechnologieën toe op een apparaat, zoals cookies, en verwerken persoonlijke gegevens, zoals IP-adressen en cookie-identificatoren, om advertenties en inhoud te personaliseren, de resultaten van advertenties en inhoud te meten, inzichten te verkrijgen over het betreffende publiek en producten te ontwikkelen en verbeteren.',
        'Met uw toestemming kunnen wij en onze partners gebruikmaken van locatiegegevens en identificatie door het scannen van apparatuur. Klik hieronder om toestemming te geven voor bovengenoemde verwerking van uw persoonlijke gegevens voor deze doeleinden. U kunt op elk moment van gedachten veranderen en uw toestemmingskeuzes wijzigen door terug te keren naar deze site. Houd er rekening mee dat voor bepaalde verwerkingen van uw persoonlijke gegevens uw toestemming niet nodig is, maar u heeft het recht om bezwaar te maken tegen dergelijke verwerking. Uw voorkeuren gelden uitsluitend voor deze website.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Wij en onze partners passen opslag- en toegangstechnologieën toe op een apparaat, zoals cookies, en verwerken persoonlijke gegevens, zoals IP-adressen en cookie-identificatoren, om advertenties en inhoud te personaliseren, de resultaten van advertenties en inhoud te meten, inzichten te verkrijgen over het betreffende publiek en producten te ontwikkelen en verbeteren.',
        'Met uw toestemming kunnen wij en onze partners gebruikmaken van locatiegegevens en identificatie door het scannen van apparatuur. Klik hieronder om toestemming te geven voor bovengenoemde verwerking van uw persoonlijke gegevens voor deze doeleinden. U kunt op elk moment van gedachten veranderen en uw toestemmingskeuzes wijzigen door terug te keren naar deze site. Houd er rekening mee dat voor bepaalde verwerkingen van uw persoonlijke gegevens uw toestemming niet nodig is, maar u heeft het recht om bezwaar te maken tegen dergelijke verwerking. Uw voorkeuren zijn van toepassing op het volledige web.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Wij en onze partners passen opslag- en toegangstechnologieën toe op een apparaat, zoals cookies, en verwerken persoonlijke gegevens, zoals IP-adressen en cookie-identificatoren, om advertenties en inhoud te personaliseren, de resultaten van advertenties en inhoud te meten, inzichten te verkrijgen over het betreffende publiek en producten te ontwikkelen en verbeteren.',
        'Met uw toestemming kunnen wij en onze partners gebruikmaken van locatiegegevens en identificatie door het scannen van apparatuur. Klik hieronder om toestemming te geven voor bovengenoemde verwerking van uw persoonlijke gegevens voor deze doeleinden. U kunt op elk moment van gedachten veranderen en uw toestemmingskeuzes wijzigen door terug te keren naar deze site. Houd er rekening mee dat voor bepaalde verwerkingen van uw persoonlijke gegevens uw toestemming niet nodig is, maar u heeft het recht om bezwaar te maken tegen dergelijke verwerking. Uw voorkeuren zijn van toepassing op een groep websites.'
      ],
      summaryScreenBodyRejectService: [
        'Wij en onze partners passen opslag- en toegangstechnologieën toe op een apparaat, zoals cookies, en verwerken persoonlijke gegevens, zoals IP-adressen en cookie-identificatoren, om advertenties en inhoud te personaliseren, de resultaten van advertenties en inhoud te meten, inzichten te verkrijgen over het betreffende publiek en producten te ontwikkelen en verbeteren.',
        'Met uw toestemming kunnen wij en onze partners gebruikmaken van locatiegegevens en identificatie door het scannen van apparatuur. Klik hieronder om toestemming te geven voor bovengenoemde verwerking van uw persoonlijke gegevens voor deze doeleinden. U kunt op elk moment van gedachten veranderen en uw toestemmingskeuzes wijzigen door terug te keren naar deze site. Houd er rekening mee dat voor bepaalde verwerkingen van uw persoonlijke gegevens uw toestemming niet nodig is, maar u heeft het recht om bezwaar te maken tegen dergelijke verwerking. Uw voorkeuren gelden uitsluitend voor deze website.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Wij en onze partners passen opslag- en toegangstechnologieën toe op een apparaat, zoals cookies, en verwerken persoonlijke gegevens, zoals IP-adressen en cookie-identificatoren, om advertenties en inhoud te personaliseren, de resultaten van advertenties en inhoud te meten, inzichten te verkrijgen over het betreffende publiek en producten te ontwikkelen en verbeteren.',
        'Met uw toestemming kunnen wij en onze partners gebruikmaken van locatiegegevens en identificatie door het scannen van apparatuur. Klik hieronder om toestemming te geven voor bovengenoemde verwerking van uw persoonlijke gegevens voor deze doeleinden. U kunt op elk moment van gedachten veranderen en uw toestemmingskeuzes wijzigen door terug te keren naar deze site. Houd er rekening mee dat voor bepaalde verwerkingen van uw persoonlijke gegevens uw toestemming niet nodig is, maar u heeft het recht om bezwaar te maken tegen dergelijke verwerking. Uw voorkeuren zijn van toepassing op het volledige web.'
      ],
      summaryScreenBodyRejectGroup: [
        'Wij en onze partners passen opslag- en toegangstechnologieën toe op een apparaat, zoals cookies, en verwerken persoonlijke gegevens, zoals IP-adressen en cookie-identificatoren, om advertenties en inhoud te personaliseren, de resultaten van advertenties en inhoud te meten, inzichten te verkrijgen over het betreffende publiek en producten te ontwikkelen en verbeteren.',
        'Met uw toestemming kunnen wij en onze partners gebruikmaken van locatiegegevens en identificatie door het scannen van apparatuur. Klik hieronder om toestemming te geven voor bovengenoemde verwerking van uw persoonlijke gegevens voor deze doeleinden. U kunt op elk moment van gedachten veranderen en uw toestemmingskeuzes wijzigen door terug te keren naar deze site. Houd er rekening mee dat voor bepaalde verwerkingen van uw persoonlijke gegevens uw toestemming niet nodig is, maar u heeft het recht om bezwaar te maken tegen dergelijke verwerking. Uw voorkeuren zijn van toepassing op een groep websites.'
      ],
      partners: 'onze partners',
      groupOfSitesLabel: 'groep websites',
      vendorScreenBody:
        'Bekijk en stel hieronder uw toestemmingsvoorkeuren in voor elke partner. Vouw de lijst uit voor meer informatie om u te helpen uw keuze te maken. Sommige persoonsgegevens worden zonder uw toestemming verwerkt, maar u heeft het recht om bezwaar aan te tekenen.',
      showPartners: 'Partners weergeven',
      hidePartners: 'Partners verbergen'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Niet-IAB-leveranciers',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Greek
  el: {
    agree: 'ΑΠΟΔΕΧΟΜΑΙ',
    saveAndExit: 'ΑΠΟΘΗΚΕΥΣΗ ΚΑΙ ΕΞΟΔΟΣ',
    disagree: 'ΔΕΝ ΑΠΟΔΕΧΟΜΑΙ',
    objectAll: 'ΑΠΟΡΡΙΨΗ ΟΛΩΝ',
    mockPurposesCopies: {
      headline: 'Σεβόμαστε την ιδιωτικότητά σας',
      body: 'Μπορείτε να ορίσετε τις προτιμήσεις συγκατάθεσης σας και να καθορίσετε τον τρόπο με τον οποίο θέλετε να χρησιμοποιηθούν τα δεδομένα σας, με βάση τους παρακάτω σκοπούς. Μπορείτε να ορίσετε τις προτιμήσεις σας για εμάς, ανεξάρτητα από αυτούς τους τρίτους συνεργάτες. Κάθε σκοπός έχει μια περιγραφή, έτσι ώστε να ξέρετε πώς χρησιμοποιούμε τα δεδομένα σας εμείς και οι συνεργάτες μας.',
      accept: 'ΑΠΟΔΟΧΗ ΟΛΩΝ',
      vendorLink: 'Εμφάνιση παρόχων',
      saveExit: 'Αποθήκευση και έξοδος',
      cancel: 'Άκυρο',
      consentLinkLabel: 'Απόρρητο'
    },
    mockHeadlinesCopies: {
      headline: 'Σεβόμαστε την ιδιωτικότητά σας',
      accept: 'ΣΥΜΦΩΝΩ',
      reject: 'ΔΕ ΣΥΜΦΩΝΩ',
      showPurposes: 'Εμφάνιση σκοπών',
    },
    coreUiLabels: {
      back: 'Επιστροφή',
      acceptAll: 'ΑΠΟΔΟΧΗ ΟΛΩΝ',
      descriptionLabel: 'Περιγραφή παρόχου:',
      featuresLabel: 'Χαρακτηριστικά',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Εμείς και οι συνεργάτες μας αποθηκεύουμε ή έχουμε πρόσβαση σε πληροφορίες σε συσκευές, όπως cookies και επεξεργαζόμαστε προσωπικά δεδομένα, όπως μοναδικά αναγνωριστικά και τυπικές πληροφορίες που αποστέλλονται από μια συσκευή για τους σκοπούς που περιγράφονται παρακάτω. Μπορείτε να κάνετε κλικ για να συναινέσετε στην επεξεργασία μας και των συνεργατών μας για τους εν λόγω σκοπούς. Εναλλακτικά, μπορείτε να κάνετε κλικ για να αρνηθείτε να δώσετε τη συγκατάθεσή σας ή να αποκτήσετε πρόσβαση σε πιο λεπτομερείς πληροφορίες και να αλλάξετε τις προτιμήσεις σας πριν από τη συγκατάθεσή σας. Οι προτιμήσεις σας θα ισχύουν μόνο για αυτόν τον ιστότοπο. Λάβετε υπόψη ότι κάποια επεξεργασία των προσωπικών σας δεδομένων ενδέχεται να μην απαιτεί τη συγκατάθεσή σας, αλλά έχετε το δικαίωμα να αντιταχθείτε σε αυτήν την επεξεργασία.',
      initScreenBodyGlobal:
        'Εμείς και οι συνεργάτες μας αποθηκεύουμε ή έχουμε πρόσβαση σε πληροφορίες σε συσκευές, όπως cookies και επεξεργαζόμαστε προσωπικά δεδομένα, όπως μοναδικά αναγνωριστικά και τυπικές πληροφορίες που αποστέλλονται από μια συσκευή για τους σκοπούς που περιγράφονται παρακάτω. Μπορείτε να κάνετε κλικ για να συναινέσετε στην επεξεργασία μας και των συνεργατών μας για τους εν λόγω σκοπούς. Εναλλακτικά, μπορείτε να κάνετε κλικ για να αρνηθείτε να δώσετε τη συγκατάθεσή σας ή να αποκτήσετε πρόσβαση σε πιο λεπτομερείς πληροφορίες για να αλλάξετε τις προτιμήσεις σας πριν από τη συγκατάθεσή σας. Οι προτιμήσεις σας θα ισχύουν σε ολόκληρο το διαδίκτυο. Λάβετε υπόψη ότι κάποια επεξεργασία των προσωπικών σας δεδομένων ενδέχεται να μην απαιτεί τη συγκατάθεσή σας, αλλά έχετε το δικαίωμα να αντιταχθείτε σε αυτήν την επεξεργασία.',
      initScreenBodyGroup:
        'Εμείς και οι συνεργάτες μας αποθηκεύουμε ή έχουμε πρόσβαση σε πληροφορίες σε συσκευές, όπως cookies και επεξεργαζόμαστε προσωπικά δεδομένα, όπως μοναδικά αναγνωριστικά και τυπικές πληροφορίες που αποστέλλονται από μια συσκευή για τους σκοπούς που περιγράφονται παρακάτω. Μπορείτε να κάνετε κλικ για να συναινέσετε στην επεξεργασία μας και των συνεργατών μας για τους εν λόγω σκοπούς. Εναλλακτικά, μπορείτε να κάνετε κλικ για να αρνηθείτε να δώσετε τη συγκατάθεσή σας ή να αποκτήσετε πρόσβαση σε πιο λεπτομερείς πληροφορίες για να αλλάξετε τις προτιμήσεις σας πριν από τη συγκατάθεσή σας. Οι προτιμήσεις σας θα ισχύουν για μια ομάδα ιστότοπων. Λάβετε υπόψη ότι κάποια επεξεργασία των προσωπικών σας δεδομένων ενδέχεται να μην απαιτεί τη συγκατάθεσή σας, αλλά έχετε το δικαίωμα να αντιταχθείτε σε αυτήν την επεξεργασία.',
      initScreenRejectButton: 'ΔΕΝ ΑΠΟΔΕΧΟΜΑΙ',
      initScreenSettingsButton: 'ΠΕΡΙΣΣΟΤΕΡΕΣ ΕΠΙΛΟΓΕΣ',
      initScreenTitle: 'Σεβόμαστε την ιδιωτικότητά σας',
      legalDescription: 'Νομική Περιγραφή',
      legitimateInterestPurposesLabel: 'ΕΓΚΥΡΟ ΕΝΔΙΑΦΕΡΟΝ',
      legitimateScreenObjected: 'ΑΠΟΡΡΙΦΘΕΝ',
      agreeButton: 'ΑΠΟΔΕΧΟΜΑΙ',
      legitimateScreenAccept: 'ΑΝΑΚΛΗΣΗ ΑΠΟΡΡΙΨΗΣ',
      legitimateScreenObject: 'Object',
      objectAllButton: 'ΑΠΟΡΡΙΨΗ ΟΛΩΝ',
      offLabel: 'Όχι',
      offOnLabel: 'Όχι/Ναι',
      onLabel: 'Ναι',
      multiLabel: 'ΠΟΛΛΑΠΛΟ',
      persistentConsentLinkLabel: 'Απόρρητο',
      privacyPolicyLabel: 'Πολιτική απορρήτου',
      legitimateScreenBody:
        'Ελέγξτε και απορρίψετε την επεξεργασία των προσωπικών σας δεδομένων χωρίς την συγκατάθεση σας βάσει έννομου συμφέροντος, για κάθε σκοπό και κάθε συνεργάτη παρακάτω. Επεκτείνετε κάθε σκοπό ή κάθε συνεργάτη στην λίστα για περισσότερες πληροφορίες που θα σας βοηθήσουν να κάνετε την επιλογή σας. Για να απορρίψετε τους ειδικούς σκοπούς που εγγυώνται την εξασφάλιση ασφάλειας, πρόληψη απάτης και εντοπισμό σφαλμάτων, και την τεχνική παράδοση διαφημίσεων ή περιεχομένου κάντε κλικ στον σύνδεσμο με την πολιτική απορρήτου του κάθε συνεργάτη.',
      saveAndExitButton: 'Αποθήκευση και έξοδος',
      purposeScreenVendorLink: 'ΣΥΝΕΡΓΑΤΕΣ',
      purposesLabel: 'Σκοποί (Συγκατάθεση)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Ειδικοί Σκοποί',
      specialFeaturesLabel: 'Ειδικά Χαρακτηριστικά',
      summaryScreenBodyNoRejectService: [
        'Εμείς και οι συνεργάτες μας αποθηκεύουμε ή/και έχουμε πρόσβαση σε πληροφορίες σε μια συσκευή, όπως cookies και επεξεργαζόμαστε προσωπικά δεδομένα, όπως μοναδικά αναγνωριστικά και τυπικές πληροφορίες που αποστέλλονται από μια συσκευή για εξατομικευμένες διαφημίσεις και περιεχόμενο, μέτρηση διαφημίσεων και περιεχομένου, καθώς και απόψεις του κοινού για την ανάπτυξη και βελτίωση προϊόντων.',
        'Με την άδειά σας, εμείς και οι συνεργάτες μας ενδέχεται να χρησιμοποιήσουμε ακριβή δεδομένα γεωγραφικής τοποθεσίας και ταυτοποίησης μέσω σάρωσης συσκευών. Μπορείτε να κάνετε κλικ για να συναινέσετε στην επεξεργασία από εμάς και τους συνεργάτες μας όπως περιγράφεται παραπάνω. Εναλλακτικά, μπορείτε να αποκτήσετε πρόσβαση σε πιο λεπτομερείς πληροφορίες και να αλλάξετε τις προτιμήσεις σας πριν από τη συγκατάθεσή σας ή να αρνηθείτε να δώσετε τη συγκατάθεσή σας. Λάβετε υπόψη ότι κάποια επεξεργασία των προσωπικών σας δεδομένων ενδέχεται να μην απαιτεί τη συγκατάθεσή σας, αλλά έχετε το δικαίωμα να αντιταχθείτε σε αυτήν την επεξεργασία. Οι προτιμήσεις σας θα ισχύουν μόνο για αυτόν τον ιστότοπο.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Εμείς και οι συνεργάτες μας αποθηκεύουμε ή/και έχουμε πρόσβαση σε πληροφορίες σε μια συσκευή, όπως cookies και επεξεργαζόμαστε προσωπικά δεδομένα, όπως μοναδικά αναγνωριστικά και τυπικές πληροφορίες που αποστέλλονται από μια συσκευή για εξατομικευμένες διαφημίσεις και περιεχόμενο, μέτρηση διαφημίσεων και περιεχομένου, καθώς και απόψεις του κοινού για την ανάπτυξη και βελτίωση προϊόντων. ',
        'Με την άδειά σας, εμείς και οι συνεργάτες μας ενδέχεται να χρησιμοποιήσουμε ακριβή δεδομένα γεωγραφικής τοποθεσίας και ταυτοποίησης μέσω σάρωσης συσκευών. Μπορείτε να κάνετε κλικ για να συναινέσετε στην επεξεργασία μας και των συνεργατών μας όπως περιγράφεται παραπάνω. Εναλλακτικά, μπορείτε να αποκτήσετε πρόσβαση σε πιο λεπτομερείς πληροφορίες και να αλλάξετε τις προτιμήσεις σας πριν από τη συγκατάθεσή σας ή να αρνηθείτε να δώσετε τη συγκατάθεσή σας. Λάβετε υπόψη ότι κάποια επεξεργασία των προσωπικών σας δεδομένων ενδέχεται να μην απαιτεί τη συγκατάθεσή σας, αλλά έχετε το δικαίωμα να αντιταχθείτε σε αυτήν την επεξεργασία. Οι προτιμήσεις σας θα ισχύουν σε ολόκληρο το διαδίκτυο.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Εμείς και οι συνεργάτες μας αποθηκεύουμε ή/και έχουμε πρόσβαση σε πληροφορίες σε μια συσκευή, όπως cookies και επεξεργαζόμαστε προσωπικά δεδομένα, όπως μοναδικά αναγνωριστικά και τυπικές πληροφορίες που αποστέλλονται από μια συσκευή για εξατομικευμένες διαφημίσεις και περιεχόμενο, μέτρηση διαφημίσεων και περιεχομένου, καθώς και απόψεις του κοινού για την ανάπτυξη και βελτίωση προϊόντων. ',
        'Με την άδειά σας, εμείς και οι συνεργάτες μας ενδέχεται να χρησιμοποιήσουμε ακριβή δεδομένα γεωγραφικής τοποθεσίας και ταυτοποίησης μέσω σάρωσης συσκευών. Μπορείτε να κάνετε κλικ για να συναινέσετε στην επεξεργασία μας και των συνεργατών μας όπως περιγράφεται παραπάνω. Εναλλακτικά, μπορείτε να αποκτήσετε πρόσβαση σε πιο λεπτομερείς πληροφορίες και να αλλάξετε τις προτιμήσεις σας πριν από τη συγκατάθεσή σας ή να αρνηθείτε να δώσετε τη συγκατάθεσή σας. Λάβετε υπόψη ότι κάποια επεξεργασία των προσωπικών σας δεδομένων ενδέχεται να μην απαιτεί τη συγκατάθεσή σας, αλλά έχετε το δικαίωμα να αντιταχθείτε σε αυτήν την επεξεργασία. Οι προτιμήσεις σας θα ισχύουν για μια ομάδα ιστότοπων.'
      ],
      summaryScreenBodyRejectService: [
        'Εμείς και οι συνεργάτες μας αποθηκεύουμε ή/και έχουμε πρόσβαση σε πληροφορίες σε μια συσκευή, όπως cookies και επεξεργαζόμαστε προσωπικά δεδομένα, όπως μοναδικά αναγνωριστικά και τυπικές πληροφορίες που αποστέλλονται από μια συσκευή για εξατομικευμένες διαφημίσεις και περιεχόμενο, μέτρηση διαφημίσεων και περιεχομένου, καθώς και απόψεις του κοινού για την ανάπτυξη και βελτίωση προϊόντων. ',
        'Με την άδειά σας, εμείς και οι συνεργάτες μας ενδέχεται να χρησιμοποιήσουμε ακριβή δεδομένα γεωγραφικής τοποθεσίας και ταυτοποίησης μέσω σάρωσης συσκευών. Μπορείτε να κάνετε κλικ για να συναινέσετε στην επεξεργασία μας και των συνεργατών μας όπως περιγράφεται παραπάνω. Εναλλακτικά, μπορείτε να αποκτήσετε πρόσβαση σε πιο λεπτομερείς πληροφορίες και να αλλάξετε τις προτιμήσεις σας πριν από τη συγκατάθεσή σας ή να αρνηθείτε να δώσετε τη συγκατάθεσή σας. Λάβετε υπόψη ότι κάποια επεξεργασία των προσωπικών σας δεδομένων ενδέχεται να μην απαιτεί τη συγκατάθεσή σας, αλλά έχετε το δικαίωμα να αντιταχθείτε σε αυτήν την επεξεργασία. Οι προτιμήσεις μας θα ισχύουν μόνο για αυτόν τον ιστότοπο.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Εμείς και οι συνεργάτες μας αποθηκεύουμε ή/και έχουμε πρόσβαση σε πληροφορίες σε μια συσκευή, όπως cookies και επεξεργαζόμαστε προσωπικά δεδομένα, όπως μοναδικά αναγνωριστικά και τυπικές πληροφορίες που αποστέλλονται από μια συσκευή για εξατομικευμένες διαφημίσεις και περιεχόμενο, μέτρηση διαφημίσεων και περιεχομένου, καθώς και απόψεις του κοινού για την ανάπτυξη και βελτίωση προϊόντων. ',
        'Με την άδειά σας, εμείς και οι συνεργάτες μας ενδέχεται να χρησιμοποιήσουμε ακριβή δεδομένα γεωγραφικής τοποθεσίας και ταυτοποίησης μέσω σάρωσης συσκευών. Μπορείτε να κάνετε κλικ για να συναινέσετε στην επεξεργασία μας και των συνεργατών μας όπως περιγράφεται παραπάνω. Εναλλακτικά, μπορείτε να αποκτήσετε πρόσβαση σε πιο λεπτομερείς πληροφορίες και να αλλάξετε τις προτιμήσεις σας πριν από τη συγκατάθεσή σας ή να αρνηθείτε να δώσετε τη συγκατάθεσή σας. Λάβετε υπόψη ότι κάποια επεξεργασία των προσωπικών σας δεδομένων ενδέχεται να μην απαιτεί τη συγκατάθεσή σας, αλλά έχετε το δικαίωμα να αντιταχθείτε σε αυτήν την επεξεργασία. Οι προτιμήσεις σας θα ισχύουν σε ολόκληρο το διαδίκτυο.'
      ],
      summaryScreenBodyRejectGroup: [
        'Εμείς και οι συνεργάτες μας αποθηκεύουμε ή/και έχουμε πρόσβαση σε πληροφορίες σε μια συσκευή, όπως cookies και επεξεργαζόμαστε προσωπικά δεδομένα, όπως μοναδικά αναγνωριστικά και τυπικές πληροφορίες που αποστέλλονται από μια συσκευή για εξατομικευμένες διαφημίσεις και περιεχόμενο, μέτρηση διαφημίσεων και περιεχομένου, καθώς και απόψεις του κοινού για την ανάπτυξη και βελτίωση προϊόντων. ',
        'Με την άδειά σας, εμείς και οι συνεργάτες μας ενδέχεται να χρησιμοποιήσουμε ακριβή δεδομένα γεωγραφικής τοποθεσίας και ταυτοποίησης μέσω σάρωσης συσκευών. Μπορείτε να κάνετε κλικ για να συναινέσετε στην επεξεργασία μας και των συνεργατών μας όπως περιγράφεται παραπάνω. Εναλλακτικά, μπορείτε να αποκτήσετε πρόσβαση σε πιο λεπτομερείς πληροφορίες και να αλλάξετε τις προτιμήσεις σας πριν από τη συγκατάθεσή σας ή να αρνηθείτε να δώσετε τη συγκατάθεσή σας. Λάβετε υπόψη ότι κάποια επεξεργασία των προσωπικών σας δεδομένων ενδέχεται να μην απαιτεί τη συγκατάθεσή σας, αλλά έχετε το δικαίωμα να αντιταχθείτε σε αυτήν την επεξεργασία. Οι προτιμήσεις σας θα ισχύουν για μια ομάδα ιστότοπων.'
      ],
      partners: 'οι συνεργάτες',
      groupOfSitesLabel: 'ομάδα ιστότοπων',
      vendorScreenBody:
        'Παρακαλώ ελέγξτε και ορίσετε τις επιλογές συγκατάθεσής σας για κάθε συνεργάτη παρακάτω. Επεκτείνετε κάθε συνεργάτη στην λίστα για περισσότερες πληροφορίες που θα σας βοηθήσουν να κάνετε την επιλογή σας. Η επεξεργασία κάποιων από τα προσωπικά σας δεδομένα μπορεί να γίνεται χωρίς την συγκατάθεσή σας, αλλά έχετε δικαίωμα να αρνηθείτε αυτή την επεξεργασία.',
      showPartners: 'Προβολή Συνεργατών',
      hidePartners: 'Απόκρυψη Συνεργατών'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Πάροχοι μη-IAB',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Hungarian
  hu: {
    agree: 'ELFOGADOM',
    saveAndExit: 'MENTÉS ÉS KILÉPÉS',
    disagree: 'NEM FOGADOM EL',
    objectAll: 'AZ ÖSSZES ELUTASÍTÁSA',
    mockPurposesCopies: {
      headline: 'Fontos számunkra adatainak védelme',
      body: 'Beállíthatja a hozzájárulási preferenciáit, és meghatározhatja, hogyan kerüljenek felhasználásra az adatai a jövőben az alábbi célok vonatkozásában. A ránk vonatkozó preferenciáit a harmadik felektől függetlenül adhatja meg. A célok melletti leírásból megtudhatja, hogy a partnereinkkel együtt hogyan használjuk fel az adatait.',
      accept: 'ÖSSZES ELFOGADÁSA',
      vendorLink: 'Beszállítók megtekintése',
      saveExit: 'Mentés és bezárás',
      cancel: 'Kilépés',
      consentLinkLabel: 'Adatvédelem'
    },
    mockHeadlinesCopies: {
      headline: 'Fontos számunkra adatainak védelme',
      accept: 'ELFOGADOM',
      reject: 'NEM FOGADOM EL',
      showPurposes: 'A célok megjelenítése',
    },
    coreUiLabels: {
      back: 'Vissza',
      acceptAll: 'ÖSSZES ELFOGADÁSA',
      descriptionLabel: 'Beszállító leírása:',
      featuresLabel: 'Funkciók',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Mi és partnereink információkat tárolunk vagy információkhoz férünk hozzá eszközökről, például sütik segítségével, hogy személyes adatokat, például egyedi azonosítókat és az eszköz által küldött, az alábbiakban részletezett célokra használható alapvető információkat dolgozunk fel. Kattintson, amennyiben hozzájárul a partnereink és az általunk végzett ilyen célú adatfeldolgozáshoz. Amennyiben szeretné, kattintással elutasíthatja a hozzájárulást, vagy a jóváhagyás előtt részletesebb információkhoz férhet hozzá, és megváltoztathatja beállításait. Beállításai csak erre a weboldalra vonatkoznak. Felhívjuk figyelmét, hogy személyes adatainak bizonyos fokú feldolgozásához nem feltétlenül van szükség az Ön hozzájárulására, ugyanakkor elutasíthatja az ilyen jellegű adatfeldolgozást.',
      initScreenBodyGlobal:
        'Mi és partnereink információkat tárolunk vagy információkhoz férünk hozzá eszközökről, például sütik segítségével, hogy személyes adatokat, például egyedi azonosítókat és az eszköz által küldött, az alábbiakban részletezett célokra használható alapvető információkat dolgozunk fel. Kattintson, amennyiben hozzájárul a partnereink és az általunk végzett ilyen célú adatfeldolgozáshoz. Amennyiben szeretné, kattintással elutasíthatja a hozzájárulást, vagy a jóváhagyás előtt részletesebb információkhoz férhet hozzá, és megváltoztathatja beállításait. Beállításai az összes weboldalra vonatkoznak. Felhívjuk figyelmét, hogy személyes adatainak bizonyos fokú feldolgozásához nem feltétlenül van szükség az Ön hozzájárulására, ugyanakkor elutasíthatja az ilyen jellegű adatfeldolgozást.',
      initScreenBodyGroup:
        'Mi és partnereink információkat tárolunk vagy információkhoz férünk hozzá eszközökről, például sütik segítségével, hogy személyes adatokat, például egyedi azonosítókat és az eszköz által küldött, az alábbiakban részletezett célokra használható alapvető információkat dolgozunk fel. Kattintson, amennyiben hozzájárul a partnereink és az általunk végzett ilyen célú adatfeldolgozáshoz. Amennyiben szeretné, kattintással elutasíthatja a hozzájárulást, vagy a jóváhagyás előtt részletesebb információkhoz férhet hozzá, és megváltoztathatja beállításait. Beállításai a webhelyek egy csoportjára vonatkoznak. Felhívjuk figyelmét, hogy személyes adatainak bizonyos fokú feldolgozásához nem feltétlenül van szükség az Ön hozzájárulására, ugyanakkor elutasíthatja az ilyen jellegű adatfeldolgozást.',
      initScreenRejectButton: 'NEM FOGADOM EL',
      initScreenSettingsButton: 'TOVÁBBI LEHETŐSÉGEK',
      initScreenTitle: 'Fontos számunkra adatainak védelme',
      legalDescription: 'Jogi leírás',
      legitimateInterestPurposesLabel: 'JOGOS ÉRDEKEK',
      legitimateScreenObjected: 'ELUTASÍTVA',
      agreeButton: 'ELFOGADOM',
      legitimateScreenAccept: 'ELUTASÍTÁS ELTÁVOLÍTÁSA',
      legitimateScreenObject: 'Object',
      objectAllButton: 'AZ ÖSSZES ELUTASÍTÁSA',
      offLabel: 'Ki',
      offOnLabel: 'Ki/Be',
      onLabel: 'Be',
      multiLabel: 'TÖBB',
      persistentConsentLinkLabel: 'Adatvédelem',
      privacyPolicyLabel: 'Adatvédelmi szabályzat',
      legitimateScreenBody:
        'Személyes adatainak beleegyezése nélküli feldolgozását jogos érdekek alapján minden célra és minden alább megnevezett partnerre vonatkozóan áttekintheti és elutasíthatja. További információkért és a döntésben való segítségért kattintással bontsa ki az egyes célokat vagy a partnerlista elemeit. Amennyiben el kívánja utasítani a speciális célú adatbiztosítást, csalásmegelőzést és hibakeresést, valamint a hirdetések vagy tartalmak technikai megjelenítését, kattintson a partner adatvédelmi irányelveinek linkjére.',
      saveAndExitButton: 'MENTÉS ÉS KILÉPÉS',
      purposeScreenVendorLink: 'PARTNEREK',
      purposesLabel: 'Célok (hozzájárulás)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Speciális célok',
      specialFeaturesLabel: 'Speciális funkciók',
      summaryScreenBodyNoRejectService: [
        'Mi és partnereink információkat tárolunk és/vagy információkhoz férünk hozzá eszközökről, például sütik segítségével, hogy személyes adatokat, például egyedi azonosítókat és az eszköz által küldött, személyre szabott hirdetésekhez és tartalmakhoz használható alapvető információkat dolgozzunk fel, hirdetés- és tartalommérést, valamint közönségelemzést végezzünk, illetve tökéletesítsük és fejlesszük termékeinket.',
        'Amennyiben engedélyezi, mi és partnereink felhasználhatjuk a pontos földrajzi helyadatait, és azonosítást végezhetünk eszközének leolvasásával. Kattintson, amennyiben hozzájárul a partnereink és az általunk végzett, fent részletezett adatfeldolgozáshoz. Mielőtt hozzájárulna vagy megtagadná a hozzájárulását, részletesebb információkhoz férhet hozzá, és megváltoztathatja beállításait. Felhívjuk figyelmét, hogy személyes adatainak bizonyos fokú feldolgozásához nem feltétlenül van szükség az Ön hozzájárulására, ugyanakkor elutasíthatja az ilyen jellegű adatfeldolgozást. Beállításai csak erre a weboldalra vonatkoznak.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Mi és partnereink információkat tárolunk és/vagy információkhoz férünk hozzá eszközökről, például sütik segítségével, hogy személyes adatokat, például egyedi azonosítókat és az eszköz által küldött, személyre szabott hirdetésekhez és tartalmakhoz használható alapvető információkat dolgozzunk fel, hirdetés- és tartalommérést, valamint közönségelemzést végezzünk, illetve tökéletesítsük és fejlesszük termékeinket.',
        'Amennyiben engedélyezi, mi és partnereink felhasználhatjuk a pontos földrajzi helyadatait, és azonosítást végezhetünk eszközének leolvasásával. Kattintson, amennyiben hozzájárul a partnereink és az általunk végzett, fent részletezett adatfeldolgozáshoz. Mielőtt hozzájárulna vagy megtagadná a hozzájárulását, részletesebb információkhoz férhet hozzá, és megváltoztathatja beállításait. Felhívjuk figyelmét, hogy személyes adatainak bizonyos fokú feldolgozásához nem feltétlenül van szükség az Ön hozzájárulására, ugyanakkor elutasíthatja az ilyen jellegű adatfeldolgozást. Beállításai az összes weboldalra vonatkoznak.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Mi és partnereink információkat tárolunk és/vagy információkhoz férünk hozzá eszközökről, például sütik segítségével, hogy személyes adatokat, például egyedi azonosítókat és az eszköz által küldött, személyre szabott hirdetésekhez és tartalmakhoz használható alapvető információkat dolgozzunk fel, hirdetés- és tartalommérést, valamint közönségelemzést végezzünk, illetve tökéletesítsük és fejlesszük termékeinket.',
        'Amennyiben engedélyezi, mi és partnereink felhasználhatjuk a pontos földrajzi helyadatait, és azonosítást végezhetünk eszközének leolvasásával. Kattintson, amennyiben hozzájárul a partnereink és az általunk végzett, fent részletezett adatfeldolgozáshoz. Mielőtt hozzájárulna vagy megtagadná a hozzájárulását, részletesebb információkhoz férhet hozzá, és megváltoztathatja beállításait. Felhívjuk figyelmét, hogy személyes adatainak bizonyos fokú feldolgozásához nem feltétlenül van szükség az Ön hozzájárulására, ugyanakkor elutasíthatja az ilyen jellegű adatfeldolgozást. Beállításai a webhelyek egy csoportjára vonatkoznak.'
      ],
      summaryScreenBodyRejectService: [
        'Mi és partnereink információkat tárolunk és/vagy információkhoz férünk hozzá eszközökről, például sütik segítségével, hogy személyes adatokat, például egyedi azonosítókat és az eszköz által küldött, személyre szabott hirdetésekhez és tartalmakhoz használható alapvető információkat dolgozzunk fel, hirdetés- és tartalommérést, valamint közönségelemzést végezzünk, illetve tökéletesítsük és fejlesszük termékeinket.',
        'Amennyiben engedélyezi, mi és partnereink felhasználhatjuk a pontos földrajzi helyadatait, és azonosítást végezhetünk eszközének leolvasásával. Kattintson, amennyiben hozzájárul a partnereink és az általunk végzett, fent részletezett adatfeldolgozáshoz. A hozzájárulást el is utasíthatja, vagy hozzájárulása előtt részletesebb információkhoz férhet hozzá, és megváltoztathatja beállításait. Felhívjuk figyelmét, hogy személyes adatainak bizonyos fokú feldolgozásához nem feltétlenül van szükség az Ön hozzájárulására, ugyanakkor elutasíthatja az ilyen jellegű adatfeldolgozást. Beállításai csak erre a weboldalra vonatkoznak.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Mi és partnereink információkat tárolunk és/vagy információkhoz férünk hozzá eszközökről, például sütik segítségével, hogy személyes adatokat, például egyedi azonosítókat és az eszköz által küldött, személyre szabott hirdetésekhez és tartalmakhoz használható alapvető információkat dolgozzunk fel, hirdetés- és tartalommérést, valamint közönségelemzést végezzünk, illetve tökéletesítsük és fejlesszük termékeinket.',
        'Amennyiben engedélyezi, mi és partnereink felhasználhatjuk a pontos földrajzi helyadatait, és azonosítást végezhetünk eszközének leolvasásával. Kattintson, amennyiben hozzájárul a partnereink és az általunk végzett, fent részletezett adatfeldolgozáshoz. Amennyiben szeretné, kattintással elutasíthatja a hozzájárulást, vagy a jóváhagyás előtt részletesebb információkhoz férhet hozzá, és megváltoztathatja beállításait. Felhívjuk figyelmét, hogy személyes adatainak bizonyos fokú feldolgozásához nem feltétlenül van szükség az Ön hozzájárulására, ugyanakkor elutasíthatja az ilyen jellegű adatfeldolgozást. Beállításai az összes weboldalra vonatkoznak.'
      ],
      summaryScreenBodyRejectGroup: [
        'Mi és partnereink információkat tárolunk és/vagy információkhoz férünk hozzá eszközökről, például sütik segítségével, hogy személyes adatokat, például egyedi azonosítókat és az eszköz által küldött, személyre szabott hirdetésekhez és tartalmakhoz használható alapvető információkat dolgozzunk fel, hirdetés- és tartalommérést, valamint közönségelemzést végezzünk, illetve tökéletesítsük és fejlesszük termékeinket.',
        'Amennyiben engedélyezi, mi és partnereink felhasználhatjuk a pontos földrajzi helyadatait, és azonosítást végezhetünk eszközének leolvasásával. Kattintson, amennyiben hozzájárul a partnereink és az általunk végzett, fent részletezett adatfeldolgozáshoz. Amennyiben szeretné, kattintással elutasíthatja a hozzájárulást, vagy a jóváhagyás előtt részletesebb információkhoz férhet hozzá, és megváltoztathatja beállításait. Felhívjuk figyelmét, hogy személyes adatainak bizonyos fokú feldolgozásához nem feltétlenül van szükség az Ön hozzájárulására, ugyanakkor elutasíthatja az ilyen jellegű adatfeldolgozást. Beállításai a webhelyek egy csoportjára vonatkoznak.'
      ],
      partners: 'és partnereink',
      groupOfSitesLabel: 'csoportjára vonatkoznak',
      vendorScreenBody:
        'Tekintse át és állítsa be a hozzájárulási beállításokat az összes alábbi partnernél. További információkért és a döntésben való segítségért kattintással bontsa ki az egyes partnerlista-elemeket. Néhány személyes adatot az Ön hozzájárulása nélkül dolgoztak fel, de Önnek jogában áll, hogy elutasítsa az ilyen jellegű adatfeldolgozást.',
      showPartners: 'Partnerek megjelenítése',
      hidePartners: 'Partnerek elrejtése'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Nem IAB-hez tartozó beszállítók',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Portuguese
  pt: {
    agree: 'CONCORDO',
    saveAndExit: 'GUARDAR E SAIR',
    disagree: 'DISCORDO',
    objectAll: 'REJEITAR TUDO',
    mockPurposesCopies: {
      headline: 'Damos valor à sua privacidade',
      body: 'Com base nas finalidades que se seguem, pode definir as suas preferências de consentimento e determinar como deseja que os seus dados sejam utilizados. Pode definir as suas preferências relativas à nossa empresa de forma independente das de terceiros. Cada finalidade contém uma descrição para saber como nós e os nossos parceiros utilizamos os seus dados.',
      accept: 'Aceitar tudo',
      vendorLink: 'Ver Fornecedores',
      saveExit: 'Guardar e sair',
      cancel: 'Cancelar',
      consentLinkLabel: 'Privacidade'
    },
    mockHeadlinesCopies: {
      headline: 'Damos valor à sua privacidade',
      accept: 'EU ACEITO',
      reject: 'EU NÃO ACEITO',
      showPurposes: 'Mostrar Finalidades',
    },
    coreUiLabels: {
      back: 'Anterior',
      acceptAll: 'ACEITAR TUDO',
      descriptionLabel: 'Descrição do Fornecedor:',
      featuresLabel: 'Funcionalidades',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Nós e os nossos parceiros armazenamos ou acedemos a informações num dispositivo, tais como cookies e processamos dados pessoais, tais como identificadores exclusivos e informações padrão enviadas para os propósitos descritos abaixo. Pode clicar para consentir com o nosso processamento e o dos nossos parceiros para tais propósitos. Como alternativa, pode clicar para recusar o consentimento ou aceder a informações mais detalhadas e alterar as suas preferências antes de consentir. As suas preferências serão aplicadas somente a este website. Observe que o processamento dos seus dados pessoais pode não exigir o seu consentimento, mas tem o direito de se opor a esse processamento.',
      initScreenBodyGlobal:
        'Nós e os nossos parceiros armazenamos ou acedemos a informações num dispositivo, tais como cookies e processamos dados pessoais, tais como identificadores exclusivos e informações padrão enviadas para os propósitos descritos abaixo. Pode clicar para consentir com o nosso processamento e o dos nossos parceiros para tais propósitos. Como alternativa, pode clicar para recusar o consentimento ou aceder a informações mais detalhadas para alterar as suas preferências antes de consentir. As suas preferências serão aplicadas a toda a web. Observe que algum do processamento dos seus dados pessoais pode não exigir o seu consentimento, mas tem o direito de se opor a esse processamento.',
      initScreenBodyGroup:
        'Nós e os nossos parceiros armazenamos ou acedemos a informações num dispositivo, tais como cookies e processamos dados pessoais, tais como identificadores exclusivos e informações padrão enviadas para os propósitos descritos abaixo. Pode clicar para consentir com o nosso processamento e o dos nossos parceiros para tais propósitos. Como alternativa, pode clicar para recusar o consentimento ou aceder a informações mais detalhadas e alterar as suas preferências antes de consentir. As suas preferências serão aplicadas a um grupo de websites. Observe que algum do processamento dos seus dados pessoais pode não exigir o seu consentimento, mas tem o direito de se opor a esse processamento.',
      initScreenRejectButton: 'DISCORDO',
      initScreenSettingsButton: 'MAIS OPÇÕES',
      initScreenTitle: 'Damos valor à sua privacidade',
      legalDescription: 'Descrição legal',
      legitimateInterestPurposesLabel: 'INTERESSES LEGÍTIMOS',
      legitimateScreenObjected: 'CONTESTADO',
      agreeButton: 'CONCORDO',
      legitimateScreenAccept: 'REMOVER OBJEÇÃO',
      legitimateScreenObject: 'Object',
      objectAllButton: 'REJEITAR TUDO',
      offLabel: 'Desligar',
      offOnLabel: 'Desligar/Ligar',
      onLabel: 'Ligar',
      multiLabel: 'Multi',
      persistentConsentLinkLabel: 'Privacidade',
      privacyPolicyLabel: 'Política de privacidade',
      legitimateScreenBody:
        'Analise e oponha-se ao processamento de dados pessoais sem o seu consentimento, com base num interesse legítimo para cada finalidade e por cada parceiro abaixo. Expanda cada item da lista de objetivos ou parceiros para obter mais informações e para ajudar a fazer a sua escolha. Para se opor aos propósitos especiais de garantir a segurança, impedir fraudes e depuração e entregar tecnicamente anúncios ou conteúdo, clique na ligação da política de privacidade de um parceiro.',
      saveAndExitButton: 'Guardar e sair',
      purposeScreenVendorLink: 'PARCEIROS',
      purposesLabel: 'Finalidades (Consentimento)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Finalidades especiais',
      specialFeaturesLabel: 'Funcionalidades especiais',
      summaryScreenBodyNoRejectService: [
        'Nós e os nossos parceiros armazenamos e/ou acedemos a informações num dispositivo, tais como cookies e processamos dados pessoais, tais como identificadores exclusivos e informações padrão enviadas por um dispositivo para anúncios e conteúdos personalizados, medição de anúncios e conteúdos e informações do público-alvo, assim como para desenvolver e melhorar produtos.',
        'Com a sua permissão, nós e os nossos parceiros podemos usar dados precisos de localização geográfica e identificação através da digitalização do dispositivo. Pode clicar para consentir com o nosso processamento e o dos nossos parceiros, conforme descrito acima. Como alternativa, pode aceder a informações mais detalhadas e alterar as suas preferências antes de consentir ou recusar o consentimento. Observe que algum do processamento dos seus dados pessoais pode não exigir o seu consentimento, mas tem o direito de se opor a esse processamento. As suas preferências serão aplicadas apenas a este website.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Nós e os nossos parceiros armazenamos e/ou acedemos a informações num dispositivo, tais como cookies e processamos dados pessoais, tais como identificadores exclusivos e informações padrão enviadas por um dispositivo para anúncios e conteúdos personalizados, medição de anúncios e conteúdos e informações do público-alvo, assim como para desenvolver e melhorar produtos.',
        'Com a sua permissão, nós e os nossos parceiros podemos usar dados precisos de localização geográfica e identificação através da digitalização do dispositivo. Pode clicar para consentir com o nosso processamento e o dos nossos parceiros, conforme descrito acima. Como alternativa, pode aceder a informações mais detalhadas e alterar as suas preferências antes de consentir ou recusar o consentimento. Observe que algum do processamento dos seus dados pessoais pode não exigir o seu consentimento, mas tem o direito de se opor a esse processamento. As suas preferências serão aplicadas a toda a web.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Nós e os nossos parceiros armazenamos e/ou acedemos a informações num dispositivo, tais como cookies e processamos dados pessoais, tais como identificadores exclusivos e informações padrão enviadas por um dispositivo para anúncios e conteúdos personalizados, medição de anúncios e conteúdos e informações do público-alvo, assim como para desenvolver e melhorar produtos.',
        'Com a sua permissão, nós e os nossos parceiros podemos usar dados precisos de localização geográfica e identificação através da digitalização do dispositivo. Pode clicar para consentir com o nosso processamento e o dos nossos parceiros, conforme descrito acima. Como alternativa, pode aceder a informações mais detalhadas e alterar as suas preferências antes de consentir ou recusar o consentimento. Observe que algum do processamento dos seus dados pessoais pode não exigir o seu consentimento, mas tem o direito de se opor a esse processamento. As suas preferências serão aplicadas a um grupo de websites.'
      ],
      summaryScreenBodyRejectService: [
        'Nós e os nossos parceiros armazenamos e/ou acedemos a informações num dispositivo, tais como cookies e processamos dados pessoais, tais como identificadores exclusivos e informações padrão enviadas por um dispositivo para anúncios e conteúdos personalizados, medição de anúncios e conteúdos e informações do público-alvo, assim como para desenvolver e melhorar produtos.',
        'Com a sua permissão, nós e os nossos parceiros podemos usar dados precisos de localização geográfica e identificação através da digitalização do dispositivo. Pode clicar para consentir com o nosso processamento e o dos nossos parceiros, conforme descrito acima. Como alternativa, pode aceder a informações mais detalhadas e alterar as suas preferências antes de consentir. Observe que o processamento dos seus dados pessoais pode não exigir o seu consentimento, mas tem o direito de se opor a esse processamento. As suas preferências serão aplicadas apenas a este website.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Nós e os nossos parceiros armazenamos e/ou acedemos a informações num dispositivo, tais como cookies e processamos dados pessoais, tais como identificadores exclusivos e informações padrão enviadas por um dispositivo para anúncios e conteúdos personalizados, medição de anúncios e conteúdos e informações do público-alvo, assim como para desenvolver e melhorar produtos.',
        'Com a sua permissão, nós e os nossos parceiros podemos usar dados precisos de localização geográfica e identificação através da digitalização do dispositivo. Pode clicar para consentir com o nosso processamento e o dos nossos parceiros, conforme descrito acima. Como alternativa, pode clicar para recusar o consentimento ou para aceder a informações mais detalhadas e alterar as suas preferências antes de consentir. Observe que algum do processamento dos seus dados pessoais pode não exigir o seu consentimento, mas tem o direito de se opor a esse processamento. As suas preferências serão aplicadas a toda a web.'
      ],
      summaryScreenBodyRejectGroup: [
        'Nós e os nossos parceiros armazenamos e/ou acedemos a informações num dispositivo, tais como cookies e processamos dados pessoais, tais como identificadores exclusivos e informações padrão enviadas por um dispositivo para anúncios e conteúdos personalizados, medição de anúncios e conteúdos e informações do público-alvo, assim como para desenvolver e melhorar produtos.',
        'Com a sua permissão, nós e os nossos parceiros podemos usar dados precisos de localização geográfica e identificação através da digitalização do dispositivo. Pode clicar para consentir com o nosso processamento e o dos nossos parceiros, conforme descrito acima. Como alternativa, pode clicar para recusar o consentimento ou para aceder a informações mais detalhadas e alterar as suas preferências antes de consentir. Observe que algum do processamento dos seus dados pessoais pode não exigir o seu consentimento, mas tem o direito de se opor a esse processamento. As suas preferências serão aplicadas a um grupo de websites.'
      ],
      partners: 'nossos parceiros',
      groupOfSitesLabel: 'grupo de websites',
      vendorScreenBody:
        'Reveja e defina as suas preferências de consentimento para cada parceiro abaixo. Expanda cada item da lista de parceiros para obter mais informações e ajudar a fazer a sua escolha. Alguns dados pessoais são processados sem o seu consentimento, mas tem o direito de se opor.',
      showPartners: 'Mostrar parceiros',
      hidePartners: 'Ocultar parceiros'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Fornecedores não IAB',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Romanian
  ro: {
    agree: 'SUNT DE ACORD',
    saveAndExit: 'SALVARE ȘI IEȘIRE',
    disagree: 'NU SUNT DE ACORD',
    objectAll: 'REFUZ TOT',
    mockPurposesCopies: {
      headline: 'Pentru noi, confidențialitatea dvs. este importantă',
      body: 'Vă puteți seta preferințele de consimțământ și determina cum doriți să vă fie utilizate datele în funcție de scopurile de mai jos. Vă puteți seta preferințele pentru noi independent de cele pentru partenerii terți. Fiecare scop are o descriere, astfel încât să știți modul în care noi și partenerii noștri vă utilizăm datele.',
      accept: 'ACCEPTAȚI-LE PE TOATE',
      vendorLink: 'Vizualizați furnizorii',
      saveExit: 'Salvare și ieșire',
      cancel: 'Anulare',
      consentLinkLabel: 'Confidențialitate'
    },
    mockHeadlinesCopies: {
      headline: 'Pentru noi, confidențialitatea dvs. este importantă',
      accept: 'Sunt de acord',
      reject: 'Nu sunt de acord',
      showPurposes: 'Afișați scopurile',
    },
    coreUiLabels: {
      back: 'Înapoi',
      acceptAll: 'ACCEPTAȚI-LE PE TOATE',
      descriptionLabel: 'Descriere furnizor:',
      featuresLabel: 'Caracteristici',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Noi și partenerii noștri stocăm sau accesăm informațiile de pe dispozitive, cum ar fi modulele cookie, și prelucrăm date cu caracter personal, cum ar fi identificatori unici și informații standard trimise de un dispozitiv, în scopurile descrise mai jos. Puteți da clic pentru a vă da acordul cu privire la prelucrarea în astfel de scopuri din partea noastră și a partenerilor noștri. În mod alternativ, puteți da clic pentru a refuza consimțământul sau pentru a accesa informații mai detaliate și pentru a schimba preferințele, înainte de a da consimțământul. Preferințele dvs. se vor aplica numai acestui site web. Vă rugăm să rețineți că este posibil ca anumite prelucrări ale datelor dvs. cu caracter personal să nu necesite consimțământul dvs., dar aveți dreptul de a refuza o astfel de prelucrare.',
      initScreenBodyGlobal:
        'Noi și partenerii noștri stocăm sau accesăm informațiile de pe dispozitive, cum ar fi modulele cookie, și prelucrăm date cu caracter personal, cum ar fi identificatori unici și informații standard trimise de un dispozitiv, în scopurile descrise mai jos. Puteți da clic pentru a vă da acordul cu privire la prelucrarea în astfel de scopuri din partea noastră și a partenerilor noștri. În mod alternativ, puteți da clic pentru a refuza consimțământul sau pentru a accesa informații mai detaliate, pentru a schimba preferințele înainte de a da consimțământul. Preferințele dvs. se aplică în rețea. Vă rugăm să rețineți că este posibil ca anumite prelucrări ale datelor dvs. cu caracter personal să nu necesite consimțământul dvs., dar aveți dreptul de a refuza o astfel de prelucrare.',
      initScreenBodyGroup:
        'Noi și partenerii noștri stocăm sau accesăm informațiile de pe dispozitive, cum ar fi modulele cookie, și prelucrăm date cu caracter personal, cum ar fi identificatori unici și informații standard trimise de un dispozitiv, în scopurile descrise mai jos. Puteți da clic pentru a vă da acordul cu privire la prelucrarea în astfel de scopuri din partea noastră și a partenerilor noștri. În mod alternativ, puteți da clic pentru a refuza consimțământul sau pentru a accesa informații mai detaliate și pentru a schimba preferințele, înainte de a da consimțământul. Preferințele dvs. se vor aplica unui grup de site-uri web. Vă rugăm să rețineți că este posibil ca anumite prelucrări ale datelor dvs. cu caracter personal să nu necesite consimțământul dvs., dar aveți dreptul de a refuza o astfel de prelucrare.',
      initScreenRejectButton: 'NU SUNT DE ACORD',
      initScreenSettingsButton: 'MAI MULTE OPȚIUNI',
      initScreenTitle: 'Pentru noi, confidențialitatea dvs. este importantă',
      legalDescription: 'Descriere legală',
      legitimateInterestPurposesLabel: 'INTERES LEGITIM',
      legitimateScreenObjected: 'AM REFUZAT',
      agreeButton: 'SUNT DE ACORD',
      legitimateScreenAccept: 'ANULARE REFUZ',
      legitimateScreenObject: 'Object',
      objectAllButton: 'REFUZ TOT',
      offLabel: 'Dezactivare',
      offOnLabel: 'Dezactivare/Activare',
      onLabel: 'Activare',
      multiLabel: 'Multi',
      persistentConsentLinkLabel: 'Confidențialitate',
      privacyPolicyLabel: 'Politica de confidențialitate',
      legitimateScreenBody:
        'Citiți și refuzați prelucrarea datelor cu caracter personal fără consimțământul dvs. pe baza unui interes legitim pentru fiecare scop și de către fiecare partener de mai jos. Pentru mai multe informații care să vă ajute să decideți, lărgiți lista specifică fiecărui scop și fiecărui partener. Pentru a refuza scopurile speciale de garantare a securității, prevenire a fraudelor și corectare, și de livrare tehnică de anunțuri sau conținut, faceți clic pe link-ul către politica de confidențialitate a partenerului în cauză.',
      saveAndExitButton: 'Salvare și ieșire',
      purposeScreenVendorLink: 'PARTENERI',
      purposesLabel: 'Scopuri (Consimțământ)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Scopuri speciale',
      specialFeaturesLabel: 'Caracteristici speciale',
      summaryScreenBodyNoRejectService: [
        'Noi și partenerii noștri stocăm și/sau accesăm informațiile de pe un dispozitiv, cum ar fi modulele cookie, și prelucrăm date cu caracter personal, cum ar fi identificatori unici și informații standard trimise de un dispozitiv, pentru anunțuri și conținut personalizate, verificări anunțuri și conținut, informații despre publicul țintă, precum și în scopul dezvoltării și îmbunătățirii produselor.',
        'Cu permisiunea dvs., noi și partenerii noștri putem folosi date și identificări precise de geolocalizare prin scanarea dispozitivului. Puteți da clic pentru a vă da acordul cu privire la prelucrarea descrisă mai sus din partea noastră și a partenerilor noștri. În mod alternativ, puteți accesa informații mai detaliate și puteți schimba preferințele, înainte de a vă exprima consimțământul, sau puteți refuza să vă dați consimțământul. Vă rugăm să rețineți că este posibil ca anumite prelucrări ale datelor dvs. cu caracter personal să nu necesite consimțământul dvs., dar aveți dreptul de a refuza o astfel de prelucrare. Preferințele dvs. se vor aplica numai acestui site web.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Noi și partenerii noștri stocăm și/sau accesăm informațiile de pe un dispozitiv, cum ar fi modulele cookie, și prelucrăm date cu caracter personal, cum ar fi identificatori unici și informații standard trimise de un dispozitiv, pentru anunțuri și conținut personalizate, verificări anunțuri și conținut, informații despre publicul țintă, precum și în scopul dezvoltării și îmbunătățirii produselor.',
        'Cu permisiunea dvs., noi și partenerii noștri putem folosi date și identificări precise de geolocalizare prin scanarea dispozitivului. Puteți da clic pentru a vă da acordul cu privire la prelucrarea descrisă mai sus din partea noastră și a partenerilor noștri. În mod alternativ, puteți accesa informații mai detaliate și puteți schimba preferințele, înainte de a vă exprima consimțământul, sau puteți refuza să vă dați consimțământul. Vă rugăm să rețineți că este posibil ca anumite prelucrări ale datelor dvs. cu caracter personal să nu necesite consimțământul dvs., dar aveți dreptul de a refuza o astfel de prelucrare. Preferințele dvs. se vor aplica în rețea.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Noi și partenerii noștri stocăm și/sau accesăm informațiile de pe un dispozitiv, cum ar fi modulele cookie, și prelucrăm date cu caracter personal, cum ar fi identificatori unici și informații standard trimise de un dispozitiv, pentru anunțuri și conținut personalizate, verificări anunțuri și conținut, informații despre publicul țintă, precum și în scopul dezvoltării și îmbunătățirii produselor.',
        'Cu permisiunea dvs., noi și partenerii noștri putem folosi date și identificări precise de geolocalizare prin scanarea dispozitivului. Puteți da clic pentru a vă da acordul cu privire la prelucrarea descrisă mai sus din partea noastră și a partenerilor noștri. În mod alternativ, puteți accesa informații mai detaliate și puteți schimba preferințele, înainte de a vă exprima consimțământul, sau puteți refuza să vă dați consimțământul. Vă rugăm să rețineți că este posibil ca anumite prelucrări ale datelor dvs. cu caracter personal să nu necesite consimțământul dvs., dar aveți dreptul de a refuza o astfel de prelucrare. Preferințele dvs. se vor aplica unui grup de site-uri web.'
      ],
      summaryScreenBodyRejectService: [
        'Noi și partenerii noștri stocăm și/sau accesăm informațiile de pe un dispozitiv, cum ar fi modulele cookie, și prelucrăm date cu caracter personal, cum ar fi identificatori unici și informații standard trimise de un dispozitiv, pentru anunțuri și conținut personalizate, verificări anunțuri și conținut, informații despre publicul țintă, precum și în scopul dezvoltării și îmbunătățirii produselor.',
        'Cu permisiunea dvs., noi și partenerii noștri putem folosi date și identificări precise de geolocalizare prin scanarea dispozitivului. Puteți da clic pentru a vă da acordul cu privire la prelucrarea descrisă mai sus din partea noastră și a partenerilor noștri. În mod alternativ, puteți accesa informații mai detaliate și puteți schimba preferințele, înainte de a vă exprima consimțământul, sau puteți refuza să vă dați consimțământul. Vă rugăm să rețineți că este posibil ca anumite prelucrări ale datelor dvs. cu caracter personal să nu necesite consimțământul dvs., dar aveți dreptul de a refuza o astfel de prelucrare. Preferințele dvs. se vor aplica numai acestui site web.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Noi și partenerii noștri stocăm și/sau accesăm informațiile de pe un dispozitiv, cum ar fi modulele cookie, și prelucrăm date cu caracter personal, cum ar fi identificatori unici și informații standard trimise de un dispozitiv, pentru anunțuri și conținut personalizate, verificări anunțuri și conținut, informații despre publicul țintă, precum și în scopul dezvoltării și îmbunătățirii produselor.',
        'Cu permisiunea dvs., noi și partenerii noștri putem folosi date și identificări precise de geolocalizare prin scanarea dispozitivului. Puteți da clic pentru a vă da acordul cu privire la prelucrarea descrisă mai sus din partea noastră și a partenerilor noștri. În mod alternativ, puteți da clic pentru a refuza consimțământul sau pentru a accesa informații mai detaliate și pentru a schimba preferințele, înainte de a da consimțământul. Vă rugăm să rețineți că este posibil ca anumite prelucrări ale datelor dvs. cu caracter personal să nu necesite consimțământul dvs., dar aveți dreptul de a refuza o astfel de prelucrare. Preferințele dvs. se vor aplica în rețea.'
      ],
      summaryScreenBodyRejectGroup: [
        'Noi și partenerii noștri stocăm și/sau accesăm informațiile de pe un dispozitiv, cum ar fi modulele cookie, și prelucrăm date cu caracter personal, cum ar fi identificatori unici și informații standard trimise de un dispozitiv, pentru anunțuri și conținut personalizate, verificări anunțuri și conținut, informații despre publicul țintă, precum și în scopul dezvoltării și îmbunătățirii produselor.',
        'Cu permisiunea dvs., noi și partenerii noștri putem folosi date și identificări precise de geolocalizare prin scanarea dispozitivului. Puteți da clic pentru a vă da acordul cu privire la prelucrarea descrisă mai sus din partea noastră și a partenerilor noștri. În mod alternativ, puteți da clic pentru a refuza consimțământul sau pentru a accesa informații mai detaliate și pentru a schimba preferințele, înainte de a da consimțământul. Vă rugăm să rețineți că este posibil ca anumite prelucrări ale datelor dvs. cu caracter personal să nu necesite consimțământul dvs., dar aveți dreptul de a refuza o astfel de prelucrare. Preferințele dvs. se vor aplica unui grup de site-uri web.'
      ],
      partners: 'partenerii noștri',
      groupOfSitesLabel: 'grup de site-uri web',
      vendorScreenBody:
        'Citiți și setați preferințele privind consimțământul pentru fiecare partener de mai jos. Pentru mai multe informații care să vă ajute să decideți, lărgiți lista specifică fiecărui partener. Unele date cu caracter personal sunt prelucrate fără consimțământul dvs., dar aveți dreptul de a refuza acest lucru.',
      showPartners: 'Afișează Partenerii',
      hidePartners: 'Ascunde Partenerii'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Furnizori non-IAB',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Finnish
  fi: {
    agree: 'HYVÄKSY',
    saveAndExit: 'TALLENNA JA POISTU',
    disagree: 'KIELTÄYDY',
    objectAll: 'VASTUSTA KAIKKIA',
    mockPurposesCopies: {
      headline: 'Yksityisyytesi on meille tärkeää',
      body: 'Voit asettaa suostumusasetuksesi ja määrittää, miten haluat tietojasi käytettävän alla olevien tarkoitusten perusteella. Voit asettaa meitä koskevat asetuksesi riippumatta kolmannen osapuolen yhteistyökumppaneita koskevista asetuksista. Jokaisella tarkoituksella on kuvaus, jotta tiedät, miten me ja yhteistyökumppanimme käytämme tietojasi.',
      accept: 'HYVÄKSY KAIKKI',
      vendorLink: 'Katso toimittajat',
      saveExit: 'Tallenna ja poistu',
      cancel: 'Peruuta',
      consentLinkLabel: 'Yksityisyys'
    },
    mockHeadlinesCopies: {
      headline: 'Yksityisyytesi on meille tärkeää',
      accept: 'Hyväksyn',
      reject: 'EN HYVÄKSY',
      showPurposes: 'Näytä tarkoitukset',
    },
    coreUiLabels: {
      back: 'Takaisin',
      acceptAll: 'HYVÄKSY KAIKKI',
      descriptionLabel: 'Toimittajan kuvaus:',
      featuresLabel: 'Ominaisuudet',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Me ja kumppanimme säilytämme tai käytämme laitteilla olevia tietoja, kuten evästeitä, ja käsittelemme henkilökohtaisia tietoja, kuten yksilöllisiä tunnisteita ja laitteen lähettämiä vakiotietoja alla mainittuihin tarpeisiin. Voit napsauttamalla suostua meidän ja kumppaneidemme tietojesi käsittelyyn yllä kuvatulla tavalla. Vaihtoehtoisesti voit kieltää suostumuksesi tai siirtyä yksityiskohtaisempiin tietoihin ja muuttaa mieltymyksiäsi ennen suostumuksesi ilmaisemista. Valinta-asetuksesi koskevat vain tätä verkkosivustoa. Huomaa, että henkilötietojesi käsittely ei ehkä edellytä suostumustasi, mutta sinulla on oikeus vastustaa tällaista käsittelyä.',
      initScreenBodyGlobal:
        'Me ja kumppanimme säilytämme tai käytämme laitteilla olevia tietoja, kuten evästeitä, ja käsittelemme henkilökohtaisia tietoja, kuten yksilöllisiä tunnisteita ja laitteen lähettämiä vakiotietoja alla mainittuihin tarpeisiin. Voit napsauttamalla suostua meidän ja kumppaneidemme tietojesi käsittelyyn yllä kuvatulla tavalla. Vaihtoehtoisesti voit kieltää suostumuksesi tai siirtyä yksityiskohtaisempiin tietoihin ja muuttaa mieltymyksiäsi ennen suostumuksesi ilmaisemista. Valinta-asetuksesi ovat voimassa kaikkialla verkossa. Huomaa, että henkilötietojesi käsittely ei ehkä edellytä suostumustasi, mutta sinulla on oikeus vastustaa tällaista käsittelyä.',
      initScreenBodyGroup:
        'Me ja kumppanimme säilytämme tai käytämme laitteilla olevia tietoja, kuten evästeitä, ja käsittelemme henkilökohtaisia tietoja, kuten yksilöllisiä tunnisteita ja laitteen lähettämiä vakiotietoja alla mainittuihin tarpeisiin. Voit napsauttamalla suostua meidän ja kumppaneidemme tietojesi käsittelyyn yllä kuvatulla tavalla. Vaihtoehtoisesti voit kieltää suostumuksesi tai siirtyä yksityiskohtaisempiin tietoihin ja muuttaa mieltymyksiäsi ennen suostumuksesi ilmaisemista. Valinta-asetuksesi koskevat verkkosivustojen ryhmää. Huomaa, että henkilötietojesi käsittely ei ehkä edellytä suostumustasi, mutta sinulla on oikeus vastustaa tällaista käsittelyä.',
      initScreenRejectButton: 'KIELTÄYDY',
      initScreenSettingsButton: 'LISÄÄ VAIHTOEHTOJA',
      initScreenTitle: 'Yksityisyytesi on meille tärkeää',
      legalDescription: 'Laillinen kuvaus',
      legitimateInterestPurposesLabel: 'OIKEUTETTU ETU',
      legitimateScreenObjected: 'VASTUSTETTU',
      agreeButton: 'HYVÄKSY',
      legitimateScreenAccept: 'PERUUTA VASTUSTUS',
      legitimateScreenObject: 'Object',
      objectAllButton: 'VASTUSTA KAIKKIA',
      offLabel: 'Pois',
      offOnLabel: 'Pois/Päälle',
      onLabel: 'Päälle',
      multiLabel: 'USEAT',
      persistentConsentLinkLabel: 'Yksityisyys',
      privacyPolicyLabel: 'Tietosuojaseloste',
      legitimateScreenBody:
        'Arvioi henkilötietojesi käsittelyä ilman suostumustasi oikeutettujen etujen perusteella jokaista yksittäistä tarkoitusta varten ja kunkin kumppanin toimesta alla. Laajenna kutakin tarkoitus- tai kumppaniluettelon kohtaa saadaksesi lisätietoja valinnan tekemiseksi. Jos haluat vastustaa tietoturvan takaamisen, petosten estämisen ja virheenkorjauksen erityistarkoituksia sekä mainosten tai sisällön teknistä toimittamista, napsauta kumppanin tietosuojakäytäntölinkkiä.',
      saveAndExitButton: 'Tallenna ja poistu',
      purposeScreenVendorLink: 'KUMPPANIT',
      purposesLabel: 'Tarkoitukset (suostumus)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Erityistarkoitukset',
      specialFeaturesLabel: 'Erityiset ominaisuudet',
      summaryScreenBodyNoRejectService: [
        'Me ja kumppanimme säilytämme ja/tai käytämme laitteella olevia tietoja, kuten evästeitä, ja käsittelemme henkilökohtaisia tietoja, kuten yksilöllisiä tunnisteita ja laitteen lähettämiä vakiotietoja mainosten ja sisällön henkilökohtaistamisen, mainosten ja sisällön mittaamisen sekä yleisön mielipiteiden keräämisen tarpeisiin ja tuotteiden kehittämiseksi ja parantamiseksi. Me ja yhteistyökumppanimme voimme suostumuksellasi käyttää tarkkoja paikkatietoja ja tunnistusta laitteen skannauksen avulla.',
        'Voit napsauttamalla suostua meidän ja kumppaneidemme tietojesi käsittelyyn yllä kuvatulla tavalla. Vaihtoehtoisesti voit siirtyä yksityiskohtaisempiin tietoihin ja muuttaa mieltymyksiäsi ennen suostumuksesi tai kieltäytymisesi ilmaisemista. Huomaa, että henkilötietojesi käsittely ei ehkä edellytä suostumustasi, mutta sinulla on oikeus vastustaa tällaista käsittelyä. Valinta-asetuksesi koskevat vain tätä verkkosivustoa.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Me ja kumppanimme säilytämme ja/tai käytämme laitteella olevia tietoja, kuten evästeitä, ja käsittelemme henkilökohtaisia tietoja, kuten yksilöllisiä tunnisteita ja laitteen lähettämiä vakiotietoja mainosten ja sisällön henkilökohtaistamisen, mainosten ja sisällön mittaamisen sekä yleisön mielipiteiden keräämisen tarpeisiin ja tuotteiden kehittämiseksi ja parantamiseksi. Me ja yhteistyökumppanimme voimme suostumuksellasi käyttää tarkkoja paikkatietoja ja tunnistusta laitteen skannauksen avulla.',
        'Voit napsauttamalla suostua meidän ja kumppaneidemme tietojesi käsittelyyn yllä kuvatulla tavalla. Vaihtoehtoisesti voit siirtyä yksityiskohtaisempiin tietoihin ja muuttaa mieltymyksiäsi ennen suostumuksesi tai kieltäytymisesi ilmaisemista. Huomaa, että henkilötietojesi käsittely ei ehkä edellytä suostumustasi, mutta sinulla on oikeus vastustaa tällaista käsittelyä. Valinta-asetuksesi ovat voimassa kaikkialla verkossa.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Me ja kumppanimme säilytämme ja/tai käytämme laitteella olevia tietoja, kuten evästeitä, ja käsittelemme henkilökohtaisia tietoja, kuten yksilöllisiä tunnisteita ja laitteen lähettämiä vakiotietoja mainosten ja sisällön henkilökohtaistamisen, mainosten ja sisällön mittaamisen sekä yleisön mielipiteiden keräämisen tarpeisiin ja tuotteiden kehittämiseksi ja parantamiseksi. Me ja yhteistyökumppanimme voimme suostumuksellasi käyttää tarkkoja paikkatietoja ja tunnistusta laitteen skannauksen avulla.',
        'Voit napsauttamalla suostua meidän ja kumppaneidemme tietojesi käsittelyyn yllä kuvatulla tavalla. Vaihtoehtoisesti voit siirtyä yksityiskohtaisempiin tietoihin ja muuttaa mieltymyksiäsi ennen suostumuksesi tai kieltäytymisesi ilmaisemista. Huomaa, että henkilötietojesi käsittely ei ehkä edellytä suostumustasi, mutta sinulla on oikeus vastustaa tällaista käsittelyä. Valinta-asetuksesi koskevat verkkosivustojen ryhmää.'
      ],
      summaryScreenBodyRejectService: [
        'Me ja kumppanimme säilytämme ja/tai käytämme laitteella olevia tietoja, kuten evästeitä, ja käsittelemme henkilökohtaisia tietoja, kuten yksilöllisiä tunnisteita ja laitteen lähettämiä vakiotietoja mainosten ja sisällön henkilökohtaistamisen, mainosten ja sisällön mittaamisen sekä yleisön mielipiteiden keräämisen tarpeisiin ja tuotteiden kehittämiseksi ja parantamiseksi. Me ja yhteistyökumppanimme voimme suostumuksellasi käyttää tarkkoja paikkatietoja ja tunnistusta laitteen skannauksen avulla.',
        'Voit napsauttamalla suostua meidän ja kumppaneidemme tietojesi käsittelyyn yllä kuvatulla tavalla. Vaihtoehtoisesti voit kieltää suostumuksesi tai siirtyä yksityiskohtaisempiin tietoihin ja muuttaa mieltymyksiäsi ennen suostumuksesi ilmaisemista. Huomaa, että henkilötietojesi käsittely ei ehkä edellytä suostumustasi, mutta sinulla on oikeus vastustaa tällaista käsittelyä. Valinta-asetuksesi koskevat vain tätä verkkosivustoa.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Me ja kumppanimme säilytämme ja/tai käytämme laitteella olevia tietoja, kuten evästeitä, ja käsittelemme henkilökohtaisia tietoja, kuten yksilöllisiä tunnisteita ja laitteen lähettämiä vakiotietoja mainosten ja sisällön henkilökohtaistamisen, mainosten ja sisällön mittaamisen sekä yleisön mielipiteiden keräämisen tarpeisiin ja tuotteiden kehittämiseksi ja parantamiseksi. Me ja yhteistyökumppanimme voimme suostumuksellasi käyttää tarkkoja paikkatietoja ja tunnistusta laitteen skannauksen avulla.',
        'Voit napsauttamalla suostua meidän ja kumppaneidemme tietojesi käsittelyyn yllä kuvatulla tavalla. Vaihtoehtoisesti voit kieltää suostumuksesi tai siirtyä yksityiskohtaisempiin tietoihin ja muuttaa mieltymyksiäsi ennen suostumuksesi ilmaisemista. Huomaa, että henkilötietojesi käsittely ei ehkä edellytä suostumustasi, mutta sinulla on oikeus vastustaa tällaista käsittelyä. Valinta-asetuksesi ovat voimassa kaikkialla verkossa.'
      ],
      summaryScreenBodyRejectGroup: [
        'Me ja kumppanimme säilytämme ja/tai käytämme laitteella olevia tietoja, kuten evästeitä, ja käsittelemme henkilökohtaisia tietoja, kuten yksilöllisiä tunnisteita ja laitteen lähettämiä vakiotietoja mainosten ja sisällön henkilökohtaistamisen, mainosten ja sisällön mittaamisen sekä yleisön mielipiteiden keräämisen tarpeisiin ja tuotteiden kehittämiseksi ja parantamiseksi. Me ja yhteistyökumppanimme voimme suostumuksellasi käyttää tarkkoja paikkatietoja ja tunnistusta laitteen skannauksen avulla.',
        'Voit napsauttamalla suostua meidän ja kumppaneidemme tietojesi käsittelyyn yllä kuvatulla tavalla. Vaihtoehtoisesti voit kieltää suostumuksesi tai siirtyä yksityiskohtaisempiin tietoihin ja muuttaa mieltymyksiäsi ennen suostumuksesi ilmaisemista. Huomaa, että henkilötietojesi käsittely ei ehkä edellytä suostumustasi, mutta sinulla on oikeus vastustaa tällaista käsittelyä. Valinta-asetuksesi koskevat verkkosivustojen ryhmää.'
      ],
      partners: 'ja kumppanimme',
      groupOfSitesLabel: 'verkkosivustojen ryhmää',
      vendorScreenBody:
        'Tarkista ja määritä kumppanikohtaiset suostumusasetuksesi alla. Laajenna kutakin kumppaniluettelon kohtaa saadaksesi lisätietoja valinnan tekemiseksi. Jotkut henkilötiedot käsitellään ilman suostumustasi, mutta sinulla on oikeus vastustaa tällaista käsittelyä.',
      showPartners: 'Näytä kumppanit',
      hidePartners: 'Piilota kumppanit'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Muut kuin IAB-toimittajat',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Polish
  pl: {
    agree: 'ZGADZAM SIĘ',
    saveAndExit: 'ZAPISZ I ZAMKNIJ',
    disagree: 'NIE ZGADZAM SIĘ',
    objectAll: 'WYRAŹ SPRZECIW WOBEC WSZYSTKICH',
    mockPurposesCopies: {
      headline: 'Szanujemy Twoją prywatność',
      body: 'Możesz ustawić preferencje dotyczące zgody i określić, w jaki sposób chcesz, aby Twoje dane były używane w oparciu o poniższe cele. Możesz ustawić swoje preferencje dla nas niezależnie od preferencji partnerów zewnętrznych. Każdy cel zawiera opis, dzięki któremu wiesz, jak my i partnerzy korzystamy z Twoich danych.',
      accept: 'AKCEPTUJ WSZYSTKIE',
      vendorLink: 'Zobacz dostawców',
      saveExit: 'Zapisz i zamknij',
      cancel: 'Anuluj',
      consentLinkLabel: 'Prywatność'
    },
    mockHeadlinesCopies: {
      headline: 'Szanujemy Twoją prywatność',
      accept: 'AKCEPTUJĘ',
      reject: 'NIE AKCEPTUJĘ',
      showPurposes: 'Pokaż cele',
    },
    coreUiLabels: {
      back: 'Wstecz',
      acceptAll: 'AKCEPTUJ WSZYSTKIE',
      descriptionLabel: 'Opis dostawcy:',
      featuresLabel: 'Funkcje',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Razem z naszymi partnerami uzyskujemy dostęp do informacji na urządzeniach, takich jak pliki cookie oraz je przechowujemy, i przetwarzamy dane osobowe, np. unikalne identyfikatory i standardowe informacje wysyłane przez urządzenie w celach opisanych poniżej. Wystarczy kliknąć przycisk poniżej, aby wyrazić zgodę na przetwarzanie danych w tych celach przez nas i naszych partnerów. Można też nie wyrazić zgody lub uzyskać dostęp do bardziej szczegółowych informacji i zmienić swoje preferencje przed wyrażeniem zgody lub odmówić jej wyrażenia. Preferencje będą miały zastosowanie tylko do tej witryny. Należy pamiętać, że niektóre rodzaje przetwarzania danych osobowych mogą nie wymagać zgody użytkownika, ale ma on prawo sprzeciwić się takiemu przetwarzaniu.',
      initScreenBodyGlobal:
        'Razem z naszymi partnerami uzyskujemy dostęp do informacji na urządzeniach, takich jak pliki cookie oraz je przechowujemy, i przetwarzamy dane osobowe, np. unikalne identyfikatory i standardowe informacje wysyłane przez urządzenie w celach opisanych poniżej. Wystarczy kliknąć przycisk poniżej, aby wyrazić zgodę na przetwarzanie danych w tych celach przez nas i naszych partnerów. Można też nie wyrazić zgody lub uzyskać dostęp do bardziej szczegółowych informacji i zmienić swoje preferencje przed wyrażeniem zgody lub odmówić jej wyrażenia. Preferencje będą miały zastosowanie w całej sieci. Należy pamiętać, że niektóre rodzaje przetwarzania danych osobowych mogą nie wymagać zgody użytkownika, ale ma on prawo sprzeciwić się takiemu przetwarzaniu.',
      initScreenBodyGroup:
        'Razem z naszymi partnerami uzyskujemy dostęp do informacji na urządzeniach, takich jak pliki cookie oraz je przechowujemy, i przetwarzamy dane osobowe, np. unikalne identyfikatory i standardowe informacje wysyłane przez urządzenie w celach opisanych poniżej. Wystarczy kliknąć przycisk poniżej, aby wyrazić zgodę na przetwarzanie danych w tych celach przez nas i naszych partnerów. Można też nie wyrazić zgody lub uzyskać dostęp do bardziej szczegółowych informacji i zmienić swoje preferencje przed wyrażeniem zgody lub odmówić jej wyrażenia. Preferencje będą miały zastosowanie do grupy witryn. Należy pamiętać, że niektóre rodzaje przetwarzania danych osobowych mogą nie wymagać zgody użytkownika, ale ma on prawo sprzeciwić się takiemu przetwarzaniu.',
      initScreenRejectButton: 'NIE ZGADZAM SIĘ',
      initScreenSettingsButton: 'WIĘCEJ OPCJI',
      initScreenTitle: 'Szanujemy Twoją prywatność',
      legalDescription: 'Szczegóły dotyczące przepisów prawa',
      legitimateInterestPurposesLabel: 'UZASADNIONY INTERES',
      legitimateScreenObjected: 'WYRAŻONO SPRZECIW',
      agreeButton: 'ZGADZAM SIĘ',
      legitimateScreenAccept: 'USUŃ SPRZECIW',
      legitimateScreenObject: 'Object',
      objectAllButton: 'Sprzeciw się wszystkim',
      offLabel: 'Wyłącz',
      offOnLabel: 'Wyłącz/Włącz',
      onLabel: 'Włącz',
      multiLabel: 'Multi',
      persistentConsentLinkLabel: 'Prywatność',
      privacyPolicyLabel: 'Polityka prywatności',
      legitimateScreenBody:
        'Zapoznaj się z zasadami przetwarzania danych osobowych bez Twojej zgody na podstawie uzasadnionego interesu i wyraź sprzeciw dla każdego celu i partnera poniżej. Rozwiń każdy element na liście celów lub partnerów, aby uzyskać więcej informacji, które pomogą Ci podjąć decyzję. Aby sprzeciwić się specjalnym celom dotyczącym bezpieczeństwa, np. zapobieganiu oszustwom i debugowaniu oraz faktycznemu dostarczaniu reklam lub treści, kliknij link do polityki prywatności partnera.',
      saveAndExitButton: 'Zapisz i zamknij',
      purposeScreenVendorLink: 'PARTNERZY',
      purposesLabel: 'Cele (zgoda)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Specjalne cele',
      specialFeaturesLabel: 'Specjalne funkcje',
      summaryScreenBodyNoRejectService: [
        'Razem z naszymi partnerami uzyskujemy dostęp do informacji na urządzeniu, takich jak pliki cookie oraz je przechowujemy, i przetwarzamy dane osobowe, np. unikalne identyfikatory i standardowe informacje wysyłane przez urządzenie w celu zapewniania spersonalizowanych reklam i treści, analizy reklam i treści oraz zbierania opinii użytkowników, a także rozwijania i ulepszania produktów.',
        'Za zgodą użytkownika my i nasi partnerzy możemy wykorzystywać precyzyjne dane geolokalizacyjne i identyfikację poprzez skanowanie urządzeń. Wystarczy kliknąć przycisk poniżej, aby wyrazić zgodę na przetwarzanie danych przez nas i naszych partnerów, zgodnie z opisem powyżej. Można też uzyskać dostęp do bardziej szczegółowych informacji i zmienić swoje preferencje przed wyrażeniem zgody lub odmówić jej wyrażenia. Należy pamiętać, że niektóre rodzaje przetwarzania danych osobowych mogą nie wymagać zgody użytkownika, ale ma on prawo sprzeciwić się takiemu przetwarzaniu. Preferencje będą miały zastosowanie tylko do tej witryny.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Razem z naszymi partnerami uzyskujemy dostęp do informacji na urządzeniu, takich jak pliki cookie oraz je przechowujemy, i przetwarzamy dane osobowe, np. unikalne identyfikatory i standardowe informacje wysyłane przez urządzenie w celu zapewniania spersonalizowanych reklam i treści, analizy reklam i treści oraz zbierania opinii użytkowników, a także rozwijania i ulepszania produktów. Za zgodą użytkownika my i nasi partnerzy możemy wykorzystywać precyzyjne dane geolokalizacyjne i identyfikację poprzez skanowanie urządzeń.',
        'Wystarczy kliknąć przycisk poniżej, aby wyrazić zgodę na przetwarzanie danych przez nas i naszych partnerów, zgodnie z opisem powyżej. Można też uzyskać dostęp do bardziej szczegółowych informacji i zmienić swoje preferencje przed wyrażeniem zgody lub odmówić jej wyrażenia. Należy pamiętać, że niektóre rodzaje przetwarzania danych osobowych mogą nie wymagać zgody użytkownika, ale ma on prawo sprzeciwić się takiemu przetwarzaniu. Preferencje będą miały zastosowanie w całej sieci.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Razem z naszymi partnerami uzyskujemy dostęp do informacji na urządzeniu, takich jak pliki cookie oraz je przechowujemy, i przetwarzamy dane osobowe, np. unikalne identyfikatory i standardowe informacje wysyłane przez urządzenie w celu zapewniania spersonalizowanych reklam i treści, analizy reklam i treści oraz zbierania opinii użytkowników, a także rozwijania i ulepszania produktów. Za zgodą użytkownika my i nasi partnerzy możemy wykorzystywać precyzyjne dane geolokalizacyjne i identyfikację poprzez skanowanie urządzeń.',
        'Wystarczy kliknąć przycisk poniżej, aby wyrazić zgodę na przetwarzanie danych przez nas i naszych partnerów, zgodnie z opisem powyżej. Można też uzyskać dostęp do bardziej szczegółowych informacji i zmienić swoje preferencje przed wyrażeniem zgody lub odmówić jej wyrażenia. Należy pamiętać, że niektóre rodzaje przetwarzania danych osobowych mogą nie wymagać zgody użytkownika, ale ma on prawo sprzeciwić się takiemu przetwarzaniu. Preferencje będą miały zastosowanie do grupy witryn.'
      ],
      summaryScreenBodyRejectService: [
        'Razem z naszymi partnerami uzyskujemy dostęp do informacji na urządzeniu, takich jak pliki cookie oraz je przechowujemy, i przetwarzamy dane osobowe, np. unikalne identyfikatory i standardowe informacje wysyłane przez urządzenie w celu zapewniania spersonalizowanych reklam i treści, analizy reklam i treści oraz zbierania opinii użytkowników, a także rozwijania i ulepszania produktów. Za zgodą użytkownika my i nasi partnerzy możemy wykorzystywać precyzyjne dane geolokalizacyjne i identyfikację poprzez skanowanie urządzeń.',
        'Wystarczy kliknąć przycisk poniżej, aby wyrazić zgodę na przetwarzanie danych przez nas i naszych partnerów, zgodnie z opisem powyżej. Można też nie wyrazić zgody, uzyskać dostęp do bardziej szczegółowych informacji i zmienić swoje preferencje przed wyrażeniem zgody lub odmówić jej wyrażenia. Należy pamiętać, że niektóre rodzaje przetwarzania danych osobowych mogą nie wymagać zgody użytkownika, ale ma on prawo sprzeciwić się takiemu przetwarzaniu. Preferencje będą miały zastosowanie tylko do tej witryny.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Razem z naszymi partnerami uzyskujemy dostęp do informacji na urządzeniu, takich jak pliki cookie oraz je przechowujemy, i przetwarzamy dane osobowe, np. unikalne identyfikatory i standardowe informacje wysyłane przez urządzenie w celu zapewniania spersonalizowanych reklam i treści, analizy reklam i treści oraz zbierania opinii użytkowników, a także rozwijania i ulepszania produktów. Za zgodą użytkownika my i nasi partnerzy możemy wykorzystywać precyzyjne dane geolokalizacyjne i identyfikację poprzez skanowanie urządzeń.',
        'Wystarczy kliknąć przycisk poniżej, aby wyrazić zgodę na przetwarzanie danych przez nas i naszych partnerów, zgodnie z opisem powyżej. Można też nie wyrazić zgody, uzyskać dostęp do bardziej szczegółowych informacji i zmienić swoje preferencje przed wyrażeniem zgody lub odmówić jej wyrażenia. Należy pamiętać, że niektóre rodzaje przetwarzania danych osobowych mogą nie wymagać zgody użytkownika, ale ma on prawo sprzeciwić się takiemu przetwarzaniu. Preferencje będą miały zastosowanie w całej sieci.'
      ],
      summaryScreenBodyRejectGroup: [
        'Razem z naszymi partnerami uzyskujemy dostęp do informacji na urządzeniu, takich jak pliki cookie oraz je przechowujemy, i przetwarzamy dane osobowe, np. unikalne identyfikatory i standardowe informacje wysyłane przez urządzenie w celu zapewniania spersonalizowanych reklam i treści, analizy reklam i treści oraz zbierania opinii użytkowników, a także rozwijania i ulepszania produktów. Za zgodą użytkownika my i nasi partnerzy możemy wykorzystywać precyzyjne dane geolokalizacyjne i identyfikację poprzez skanowanie urządzeń.',
        'Wystarczy kliknąć przycisk poniżej, aby wyrazić zgodę na przetwarzanie danych przez nas i naszych partnerów, zgodnie z opisem powyżej. Można też nie wyrazić zgody lub uzyskać dostęp do bardziej szczegółowych informacji i zmienić swoje preferencje przed wyrażeniem zgody lub odmówić jej wyrażenia. Należy pamiętać, że niektóre rodzaje przetwarzania danych osobowych mogą nie wymagać zgody użytkownika, ale ma on prawo sprzeciwić się takiemu przetwarzaniu. Preferencje będą miały zastosowanie do grupy witryn.'
      ],
      partners: 'naszymi partnerami',
      groupOfSitesLabel: 'grupy witryn',
      vendorScreenBody:
        'Zapoznaj się z preferencjami zgody dla każdego partnera poniżej i zmień je w razie potrzeby. Rozwiń każdy element na liście partnerów, aby uzyskać więcej informacji, które pomogą Ci podjąć decyzję. Niektóre dane osobowe są przetwarzane bez Twojej zgody, ale masz prawo sprzeciwić się ich przetwarzaniu.',
      showPartners: 'Pokaż partnerów',
      hidePartners: 'Ukryj partnerów'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Dostawcy spoza IAB',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Slovak
  sk: {
    agree: 'SÚHLASÍM',
    saveAndExit: 'ULOŽIŤ A ZATVORIŤ',
    disagree: 'NESÚHLASÍM',
    objectAll: 'NAMIETAŤ PROTI VŠETKÉMU',
    mockPurposesCopies: {
      headline: 'Vážime si vaše súkromie',
      body: 'Na základe nižšie uvedených účelov môžete upraviť preferencie svojho súhlasu týkajúce sa používania vašich údajov. Svoje preferencie pre naše používanie vašich údajov môžete nastaviť nezávisle od preferencií v súvislosti s externými partnermi. Každý účel má svoj opis, aby ste vedeli, ako my a naši partneri používame vaše údaje.',
      accept: 'Prijať všetko',
      vendorLink: 'Zobraziť dodávateľov',
      saveExit: 'Uložiť a skončiť',
      cancel: 'Zrušiť',
      consentLinkLabel: 'Súkromie'
    },
    mockHeadlinesCopies: {
      headline: 'Vážime si vaše súkromiey',
      accept: 'Súhlasím',
      reject: 'Nesúhlasím',
      showPurposes: 'Zobraziť účely',
    },
    coreUiLabels: {
      back: 'Späť',
      acceptAll: 'PRIJAŤ VŠETKO',
      descriptionLabel: 'Opis dodávateľa:',
      featuresLabel: 'Charakteristiky',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Naša spoločnosť a naši partneri uchovávame alebo pristupujeme k informáciám v zariadeniach, napríklad k súborom cookies, a spracúvame osobné údaje, ako sú jedinečné identifikátory a štandardné informácie, ktoré zariadenie posiela na účely opísané nižšie. Súhlas s vyššie uvedeným spracúvaním zo strany našej spoločnosti a našich partnerov môžete poskytnúť kliknutím na príslušné tlačidlo. Skôr než súhlas poskytnete, môžete kliknutím jeho poskytnutie odmietnuť alebo si preštudovať podrobnejšie informácie a zmeniť svoje prednostné nastavenia. Vaše prednostné nastavenia sa budú vzťahovať len na túto webovú lokalitu. Zoberte na vedomie, že na niektoré formy spracúvania vašich osobných údajov nepotrebujeme váš súhlas, ale proti takémuto spracovaniu však máte právo namietať.',
      initScreenBodyGlobal:
        'Naša spoločnosť a naši partneri uchovávame alebo pristupujeme k informáciám v zariadeniach, napríklad k súborom cookies, a spracúvame osobné údaje, ako sú jedinečné identifikátory a štandardné informácie, ktoré zariadenie posiela na účely opísané nižšie. Súhlas s vyššie uvedeným spracúvaním zo strany našej spoločnosti a našich partnerov môžete poskytnúť kliknutím na príslušné tlačidlo. Skôr než súhlas poskytnete, môžete kliknutím jeho poskytnutie odmietnuť alebo si preštudovať podrobnejšie informácie a zmeniť svoje prednostné nastavenia. Vaše prednostné nastavenia sa budú vzťahovať na celý internet. Zoberte na vedomie, že na niektoré formy spracúvania vašich osobných údajov nepotrebujeme váš súhlas, ale proti takémuto spracovaniu však máte právo namietať.',
      initScreenBodyGroup:
        'Naša spoločnosť a naši partneri uchovávame alebo pristupujeme k informáciám v zariadeniach, napríklad k súborom cookies, a spracúvame osobné údaje, ako sú jedinečné identifikátory a štandardné informácie, ktoré zariadenie posiela na účely opísané nižšie. Súhlas s vyššie uvedeným spracúvaním zo strany našej spoločnosti a našich partnerov môžete poskytnúť kliknutím na príslušné tlačidlo. Skôr než súhlas poskytnete, môžete kliknutím jeho poskytnutie odmietnuť alebo si preštudovať podrobnejšie informácie a zmeniť svoje prednostné nastavenia. Vaše prednostné nastavenia sa budú vzťahovať na skupinu webových lokalít. Zoberte na vedomie, že na niektoré formy spracúvania vašich osobných údajov nepotrebujeme váš súhlas, proti takémuto spracovaniu však máte právo namietať.',
      initScreenRejectButton: 'NESÚHLASÍM',
      initScreenSettingsButton: 'VIAC MOŽNOSTÍ',
      initScreenTitle: 'Vážime si vaše súkromie',
      legalDescription: 'Právny opis',
      legitimateInterestPurposesLabel: 'OPRÁVNENÝ ZÁUJEM',
      legitimateScreenObjected: 'PODANÁ NÁMIETKA',
      agreeButton: 'SÚHLASÍM',
      legitimateScreenAccept: 'ODSTRÁNIŤ NÁMIETKU',
      legitimateScreenObject: 'Object',
      objectAllButton: 'NAMIETAŤ PROTI VŠETKÉMU',
      offLabel: 'Vyp.',
      offOnLabel: 'Vyp./Zap.',
      onLabel: 'Zap.',
      multiLabel: 'Multi',
      persistentConsentLinkLabel: 'Súkromie',
      privacyPolicyLabel: 'Zásady ochrany osobných údajov',
      legitimateScreenBody:
        'Preštudujte si text a namietajte proti spracovaniu svojich údajov bez vášho súhlasu na základe oprávneného záujmu pre každý účel a podľa každého partnera nižšie. Viac informácií na uľahčenie výberu získate rozbalením položky s opisom účelu alebo položky zoznamu partnerov. Ak chcete namietať proti osobitným účelom zaistenia bezpečnosti, predchádzania podvodom a odstraňovania chýb a proti technickému odosielaniu reklám alebo obsahu, kliknite na zásady partnera v oblasti ochrany osobných údajov.',
      saveAndExitButton: 'ULOŽIŤ A ZATVORIŤ',
      purposeScreenVendorLink: 'PARTNERI',
      purposesLabel: 'Účely (súhlas)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Osobitné účely',
      specialFeaturesLabel: 'Osobitné charakteristiky',
      summaryScreenBodyNoRejectService: [
        'Naša spoločnosť a naši partneri uchovávame a/alebo pristupujeme k informáciám v zariadení, napríklad k súborom cookies, a spracúvame osobné údaje, ako sú jedinečné identifikátory a štandardné informácie, ktoré zariadenie posiela na účely prispôsobených reklám a obsahu, merania reklám a obsahu a získavania poznatkov o návštevníkoch našich stránok, ako aj na účely skvalitňovania produktov.',
        'S vaším povolením môže naša spoločnosť a naši partneri používať presné geolokalizačné údaje a identifikáciu prostredníctvom skenovania zariadenia. Súhlas s vyššie uvedeným spracúvaním zo strany našej spoločnosti a našich partnerov môžete poskytnúť kliknutím na príslušné tlačidlo. Skôr než súhlas poskytnete alebo odmietnete poskytnúť, môžete si preštudovať podrobnejšie informácie a zmeniť svoje prednostné nastavenia. Zoberte na vedomie, že na niektoré formy spracúvania vašich osobných údajov nepotrebujeme váš súhlas, ale proti takémuto spracovaniu však máte právo namietať. Vaše prednostné nastavenia sa budú vzťahovať len na túto webovú lokalitu.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Naša spoločnosť a/alebo naši partneri uchovávame a/alebo pristupujeme k informáciám v zariadení, napríklad k súborom cookies, a spracúvame osobné údaje, ako sú jedinečné identifikátory a štandardné informácie, ktoré zariadenie posiela na účely prispôsobených reklám a obsahu, merania reklám a obsahu a získavania poznatkov o návštevníkoch našich stránok, ako aj na účely skvalitňovania produktov.',
        'S vaším povolením môže naša spoločnosť a naši partneri používať presné geolokalizačné údaje a identifikáciu prostredníctvom skenovania zariadenia. Súhlas s vyššie uvedeným spracúvaním zo strany našej spoločnosti a našich partnerov môžete poskytnúť kliknutím na príslušné tlačidlo. Skôr než súhlas poskytnete alebo odmietnete poskytnúť, môžete si preštudovať podrobnejšie informácie a zmeniť svoje prednostné nastavenia. Zoberte na vedomie, že na niektoré formy spracúvania vašich osobných údajov nepotrebujeme váš súhlas, ale proti takémuto spracovaniu však máte právo namietať. Vaše prednostné nastavenia sa budú vzťahovať len na celý obsah internetu.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Naša spoločnosť a/alebo naši partneri uchovávame a/alebo pristupujeme k informáciám v zariadení, napríklad k súborom cookies, a spracúvame osobné údaje, ako sú jedinečné identifikátory a štandardné informácie, ktoré zariadenie posiela na účely prispôsobených reklám a obsahu, merania reklám a obsahu a získavanie poznatkov o návštevníkoch našich stránok, ako aj na účely skvalitňovania produktov.',
        'S vaším povolením môže naša spoločnosť a naši partneri používať presné geolokalizačné údaje a identifikáciu prostredníctvom skenovania zariadenia. Súhlas s vyššie uvedeným spracúvaním zo strany našej spoločnosti a našich partnerov môžete poskytnúť kliknutím na príslušné tlačidlo. Skôr než súhlas poskytnete alebo odmietnete poskytnúť, môžete si preštudovať podrobnejšie informácie a zmeniť svoje prednostné nastavenia. Zoberte na vedomie, že na niektoré formy spracúvania vašich osobných údajov nepotrebujeme váš súhlas, ale proti takémuto spracovaniu však máte právo namietať. Vaše prednostné nastavenia sa budú vzťahovať na skupinu webových lokalít.'
      ],
      summaryScreenBodyRejectService: [
        'Naša spoločnosť a/alebo naši partneri uchovávame a/alebo pristupujeme k informáciám v zariadení, napríklad k súborom cookies, a spracúvame osobné údaje, ako sú jedinečné identifikátory a štandardné informácie, ktoré zariadenie posiela na účely prispôsobených reklám a obsahu, merania reklám a obsahu a získavania poznatkov o návštevníkoch našich stránok, ako aj na účely skvalitňovania produktov.',
        'S vaším povolením môže naša spoločnosť a naši partneri používať presné geolokalizačné údaje a identifikáciu prostredníctvom skenovania zariadenia. Súhlas s vyššie uvedeným spracúvaním zo strany našej spoločnosti a našich partnerov môžete poskytnúť kliknutím na príslušné tlačidlo. Skôr než súhlas poskytnete, môžete kliknutím jeho poskytnutie odmietnuť alebo si preštudovať podrobnejšie informácie a zmeniť svoje prednostné nastavenia. Zoberte na vedomie, že na niektoré formy spracúvania vašich osobných údajov nepotrebujeme váš súhlas, proti takémuto spracovaniu však máte právo namietať. Vaše prednostné nastavenia sa budú vzťahovať len na túto webovú lokalitu.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Naša spoločnosť a/alebo naši partneri uchovávame a/alebo pristupujeme k informáciám v zariadení, napríklad k súborom cookies, a spracúvame osobné údaje, ako sú jedinečné identifikátory a štandardné informácie, ktoré zariadenie posiela na účely prispôsobených reklám a obsahu, merania reklám a obsahu a získavania poznatkov o návštevníkoch našich stránok, ako aj na účely skvalitňovania produktov.',
        'S vaším povolením môže naša spoločnosť a naši partneri používať presné geolokalizačné údaje a identifikáciu prostredníctvom skenovania zariadenia. Súhlas s vyššie uvedeným spracúvaním zo strany našej spoločnosti a našich partnerov môžete poskytnúť kliknutím na príslušné tlačidlo. Skôr než súhlas poskytnete, môžete kliknutím jeho poskytnutie odmietnuť alebo si preštudovať podrobnejšie informácie a zmeniť svoje prednostné nastavenia. Zoberte na vedomie, že na niektoré formy spracúvania vašich osobných údajov nepotrebujeme váš súhlas, proti takémuto spracovaniu však máte právo namietať. Vaše prednostné nastavenia sa budú vzťahovať len na celý internet.'
      ],
      summaryScreenBodyRejectGroup: [
        'Naša spoločnosť a/alebo naši partneri uchovávame a/alebo pristupujeme k informáciám v zariadení, napríklad k súborom cookies, a spracúvame osobné údaje, ako sú jedinečné identifikátory a štandardné informácie, ktoré zariadenie posiela na účely prispôsobených reklám a obsahu, merania reklám a obsahu a získavania poznatkov o návštevníkoch našich stránok, ako aj na účely skvalitňovania produktov.',
        'S vaším povolením môže naša spoločnosť a naši partneri používať presné geolokalizačné údaje a identifikáciu prostredníctvom skenovania zariadenia. Súhlas s vyššie uvedeným spracúvaním zo strany našej spoločnosti a našich partnerov môžete poskytnúť kliknutím na príslušné tlačidlo. Skôr než súhlas poskytnete, môžete kliknutím jeho poskytnutie odmietnuť alebo si preštudovať podrobnejšie informácie a zmeniť svoje prednostné nastavenia. Zoberte na vedomie, že na niektoré formy spracúvania vašich osobných údajov nepotrebujeme váš súhlas, ale proti takémuto spracovaniu však máte právo namietať. Vaše prednostné nastavenia sa budú vzťahovať na skupinu webových lokalít.'
      ],
      partners: 'naši partneri',
      groupOfSitesLabel: 'webových lokalít',
      vendorScreenBody:
        'Preštudujte a nastavte si svoje prednostné nastavenia týkajúce sa obsahu pre každého partnera nižšie. Viac informácií na uľahčenie výberu získate rozbalením každej položky v zozname partnerov. Niektoré osobné údaje sú spracúvané bez vášho súhlasu, máte však právo proti tomu namietať.',
      showPartners: 'Zobraziť partnerov',
      hidePartners: 'Skryť partnerov'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Dodávatelia bez IAB',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Swedish
  sv: {
    agree: 'GODKÄNN',
    saveAndExit: 'SPARA & AVSLUTA',
    disagree: 'GODKÄNN INTE',
    objectAll: 'INVÄND MOT ALLA',
    mockPurposesCopies: {
      headline: 'Vi värderar din integritet',
      body: 'Du kan ange dina samtyckespreferenser och avgöra hur du vill att dina data ska användas utifrån ändamålen nedan. Du kan ange separata preferenser för oss och tredje parter. Varje ändamål har en beskrivning så att du kan se hur vi och våra samarbetspartner använder dina uppgifter.',
      accept: 'GODKÄNN ALLA',
      vendorLink: 'Visa säljare',
      saveExit: 'Spara & Avsluta',
      cancel: 'Avbryt',
      consentLinkLabel: 'Integritet'
    },
    mockHeadlinesCopies: {
      headline: 'Vi värdesätter din integritet',
      accept: 'JAG GODKÄNNER',
      reject: 'JAG GODKÄNNER INTE',
      showPurposes: 'Visa ändamål',
    },
    coreUiLabels: {
      back: 'Tillbaka',
      acceptAll: 'GODKÄNN ALLA',
      descriptionLabel: 'Leverantörsbeskrivning:',
      featuresLabel: 'Funktioner',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Vi och våra samarbetspartner lagrar eller får åtkomst till information på enheter, exempelvis cookies, samt bearbetar personuppgifter, exempelvis unika identifierare och standardinformation som skickas av en enhet för de ändamål som beskrivs nedan. Du kan klicka för att godkänna vår och våra samarbetspartners behandling för sådana ändamål. Alternativt kan du klicka för att neka samtycke eller få tillgång till mer detaljerad information och ändra dina inställningar innan du samtycker. Dina inställningar gäller endast den här webbplatsen. Observera att viss behandling av dina personuppgifter kanske inte kräver ditt samtycke, men du har rätt att invända mot sådan behandling.',
      initScreenBodyGlobal:
        'Vi och våra samarbetspartner lagrar eller får åtkomst till information på enheter, exempelvis cookies, samt bearbetar personuppgifter, exempelvis unika identifierare och standardinformation som skickas av en enhet för de ändamål som beskrivs nedan. Du kan klicka för att godkänna vår och våra samarbetspartners behandling för sådana ändamål. Alternativt kan du klicka för att neka samtycke eller få tillgång till mer detaljerad information och ändra dina inställningar innan du samtycker. Dina inställningar kommer att gälla över webben. Observera att viss behandling av dina personuppgifter kanske inte kräver ditt samtycke, men du har rätt att invända mot sådan behandling.',
      initScreenBodyGroup:
        'Naša spoločnosť a naši partneri uchovávame alebo pristupujeme k informáciám v zariadeniach, napríklad k súborom cookies, a spracúvame osobné údaje, ako sú jedinečné identifikátory a štandardné informácie, ktoré zariadenie posiela na účely opísané nižšie. Súhlas s vyššie uvedeným spracúvaním zo strany našej spoločnosti a našich partnerov môžete poskytnúť kliknutím na príslušné tlačidlo. Skôr než súhlas poskytnete, môžete kliknutím jeho poskytnutie odmietnuť alebo si preštudovať podrobnejšie informácie a zmeniť svoje prednostné nastavenia. Vaše prednostné nastavenia sa budú vzťahovať na skupinu webových lokalít [odkazy na doménu, kde sú uvedené všetky vlastnosti týkajúce sa tejto skupinovej konfigurácie]. Zoberte na vedomie, že na niektoré formy spracúvania vašich osobných údajov nepotrebujeme váš súhlas, proti takémuto spracovaniu však máte právo namietať.	Vi och våra samarbetspartner lagrar eller får åtkomst till information på enheter, exempelvis cookies, samt bearbetar personuppgifter, exempelvis unika identifierare och standardinformation som skickas av en enhet för de ändamål som beskrivs nedan. Du kan klicka för att godkänna vår och våra samarbetspartners behandling för sådana ändamål. Alternativt kan du klicka för att neka samtycke eller få tillgång till mer detaljerad information och ändra dina inställningar innan du samtycker. Dina inställningar kommer att gälla för en grupp webbplatser. Observera att viss behandling av dina personuppgifter kanske inte kräver ditt samtycke, men du har rätt att invända mot sådan behandling.',
      initScreenRejectButton: 'GODKÄNNER INTE',
      initScreenSettingsButton: 'FLER ALTERNATIV',
      initScreenTitle: 'Vi värdesätter din integritet',
      legalDescription: 'Juridisk beskrivning',
      legitimateInterestPurposesLabel: 'BERÄTTIGAT INTRESSE',
      legitimateScreenObjected: 'INVÄNDNING',
      agreeButton: 'GODKÄNNER',
      legitimateScreenAccept: 'TA BORT INVÄNDNING',
      legitimateScreenObject: 'Object',
      objectAllButton: 'INVÄND MOT ALLA',
      offLabel: 'Av',
      offOnLabel: 'Av/På',
      onLabel: 'På',
      multiLabel: 'FLERA',
      persistentConsentLinkLabel: 'Integritet',
      privacyPolicyLabel: 'Integritetspolicy',
      legitimateScreenBody:
        'Granska och invända mot behandling av personuppgifter utan ditt samtycke baserat på ett legitimt intresse för varje ändamål och av varje partner nedan. Expandera varje ändamål eller partnerlista för mer information för att göra ditt val. För att göra invändningar mot de särskilda ändamålen att säkerställa säkerhet, förhindra bedrägerier samt felsökning/korrigering och tekniskt tillhandahålla annonser eller innehåll klickar du på en samarbetspartners länk till sekretesspolicy.',
      saveAndExitButton: 'Spara & Avsluta',
      purposeScreenVendorLink: 'SAMARBETSPARTNER',
      purposesLabel: 'Ändamål (samtycke)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Särskilda ändamål',
      specialFeaturesLabel: 'Särskilda funktioner',
      summaryScreenBodyNoRejectService: [
        'Vi och våra samarbetspartner lagrar och/eller får åtkomst till information på en enhet, exempelvis cookies, samt bearbetar personuppgifter, exempelvis unika identifierare och standardinformation som skickas av en enhet för personanpassade annonser och andra typer av innehåll, annons- och innehållsmätning samt målgruppsinsikter, samt för att utveckla och förbättra produkter.',
        'Med din tillåtelse kan vi och våra samarbetspartner använda exakta uppgifter om geografisk placering och identifiering via skanning av enheten. Du kan klicka för att godkänna vår och våra samarbetspartners behandling enligt beskrivningen ovan. Alternativt kan du få åtkomst till mer detaljerad information och ändra dina inställningar innan du samtycker eller för att neka samtycke. Observera att viss behandling av dina personuppgifter kanske inte kräver ditt samtycke, men du har rätt att invända mot sådan behandling. Dina inställningar gäller endast den här webbplatsen.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Vi och våra samarbetspartner lagrar och/eller får åtkomst till information på en enhet, exempelvis cookies, samt bearbetar personuppgifter, exempelvis unika identifierare och standardinformation som skickas av en enhet för personanpassade annonser och andra typer av innehåll, annons- och innehållsmätning samt målgruppsinsikter, samt för att utveckla och förbättra produkter. Med din tillåtelse kan vi och våra samarbetspartner använda exakta uppgifter om geografisk placering och identifiering via skanning av enheten.',
        'Du kan klicka för att godkänna vår och våra samarbetspartners behandling enligt beskrivningen ovan. Alternativt kan du få åtkomst till mer detaljerad information och ändra dina inställningar innan du samtycker eller för att neka samtycke. Observera att viss behandling av dina personuppgifter kanske inte kräver ditt samtycke, men du har rätt att invända mot sådan behandling. Dina inställningar kommer att gälla över webben.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Vi och våra samarbetspartner lagrar och/eller får åtkomst till information på en enhet, exempelvis cookies, samt bearbetar personuppgifter, exempelvis unika identifierare och standardinformation som skickas av en enhet för personanpassade annonser och andra typer av innehåll, annons- och innehållsmätning samt målgruppsinsikter, samt för att utveckla och förbättra produkter. Med din tillåtelse kan vi och våra samarbetspartner använda exakta uppgifter om geografisk placering och identifiering via skanning av enheten.',
        'Du kan klicka för att godkänna vår och våra samarbetspartners behandling enligt beskrivningen ovan. Alternativt kan du få åtkomst till mer detaljerad information och ändra dina inställningar innan du samtycker eller för att neka samtycke. Observera att viss behandling av dina personuppgifter kanske inte kräver ditt samtycke, men du har rätt att invända mot sådan behandling. Dina inställningar kommer att gälla för en grupp webbplatser.'
      ],
      summaryScreenBodyRejectService: [
        'Vi och våra samarbetspartner lagrar och/eller får åtkomst till information på en enhet, exempelvis cookies, samt bearbetar personuppgifter, exempelvis unika identifierare och standardinformation som skickas av en enhet för personanpassade annonser och andra typer av innehåll, annons- och innehållsmätning samt målgruppsinsikter, samt för att utveckla och förbättra produkter. Med din tillåtelse kan vi och våra samarbetspartner använda exakta uppgifter om geografisk placering och identifiering via skanning av enheten.',
        'Du kan klicka för att godkänna vår och våra samarbetspartners behandling enligt beskrivningen ovan. Alternativt kan du klicka för att neka samtycke eller få tillgång till mer detaljerad information och ändra dina inställningar innan du samtycker. Observera att viss behandling av dina personuppgifter kanske inte kräver ditt samtycke, men du har rätt att invända mot sådan behandling. Dina inställningar gäller endast den här webbplatsen.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Vi och våra samarbetspartner lagrar och/eller får åtkomst till information på en enhet, exempelvis cookies, samt bearbetar personuppgifter, exempelvis unika identifierare och standardinformation som skickas av en enhet för personanpassade annonser och andra typer av innehåll, annons- och innehållsmätning samt målgruppsinsikter, samt för att utveckla och förbättra produkter. Med din tillåtelse kan vi och våra samarbetspartner använda exakta uppgifter om geografisk placering och identifiering via skanning av enheten.',
        'Du kan klicka för att godkänna vår och våra samarbetspartners behandling enligt beskrivningen ovan. Alternativt kan du klicka för att neka samtycke eller få tillgång till mer detaljerad information och ändra dina inställningar innan du samtycker. Observera att viss behandling av dina personuppgifter kanske inte kräver ditt samtycke, men du har rätt att invända mot sådan behandling. Dina inställningar kommer att gälla över webben.'
      ],
      summaryScreenBodyRejectGroup: [
        'Vi och våra samarbetspartner lagrar och/eller får åtkomst till information på en enhet, exempelvis cookies, samt bearbetar personuppgifter, exempelvis unika identifierare och standardinformation som skickas av en enhet för personanpassade annonser och andra typer av innehåll, annons- och innehållsmätning samt målgruppsinsikter, samt för att utveckla och förbättra produkter. Med din tillåtelse kan vi och våra samarbetspartner använda exakta uppgifter om geografisk placering och identifiering via skanning av enheten.',
        ' Du kan klicka för att godkänna vår och våra samarbetspartners behandling enligt beskrivningen ovan. Alternativt kan du klicka för att neka samtycke eller få tillgång till mer detaljerad information och ändra dina inställningar innan du samtycker. Observera att viss behandling av dina personuppgifter kanske inte kräver ditt samtycke, men du har rätt att invända mot sådan behandling. Dina inställningar kommer att gälla för en grupp webbplatser.'
      ],
      partners: 'våra samarbetspartner',
      groupOfSitesLabel: 'grupp webbplatser',
      vendorScreenBody:
        'Granska och ställ in dina samtyckesinställningar för varje samarbetspartner nedan. Expandera för mer information som kan hjälpa dig att fatta beslut om respektive samarbetspartner. Vissa personuppgifter behandlas utan ditt samtycke, men du har rätt att invända.',
      showPartners: 'Visa samarbetspartner',
      hidePartners: 'Dölj samarbetspartner'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Icke-IAB-leverantörer',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Norwegian
  no: {
    agree: 'ENIG',
    saveAndExit: 'LAGRE OG AVSLUTT',
    disagree: 'UENIG',
    objectAll: 'PROTESTER PÅ ALLE',
    mockPurposesCopies: {
      headline: 'Vi verdsetter personvernet ditt',
      body: 'Du kan angi samtykkepreferansene dine og bestemme hvordan du vil at dataene skal brukes, basert på formålene nedenfor. Du kan angi preferansene dine uavhengig av tredjepartspartnere. Hvert formål har en beskrivelse slik at du vet hvordan vi og partnerne bruker dataene dine.',
      accept: 'AKSEPTER ALT',
      vendorLink: 'Se leverandører',
      saveExit: 'LAGRE OG AVSLUTT',
      cancel: 'Avbryt',
      consentLinkLabel: 'Personvern'
    },
    mockHeadlinesCopies: {
      headline: 'Vi verdsetter personvernet ditt',
      accept: 'JEG AKSEPTERER',
      reject: 'JEG AKSEPTERER IKKE',
      showPurposes: 'Vis formål',
    },
    coreUiLabels: {
      back: 'Tilbake',
      acceptAll: 'Accept all',
      descriptionLabel: 'Leverandørbeskrivelser:',
      featuresLabel: 'Funksjoner',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Vi og våre partnere lagrer og/eller benytter oss av informasjon fra en enhet, for eksempel informasjonskapsler og behandler personopplysninger, for eksempel unike identifikatorer og standardinformasjon sendt fra en enhet for tilpasning av annonser og innhold, måling av annonser og innhold, samt innsikt fra publikum, i tillegg til utvikling og forbedring av produktene. Med din tillatelse kan vi og våre partnere bruke presise geolokaliseringsdata og identifikasjon gjennom enhetsskanning. Du kan, som beskrevet ovenfor, klikke for å samtykke til våre og våre partneres databehandling. Alternativt kan du få tilgang til mer detaljert informasjon og endre preferansene dine før du samtykker. Dine preferanser vil kun gjelde for dette nettstedet. Vær oppmerksom på at en viss behandling av dine personopplysninger kanskje ikke krever ditt samtykke, men at du har rett til å protestere mot slik databehandling.',
      initScreenBodyGlobal:
        'Vi og våre partnere lagrer og/eller benytter oss av informasjon fra en enhet, for eksempel informasjonskapsler og behandler personopplysninger, for eksempel unike identifikatorer og standardinformasjon sendt fra en enhet for tilpasning av annonser og innhold, måling av annonser og innhold, samt innsikt fra publikum, i tillegg til utvikling og forbedring av produktene. Med din tillatelse kan vi og våre partnere bruke presise geolokaliseringsdata og identifikasjon gjennom enhetsskanning. Du kan, som beskrevet ovenfor, klikke for å samtykke til våre og våre partneres databehandling. Alternativt kan du få tilgang til mer detaljert informasjon og endre preferansene dine før du samtykker. Dine preferanser gjelder over hele verdensveven. Vær oppmerksom på at en viss behandling av dine personopplysninger kanskje ikke krever ditt samtykke, men at du har rett til å protestere mot slik databehandling.',
      initScreenBodyGroup:
        'Vi og våre partnere lagrer eller benytter oss av informasjon på enheter, for eksempel informasjonskapsler og behandler personopplysninger, for eksempel unike identifikatorer og standardinformasjon sendt av en enhet for de nedenfor beskrevne formål. Du kan klikke for å samtykke til våre og våre partneres behandling for slike formål. Alternativt kan du klikke for å nekte å samtykke, eller få tilgang til mer detaljert informasjon og endre preferansene dine før du samtykker. Innstillingene dine kommer til å gjelde for en gruppe nettsteder. Vær oppmerksom på at en viss behandling av dine personopplysninger kanskje ikke krever ditt samtykke, men at du har rett til å innvende mot slik behandling.',
      initScreenRejectButton: 'UENIG',
      initScreenSettingsButton: 'FLERE VALG',
      initScreenTitle: 'Vi verdsetter personvernet ditt',
      legalDescription: 'Juridiske beskrivelser',
      legitimateInterestPurposesLabel: 'LEGITIME INTERESSER',
      legitimateScreenObjected: 'PROTESTERT',
      agreeButton: 'ENIG',
      legitimateScreenAccept: 'FJERN PROTESTEN',
      legitimateScreenObject: 'Object',
      objectAllButton: 'PROTESTER PÅ ALLE',
      offLabel: 'Av',
      offOnLabel: 'Av/På',
      onLabel: 'På',
      multiLabel: 'Multi',
      persistentConsentLinkLabel: 'Personvern',
      privacyPolicyLabel: 'Personvernregler',
      legitimateScreenBody:
        'Se gjennom og protester mot behandling av persondata uten ditt samtykke på basis av legitime interesser for hvert av formålene og hver av partnerne nevnt nedenfor. Utvid hvert enkelt formål eller partneroppføring for mer informasjon for å hjelpe deg til å foreta dine valg. For å protestere til de spesielle formålene for sikkerhetsgaranti, for å unngå svindelforsøk og for debugging og teknisk levering av annonser eller innhold, klikker du på partnerens personvernlenke.',
      saveAndExitButton: 'LAGRE OG AVSLUTT',
      purposeScreenVendorLink: 'PARTNERE',
      purposesLabel: 'Formål (samtykke)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Særskilte formål',
      specialFeaturesLabel: 'Særskilte funksjoner',
      summaryScreenBodyNoRejectService: [
        'Vi og våre partnere lagrer og/eller benytter oss av informasjon fra en enhet, for eksempel informasjonskapsler og behandler personopplysninger, for eksempel unike identifikatorer og standardinformasjon sendt fra en enhet for tilpasning av annonser og innhold, måling av annonser og innhold, samt innsikt fra publikum, i tillegg til utvikling og forbedring av produktene.',
        'Med din tillatelse kan vi og våre partnere bruke presise geolokaliseringsdata og identifikasjon gjennom enhetsskanning. Du kan, som beskrevet ovenfor, klikke for å samtykke til våre og våre partneres databehandling. Alternativt kan du få tilgang til mer detaljert informasjon og endre preferansene dine før du samtykker til eller nekter å samtykke. Vær oppmerksom på at en viss behandling av dine personopplysninger kanskje ikke krever ditt samtykke, men at du har rett til å protestere mot slik databehandling. Dine preferanser gjelder kun for dette nettstedet.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Vi og våre partnere lagrer og/eller benytter oss av informasjon fra en enhet, for eksempel informasjonskapsler og behandler personopplysninger, for eksempel unike identifikatorer og standardinformasjon sendt fra en enhet for tilpasning av annonser og innhold, måling av annonser og innhold, samt innsikt fra publikum, i tillegg til utvikling og forbedring av produktene.',
        'Med din tillatelse kan vi og våre partnere bruke presise geolokaliseringsdata og identifikasjon gjennom enhetsskanning. Du kan, som beskrevet ovenfor, klikke for å samtykke til våre og våre partneres databehandling. Alternativt kan du få tilgang til mer detaljert informasjon og endre preferansene dine før du samtykker til eller nekter å samtykke. Vær oppmerksom på at en viss behandling av dine personopplysninger kanskje ikke krever ditt samtykke, men at du har rett til å protestere mot slik databehandling. Dine preferanser gjelder over hele verdensveven.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Vi og våre partnere lagrer og/eller benytter oss av informasjon fra en enhet, for eksempel informasjonskapsler og behandler personopplysninger, for eksempel unike identifikatorer og standardinformasjon sendt fra en enhet for tilpasning av annonser og innhold, måling av annonser og innhold, samt innsikt fra publikum, i tillegg til utvikling og forbedring av produktene.',
        'Med din tillatelse kan vi og våre partnere bruke presise geolokaliseringsdata og identifikasjon gjennom enhetsskanning. Du kan, som beskrevet ovenfor, klikke for å samtykke til våre og våre partneres databehandling. Alternativt kan du få tilgang til mer detaljert informasjon og endre preferansene dine før du samtykker til eller nekter å samtykke. Vær oppmerksom på at en viss behandling av dine personopplysninger kanskje ikke krever ditt samtykke, men at du har rett til å protestere mot slik databehandling. Dine preferanser kommer til å gjelde på en gruppe nettsteder.'
      ],
      summaryScreenBodyRejectService: [
        'Vi og våre partnere lagrer og/eller benytter oss av informasjon fra en enhet, for eksempel informasjonskapsler og behandler personopplysninger, for eksempel unike identifikatorer og standardinformasjon sendt fra en enhet for tilpasning av annonser og innhold, måling av annonser og innhold, samt innsikt fra publikum, i tillegg til utvikling og forbedring av produktene.',
        'Med din tillatelse kan vi og våre partnere bruke presise geolokaliseringsdata og identifikasjon gjennom enhetsskanning. Du kan, som beskrevet ovenfor, klikke for å samtykke til våre og våre partneres databehandling. Alternativt kan du få tilgang til mer detaljert informasjon og endre preferansene dine før du samtykker til eller nekter samtykke. Vær oppmerksom på at en viss behandling av dine personopplysninger kanskje ikke krever ditt samtykke, men du har rett til å innvende deg mot slik behandling. Dine preferanser vil kun gjelde for dette nettstedet.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Vi og våre partnere lagrer og/eller benytter oss av informasjon fra en enhet, for eksempel informasjonskapsler og behandler personopplysninger, for eksempel unike identifikatorer og standardinformasjon sendt fra en enhet for tilpasning av annonser og innhold, måling av annonser og innhold, samt innsikt fra publikum, i tillegg til utvikling og forbedring av produktene.',
        'Med din tillatelse kan vi og våre partnere bruke presise geolokaliseringsdata og identifikasjon gjennom enhetsskanning. Du kan, som beskrevet ovenfor, klikke for å samtykke til våre og våre partneres databehandling. Alternativt kan du få tilgang til mer detaljert informasjon og endre preferansene dine før du samtykker. Vær oppmerksom på at en viss behandling av dine personopplysninger kanskje ikke krever ditt samtykke, men du har rett til å innvende mot slik behandling. Dine preferanser gjelder over hele verdensveven.'
      ],
      summaryScreenBodyRejectGroup: [
        'Vi og våre partnere lagrer og/eller benytter oss av informasjon fra en enhet, for eksempel informasjonskapsler og behandler personopplysninger, for eksempel unike identifikatorer og standardinformasjon sendt fra en enhet for tilpasning av annonser og innhold, måling av annonser og innhold, samt innsikt fra publikum, i tillegg til utvikling og forbedring av produktene.',
        'Med din tillatelse kan vi og våre partnere bruke presise geolokaliseringsdata og identifikasjon gjennom enhetsskanning. Du kan, som beskrevet ovenfor, klikke for å samtykke til våre og våre partneres databehandling. Alternativt kan du få tilgang til mer detaljert informasjon og endre preferansene dine før du samtykker. Vær oppmerksom på at en viss behandling av dine personopplysninger kanskje ikke krever ditt samtykke, men at du har rett til å protestere mot slik databehandling. Dine preferanser kommer til å gjelde på en gruppe nettsteder.'
      ],
      partners: 'våre partnere',
      groupOfSitesLabel: 'gruppe nettsteder',
      vendorScreenBody:
        'Se gjennom og angi dine samtykkepreferanser for hver av partnerne slik de er omtalt nedenfor. Utvid hver enkelt partnerliste for mer informasjon for å hjelpe deg å foreta ditt valg. Enkelte persondata blir behandlet uten ditt samtykke, men du har rett til å protestere.',
      showPartners: 'Vis partnere',
      hidePartners: 'Skjul partnere'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Ikke-IAB-leverandører',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Russian
  ru: {
    agree: 'СОГЛАСЕН',
    saveAndExit: 'СОХРАНИТЬ И ВЫЙТИ',
    disagree: 'НЕ СОГЛАСЕН',
    objectAll: 'ОТКАЗАТЬСЯ ОТ ВСЕХ',
    mockPurposesCopies: {
      headline: 'Мы с большим уважением относимся к вашей конфиденциальности',
      body: 'Вы можете установить настройки и решить, хотите ли вы, чтобы ваши данные использовались в указанных ниже целях. Вы можете установить настройки для нас отдельно от сторонних партнеров. Каждая цель подробно описана, чтобы вы знали, как мы и партнеры используем ваши данные.',
      accept: 'ПРИНЯТЬ ВСЁ',
      vendorLink: 'Посмотреть поставщиков',
      saveExit: 'Сохранить и выйти',
      cancel: 'Отменить',
      consentLinkLabel: 'Конфиденциальность'
    },
    mockHeadlinesCopies: {
      headline: 'Мы с большим уважением относимся к вашей конфиденциальности',
      accept: 'Я согласен',
      reject: 'Я НЕ СОГЛАСЕН / НЕ СОГЛАСНА',
      showPurposes: 'Показать цели',
    },
    coreUiLabels: {
      back: 'Назад',
      acceptAll: 'ПРИНЯТЬ ВСЁ',
      descriptionLabel: 'Описание поставщика:',
      featuresLabel: 'Особенности',
      initScreenAttributionText: 'Powered by',
      initScreenBodyService:
        'Мы и наши партнеры храним информацию или получаем доступ к информации на устройствах, например к файлам cookie, и обрабатываем персональные данные, к которым относятся уникальные идентификаторы и стандартная информация, отправляемая устройством, для целей, указанных ниже. Вы можете нажать на кнопку согласия, чтобы согласиться на обработку информации нашей компанией и нашими партнерами для таких целей. Также вы можете нажать на кнопку отказа от предоставления согласия или получить доступ к более подробной информации и изменить свои пользовательские настройки, прежде чем дать согласие. Ваши настройки будут применяться только к данному веб-сайту. Обратите внимание, что при обработке ваших персональных данных в некоторых случаях ваше согласие может не потребоваться, однако вы имеете право возразить против такой обработки.',
      initScreenBodyGlobal:
        'Мы и наши партнеры храним информацию или получаем доступ к информации на устройствах, например к файлам cookie, и обрабатываем персональные данные, к которым относятся уникальные идентификаторы и стандартная информация, отправляемая устройством, для целей, указанных ниже. Вы можете нажать на кнопку согласия, чтобы согласиться на обработку информации нашей компанией и нашими партнерами для таких целей. Также вы можете нажать на кнопку отказа от предоставления согласия или получить доступ к более подробной информации для изменения своих пользовательских настроек, прежде чем дать согласие. Ваши настройки будут применяться ко всей сети Интернет. Обратите внимание, что при обработке ваших персональных данных в некоторых случаях ваше согласие может не потребоваться, однако вы имеете право возразить против такой обработки.',
      initScreenBodyGroup:
        'Мы и наши партнеры храним информацию или получаем доступ к информации на устройствах, например к файлам cookie, и обрабатываем персональные данные, к которым относятся уникальные идентификаторы и стандартная информация, отправляемая устройством, для целей, указанных ниже. Вы можете нажать на кнопку согласия, чтобы согласиться на обработку информации нашей компанией и нашими партнерами для таких целей. Также вы можете нажать на кнопку отказа от предоставления согласия или получить доступ к более подробной информации и изменить свои пользовательские настройки, прежде чем дать согласие. Ваши настройки будут применяться к группе веб-сайтов. Обратите внимание, что при обработке ваших персональных данных в некоторых случаях ваше согласие может не потребоваться, однако вы имеете право возразить против такой обработки.',
      initScreenRejectButton: 'НЕ СОГЛАСЕН',
      initScreenSettingsButton: 'ДОПОЛНИТЕЛЬНЫЕ ПАРАМЕТРЫ',
      initScreenTitle: 'Мы ценим вашу конфиденциальность',
      legalDescription: 'Юридическое описание',
      legitimateInterestPurposesLabel: 'ЗАКОННЫЕ ИНТЕРЕСЫ',
      legitimateScreenObjected: 'ОТКАЗ',
      agreeButton: 'СОГЛАСЕН',
      legitimateScreenAccept: 'УДАЛИТЬ ОТКАЗ',
      legitimateScreenObject: 'Object',
      objectAllButton: 'ОТКАЗАТЬСЯ ОТ ВСЕХ',
      offLabel: 'Выкл.',
      offOnLabel: 'Выкл./Вкл.',
      onLabel: 'Вкл.',
      multiLabel: 'МУЛЬТИ-',
      persistentConsentLinkLabel: 'Конфиденциальность',
      privacyPolicyLabel: 'Политика конфиденциальности',
      legitimateScreenBody:
        'Изучите условия и откажитесь от обработки персональных данных без вашего согласия на основании законных интересов по каждой цели и каждому из партнеров, указанных ниже. Разверните каждый элемент списка целей и партнеров для получения дополнительной информации, которая поможет вам сделать выбор. Для отказа от особых целей обеспечения безопасности, предотвращения мошенничества, отладки и технического показа рекламы или контента, нажмите на ссылку политики конфиденциальности партнера.',
      saveAndExitButton: 'СОХРАНИТЬ И ВЫЙТИ',
      purposeScreenVendorLink: 'ПАРТНЕРЫ',
      purposesLabel: 'Цели (согласие)',
      flexiblePurposesLabel: 'Flexible Purposes',
      softOptInAlertBody:
        'To withdraw your consent or change your preferences, please click on the <strong>Privacy</strong> button.',
      softOptInAlertTitle: 'Your consent preferences have been saved.',
      specialPurposesLabel: 'Особые цели',
      specialFeaturesLabel: 'Особые возможности',
      summaryScreenBodyNoRejectService: [
        'Мы и наши партнеры храним информацию и/или получаем доступ к информации на устройстве, например к файлам cookie, и обрабатываем персональные данные, к которым относятся уникальные идентификаторы и стандартная информация, отправляемая устройством, для настройки персонализации и анализа рекламы и контента, а также для оценки аудитории наряду с разработкой и совершенствованием продуктов.',
        'С вашего разрешения мы и наши партнеры можем использовать точные геолокационные данные и проводить идентификацию путем сканирования устройства. Вы можете нажать на кнопку согласия, чтобы согласиться на обработку информации нашей компанией и нашими партнерами, как описано выше. Также вы можете получить доступ к более подробной информации и изменить свои пользовательские настройки, прежде чем дать согласие на обработку информации или отказаться от нее. Обратите внимание, что при обработке ваших персональных данных в некоторых случаях ваше согласие может не потребоваться, однако вы имеете право возразить против такой обработки. Ваши настройки будут применяться только к данному веб-сайту.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Мы и наши партнеры храним информацию и/или получаем доступ к информации на устройстве, например к файлам cookie, и обрабатываем персональные данные, к которым относятся уникальные идентификаторы и стандартная информация, отправляемая устройством, для настройки персонализации и анализа рекламы и контента, а также для оценки аудитории наряду с разработкой и совершенствованием продуктов.',
        'С вашего разрешения мы и наши партнеры можем использовать точные геолокационные данные и проводить идентификацию путем сканирования устройства. Вы можете нажать на кнопку согласия, чтобы согласиться на обработку информации нашей компанией и нашими партнерами, как описано выше. Также вы можете получить доступ к более подробной информации и изменить свои пользовательские настройки, прежде чем дать согласие на обработку информации или отказаться от нее. Обратите внимание, что при обработке ваших персональных данных в некоторых случаях ваше согласие может не потребоваться, однако вы имеете право возразить против такой обработки. Ваши настройки будут применяться во всей сети Интернет.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Мы и наши партнеры храним информацию и/или получаем доступ к информации на устройстве, например к файлам cookie, и обрабатываем персональные данные, к которым относятся уникальные идентификаторы и стандартная информация, отправляемая устройством, для настройки персонализации и анализа рекламы и контента, а также для оценки аудитории наряду с разработкой и совершенствованием продуктов.',
        'С вашего разрешения мы и наши партнеры можем использовать точные геолокационные данные и проводить идентификацию путем сканирования устройства. Вы можете нажать на кнопку согласия, чтобы согласиться на обработку информации нашей компанией и нашими партнерами, как описано выше. Также вы можете получить доступ к более подробной информации и изменить свои пользовательские настройки, прежде чем дать согласие на обработку информации или отказаться от нее. Обратите внимание, что при обработке ваших персональных данных в некоторых случаях ваше согласие может не потребоваться, однако вы имеете право возразить против такой обработки. Ваши настройки будут применяться к группе веб-сайтов.'
      ],
      summaryScreenBodyRejectService: [
        'Мы и наши партнеры храним информацию и/или получаем доступ к информации на устройстве, например к файлам cookie, и обрабатываем персональные данные, к которым относятся уникальные идентификаторы и стандартная информация, отправляемая устройством, для настройки персонализации и анализа рекламы и контента, а также для оценки аудитории наряду с разработкой и совершенствованием продуктов.',
        'С вашего разрешения мы и наши партнеры можем использовать точные геолокационные данные и проводить идентификацию путем сканирования устройства. Вы можете нажать на кнопку согласия, чтобы согласиться на обработку информации нашей компанией и нашими партнерами, как описано выше. Также вы можете нажать на кнопку отказа от предоставления согласия или получить доступ к более подробной информации и изменить свои пользовательские настройки, прежде чем дать согласие. Обратите внимание, что при обработке ваших персональных данных в некоторых случаях ваше согласие может не потребоваться, однако вы имеете право возразить против такой обработки. Ваши настройки будут применяться только к данному веб-сайту.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Мы и наши партнеры храним информацию и/или получаем доступ к информации на устройстве, например к файлам cookie, и обрабатываем персональные данные, к которым относятся уникальные идентификаторы и стандартная информация, отправляемая устройством, для настройки персонализации и анализа рекламы и контента, а также для оценки аудитории наряду с разработкой и совершенствованием продуктов.',
        'С вашего разрешения мы и наши партнеры можем использовать точные геолокационные данные и проводить идентификацию путем сканирования устройства. Вы можете нажать на кнопку согласия, чтобы согласиться на обработку информации нашей компанией и нашими партнерами, как описано выше. Также вы можете нажать на кнопку отказа от предоставления согласия или получить доступ к более подробной информации и изменить свои пользовательские настройки, прежде чем дать согласие. Обратите внимание, что при обработке ваших персональных данных в некоторых случаях ваше согласие может не потребоваться, однако вы имеете право возразить против такой обработки. Ваши настройки будут применяться ко всей сети Интернет.'
      ],
      summaryScreenBodyRejectGroup: [
        'Мы и наши партнеры храним информацию и/или получаем доступ к информации на устройстве, например к файлам cookie, и обрабатываем персональные данные, к которым относятся уникальные идентификаторы и стандартная информация, отправляемая устройством, для настройки персонализации и анализа рекламы и контента, а также для оценки аудитории наряду с разработкой и совершенствованием продуктов.',
        'С вашего разрешения мы и наши партнеры можем использовать точные геолокационные данные и проводить идентификацию путем сканирования устройства. Вы можете нажать на кнопку согласия, чтобы согласиться на обработку информации нашей компанией и нашими партнерами, как описано выше. Также вы можете нажать на кнопку отказа от предоставления согласия или получить доступ к более подробной информации и изменить свои пользовательские настройки, прежде чем дать согласие. Обратите внимание, что при обработке ваших персональных данных в некоторых случаях ваше согласие может не потребоваться, однако вы имеете право возразить против такой обработки. Ваши настройки будут применяться к группе веб-сайтов.'
      ],
      partners: 'наши партнеры',
      groupOfSitesLabel: 'группе веб-сайтов',
      vendorScreenBody:
        'Проверьте и установите свои настройки согласия по каждому партнеру, как указано ниже. Разверните каждый элемент списка партнеров для получения дополнительной информации, которая поможет вам сделать выбор. Некоторые персональные данные обрабатываются без вашего согласия, но вы имеете право отказаться от обработки.',
      showPartners: 'Показать партнеров',
      hidePartners: 'Скрыть партнеров'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Сторонние поставщики',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Ukrainian
  uk: {
    agree: 'ПОГОДЖЕН',
    saveAndExit: 'ЗБЕРЕГТИ ТА ВИХОДИТИ',
    disagree: 'НЕ ЗГОДЖЕН',
    objectAll: 'ОБ\'ЄКТ ВСЕ',
    mockPurposesCopies: {
      headline: 'Ми цінуємо вашу конфіденційність',
      body: 'Ви можете встановити параметри згоди та визначити, як ви хочете використовувати ваші дані, виходячи з наведених нижче цілей. Ви можете встановити свої параметри для нас незалежно від параметрів сторонніх партнерів. Кожна мета має опис, щоб ви знали, як ми та партнери використовуємо ваші дані.',
      accept: 'Прийняти все',
      vendorLink: 'Дивіться Продавці',
      saveExit: 'ЗБЕРЕГТИ ТА ВИХОДИТИ',
      cancel: 'Скасувати',
      consentLinkLabel: 'Конфіденційність'
    },
    mockHeadlinesCopies: {
      headline: 'Ми цінуємо вашу конфіденційність',
      accept: 'я приймаю',
      reject: 'я не приймаю',
      showPurposes: 'Показати цілі',
    },
    coreUiLabels: {
      back: 'Назад',
      acceptAll: 'Прийняти все',
      descriptionLabel: 'Опис постачальника:',
      featuresLabel: 'Особливості',
      initScreenAttributionText: 'Працює на',
      initScreenBodyService:
        'Ми та наші партнери зберігаємо або отримуємо інформацію на пристроях, таких як файли cookie, та обробляємо особисті дані, такі як унікальні ідентифікатори та стандартна інформація, надіслана пристроєм для цілей, описаних нижче. Ви можете натиснути, щоб дати згоду на обробку нами та нашими партнерами для таких цілей. Альтернативно, ви можете натиснути, щоб відмовитися від згоди, або отримати більш детальну інформацію та змінити свої уподобання перед наданням згоди. Ваші уподобання застосовуватимуться лише до цього веб-сайту. Зверніть увагу, що деякі процеси обробки ваших особистих даних можуть не вимагати вашої згоди, але у вас є право заперечувати проти такої обробки.',
      initScreenBodyGlobal:
        'Ми та наші партнери зберігаємо або отримують інформацію на пристроях, таких як файли cookie, та обробляємо особисті дані, такі як унікальні ідентифікатори та стандартна інформація, надіслана пристроєм для цілей, описаних нижче. Ви можете натиснути, щоб дати згоду на обробку нами та нашими партнерами для таких цілей. Альтернативно, ви можете натиснути, щоб відмовитися від згоди, або отримати більш детальну інформацію та змінити свої уподобання перед наданням згоди. Ваші уподобання застосовуватимуться в усьому Інтернеті. Зверніть увагу, що деякі процеси обробки ваших особистих даних можуть не вимагати вашої згоди, але у вас є право заперечувати проти такої обробки.',
      initScreenBodyGroup:
        'Ми та наші партнери зберігаємо або отримуємо інформацію на пристроях, таких як файли cookie, та обробляємо особисті дані, такі як унікальні ідентифікатори та стандартна інформація, надіслана пристроєм для цілей, описаних нижче. Ви можете натиснути, щоб дати згоду на обробку нами та нашими партнерами для таких цілей. Альтернативно, ви можете натиснути, щоб відмовитися від згоди, або отримати більш детальну інформацію та змінити свої уподобання перед наданням згоди. Ваші уподобання застосовуватимуться до групи веб-сайтів. Зверніть увагу, що деякі процеси обробки ваших особистих даних можуть не вимагати вашої згоди, але у вас є право заперечувати проти такої обробки.',
      initScreenRejectButton: 'Не згоден',
      initScreenSettingsButton: 'БІЛЬШЕ ОПЦІЙ',
      initScreenTitle: 'Ми цінуємо вашу конфіденційність',
      legalDescription: 'Юридичний опис',
      legitimateInterestPurposesLabel: 'Законні інтереси',
      legitimateScreenObjected: 'Заперечено',
      agreeButton: 'Погодитися',
      legitimateScreenAccept: 'ЗНЯТИ ЗАПЕРЕЧЕННЯ',
      legitimateScreenObject: 'Заперечити',
      objectAllButton: 'Заперечити все',
      offLabel: 'Вимк.',
      offOnLabel: 'Вимк./Увімк.',
      onLabel: 'Увімк.',
      multiLabel: 'Багато',
      persistentConsentLinkLabel: 'Конфіденційність',
      privacyPolicyLabel: 'Політика конфіденційності',
      softOptInText:
        'Ознайомтеся та заперечуйте обробку особистих даних без вашої згоди на основі законного інтересу для кожної цілі та кожного партнера нижче. Розгорніть кожну мету або елемент списку партнерів для отримання додаткової інформації, яка допоможе вам зробити вибір. Щоб заперечити проти спеціальних цілей забезпечення безпеки, запобігання шахрайству та налагодження, а також технічного надання реклами або контенту, натисніть на посилання на політику конфіденційності партнера.',
      legitimateScreenBody:
        'Ознайомтеся та заперечуйте обробку особистих даних без вашої згоди на основі законного інтересу для кожної цілі та кожного партнера нижче. Розгорніть кожну мету або елемент списку партнерів для отримання додаткової інформації, яка допоможе вам зробити вибір. Щоб заперечити проти спеціальних цілей забезпечення безпеки, запобігання шахрайству та налагодження, а також технічного надання реклами або контенту, натисніть на посилання на політику конфіденційності партнера.',
      saveAndExitButton: 'Зберегти та вийти',
      purposeScreenVendorLink: 'Партнери',
      purposesLabel: 'Цілі (Згода)',
      flexiblePurposesLabel: 'Гнучкі цілі',
      softOptInAlertBody:
        'Щоб відкликати свою згоду або змінити свої уподобання, натисніть кнопку <strong>Конфіденційність</strong>.',
      softOptInAlertTitle: 'Ваші уподобання згоди збережені.',
      specialPurposesLabel: 'Спеціальні цілі',
      specialFeaturesLabel: 'Спеціальні функції',
      summaryScreenBodyNoRejectService: [
        'Ми та наші партнери зберігаємо та/або отримуємо інформацію на пристрої, таку як файли cookie, та обробляємо особисті дані, такі як унікальні ідентифікатори та стандартна інформація, надіслана пристроєм для персоналізованої реклами та контенту, вимірювання реклами та контенту, а також для отримання аналітичної інформації про аудиторію, а також для розробки та вдосконалення продуктів. ',
        'З вашого дозволу ми та наші партнери можемо використовувати точні дані про геолокацію та ідентифікацію через сканування пристрою. Ви можете натиснути, щоб дати згоду на обробку нами та нашими партнерами, як описано вище. Альтернативно ви можете отримати більш детальну інформацію та змінити свої уподобання перед наданням згоди або відмовою від надання згоди. Зверніть увагу, що деякі процеси обробки ваших особистих даних можуть не вимагати вашої згоди, але у вас є право заперечувати проти такої обробки. Ваші уподобання будуть застосовуватися лише до цього веб-сайту.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Ми та наші партнери зберігаємо та/або отримуємо інформацію на пристрої, таку як файли cookie, та обробляємо особисті дані, такі як унікальні ідентифікатори та стандартна інформація, надіслана пристроєм для персоналізованої реклами та контенту, вимірювання реклами та контенту, а також для отримання аналітичної інформації про аудиторію, а також для розробки та вдосконалення продуктів. ',
        'З вашого дозволу ми та наші партнери можемо використовувати точні дані про геолокацію та ідентифікацію через сканування пристрою. Ви можете натиснути, щоб дати згоду на обробку нами та нашими партнерами, як описано вище. Альтернативно ви можете отримати більш детальну інформацію та змінити свої уподобання перед наданням згоди або відмовою від надання згоди. Зверніть увагу, що деякі процеси обробки ваших особистих даних можуть не вимагати вашої згоди, але у вас є право заперечувати проти такої обробки. Ваші уподобання будуть застосовуватися в усьому Інтернеті.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Ми та наші партнери зберігаємо та/або отримуємо інформацію на пристрої, таку як файли cookie, та обробляємо особисті дані, такі як унікальні ідентифікатори та стандартна інформація, надіслана пристроєм для персоналізованої реклами та контенту, вимірювання реклами та контенту, а також для отримання аналітичної інформації про аудиторію, а також для розробки та вдосконалення продуктів. ',
        'З вашого дозволу ми та наші партнери можемо використовувати точні дані про геолокацію та ідентифікацію через сканування пристрою. Ви можете натиснути, щоб дати згоду на обробку нами та нашими партнерами, як описано вище. Альтернативно ви можете отримати більш детальну інформацію та змінити свої уподобання перед наданням згоди або відмовою від надання згоди. Зверніть увагу, що деякі процеси обробки ваших особистих даних можуть не вимагати вашої згоди, але у вас є право заперечувати проти такої обробки. Ваші уподобання будуть застосовуватися до групи веб-сайтів.'
      ],
      summaryScreenBodyRejectService: [
        'Ми та наші партнери зберігаємо та/або отримуємо інформацію на пристрої, таку як файли cookie, та обробляємо особисті дані, такі як унікальні ідентифікатори та стандартна інформація, надіслана пристроєм для персоналізованої реклами та контенту, вимірювання реклами та контенту, а також для отримання аналітичної інформації про аудиторію, а також для розробки та вдосконалення продуктів. ',
        'З вашого дозволу ми та наші партнери можемо використовувати точні дані про геолокацію та ідентифікацію через сканування пристрою. Ви можете натиснути, щоб дати згоду на обробку нами та нашими партнерами, як описано вище. Альтернативно ви можете натиснути, щоб відмовитися від надання згоди або отримати більш детальну інформацію та змінити свої уподобання перед наданням згоди. Зверніть увагу, що деякі процеси обробки ваших особистих даних можуть не вимагати вашої згоди, але у вас є право заперечувати проти такої обробки. Ваші уподобання будуть застосовуватися лише до цього веб-сайту.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Ми та наші партнери зберігаємо та/або отримують інформацію на пристрої, таку як файли cookie, та обробляємо особисті дані, такі як унікальні ідентифікатори та стандартна інформація, надіслана пристроєм для персоналізованої реклами та контенту, вимірювання реклами та контенту, а також для отримання аналітичної інформації про аудиторію, а також для розробки та вдосконалення продуктів. ',
        'З вашого дозволу ми та наші партнери можемо використовувати точні дані про геолокацію та ідентифікацію через сканування пристрою. Ви можете натиснути, щоб дати згоду на обробку нами та нашими партнерами, як описано вище. Альтернативно ви можете натиснути, щоб відмовитися від надання згоди або отримати більш детальну інформацію та змінити свої уподобання перед наданням згоди. Зверніть увагу, що деякі процеси обробки ваших особистих даних можуть не вимагати вашої згоди, але у вас є право заперечувати проти такої обробки. Ваші уподобання будуть застосовуватися в усьому Інтернеті.'
      ],
      summaryScreenBodyRejectGroup: [
        'Ми та наші партнери зберігаємо та/або отримують інформацію на пристрої, таку як файли cookie, та обробляємо особисті дані, такі як унікальні ідентифікатори та стандартна інформація, надіслана пристроєм для персоналізованої реклами та контенту, вимірювання реклами та контенту, а також для отримання аналітичної інформації про аудиторію, а також для розробки та вдосконалення продуктів. ',
        'З вашого дозволу ми та наші партнери можемо використовувати точні дані про геолокацію та ідентифікацію через сканування пристрою. Ви можете натиснути, щоб дати згоду на обробку нами та нашими партнерами, як описано вище. Альтернативно ви можете натиснути, щоб відмовитися від надання згоди або отримати більш детальну інформацію та змінити свої уподобання перед наданням згоди. Зверніть увагу, що деякі процеси обробки ваших особистих даних можуть не вимагати вашої згоди, але у вас є право заперечувати проти такої обробки. Ваші уподобання будуть застосовуватися до групи веб-сайтів.'
      ],
      partners: 'наші партнери',
      groupOfSitesLabel: 'група веб-сайтів',
      vendorScreenBody:
        'Ознайомтеся та встановіть свої уподобання згоди для кожного партнера нижче. Розгорніть кожен елемент списку партнерів для отримання додаткової інформації, яка допоможе вам зробити вибір. Деякі особисті дані обробляються без вашої згоди, але ви маєте право заперечувати.',
      showPartners: 'Показати партнерів',
      hidePartners: 'Сховати партнерів'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Небі-а-бі постачальники',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
  // Turkish
  tr: {
    agree: 'KABUL ET',
    saveAndExit: 'KAYDET & ÇIK',
    disagree: 'KABUL ETMİYORUM',
    objectAll: 'TÜMÜNE İTİRAZ ET',
    mockPurposesCopies: {
      headline: 'Gizliliğinize değer veriyoruz',
      body: 'Aşağıdaki amaçlara göre verilerinizin nasıl kullanılacağını belirleyerek onay tercihlerinizi ayarlayabilirsiniz. Üçüncü taraf ortaklarımızdan bağımsız olarak bizim için tercihlerinizi ayarlayabilirsiniz. Her amaç, verilerinizi nasıl kullandığımızı ve ortaklarımızın nasıl kullandığını bilmeniz için bir açıklamaya sahiptir.',
      accept: 'Hepsini kabul et',
      vendorLink: 'Tedarikçileri Gör',
      saveExit: 'Kaydet & Çık',
      cancel: 'İptal',
      consentLinkLabel: 'Gizlilik'
    },
    mockHeadlinesCopies: {
      headline: 'Gizliliğinize değer veriyoruz',
      accept: 'Kabul ediyorum',
      reject: 'Kabul etmiyorum',
      showPurposes: 'Amaçları Göster',
    },
    coreUiLabels: {
      back: 'Geri',
      acceptAll: 'Hepsini kabul et',
      descriptionLabel: 'Tedarikçi Açıklaması:',
      featuresLabel: 'Özellikler',
      initScreenAttributionText: 'Tarafından desteklenmektedir',
      initScreenBodyService:
        'Biz ve ortaklarımız, aşağıda açıklanan amaçlar için cihazlarda bilgi depolar veya bu bilgilere erişiriz. Cihazlardan gönderilen benzersiz tanımlayıcılar ve standart bilgiler gibi kişisel verileri işleriz. Bu tür işlemlere onay vermek için tıklayabilirsiniz. Alternatif olarak, onay vermeyi reddedebilir veya daha ayrıntılı bilgileri inceleyip onay tercihlerinizi değiştirebilirsiniz. Tercihleriniz sadece bu web sitesinde geçerli olacaktır. Kişisel verilerinizin bazı işlemlerinin onayınızı gerektirmeyebileceğini, ancak bu tür işlemlere itiraz etme hakkınızın olduğunu unutmayın.',
      initScreenBodyGlobal:
        'Biz ve ortaklarımız, aşağıda açıklanan amaçlar için cihazlarda bilgi depolar veya bu bilgilere erişiriz. Cihazlardan gönderilen benzersiz tanımlayıcılar ve standart bilgiler gibi kişisel verileri işleriz. Bu tür işlemlere onay vermek için tıklayabilirsiniz. Alternatif olarak, onay vermeyi reddedebilir veya daha ayrıntılı bilgileri inceleyip onay tercihlerinizi değiştirebilirsiniz. Tercihleriniz tüm web üzerinde geçerli olacaktır. Kişisel verilerinizin bazı işlemlerinin onayınızı gerektirmeyebileceğini, ancak bu tür işlemlere itiraz etme hakkınızın olduğunu unutmayın.',
      initScreenBodyGroup:
        'Biz ve ortaklarımız, aşağıda açıklanan amaçlar için cihazlarda bilgi depolar veya bu bilgilere erişiriz. Cihazlardan gönderilen benzersiz tanımlayıcılar ve standart bilgiler gibi kişisel verileri işleriz. Bu tür işlemlere onay vermek için tıklayabilirsiniz. Alternatif olarak, onay vermeyi reddedebilir veya daha ayrıntılı bilgileri inceleyip onay tercihlerinizi değiştirebilirsiniz. Tercihleriniz bir grup web sitesinde geçerli olacaktır. Kişisel verilerinizin bazı işlemlerinin onayınızı gerektirmeyebileceğini, ancak bu tür işlemlere itiraz etme hakkınızın olduğunu unutmayın.',
      initScreenRejectButton: 'Kabul Etmiyorum',
      initScreenSettingsButton: 'DAHA FAZLA SEÇENEK',
      initScreenTitle: 'Gizliliğinize değer veriyoruz',
      legalDescription: 'Yasal Açıklama',
      legitimateInterestPurposesLabel: 'Meşru İlgi',
      legitimateScreenObjected: 'İtiraz Edildi',
      agreeButton: 'Kabul Et',
      legitimateScreenAccept: 'İTİRAZI KALDIR',
      legitimateScreenObject: 'İtiraz Et',
      objectAllButton: 'Tümüne itiraz et',
      offLabel: 'Kapalı',
      offOnLabel: 'Kapalı/Açık',
      onLabel: 'Açık',
      multiLabel: 'Çoklu',
      persistentConsentLinkLabel: 'Gizlilik',
      privacyPolicyLabel: 'Gizlilik Politikası',
      softOptInText:
        'Aşağıda her amaç ve ortak için kişisel verilerin meşru çıkarlar doğrultusunda işlenmesine itiraz edin. Daha fazla bilgi almak ve tercihinizi yapmak için her amacı veya ortak listesini genişletin. Güvenliği sağlama, sahtekarlığı önleme ve hata ayıklama ve teknik olarak reklam veya içerik sunma gibi özel amaçlara itiraz etmek için bir ortağın gizlilik politikası bağlantısına tıklayın.',
      legitimateScreenBody:
        'Aşağıda her amaç ve ortak için kişisel verilerin meşru çıkarlar doğrultusunda işlenmesine itiraz edin. Daha fazla bilgi almak ve tercihinizi yapmak için her amacı veya ortak listesini genişletin. Güvenliği sağlama, sahtekarlığı önleme ve hata ayıklama ve teknik olarak reklam veya içerik sunma gibi özel amaçlara itiraz etmek için bir ortağın gizlilik politikası bağlantısına tıklayın.',
      saveAndExitButton: 'Kaydet & Çık',
      purposeScreenVendorLink: 'Ortaklar',
      purposesLabel: 'Amaçlar (Onay)',
      flexiblePurposesLabel: 'Esnek Amaçlar',
      softOptInAlertBody:
        'Onayınızı geri çekmek veya tercihlerinizi değiştirmek için lütfen <strong>Gizlilik</strong> düğmesine tıklayın.',
      softOptInAlertTitle: 'Onay tercihlerinizi kaydettiniz.',
      specialPurposesLabel: 'Özel Amaçlar',
      specialFeaturesLabel: 'Özel Özellikler',
      summaryScreenBodyNoRejectService: [
        'Biz ve ortaklarımız cihazlarda bilgi depolar ve/veya bu bilgilere erişiriz, kişisel verileri işleriz, örneğin benzersiz tanımlayıcılar ve cihazdan gönderilen standart bilgiler gibi verileri kişiselleştirilmiş reklamlar ve içerik, reklam ve içerik ölçümü ve kitle analizleri için kullanırız ve ürünleri geliştirmek için işlem yaparız.',
        'Sizin izninizle biz ve ortaklarımız kesin konum verilerini ve cihaz taraması yoluyla tanımlama verilerini kullanabiliriz. Yukarıda açıklandığı gibi bizim ve ortaklarımızın işlem yapmasına onay vermek için tıklayabilirsiniz. Alternatif olarak, daha ayrıntılı bilgilere erişebilir ve onay vermeden önce tercihlerinizi değiştirebilir veya onay vermeyi reddedebilirsiniz. Kişisel verilerinizin bazı işlemlerinin onayınızı gerektirmeyebileceğini, ancak bu tür işlemlere itiraz etme hakkınızın olduğunu unutmayın. Tercihleriniz sadece bu web sitesi için geçerli olacaktır.'
      ],
      summaryScreenBodyNoRejectGlobal: [
        'Biz ve ortaklarımız cihazlarda bilgi depolar ve/veya bu bilgilere erişiriz, kişisel verileri işleriz, örneğin benzersiz tanımlayıcılar ve cihazdan gönderilen standart bilgiler gibi verileri kişiselleştirilmiş reklamlar ve içerik, reklam ve içerik ölçümü ve kitle analizleri için kullanırız ve ürünleri geliştirmek için işlem yaparız.',
        'Sizin izninizle biz ve ortaklarımız kesin konum verilerini ve cihaz taraması yoluyla tanımlama verilerini kullanabiliriz. Yukarıda açıklandığı gibi bizim ve ortaklarımızın işlem yapmasına onay vermek için tıklayabilirsiniz. Alternatif olarak, daha ayrıntılı bilgilere erişebilir ve onay vermeden önce tercihlerinizi değiştirebilir veya onay vermeyi reddedebilirsiniz. Kişisel verilerinizin bazı işlemlerinin onayınızı gerektirmeyebileceğini, ancak bu tür işlemlere itiraz etme hakkınızın olduğunu unutmayın. Tercihleriniz tüm web üzerinde geçerli olacaktır.'
      ],
      summaryScreenBodyNoRejectGroup: [
        'Biz ve ortaklarımız cihazlarda bilgi depolar ve/veya bu bilgilere erişiriz, kişisel verileri işleriz, örneğin benzersiz tanımlayıcılar ve cihazdan gönderilen standart bilgiler gibi verileri kişiselleştirilmiş reklamlar ve içerik, reklam ve içerik ölçümü ve kitle analizleri için kullanırız ve ürünleri geliştirmek için işlem yaparız.',
        'Sizin izninizle biz ve ortaklarımız kesin konum verilerini ve cihaz taraması yoluyla tanımlama verilerini kullanabiliriz. Yukarıda açıklandığı gibi bizim ve ortaklarımızın işlem yapmasına onay vermek için tıklayabilirsiniz. Alternatif olarak, daha ayrıntılı bilgilere erişebilir ve onay vermeden önce tercihlerinizi değiştirebilir veya onay vermeyi reddedebilirsiniz. Kişisel verilerinizin bazı işlemlerinin onayınızı gerektirmeyebileceğini, ancak bu tür işlemlere itiraz etme hakkınızın olduğunu unutmayın. Tercihleriniz bir grup web sitesi için geçerli olacaktır.'
      ],
      summaryScreenBodyRejectService: [
        'Biz ve ortaklarımız cihazlarda bilgi depolar ve/veya bu bilgilere erişiriz, kişisel verileri işleriz, örneğin benzersiz tanımlayıcılar ve cihazdan gönderilen standart bilgiler gibi verileri kişiselleştirilmiş reklamlar ve içerik, reklam ve içerik ölçümü ve kitle analizleri için kullanırız ve ürünleri geliştirmek için işlem yaparız.',
        'Sizin izninizle biz ve ortaklarımız kesin konum verilerini ve cihaz taraması yoluyla tanımlama verilerini kullanabiliriz. Yukarıda açıklandığı gibi bizim ve ortaklarımızın işlem yapmasına onay vermek için tıklayabilirsiniz. Alternatif olarak, daha ayrıntılı bilgilere erişebilir ve onay vermeden önce tercihlerinizi değiştirebilir veya onay vermeyi reddedebilirsiniz. Kişisel verilerinizin bazı işlemlerinin onayınızı gerektirmeyebileceğini, ancak bu tür işlemlere itiraz etme hakkınızın olduğunu unutmayın. Tercihleriniz sadece bu web sitesi için geçerli olacaktır.'
      ],
      summaryScreenBodyRejectGlobal: [
        'Biz ve ortaklarımız cihazlarda bilgi depolar ve/veya bu bilgilere erişiriz, kişisel verileri işleriz, örneğin benzersiz tanımlayıcılar ve cihazdan gönderilen standart bilgiler gibi verileri kişiselleştirilmiş reklamlar ve içerik, reklam ve içerik ölçümü ve kitle analizleri için kullanırız ve ürünleri geliştirmek için işlem yaparız.',
        'Sizin izninizle biz ve ortaklarımız kesin konum verilerini ve cihaz taraması yoluyla tanımlama verilerini kullanabiliriz. Yukarıda açıklandığı gibi bizim ve ortaklarımızın işlem yapmasına onay vermek için tıklayabilirsiniz. Alternatif olarak, daha ayrıntılı bilgilere erişebilir ve onay vermeden önce tercihlerinizi değiştirebilir veya onay vermeyi reddedebilirsiniz. Kişisel verilerinizin bazı işlemlerinin onayınızı gerektirmeyebileceğini, ancak bu tür işlemlere itiraz etme hakkınızın olduğunu unutmayın. Tercihleriniz tüm web üzerinde geçerli olacaktır.'
      ],
      summaryScreenBodyRejectGroup: [
        'Biz ve ortaklarımız cihazlarda bilgi depolar ve/veya bu bilgilere erişiriz, kişisel verileri işleriz, örneğin benzersiz tanımlayıcılar ve cihazdan gönderilen standart bilgiler gibi verileri kişiselleştirilmiş reklamlar ve içerik, reklam ve içerik ölçümü ve kitle analizleri için kullanırız ve ürünleri geliştirmek için işlem yaparız.',
        'Sizin izninizle biz ve ortaklarımız kesin konum verilerini ve cihaz taraması yoluyla tanımlama verilerini kullanabiliriz. Yukarıda açıklandığı gibi bizim ve ortaklarımızın işlem yapmasına onay vermek için tıklayabilirsiniz. Alternatif olarak, daha ayrıntılı bilgilere erişebilir ve onay vermeden önce tercihlerinizi değiştirebilir veya onay vermeyi reddedebilirsiniz. Kişisel verilerinizin bazı işlemlerinin onayınızı gerektirmeyebileceğini, ancak bu tür işlemlere itiraz etme hakkınızın olduğunu unutmayın. Tercihleriniz bir grup web sitesi için geçerli olacaktır.'
      ],
      partners: 'ortaklarımız',
      groupOfSitesLabel: 'bir grup web sitesi',
      vendorScreenBody:
        'Aşağıda her ortak için onay tercihlerinizi inceleyin ve ayarlayın. Daha fazla bilgi almak ve tercihinizi yapmak için her ortağı genişletin. Bazı kişisel verileriniz onayınız olmadan işlenmektedir, ancak bu tür işlemlere itiraz etme hakkınız bulunmaktadır.',
      showPartners: 'Ortakları Göster',
      hidePartners: 'Ortakları Gizle'
    },
    premiumUiLabels: {
      nonIabVendorsLabel: 'Non-IAB Vendors',
      uspDnsTitle: 'Do Not Sell My Data',
      uspDnsText: [
        'We, and our partners, use technologies to process personal \
        information, including IP addresses, pseudonymous identifiers associated \
        with cookies, and in some cases mobile ad IDs. This information is processed \
        to personalize content based on your interests, run and optimize marketing \
        campaigns, measure the performance of ads and content, and derive insights \
        about the audiences who engage with ads and content. This data is an integral \
        part of how we operate our site, make revenue to support our staff, and generate \
        relevant content for our audience. You can learn more about our data collection \
        and use practices in our Privacy Policy.',
        'If you wish to request that your personal information is not shared with third \
        parties, please click on the below checkbox and confirm your selection. Please note \
        that after your opt out request is processed, we may still collect your \
        information in order to operate our site.'
      ],
      uspDoNotSellToggleText:
        'I want to make a "Do Not Sell My Data" request. Note: this action will make it harder to us to tailor content for you.',
      uspPrivacyPolicyLinkText: 'Privacy Policy',
      uspDeleteDataLinkText: 'Data Deletion',
      uspAccessDataLinkText: 'Data Access',
      uspAcceptButton: 'CONFIRM'
    }
  },
};

export default TRANSLATIONS;
