import { getPremiumSites } from '../services/sites';
import { useQueryWrapper } from './common';
// @flow

const PREMIUM_SITES_LIST_QUERY = 'premiumSitesList';

export const usePremiumSitesList = (pCode: string) => {
  const queryPayload = { pCode };
  return useQueryWrapper([PREMIUM_SITES_LIST_QUERY, queryPayload], getPremiumSites, {
    onError: (errors) => {
      console.log('Error ', errors);
    }
  });
};
