import domPurify from 'dompurify';
import {
  FIELD_REQUIRED_TEXT,
  FIELD_URL_INVALID,
  IMAGE_SITE_INVALID_TYPE,
  MSPA_OPT_OUT_PURPOSES_REQUIRED,
  MSPA_PURPOSES_REQUIRED,
  OPT_OUT_PURPOSES,
  XSS_ERROR_TEXT
} from '../../../utils/constants';

const validate = () => {
  const URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!%$&'()*+,;=]+$/;
  const IMAGE_EXTENSIONS = /.(PNG|JPG|JPEG|GIF|BMP|SVG|SVGI)$/i;
  const URL_PROTOCOL_REGEX = /^(http|https):\/\/[^ "]+$/;
  const VERSION_REGEX = /(\d{1,2}|latest)/;
  const INVALID_SITE_URL = 'URL field is not valid.';
  const CONSENT_SCOPE_AMP = "Invalid consent scope for AMP sites. Please use 'Your site only'";
  const INVALID_URL_PROTOCOL = 'Oops, remove http:// or https:// protocol';
  const EXISTING_URL = 'Oops, this URL already exists. Please update your Site URL.';
  const VERSION_DOES_NOT_SUPPORT_AMP = 'This version does not support AMP, please select v20 or newer to enable it';
  const MIN_DAYS_MINIMUM = 'The minimum is 6 days';

  return {
    url: (sites, isCreate) => [
      {
        required: true,
        message: FIELD_REQUIRED_TEXT
      },
      {
        validator(_, value) {
          const existingUrls = sites.filter(site => site.url).map(site => site.url.toLowerCase());
          if (sites && value) {
            if (URL_PROTOCOL_REGEX.test(value)) {
              return Promise.reject(new Error(INVALID_URL_PROTOCOL));
            }
            if (!URL_REGEX.test(value)) {
              return Promise.reject(new Error(INVALID_SITE_URL));
            }
            if (isCreate) {
              if (existingUrls.includes(value.toLowerCase())) {
                return Promise.reject(new Error(EXISTING_URL));
              }
            }
          }
          return Promise.resolve();
        },
      }],
    publisherLogoUrl: [
      {
        type: 'url',
        message: FIELD_URL_INVALID
      },
      {
        validator(_, value) {
          if (value) {
            const extensionSection = value.slice(value.lastIndexOf('.'));
            const lastIndexOf = extensionSection.lastIndexOf('?');
            const extension = lastIndexOf !== -1 ? extensionSection.slice(0, lastIndexOf) : extensionSection;
            if (!extension.match(IMAGE_EXTENSIONS)) {
              return Promise.reject(new Error(IMAGE_SITE_INVALID_TYPE));
            }
          }

          return Promise.resolve();
        },
      }
    ],
    publisherName: noLegalBasis => [
      {
        validator(_, value) {
          if (!noLegalBasis && (!value || value === '')) {
            return Promise.reject(new Error(FIELD_REQUIRED_TEXT));
          }
          return Promise.resolve();
        }
      },
      {
        pattern: /^[^<>]*$/,
        message: "Field should not contain '<' or '>' symbols."
      }
    ],
    themeId: [
      {
        required: true,
        message: FIELD_REQUIRED_TEXT
      },
    ],
    cmpVersion: ampEnabled => ([
      {
        validator(_, value) {
          let version = VERSION_REGEX.exec(value)[0];
          if (ampEnabled && version !== 'latest') {
            version = parseInt(version, 10);
            if (version < 20) {
              return Promise.reject(new Error(VERSION_DOES_NOT_SUPPORT_AMP));
            }
          }
          return Promise.resolve();
        }
      }
    ]),
    repromtDaysAfterGvlUpdate: [
      {
        validator(_, value) {
          if (value && value < 6) {
            return Promise.reject(new Error(MIN_DAYS_MINIMUM));
          }
          return Promise.resolve();
        }
      }
    ],
    consentScopeGroupURL: scopeGroup => ([
      {
        required: scopeGroup,
        message: FIELD_REQUIRED_TEXT
      },
      {
        validator(_, value) {
          if (value && !URL_REGEX.test(value)) {
            return Promise.reject(new Error(FIELD_URL_INVALID));
          }
          return Promise.resolve();
        }
      }
    ]),
    groupOfWebsitesURL: [
      {
        validator(_, value) {
          if (value && !(URL_REGEX.test(value))) {
            return Promise.reject(new Error(FIELD_URL_INVALID));
          }
          return Promise.resolve();
        }
      }
    ],
    redirectUrl: values => ([
      {
        validator(_, value) {
          if (value && !URL_REGEX.test(values.redirectUrl)) {
            return Promise.reject(new Error(FIELD_URL_INVALID));
          }
          return Promise.resolve();
        }
      }
    ]),
    consentScope: ampEnabled => ([
      {
        validator(_, value) {
          if (ampEnabled && value !== 'service') {
            return Promise.reject(new Error(CONSENT_SCOPE_AMP));
          }
          return Promise.resolve();
        }
      }
    ]),
    regulation: gbcEnabled => ([
      {
        validator(_, value) {
          if (!gbcEnabled && value.length === 0) {
            return Promise.reject(new Error(FIELD_REQUIRED_TEXT));
          }
          return Promise.resolve();
        }
      }
    ]),
    mspaOptOutPurposeIdsRequired: [
      {
        validator(_, value) {
          if (value.length > 0 && !OPT_OUT_PURPOSES.some(id => value.includes(id))) {
            return Promise.reject(new Error(MSPA_OPT_OUT_PURPOSES_REQUIRED));
          }
          return Promise.resolve();
        },
      }
    ],
    mspaPurposeIdsRequired: [
      {
        required: true,
        message: MSPA_PURPOSES_REQUIRED
      },
    ],
    vendorName: [
      {
        required: true,
        message: FIELD_REQUIRED_TEXT
      },
      {
        pattern: /^[^<>]*$/,
        message: "Field should not contain '<' or '>' symbols."
      }
    ],
    validateURL: [
      {
        validator(_, value) {
          if (value && !(URL_REGEX.test(value))) {
            return Promise.reject(new Error(FIELD_URL_INVALID));
          }
          return Promise.resolve();
        }
      }
    ],
    textInput: [
      {
        pattern: /^[^<>]*$/,
        message: "Field should not contain '<' or '>' symbols."
      }
    ],
    consentOrPayEnable: isNonPersonalisedVendorPresent => ([
      {
        validator(_, value) {
          if (!isNonPersonalisedVendorPresent) {
            const text = `Cannot enable consent or pay, since none of the vendors have non-personalised purposes. \nChange vendors and retry.
            `;
            return Promise.reject(new Error(text));
          }
          return Promise.resolve();
        }
      }
    ]),
    consentOrPayAction1: callbackType => ([
      {
        validator(_, value) {
          if (callbackType === 'publisher' && !value) {
            return Promise.reject(new Error(FIELD_REQUIRED_TEXT));
          }
          return Promise.resolve();
        }
      },
      {
        type: 'url',
        message: FIELD_URL_INVALID
      },
    ]),
    consentOrPayAction2: [
      {
        type: 'url',
        message: FIELD_URL_INVALID
      },
    ],
    consentOrPayCountries: [
      {
        validator(_, value) {
          if (value.length === 0) {
            return Promise.reject(new Error(FIELD_REQUIRED_TEXT));
          }
          return Promise.resolve();
        }
      }
    ]
  };
};

export default validate;
