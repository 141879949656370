// @flow
import React from 'react';

// Extend on future
interface iCardContent {
    children: JSX.Element;
    className: String | null;
}
export default ({ children, className }: iCardContent) => (
  <div className={`qc-card__content ${className || ''}`}>
    { children }
  </div>
);
