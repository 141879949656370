// @flow

import { Button, Checkbox, Col, Form, Radio, Row, Space, Tooltip } from 'antd/es';

import React from 'react';
import classNames from 'classnames';
import Card from '../../ui/card/lib/card';
import InfoIconTooltip from '../../ui/infoIconTooltip';
import Modal from '../../ui/modal';
import SITETOOLTIPS from '../../../utils/toolTipsMessages';
import Styles from './consentScopeUSP.module.scss';
import TEXT from './text';
import useMSPAPurposes from './useMSPAPurposes';
import Alert from '../../ui/alert';
import {
  AUTO_POPUP,
  CHILDREN_DATA_PROCESSING, OPT_OUT_PURPOSE_IDS, SENSITIVE_DATA_IDS,
  SENSITIVE_DATA_PROCESSING
} from './constants';
import validate from '../sites/syncValidation';
import { SENSITIVE_PURPOSE_ID } from '../../../utils/constants';

const { CardHeader, CardContent } = Card;
const { mspaOptOutPurposeIdsRequired, mspaPurposeIdsRequired } = validate();

type Props = {
  className: string,
  setFieldsValue: Function,
  getFieldValue: Function,
  validateFields: Function,
  isBulkUpdate: boolean,
  handleChange: Function,
};

const ConsentScopeUSP = ({
  className,
  setFieldsValue,
  getFieldValue,
  validateFields,
  isBulkUpdate = false,
  handleChange = (() => {})
}: Props) => {
  const mspaPurposes = useMSPAPurposes();
  const [modalOpen, setModalOpen] = React.useState(false);
  const sensitivePurposeIds = getFieldValue(SENSITIVE_DATA_IDS) || [];
  const [isEditEnabled, setIsEditEnabled] = React.useState(Boolean(sensitivePurposeIds.length));
  const [sensitiveDataIds, setSensitiveDataIds] = React.useState(sensitivePurposeIds);

  const getPurposeOptions = () => {
    const optOutPurposes = [];
    const sensitivePurposes = [];
    mspaPurposes.forEach((purpose) => {
      if (purpose.id === SENSITIVE_PURPOSE_ID) {
        const optOutLabel = (
          <Space direction="vertical" size={2} className={Styles.optionsLabel}>
            <Space>
              <span>{purpose.portal.title}</span>
              {isEditEnabled && (

                <Button
                  type="link"
                  onClick={() => setModalOpen(true)}
                  className={Styles.EditButton}
                >
                  {TEXT.EDIT_PURPOSES}
                </Button>
              )}
            </Space>
            <span className={Styles.subTitle}>{purpose.portal.description}</span>
          </Space>
        );
        optOutPurposes.push({ label: optOutLabel, value: purpose.id });
        purpose.purposes.forEach((subPurpose) => {
          const sensitivePurposeLabel = (
            <Space direction="vertical" size={2} className={Styles.optionsLabel}>
              <span>{subPurpose.portal.title}</span>
              <span className={Styles.subTitle}>{subPurpose.portal.description}</span>
            </Space>
          );
          sensitivePurposes.push({ label: sensitivePurposeLabel, value: subPurpose.id });
        });
      } else {
        const optOutLabel = (
          <Space direction="vertical" size={2} className={Styles.optionsLabel}>
            <span>{purpose.portal.title}</span>
            <span className={Styles.subTitle}>{purpose.portal.description}</span>
          </Space>
        );
        optOutPurposes.push({ label: optOutLabel, value: purpose.id });
      }
    });
    return {
      optOutPurposes,
      sensitivePurposes,
    };
  };

  const optOutConsentOptions = mspaPurposes && getPurposeOptions().optOutPurposes;
  const sensitiveDataPurposeOptions = mspaPurposes && getPurposeOptions().sensitivePurposes;

  const booleanOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const autoPopUpOptions = [
    {
      label: 'Display the opt-out consent screen immediately after the customer lands on my website/app.',
      value: true
    },
    {
      label: 'Do not auto-display the opt-out notice screen.',
      value: false
    }
  ];

  const onCancel = () => {
    if (sensitiveDataIds.length === 0) {
      if (isBulkUpdate) {
        const purposeIds = getFieldValue(OPT_OUT_PURPOSE_IDS).filter(id => id !== SENSITIVE_PURPOSE_ID);
        setFieldsValue({ mspaPurposeIds: purposeIds });
        handleChange(OPT_OUT_PURPOSE_IDS, purposeIds);
      } else {
        setFieldsValue({ mspaSensitiveDataProcessing: false });
      }
      setIsEditEnabled(false);
    }
    setFieldsValue({ mspaSensitiveDataPurposeIds: sensitiveDataIds });
    handleChange(SENSITIVE_DATA_IDS, sensitiveDataIds);
    setModalOpen(false);
  };

  const onSave = () => {
    validateFields([SENSITIVE_DATA_IDS]).then(() => {
      setSensitiveDataIds(getFieldValue(SENSITIVE_DATA_IDS));
      setModalOpen(false);
    }).catch(() => {
      // console.log(err);
    });
  };

  const sensitiveDataChanged = (e) => {
    if (e.target.value) {
      setModalOpen(true);
      setIsEditEnabled(true);
    } else {
      setIsEditEnabled(false);
    }
  };

  const handlePurposeIdsChange = (values) => {
    handleChange(OPT_OUT_PURPOSE_IDS, values);
    if (values.includes(SENSITIVE_PURPOSE_ID) && !isEditEnabled) {
      setModalOpen(true);
      setIsEditEnabled(true);
    }
    if (!values.includes(SENSITIVE_PURPOSE_ID)) {
      setIsEditEnabled(false);
    }
  };

  return (
    <>
      <Card className={classNames(className, Styles.consentScope)}>
        <CardHeader title={TEXT.CARD_TITLE}>
          <span className="small">{TEXT.CARD_SUBTITLE}</span>
          <span className="regulation">{TEXT.REGULATION}</span>
        </CardHeader>
        <CardContent>
          {isBulkUpdate ? (
            <Form.Item
              label={
                (
                  <Space direction="vertical">
                    <Space>
                      <span>{TEXT.PURPOSES_LABEL}</span>
                      <Tooltip
                        title={SITETOOLTIPS.optOutPurposes}
                        placement="top"
                      >
                        <span><InfoIconTooltip /></span>
                      </Tooltip>
                    </Space>
                    <Alert
                      type="info"
                      message={TEXT.PURPOSES_ALERT}
                      className={Styles.alert}
                    />
                  </Space>
                )
              }
              rules={mspaOptOutPurposeIdsRequired}
              name={OPT_OUT_PURPOSE_IDS}
            >
              <Checkbox.Group onChange={handlePurposeIdsChange}>
                <Space direction="vertical">
                  {optOutConsentOptions.map(option => (
                    <Checkbox value={option.value} key={option.value}>{option.label}</Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </Form.Item>
          ) : (
            <Space direction="vertical">
              <Form.Item
                label={
                  (
                    <Space direction="vertical">
                      <Space>
                        <span>{TEXT.PURPOSES_LABEL}</span>
                        <Tooltip
                          title={SITETOOLTIPS.optOutPurposes}
                          placement="top"
                        >
                          <span><InfoIconTooltip /></span>
                        </Tooltip>
                      </Space>
                      <span className={Styles.subTitle}>{TEXT.PURPOSES_SUB_LABEL}</span>
                    </Space>
                  )
                }
                rules={mspaPurposeIdsRequired}
                name={OPT_OUT_PURPOSE_IDS}
              >
                <Checkbox.Group>
                  <Space direction="vertical">
                    {optOutConsentOptions.slice(0, 4).map(option => (
                      <Checkbox value={option.value} key={option.value}>{option.label}</Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
              </Form.Item>

              <Row justify="space-between">
                <Col>
                  <Form.Item
                    label={
                      (
                        <Space direction="vertical">
                          <Space>
                            <span>{TEXT.SENSITIVE_DATA_LABEL}</span>
                            <Tooltip
                              title={SITETOOLTIPS.optOutPurposes}
                              placement="top"
                            >
                              <span><InfoIconTooltip /></span>
                            </Tooltip>
                          </Space>
                          <span className={Styles.subTitle}>{TEXT.SENSITIVE_DATA_SUB_LABEL}</span>
                        </Space>
                      )
                    }
                    name={SENSITIVE_DATA_PROCESSING}
                    className={Styles.radioItem}
                  >
                    <Radio.Group
                      onChange={sensitiveDataChanged}
                      options={booleanOptions}
                    />
                  </Form.Item>
                </Col>

                {isEditEnabled && (
                  <Col>
                    <Button
                      type="link"
                      onClick={() => setModalOpen(true)}
                      className={Styles.EditButton}
                    >
                      {TEXT.EDIT_PURPOSES}
                    </Button>
                  </Col>
                )}
              </Row>


              <Form.Item
                label={
                  (
                    <Space direction="vertical">
                      <Space>
                        <span>{TEXT.CHILD_DATA_LABEL}</span>
                        <Tooltip
                          title={SITETOOLTIPS.optOutPurposes}
                          placement="top"
                        >
                          <span><InfoIconTooltip /></span>
                        </Tooltip>
                      </Space>
                      <span className={Styles.subTitle}>{TEXT.CHILD_DATA_SUB_LABEL}</span>
                    </Space>
                  )
                }
                name={CHILDREN_DATA_PROCESSING}
                className={Styles.radioItem}
              >
                <Radio.Group options={booleanOptions} />
              </Form.Item>
            </Space>
          )}

          <Form.Item
            label={
              (
                <Space direction="vertical">
                  <span>{TEXT.AUTO_POPUP_LABEL}</span>
                  <span className={Styles.subTitle}>{TEXT.AUTO_POPUP_SUB_LABEL}</span>
                </Space>
              )
            }
            name={AUTO_POPUP}
          >
            <Radio.Group onChange={e => handleChange(AUTO_POPUP, e.target.value)}>
              <Space direction="vertical">
                {autoPopUpOptions.map(option => (
                  <Radio
                    className={Styles.optionsLabel}
                    value={option.value}
                  >
                    {option.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>

        </CardContent>
      </Card>

      <Modal
        title={TEXT.MODAL_TITLE}
        onClose={onCancel}
        onCancel={onCancel}
        open={modalOpen}
        closable={false}
        onOk={onSave}
        width={550}
        className={Styles.consentScope}
      >
        <Form.Item
          name={SENSITIVE_DATA_IDS}
          rules={mspaPurposeIdsRequired}
        >
          <Checkbox.Group
            value={sensitiveDataIds}
            onChange={value => handleChange(SENSITIVE_DATA_IDS, value)}
          >
            <Space direction="vertical" size={12}>
              {sensitiveDataPurposeOptions.map(option => <Checkbox value={option.value}>{option.label}</Checkbox>)}
            </Space>
          </Checkbox.Group>
        </Form.Item>
      </Modal>
    </>
  );
};

export default ConsentScopeUSP;
