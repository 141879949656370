function getMenuElements() {
  return [
    { path: 'properties', label: 'Properties' },
    { path: 'themes', label: 'Themes' },
    { path: 'vendors', label: 'Vendors' },
    { path: 'reports', label: 'Reports' },
  ];
}

export default getMenuElements();
