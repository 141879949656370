// @flow
import { faAngleDown, faSearch, faSpinnerThird } from '@fortawesome/fontawesome-pro-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AntSelect, { SelectProps } from 'antd/es/select';
import React, { useState } from 'react';
import './index.scss';

const Select = (props: SelectProps) => {
  const { defaultOpen, loading, open, showSearch } = props;
  const [isOpen, setIsOpen] = useState(defaultOpen || open || false);

  const icon = loading ? (
    <FontAwesomeIcon icon={faSpinnerThird} spin />
  ) : (
    <FontAwesomeIcon className="ant-select-suffix" icon={isOpen && showSearch ? faSearch : faAngleDown} />
  );

  return <AntSelect onDropdownVisibleChange={setIsOpen} suffixIcon={icon} {...props} />;
};

Select.Option = AntSelect.Option;
Select.OptGroup = AntSelect.OptGroup;

export default Select;
