import React, { useState } from 'react';
import Form from 'antd/es/form';
import { Input, Radio } from 'antd/es';
import Card from '../card';
import InfoIconTooltip from '../infoIconTooltip';
import { isFieldEnabled } from '../../../utils/sites';

const { CardHeader, CardContent } = Card;

// @flow

interface Props {
  gdprPrivacyLink?: string;
  cmpVersion?: string;
  onValuesChange: Function;
  setFieldValue: Function;
}

const VALUES = {
  YES: 1,
  NO: 0
};

function PrivacyLink({ gdprPrivacyLink, cmpVersion, onValuesChange, setFieldValue }: Props) {
  const [showPrivacyLinkInput, setShowPrivacyLinkInput] = useState(gdprPrivacyLink ? VALUES.YES : VALUES.NO);

  const handlePrivacyLinkChange = (e) => {
    if (!e.target.value) {
      setFieldValue('gdprPrivacyLink', '');
    }
    setShowPrivacyLinkInput(e.target.value);
    onValuesChange();
  };

  return (
    <>
      {isFieldEnabled('gdprPrivacyLink', cmpVersion) ? (
        <Card>
          <CardHeader title="Publisher Privacy Policy">
            <span className="regulation">GDPR</span>
          </CardHeader>

          <CardContent>
            <Form.Item
              label="SHOW PUBLISHER PRIVACY POLICY LINK ON THE CONSENT"
              tooltip={{
                title: 'Publisher privacy policy',
                icon: (
                  <span>
                    <InfoIconTooltip />
                  </span>
                )
              }}
            >
              <Radio.Group
                name="show_publisher_policy"
                value={showPrivacyLinkInput}
                onChange={handlePrivacyLinkChange}
              >
                <Radio value={VALUES.YES}>Yes</Radio>
                <Radio value={VALUES.NO}>No</Radio>
              </Radio.Group>
            </Form.Item>

            {showPrivacyLinkInput === VALUES.YES ? (
              <Form.Item
                label="PUBLISHER PRIVACY POLICY"
                rules={[
                  {
                    required: true,
                    message: 'Privacy policy is required'
                  }
                ]}
                name="gdprPrivacyLink"
              >
                <Input />
              </Form.Item>
            ) : null}
          </CardContent>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}

export default PrivacyLink;
