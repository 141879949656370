// @flow
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faFileExclamation,
  faFileTimes,
  faLock,
  faTimesCircle
} from '@fortawesome/fontawesome-pro-light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Result, { IconMap, ExceptionMap } from 'antd/es/result';
import React from 'react';

import './index.scss';

IconMap.success = React.forwardRef<HTMLSpanElement>(() => <FontAwesomeIcon icon={faCheckCircle} />);
IconMap.info = React.forwardRef<HTMLSpanElement>(() => <FontAwesomeIcon icon={faExclamationCircle} />);
IconMap.warning = React.forwardRef<HTMLSpanElement>(() => <FontAwesomeIcon icon={faExclamationTriangle} />);
IconMap.error = React.forwardRef<HTMLSpanElement>(() => <FontAwesomeIcon icon={faTimesCircle} />);

ExceptionMap[403] = () => <FontAwesomeIcon icon={faLock} />;
ExceptionMap[404] = () => <FontAwesomeIcon icon={faFileExclamation} />;
ExceptionMap[500] = () => <FontAwesomeIcon icon={faFileTimes} />;

export default Result;
