// @flow
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import uid from 'uid';
import Loader from '../../../../../../ui/loader';
import RecommendedList from '../../../../../../ui/recommended-list/recommendedList';
import Styles from '../../../../../../ui/recommended-list/stacks.module.scss';
import { setStacksInformation } from '../../../../../../../store/duck/stacks';
import Radios from '../../../../../../ui/forms/radios';

type Props = {
  onChange: Function;
  specialPurposes: Array;
  features: Array;
  list: Array;
  pending: Boolean;
  selectedStack: number;
  purposesData: Record<String, any>
}

const Stacks = ({
  onChange,
  specialPurposes,
  features,
  list,
  pending,
  selectedStack,
  purposesData,
}: Props) => {
  const [useRecommendedRadio, setUseRecommendedRadio] = useState(0);
  const dispatch = useDispatch();
  const handleChange = (field, e) => {
    setUseRecommendedRadio(parseInt(e.target.value, 10));
  };
  const buildInput = (field) => {
    const value = useRecommendedRadio;

    return {
      name: field,
      value,
      onChange: e => handleChange(field, e)
    };
  };

  const stacksInfo = [];

  useEffect(() => {
    setUseRecommendedRadio(selectedStack);
  }, [selectedStack]);

  useEffect(() => {
    const setValues = () => {
      if (list.length === 1) {
        onChange(list[0].stacks);
      } else if (list.length > 1) {
        onChange(list[useRecommendedRadio].stacks);
      } else {
        onChange([]);
      }
      dispatch(setStacksInformation(stacksInfo));
    };
    setValues();
  }, [useRecommendedRadio, list]);
  const content = pending && !list.length ? <Loader />
    : (
      <>
        <h5>CHOOSE YOUR STACK CONFIGURATION</h5>
        <p data-testid="stackGroupingText">
          A stack is a grouping of purposes. We&rsquo;ve provided recommended stacks options below,
          with the minimum number of stacks that cover your purposes configurations.
          We recommend to display stacks when possible to provide the most clarifying information to the consumer,
          to ensure the highest consent rate. To learn more about stacks, please see&nbsp;
          <a href="https://iabeurope.eu/tcf-2-0/" target="_blank" rel="noopener noreferrer">
            IAB TCF v2.0
          </a>
        </p>
        {list.length && list.length === 1 ? (
          <RecommendedList
            stackList={list[0]}
            purposesData={purposesData}
          />
        )
          : (
            <Radios
              key={uid()}
              input={buildInput('radio')}
              options={list.map((item, idx) => (
                {
                  value: idx,
                  label: `Stack option ${idx + 1}`,
                  component: <RecommendedList stackList={item} purposesData={purposesData} />
                }))}
            />
          )
        }
      </>
    );


  if (!pending && list.length) {
    if (list.length === 1) {
      const newStackList = [...list];
      newStackList[0].specialPurposes = specialPurposes;
      newStackList[0].features = features;
      stacksInfo.push({ isRadio: false, list: newStackList });
    } else if (list.length > 1) {
      const newList = list.map(item => ({
        ...item,
        specialPurposes,
        features
      }));
      stacksInfo.push({ isRadio: true, list: newList });
    }
  }

  return (
    <div className={Styles.wrapper}>
      {content}
    </div>
  );
};

export default Stacks;
