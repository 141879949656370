// @flow
import React from 'react';

// Extend on future
interface iCardHeader {
    children: JSX.Element;
    className: String | null;
    title: JSX.Element;
}
export default ({ children, className, title }: iCardHeader) => (
  <div className={`qc-card__header ${className || ''}`}>
    <h2>{title}</h2>
    { children }
  </div>
);
