import React from 'react';

const TEXT = {

  RECONFIRM_CONTENT: (
    <span>
      You have selected
      {' '}
      <b>Yes.</b>
      <br />
      Please re-confirm if you are an MSPA signatory
    </span>
  ),
  RECONFIRM_TEXT: 'Not sure about your signatory status?',
  RECONFIRM_LINK: 'Click here to review the signatory list and sign the framework',
  OK: 'Yes, I am a MSPA Signatory',
  CANCEL: "No, I'm not a MSPA Signatory",
  CARD_TITLE: 'MSPA Regulation Settings',
  CARD_SUBTITLE: 'Under what US Legislation, the publisher comes under',
  REGULATION: 'US Regulation',
  TRANSACTION_COVERED_LABEL: 'ARE YOUR TRANSACTION COVERED UNDER MSPA FRAMEWORK?',
  TRANSACTION_COVERED_ALERT: `Leaving this field blank would set this to "No" as default value for
    properties migrating from previous versions.`,
  REGULATORY_PATH_LABEL: "WHAT'S YOUR PREFERRED REGULATORY PATH?",
  REGULATORY_PATH_ALERT: `Leaving this field blank would set this to "National framework across US"
    as default value for properties migrating from previous versions.`,
  REGULATORY_PATH_OPTION1: 'Apply National framework across US',
  REGULATORY_PATH_OPTION2: (
    <>
      Apply state frameworks in California, Virginia, Connecticut, Colorado & Utah.
      <br />
      Apply national framework across rest of US
    </>
  ),
  CCPA_LABEL: `Should US Privacy string be used for the state of California?`,
  CCPA_NOTE: <>
  <b>Note</b>
  : IAB has deprecated USP string format starting Jan-31, 2024.
  </>,
  CCPA_ALERT: `Leaving this field blank would set this to "No" as default value for properties
    migrating from previous versions.`,
  CCPA_OPTION1: 'No. Use GPP for California as well (recommended)',
  CCPA_OPTION2: 'Yes. Use US privacy for encoding opt-outs in California region (CCPA)',
  GPC_LABEL: 'ENABLE GPC SIGNAL TO DOWNSTREAM PARTNERS?',
  LSPA_SIGNED_LABEL: 'IAB LSPA SIGNED?',
  LSPA_SIGNED_ALERT: `Leaving this field blank would set this to "No" as default value for
    properties migrating from previous versions.`,
  CCPA_VIA_USP_WARNING: (
    <>
      <strong>Warnings:</strong>
      <br />
      <ol style={{ paddingLeft: '10px' }}>
        <li>The USPrivacy string does not include the updates required as per the CPRA.</li>
        <li>Additionally, the USPrivacy string was not developed to comply with any of the other state laws that have come into effect (VA, CO, UT, CT).</li>
        <li>MSPA signatories will not be in compliance with MSPA guidelines if US privacy is used beyond Jan-31, 2024.</li>
      </ol>
    </>
  ),
};

export default TEXT;
