// @flow
import React from 'react';
import Card from 'antd/es/card';
import Checkbox from 'antd/es/checkbox';
import Form from 'antd/es/form';
import { Row, Col } from 'antd/es/grid';
import Radio from 'antd/es/radio';
import Input from 'antd/es/input';
import uid from 'uid';
import classNames from 'classnames';
import Tooltip from 'antd/es/tooltip';
import CustomCss from '../../../ui/forms/custom-css';
import Select from '../../../ui/select';
import InfoIconTooltip from '../../../ui/infoIconTooltip';
import PreviewModalImg from '../../../../assets/images/create-theme-preview-modal.png';
import PreviewTraylImg from '../../../../assets/images/create-theme-preview-tray.png';
import SummaryViewImg from '../../../../assets/images/legal-text-preview.png';
import ListViewImg from '../../../../assets/images/list-view-preview.png';
import Styles from '../themesForm.module.scss';
import { defaultHexCodesV2 } from '../constants';
import { SUPPORTED_LANGUAGES, getTranslationsForLang } from '../../../../utils/language';
import { HEADER_REGULATION_TEXT } from '../../../../utils/constants';
import SITETOOLTIPS from '../../../../utils/toolTipsMessages';
import CustomizeInitScreen from './customize-init-screen';

type Props = {
  normalizeHex: Function,
  handleLanguageChange: Function,
  handleDisagreeButtonChange: Function,
  handleCustomLinks: Function,
  addCustomLink: boolean,
  language: string,
  values: Object,
  onChange: Function,
  validations: Object,
  validateFields: Function
};

const CustomizeUI = ({
  normalizeHex,
  handleLanguageChange,
  handleDisagreeButtonChange,
  handleCustomLinks,
  addCustomLink,
  language,
  values,
  onChange,
  validations,
  validateFields
}: Props) => {
  const { Option } = Select;
  const {
    initialScreen,
    displayUi,
    uxBackgroundColor,
    uxPrimaryButtonColor,
    uxSecondaryButtonColor,
    initScreenRejectButtonShowing,
    initScreenCloseButtonShowing
  } = values;
  const disableDisplayUi = initialScreen !== 'SUMMARY';

  const translations = getTranslationsForLang(language);

  return (
    <Card
      title="Customize UI"
      extra={HEADER_REGULATION_TEXT.GDPR}
      className={Styles.customizeUi}
    >
      <Form.Item
        name="language"
        label="CMP UI Language"
      >
        <Select
          showSearch
          onChange={handleLanguageChange}
          filterOption={(input, option) => (
            option.props.children.toLowerCase().includes(input.toLowerCase())
            || option.props.value.toLowerCase().includes(input.toLowerCase())
          )}
        >
          {SUPPORTED_LANGUAGES.map(opt => (
            <Option key={opt.value} value={opt.value}>
              {opt.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className={Styles.viewsContainer}
        name="initialScreen"
        tooltip={{
          title: SITETOOLTIPS.initialThemeScreen,
          icon: <span><InfoIconTooltip /></span>,
        }}
        label={[
          <React.Fragment key={uid}>
            <span> INITIAL SCREEN </span>
          </React.Fragment>
        ]}
      >
        <Radio.Group
          className={Styles.RadioGroup}
          onChange={({ target }) => onChange(preValues => ({ ...preValues, initialScreen: target.value }))}
        >
          <Radio
            value="SUMMARY"
            className={classNames([Styles.Radio], {
              [Styles.RadioSelected]: initialScreen && initialScreen === 'SUMMARY',
              [Styles.RadioNoSelected]: !initialScreen || initialScreen !== 'SUMMARY'
            })}
          >
            <>
              <strong>SUMMARY VIEW</strong>
              <Tooltip
                title={SITETOOLTIPS.sumaryView}
                placement="right"
              >
                <span className="info-icon-container">
                  <InfoIconTooltip style={{ marginLeft: '5' }} />
                </span>
              </Tooltip>
              <div className={Styles.imageContainer}>
                <img src={PreviewModalImg} alt="Preview" />
              </div>
            </>
          </Radio>
          <Radio
            value="STACKS_AND_PURPOSES"
            className={classNames([Styles.Radio], {
              [Styles.RadioSelected]: initialScreen && initialScreen === 'STACKS_AND_PURPOSES',
              [Styles.RadioNoSelected]: !initialScreen || initialScreen !== 'STACKS_AND_PURPOSES'
            })
            }
          >
            <>
              <strong>LIST VIEW</strong>
              <Tooltip
                title={SITETOOLTIPS.listView}
                placement="right"
              >
                <span className="info-icon-container">
                  <InfoIconTooltip style={{ marginLeft: '5' }} />
                </span>
              </Tooltip>
              <div className={Styles.imageContainer}>
                <img src={ListViewImg} alt="Stacks and Purposes Preview" />
              </div>
            </>
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        className={Styles.viewsContainer}
        label="SUMMARY VIEW DISPLAY OPTIONS"
        name="displayUi"
      >
        <Radio.Group
          className={Styles.RadioGroup}
          onChange={({ target }) => {
            onChange(preValues => ({ ...preValues, displayUi: target.value }));
            validateFields(['uxCustomizedCss']);
          }}
        >
          <Radio
            value="popup"
            disabled={disableDisplayUi}
            className={classNames([Styles.Radio], {
              [Styles.RadioSelected]: displayUi && displayUi === 'popup' && initialScreen === 'SUMMARY',
              [Styles.RadioNoSelected]: !displayUi || displayUi !== 'popup' || initialScreen !== 'SUMMARY'
            })}
          >
            <>
              <strong> CENTERED BOX MODAL </strong>
              <div className={Styles.imageContainer}>
                <img
                  src={SummaryViewImg}
                  alt="Summary Preview"
                  className={classNames({ [Styles.disableImage]: disableDisplayUi })}
                />
              </div>
            </>
          </Radio>
          <Radio
            value="banner"
            disabled={disableDisplayUi}
            className={classNames([Styles.Radio], {
              [Styles.RadioSelected]: displayUi && displayUi === 'banner' && initialScreen === 'SUMMARY',
              [Styles.RadioNoSelected]: !displayUi || displayUi !== 'banner' || initialScreen !== 'SUMMARY'
            })}
          >
            <>
              <strong>BOTTOM TRAY MODAL</strong>
              <div className={Styles.imageContainer}>
                <img
                  alt="Preview"
                  src={PreviewTraylImg}
                  className={classNames({ [Styles.disableImage]: disableDisplayUi })}
                />
              </div>
            </>
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Col span="24">
        <Row gutter={[16, 16]}>
          <Col span="12">
            <Form.Item
              name="agreeButton"
              className={Styles.formItem}
              label="PRIMARY BUTTON TEXT"
              rules={validations.textInput}
            >
              <Input maxLength={24} placeholder={translations.agree} />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item name="saveAndExitButton" label="SECONDARY BUTTON TEXT" rules={validations.textInput}>
              <Input placeholder={translations.saveAndExit} maxLength={24} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span="12">
            <Form.Item
              name="uxFontColor"
              label="BODY TEXT COLOR"
              normalize={normalizeHex}
              rules={validations.uxElementColor(uxBackgroundColor, 'text')}
            >
              <Input
                placeholder={defaultHexCodesV2._bodyTextColor}
                maxLength={7}
                onChange={() => validateFields(['uxBackgroundColor'])}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name="uxLinkColor"
              label="LINK COLOR"
              normalize={normalizeHex}
              rules={validations.uxElementColor(uxBackgroundColor, 'link')}
            >
              <Input
                placeholder={defaultHexCodesV2._linkColor}
                maxLength={7}
                onChange={() => validateFields(['uxBackgroundColor'])}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span="12">
            <Form.Item
              name="uxBackgroundColor"
              label="BACKGROUND COLOR"
              normalize={normalizeHex}
              rules={validations.uxBackgroundColor}
            >
              <Input
                onChange={({ target }) => {
                  onChange(preValues => ({ ...preValues, uxBackgroundColor: target.value }));
                  validateFields([
                    'uxFontColor',
                    'uxLinkColor',
                    'uxToogleActiveColor',
                    'uxPrimaryButtonColor',
                    'uxCustomizedCss'
                  ]);
                }}
                placeholder={defaultHexCodesV2._backgroundColor}
                maxLength={7}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name="uxToogleActiveColor"
              label="TOGGLE ACTIVE COLOR"
              normalize={normalizeHex}
              rules={validations.uxElementColor(uxBackgroundColor, 'toggle active color')}
            >
              <Input
                placeholder={defaultHexCodesV2._toggleActiveColor}
                maxLength={7}
                onChange={() => validateFields(['uxBackgroundColor'])}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span="12">
            <Form.Item
              name="uxPrimaryButtonColor"
              label="PRIMARY BUTTON COLOR"
              normalize={normalizeHex}
              rules={validations.uxElementColor(uxBackgroundColor, 'primary')}
            >
              <Input
                onChange={({ target }) => {
                  onChange(preValues => ({ ...preValues, uxPrimaryButtonColor: target.value }));
                  validateFields(['uxPrimaryButtonTextColor', 'uxCustomizedCss', 'uxBackgroundColor']);
                }}
                maxLength={7}
                placeholder={defaultHexCodesV2._primaryButtonColor}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name="uxPrimaryButtonTextColor"
              normalize={normalizeHex}
              label="PRIMARY BUTTON TEXT COLOR"
              rules={validations.uxPrimaryButtonTextColor(uxPrimaryButtonColor)}
            >
              <Input placeholder={defaultHexCodesV2._primaryButtonTextColor} maxLength={7} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span="12">
            <Form.Item
              name="uxSecondaryButtonColor"
              label="SECONDARY BUTTON COLOR"
              normalize={normalizeHex}
              rules={validations.uxSecondaryButtonColor}
            >
              <Input
                onChange={({ target }) => {
                  onChange(preValues => ({ ...preValues, uxSecondaryButtonColor: target.value }));
                  validateFields(['uxSecondaryButtonTextColor', 'uxCustomizedCss']);
                }}
                placeholder={defaultHexCodesV2._secondaryButtonColor}
                maxLength={7}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name="uxSecondaryButtonTextColor"
              label="SECONDARY BUTTON TEXT COLOR"
              onChange={() => {
                validateFields(['uxSecondaryButtonColor']);
              }}
              normalize={normalizeHex}
              rules={validations.uxSecondaryButtonTextColor}
            >
              <Input placeholder={defaultHexCodesV2._secondaryButtonTextColor} maxLength={7} />
            </Form.Item>
          </Col>
        </Row>
        <Col span="24">
          <Form.Item
            name="initScreenRejectButtonShowing"
            valuePropName="checked"
            className={Styles.disagreeButton}
          >
            <Checkbox
              name="initScreenRejectButtonShowing"
              onChange={handleDisagreeButtonChange}
            >
              Show DISAGREE button on the Summary View
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span="24">
          <Form.Item
            name="initScreenCloseButtonShowing"
            valuePropName="checked"
            className={Styles.disagreeButton}
          >
            <Checkbox
              name="initScreenCloseButtonShowing"
              onChange={handleDisagreeButtonChange}
            >
              Add a disagree/close [X] button to the upper right corner of the initial screen.
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span="24">
          <Form.Item
            name="initScreenRejectButton"
            label="Disagree Button / X Button Tooltip Text"
            rules={validations.textInput}
          >
            <Input
              placeholder={translations.disagree}
              disabled={!initScreenRejectButtonShowing && !initScreenCloseButtonShowing}
              maxLength={24}
            />
          </Form.Item>
        </Col>
        <CustomizeInitScreen
          addCustomLink={addCustomLink}
          validations={validations}
          validateFields={validateFields}
          values={values}
          handleCustomLinks={handleCustomLinks}
          language={language}
        />
      </Col>
      <CustomCss
        placeholder="Enter css here"
        value={values.customcss}
        validations={
          () => validations.uxCustomizedCss({
            uxBackgroundColor,
            uxPrimaryButtonColor,
            uxSecondaryButtonColor,
            displayUi
          })
        }
      />
    </Card>
  );
};

export default CustomizeUI;
