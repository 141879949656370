import React, { useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/fontawesome-pro-solid';
import Styles from '../themesForm.module.scss';
import { getDefaultAddCustomLink } from '../utils';

// @flow

interface ICustomLinksProps {
  lang: string;
  index: number;
  validations: Object;
  getFieldValue: Function;
  setFieldsValue: Function;
  fieldName: string;
}

const CustomLinks = ({ lang, index, validations, getFieldValue, setFieldsValue, fieldName }: ICustomLinksProps) => {
  const currentSettings = getFieldValue(fieldName);
  const [addCustomLink, setAddCustomLink] = useState(getDefaultAddCustomLink(currentSettings, lang));

  const handleCustomLinks = (lang, fieldName) => {
    const currentValues = getFieldValue(fieldName);
    setAddCustomLink((prevState) => {
      if (prevState) {
        const updatedValues = currentValues.map((item) => {
          if (item.language === lang) {
            return { ...item, customFooterLink: [item.customFooterLink[0]] };
          }
          return item;
        });
        setFieldsValue({ [fieldName]: updatedValues });
      }
      return !prevState;
    });
  };

  return (
    <Col span="24" className={Styles.customLinks}>
      <h5>
        CUSTOM FOOTER LINKS &nbsp;
        <span>The first entry in the custom footer list is reserved for your privacy policy.</span>
      </h5>
      <Row gutter={[16, 16]}>
        <Col span="12">
          <Form.Item
            name={[fieldName, index, 'customFooterLink', 0, 'label']}
            rules={validations.customLinksText([fieldName, index, 'customFooterLink', 0, 'link'])}
            dependencies={[[fieldName, index, 'customFooterLink', 0, 'link']]}
          >
            <Input placeholder="Link text" />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            name={[fieldName, index, 'customFooterLink', 0, 'link']}
            rules={validations.customLinksUrl([fieldName, index, 'customFooterLink', 0, 'label'])}
            dependencies={[[fieldName, index, 'customFooterLink', 0, 'label']]}
          >
            <Input placeholder="Link URL" />
          </Form.Item>
        </Col>
      </Row>
      {addCustomLink && (
        <Row gutter={[16, 16]}>
          <Col span="12">
            <Form.Item
              name={[fieldName, index, 'customFooterLink', 1, 'label']}
              rules={validations.customLinksText([
                fieldName,
                index,
                'customFooterLink',
                1,
                'link',
              ])}
              dependencies={[[fieldName, index, 'customFooterLink', 1, 'link']]}
            >
              <Input placeholder="Link text" />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name={[fieldName, index, 'customFooterLink', 1, 'link']}
              rules={validations.customLinksUrl([
                fieldName,
                index,
                'customFooterLink',
                1,
                'label',
              ])}
              dependencies={[[fieldName, index, 'customFooterLink', 1, 'label']]}
            >
              <Input placeholder="Link URL" />
            </Form.Item>
          </Col>
        </Row>
      )}

      <button type="button" className={Styles.btnPlusToggle} onClick={() => handleCustomLinks(lang, fieldName)}>
        <span>
          {addCustomLink ? <FontAwesomeIcon icon={faMinus} size="xs" /> : <FontAwesomeIcon icon={faPlus} size="xs" />}
        </span>
        <span>{addCustomLink ? 'Remove one' : 'Add one'}</span>
      </button>
    </Col>
  );
};

export default CustomLinks;
