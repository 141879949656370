// @flow
import React from 'react';
import './styles.scss';

// Extend on future
interface iCard {
    children: JSX.Element;
    className: String | null;
}
export default ({ children, className, ...props }: iCard) => (
  <div className={`qc-card ${className || ''}`} {...props}>
    { children }
  </div>
);
