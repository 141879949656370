import axios from 'axios';
// @flow

const getAxiosInstance = (baseURL: string) => {
  const axiosInstance = axios.create({
    baseURL,
    withCredentials: process.env.NODE_ENV === 'development',
    validateStatus(status) {
      return status < 400;
    },
  });

  axiosInstance.interceptors.response.use(
    response => response.data,
    (error) => {
      if (error && error.response && error.response.data) {
        const standardErrors = error.response.data;
        return Promise.reject(standardErrors);
      }

      return Promise.reject(
        {
          status: 'UNKNOWN_ERROR',
          error: 'An unknown error occured. Please try again.',
        },
      );
    },
  );

  return axiosInstance;
};

export default getAxiosInstance;
