import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/fontawesome-pro-solid';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import React from 'react';

import Styles from './help-center-bubble.module.scss';

const HelpCenterBubble = () => (
  <Tooltip title="Help Center" placement="left">
    <Button
      className={Styles.helpCenterBubble}
      // eslint-disable-next-line max-len
      href="https://support.inmobi.com"
      target="_blank"
      rel="noopener noreferrer"
      type="primary"
      shape="circle"
      icon={<FontAwesomeIcon icon={faQuestion} className={Styles.helpCenterBubbleIcon} />}
    />
  </Tooltip>
);

export default HelpCenterBubble;
