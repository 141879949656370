// @flow
import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/fontawesome-pro-light';
import Button from 'antd/es/button';
import Tabs from 'antd/es/tabs';
import Space from 'antd/es/space';
import TabPane from 'antd/es/tabs/TabPane';
import { useHistory } from 'react-router-dom';
import { downloadFile } from '../../../store/requests/utils';
import menuItems from './menu-items';
import ModalTag from '../modal-tag';
import { useOldVersionProperties } from '../../../data/hooks/banner';
import { csvToJSON, downloadCsv } from '../../../utils/property';
import OldVersionBanner from '../../../pages/sites/components/old-version-banner/oldVersionBanner';

type Props = {
  path: string;
}

function Navbar({ path }: Props) {
  const history = useHistory();
  const app = useSelector(state => state.app);
  const [tagOpen, setTagOpen] = useState(false);
  const [bannerOpen, setBannerOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const { data: oldVersionProperties, isLoading } = useOldVersionProperties(app.pCode);

  const jsonContent = oldVersionProperties ? csvToJSON(oldVersionProperties) : '';

  const toggleTag = () => {
    setTagOpen(!tagOpen);
  };

  const toggleBanner = () => {
    setBannerOpen(!bannerOpen);
  };

  const downloadClick = useCallback(async () => {
    try {
      setIsDownloading(true);
      await downloadFile(
        `${process.env.REACT_APP_CHOICE_2_V2_CUSTOMIZATION_API_URL}/app/sdk`,
        'InmobiCMP.zip'
      );
    } finally {
      setIsDownloading(false);
    }
  }, []);

  const handleTabClick = (path) => {
    history.push(`/protect/p-${app.pCode}/${path}`);
  };

  let activeKey = menuItems.find(item => window.location.pathname.toString().includes(item.path));

  if (!activeKey) {
    activeKey = menuItems[0];
  }

  return (
    <>
      <div style={{ padding: '5px 24px 0px 24px' }}>
        <Tabs
          items={menuItems.map(item => (
            { label: item.label, key: item.path }
          ))}
          defaultActiveKey={activeKey.path}
          onTabClick={handleTabClick}
          tabBarExtraContent={(
            <Space>
              {
              oldVersionProperties && oldVersionProperties.length > 0 && (
                <Button
                  type="alert"
                  style={{ backgroundColor: 'rgba(218, 59, 59, 1)', color: 'white' }}
                  key={'change-version'}
                  onClick={() => setBannerOpen(true)}
                  isLoading={isLoading}
                >
                  Update Older Version Properties Now!
                </Button>
              )
            }
              <Button
                type="primary"
                data-testid="sites_downloadsdk"
                key={'download-SDK'}
                onClick={() => downloadClick()}
                disabled={isDownloading}
                loading={isDownloading}
              >
                DOWNLOAD SDK
              </Button>
              <Button
                type="primary"
                key={'universal-tag'}
                data-testid="sites_get_your_tag_btn"
                onClick={() => toggleTag()}
              >
                <FontAwesomeIcon icon={faCode} />
                {' '}
                UNIVERSAL TAG
              </Button>
            </Space>
)}
        />
      </div>
      <ModalTag
        pCode={app.pCode}
        isOpen={tagOpen}
        handleClose={toggleTag}
        isPremium={app.isPremium}
      />
      <OldVersionBanner
        isOpen={bannerOpen}
        onClose={toggleBanner}
        data={jsonContent}
        download={() => downloadCsv(oldVersionProperties, app.pCode)}
      />
    </>
  );
}

export default Navbar;
