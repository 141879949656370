import { Card, Checkbox, Col, Form, Input, Row, Select, Space, Tooltip } from 'antd/es';
import React, { useState } from 'react';
import classNames from 'classnames';
import uid from 'uid';
import { COUNTRY_CODES, EEA, EEA_AND_UK, GDPR, NEVER, USA, USP, WORLDWIDE } from '../../../utils/constants';

import InfoIconTooltip from '../../ui/infoIconTooltip';
import SITETOOLTIPS from '../../../utils/toolTipsMessages';
import Styles from '../sites/sitesForm.module.scss';
import TEXT from './text';
import validate from '../sites/syncValidation';
import GBCForm from '../gbc';
import ConsentOrPay from '../consent_or_pay';
import useGBCPurposes from '../gbc/useGBCPurposes';
import Alert from '../../ui/alert';
import {
  getConsentLocationOptions,
  getRegulationOptions,
  isGBCSupported,
  isGPPSupported,
} from '../../../utils/property';

// @flow

type Props = {
  initData: Object,
  themes: Array<iTheme>,
  sites: Array<any>,
  versionsV2: Array<string>,
  isNew: boolean,
  setValues: Function,
  getFieldValue: Function,
  setFieldsValue: Function,
  validateFields: Function,
  values: Object,
  setConsentError: Function,
  handleValuesChange: Function,
  onValuesChange: Function,
  isNonPersonalisedAdPresent: boolean,
};

const isValidParentDomain = url => {
  // IMPROVEMENT: find a regular expression to catch this conditions
  if (url) {
    if (url.includes('http:') || url === '' || url.includes('/')) {
      return false;
    }

    const segments = url.split('.');

    if (segments.length === 2) {
      return true;
    }
    if (segments.length === 3 && (segments[0] === 'www' || segments[2].length === 2)) {
      return true;
    }
    if (segments.length === 4 && segments[0] === 'www' && segments[3].length === 2) {
      return true;
    }
  }
  return false;
};

const GeneralSettings = ({
  sites,
  isNew,
  getFieldValue,
  setFieldsValue,
  validateFields,
  setValues,
  themes,
  values,
  versionsV2,
  initData,
  setConsentError,
  handleValuesChange,
  onValuesChange,
  isNonPersonalisedAdPresent,
}: Props) => {
  const [disableApplySub, setDisableApplySub] = useState(!isValidParentDomain(initData.url) || initData.ampEnabled);
  const [disableAmp, setDisableAmp] = useState(false);
  const [consentLocations, setConsentLocations] = useState(getFieldValue('consentLocations') || [WORLDWIDE]);
  const { Option } = Select;
  const validations = validate();
  const setDataTestId = input => `site-${input}-input`;
  const versionList = versionsV2.sort((a, b) => b - a);
  const gbcPurposesData = useGBCPurposes();

  const themesList = themes.map(({ themeId, themeName, gvlVersion }) => ({
    value: `${themeId}`,
    label: themeName,
    gvlVersion,
  }));

  const mapVersionList = version => {
    if (version.includes('latest')) {
      return { value: 'latest', label: version };
    }
    return {
      value: version,
      label: version,
    };
  };

  const regulationOptions = getRegulationOptions(consentLocations);

  const consentLocationsOptions = getConsentLocationOptions(consentLocations);

  const onChangePublisherCC = value => {
    // if the publisherCC is set to an EEA or UK force requireConsentFrom to 'always'
    if (EEA_AND_UK.includes(value)) {
      setFieldsValue({ requireConsentFrom: 'always' });
    }
  };

  const changeRegulations = regulations => {
    setValues(prevValues => ({
      ...prevValues,
      hasGDPR: regulations.includes(GDPR),
      hasCCPA: regulations.includes(USP),
    }));
  };

  const disableApplySubdomains = (url, ampEnable) => {
    if (!isValidParentDomain(url) || ampEnable) {
      setDisableApplySub(true);
      setFieldsValue({ applyToSubDomains: false });
    } else {
      setDisableApplySub(false);
    }
  };

  const handleConsentLocationChanged = value => {
    let regulation;
    if (value.includes(WORLDWIDE) || (value.includes(EEA) && value.includes(USA))) {
      regulation = [GDPR, USP];
    } else if (value.includes(EEA)) {
      regulation = [GDPR];
    } else if (value.includes(USA)) {
      regulation = [USP];
    } else {
      regulation = [GDPR];
    }
    setFieldsValue({ privacyModes: regulation });
    changeRegulations(regulation);
    setConsentLocations(value);
  };

  const handleCmpVersionChange = newVersion => {
    const oldVersion = values.cmpVersion;
    if (isGPPSupported(newVersion) && !isGPPSupported(oldVersion)) {
      // Migration logic for upgrade to GPP version
      const regulation = getFieldValue('privacyModes');
      const consentFrom = getFieldValue('requireConsentFrom');
      const locations = [];
      if (regulation.includes(GDPR)) {
        if (consentFrom === 'always') {
          locations.push(WORLDWIDE);
        } else if (consentFrom === 'inEU') {
          locations.push(EEA);
        }
      }

      if (regulation.includes(USP) && consentFrom !== 'always') {
        locations.push(USA);
      }

      if (locations.length === 0) {
        locations.push(NEVER);
      }
      setFieldsValue({ consentLocations: locations });
      handleConsentLocationChanged(locations);
    } else if (!isGPPSupported(newVersion) && isGPPSupported(oldVersion)) {
      // Migration logic for downgrade to older version
      const locations = getFieldValue('consentLocations') || [];
      if (locations.includes(WORLDWIDE)) {
        setFieldsValue({ requireConsentFrom: 'always' });
      } else if (locations.includes(EEA)) {
        setFieldsValue({ requireConsentFrom: 'inEU' });
      } else if (locations.includes(NEVER) || (locations.includes(USA) && !locations.includes(EEA))) {
        setFieldsValue({ requireConsentFrom: 'never' });
      }
    }

    setValues(preVal => ({ ...preVal, cmpVersion: newVersion }));
  };

  return (
    <Card title={TEXT.CARD_TITLE} className={Styles.consentCard}>
      <Form.Item label={TEXT.URL_LABEL} name="url" required rules={validations.url(sites, isNew)}>
        <Input
          placeholder="Add URL"
          required
          onChange={e => {
            disableApplySubdomains(e.target.value, getFieldValue('ampEnabled'));
          }}
          data-testid={setDataTestId('url')}
        />
      </Form.Item>
      <Form.Item className={Styles.formTooltip}>
        <Form.Item
          name="applyToSubDomains"
          valuePropName="checked"
          className={classNames(Styles.checkbox, Styles.formItemToolTip)}
        >
          <Checkbox
            disabled={disableApplySub}
            data-testid={setDataTestId('applyToSubDomains')}
            onChange={e => {
              if (e.target.checked) {
                setDisableAmp(true);
                setFieldsValue({ ampEnabled: false });
              } else {
                setDisableAmp(false);
              }
            }}
          >
            {TEXT.SUBDOMAIN_LABEL}
          </Checkbox>
        </Form.Item>
        <Tooltip title={SITETOOLTIPS.APPLY_SUBDOMAINS} placement="right">
          <span>
            <InfoIconTooltip style={{ marginTop: '9' }} />
          </span>
        </Tooltip>
      </Form.Item>
      <Form.Item className={Styles.formTooltip}>
        <Form.Item
          name="ampEnabled"
          valuePropName="checked"
          className={classNames(Styles.checkbox, Styles.formItemToolTip)}
        >
          <Checkbox
            disabled={disableAmp}
            data-testid={setDataTestId('ampEnabled')}
            onChange={e => {
              disableApplySubdomains(getFieldValue('url'), e.target.checked);
              validateFields(['cmpVersion', 'consentScope'])
                .then(() => setConsentError(false))
                .catch(({ errorFields }) => {
                  if (errorFields && errorFields[0] && errorFields[0].name) {
                    if (errorFields[0].name.includes('consentScope')) {
                      setConsentError(true);
                    }
                  }
                });
              setValues(preValues => ({ ...preValues, ampEnabled: e.target.checked }));
            }}
          >
            {TEXT.AMP_LABEL}
          </Checkbox>
        </Form.Item>
        <Tooltip title={SITETOOLTIPS.AMP} placement="right">
          <span>
            <InfoIconTooltip style={{ marginTop: '9' }} />
          </span>
        </Tooltip>
      </Form.Item>
      <Form.Item
        label={TEXT.THEME_LABEL}
        name="themeId"
        data-testid={setDataTestId('themeId')}
        required
        rules={validations.themeId}
      >
        <Select
          required
          data-testid={setDataTestId('themeId')}
          onChange={themeId => setValues(preVal => ({ ...preVal, themeId }))}
        >
          {themesList &&
            themesList.map(opt => (
              <Option key={opt.value} value={opt.value} disabled={opt.disabled}>
                {opt.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      {values.themeId !== '-2' && values.themeId !== '-3' && values.ampEnabled && (
        <div className={Styles.themeAlert}>{TEXT.THEME_ALERT}</div>
      )}
      <Row gutter={16}>
        <Col span="12">
          <Form.Item
            label={TEXT.SITE_NAME_LABEL}
            name="publisherName"
            required={values.hasGDPR && !values.noLegalBasis}
            rules={validations.publisherName(values.noLegalBasis)}
          >
            <Input placeholder="Name" data-testid={setDataTestId('publisherName')} />
          </Form.Item>
        </Col>
        <Col span="12">
          {values.hasGDPR && (
            <Form.Item
              label={TEXT.LOGO_LABEL}
              name="publisherLogoUrl"
              tooltip={{
                icon: (
                  <span>
                    <InfoIconTooltip />
                  </span>
                ),
                title: SITETOOLTIPS.siteLogo,
              }}
              rules={validations.publisherLogoUrl}
            >
              <Input placeholder="Logo URL" data-testid={setDataTestId('publisherLogoUrl')} />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Form.Item label={TEXT.CMP_VERSION_LABEL} name="cmpVersion" rules={validations.cmpVersion(values.ampEnabled)}>
        <Select
          data-testid={setDataTestId('cmpVersion')}
          required
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().includes(input.toLowerCase()) ||
            option.props.value.toLowerCase().includes(input.toLowerCase())
          }
          onChange={handleCmpVersionChange}
        >
          {versionList &&
            versionList.map(mapVersionList).map(opt => (
              <Option key={opt.value} value={opt.value} disabled={opt.disabled}>
                {opt.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      {isGPPSupported(values.cmpVersion) && (
        <Form.Item
          data-testid={setDataTestId('requireConsentFrom')}
          label={[<React.Fragment key={uid}>{TEXT.USER_CONSENT_LABEL}</React.Fragment>]}
          tooltip={{
            title: SITETOOLTIPS.consentLocations,
            icon: (
              <span>
                <InfoIconTooltip />
              </span>
            ),
          }}
          name="consentLocations"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select
            mode="multiple"
            allowClear
            onChange={handleConsentLocationChanged}
            options={consentLocationsOptions}
          />
        </Form.Item>
      )}
      {isGPPSupported(values.cmpVersion) ? (
        <Space direction="vertical" size={0}>
          <Form.Item
            label={TEXT.REGULATIONS_LABEL}
            rules={[{ required: true, message: 'Please select at least one regulation' }]}
            name="privacyModes"
            style={{ marginBottom: '10px' }}
          >
            <Checkbox.Group onChange={changeRegulations}>
              <Space direction="vertical">
                {regulationOptions.map(option => (
                  <Space size={2}>
                    <Checkbox disabled={option.disabled} value={option.value} key={option.value}>
                      {option.label}
                    </Checkbox>
                    <Tooltip title={option.tooltip} placement="right">
                      <span>
                        <InfoIconTooltip />
                      </span>
                    </Tooltip>
                  </Space>
                ))}
              </Space>
            </Checkbox.Group>
          </Form.Item>
          {values.hasCCPA && values.hasGDPR && (
            <Alert type="warning" message={TEXT.REGULATION_WARNING} className={Styles.warningAlert} showIcon />
          )}
        </Space>
      ) : (
        <Row gutter={16}>
          <Col span="12">
            <section className={Styles.regulationCheckboxes}>
              <Form.Item
                label="REGULATION"
                name="privacyModes"
                className={Styles.container}
                rules={validations.regulation(values.gbcEnabled)}
              >
                <Checkbox.Group onChange={changeRegulations} data-testid={setDataTestId('privacyModes')}>
                  <Space direction="vertical">
                    <Checkbox value="GDPR" data-testid={setDataTestId('GDPR')}>
                      European regulation (GDPR)
                    </Checkbox>
                    <div>
                      <Checkbox value="USP">US regulation</Checkbox>
                      <Tooltip title={SITETOOLTIPS.CCPA} placement="right">
                        <span className="info-icon-container">
                          <InfoIconTooltip />
                        </span>
                      </Tooltip>
                    </div>
                  </Space>
                </Checkbox.Group>
              </Form.Item>
            </section>
          </Col>

          {values.hasCCPA && (
            <Col span="12">
              <div className={`column ${Styles.ccpa}`}>
                <Form.Item label="CCPA" className={Styles.container}>
                  <Form.Item
                    name="uspJurisdiction"
                    data-testid={setDataTestId('uspJurisdiction')}
                    valuePropName="checked"
                    noStyle
                  >
                    <Checkbox>{TEXT.ENTIRE_US_LABEL}</Checkbox>
                  </Form.Item>
                  <div className={Styles.formTooltip}>
                    <Form.Item
                      name="uspLspact"
                      data-testid={setDataTestId('uspLspact')}
                      valuePropName="checked"
                      className={classNames(Styles.checkbox, Styles.formItemToolTip)}
                    >
                      <Checkbox>{TEXT.LSPA_SIGNED}</Checkbox>
                    </Form.Item>

                    <Tooltip title={SITETOOLTIPS.uspLspact} placement="right">
                      <span>
                        <InfoIconTooltip style={{ marginTop: '9' }} />
                      </span>
                    </Tooltip>
                  </div>
                </Form.Item>
              </div>
            </Col>
          )}
        </Row>
      )}

      {values.hasGDPR && (
        <Form.Item
          label={[<section key={uid()}>{TEXT.PUBLISHER_CC_LABEL}</section>]}
          name="publisherCC"
          tooltip={{
            icon: (
              <span>
                <InfoIconTooltip />
              </span>
            ),
            title: SITETOOLTIPS.countryCode,
          }}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().includes(input.toLowerCase()) ||
              option.props.value.toLowerCase().includes(input.toLowerCase())
            }
            data-testid={setDataTestId('publisherCC')}
            onChange={onChangePublisherCC}
          >
            {COUNTRY_CODES &&
              COUNTRY_CODES.map(opt => (
                <Option key={opt.value} value={opt.value} disabled={opt.disabled}>
                  {opt.label}
                </Option>
              ))}
          </Select>
        </Form.Item>
      )}

      {values.cmpVersion && isGBCSupported(values.cmpVersion) && (
        <GBCForm
          handleChange={handleValuesChange}
          values={values}
          setFieldsValue={setFieldsValue}
          getFieldValue={getFieldValue}
          validateFields={validateFields}
          purposesData={gbcPurposesData}
        />
      )}

      <ConsentOrPay
        getFieldValue={getFieldValue}
        setFieldsValue={setFieldsValue}
        handleChange={handleValuesChange}
        values={values}
        onValuesChange={onValuesChange}
        isNonPersonalisedAdPresent={isNonPersonalisedAdPresent}
        validateFields={validateFields}
      />
    </Card>
  );
};

export default GeneralSettings;
