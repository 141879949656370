// @flow
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/fontawesome-pro-light';

type Props = {
  style: Object;
}
const InfoIconTooltip = ({ style }: Props) => (
  <FontAwesomeIcon
    icon={faQuestionCircle}
    style={{
      width: '14px',
      height: '14px',
      cursor: 'pointer',
      color: 'var(--gray-10)',
      ...style
    }}
  />
);

export default InfoIconTooltip;
