import React from 'react';

const TEXT = {
  CARD_TITLE: 'Consent Scope',
  CARD_SUBTITLE: 'What purposes should be notified to end-users',
  REGULATION: 'US Regulation',
  PURPOSES_LABEL: 'Declare what opt-out purposes should be notified to end users',
  PURPOSES_ALERT: `Leaving this field blank would select the Sale, Sharing, targeted ads and personal
  data consents as default value for properties migrating from previous versions.`,
  PURPOSES_SUB_LABEL: (
    <>
      <b>Note</b>
      : De-selecting an opt-out purpose while engaging in that specific data-processing activity will lead to Non-compliance.
    </>
  ),
  SENSITIVE_DATA_LABEL: 'do you want to Enable sensitive data processing',
  SENSITIVE_DATA_SUB_LABEL: 'Select `Yes` if the Business Processes any specific category of Sensitive Data',
  EDIT_PURPOSES: 'Edit Purposes',
  CHILD_DATA_LABEL: 'do you want to Enable child sensitive data processing',
  CHILD_DATA_SUB_LABEL: 'Applicable if the publisher knowingly processes personal/sensitive data of children',
  MODAL_TITLE: 'Please select the purposes for your sensitive data processing:',
  AUTO_POPUP_LABEL: 'How should the opt-out notice screen trigger?',
  AUTO_POPUP_SUB_LABEL: (
    <>
      {' '}
      Before selecting this, please review the implications here -
      <a href="https://support.inmobi.com/choice/gpp-cmp/consents-covered-under-mspa#consents-covered-under-mspa" target="_blank" rel="noopener noreferrer">
        link
      </a>
    </>
  ),
};

export default TEXT;
