// @flow
import { faArrowFromLeft, faUser } from '@fortawesome/fontawesome-pro-light';
import { faUser as faUserFilled } from '@fortawesome/fontawesome-pro-solid';
import { faKey as faPassword } from '@fortawesome/fontawesome-pro-light';
import { faCaretDown } from '@fortawesome/fontawesome-pro-light';
import { faCaretDown as faCaretDownFilled } from '@fortawesome/fontawesome-pro-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Dropdown from 'antd/es/dropdown';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import { Auth } from 'aws-amplify';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as AppActions from '../../../store/duck/app';
import ContentHeader from '../content-header';
import Styles from './global-navbar.module.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function errorMessage(error) {
   return error.message || error.log || error.code;
}


const GlobalNavbar = () => {
  const { auth } = useSelector(s => s);
  const { pcode } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isPasswordChangeOpen, setPsswordChangeOpen] = useState(false);
  const [passwordChangeError, setPasswordChangeError] = useState(null);
  const [passwordChangeForm] = Form.useForm();
  const user = auth.user ? {
    name: `${auth.user.attributes.given_name} ${auth.user.attributes.family_name}`,
    email: auth.user.attributes.email,
    pcodes: auth.user.attributes['custom:pcode'].split(",")
  }: {name: '', email: ''};
  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error('error signing out: ', error);
    }
  }
  async function changePassword() {
    try {
      var {old_password, new_password} = passwordChangeForm.getFieldsValue([['old_password'],['new_password']]);
      await Auth.changePassword(auth.user,old_password, new_password);
      setPsswordChangeOpen(false);
    } catch (error) {
      console.error('error signing out: ', error);
      setPasswordChangeError(error);
    }
  }
  function switchAccount(pCode) {
    location.href = `/protect/p-${pCode}/properties`;
  }

  useEffect(() => {
      dispatch(AppActions.getAppInfo(pcode));
  }, [pcode]);

  const items = [
    ...(user
      ? [{
        key: '1',
        label: (
          <div>
            <div className={Styles.userName}>{user.name}</div>
            <div className={Styles.userEmail}>{user.email}</div>
          </div>
        ),
        className: Styles.userInfo
      }]
      : []),
    {
      key: '2',
      label: 'Change Password',
      icon: <FontAwesomeIcon icon={faPassword} />,
      onClick: () => {
        setIsUserMenuOpen(false);
        setPsswordChangeOpen(true);
      }
    },
    {
      key: '3',
      label: 'Logout',
      icon: <FontAwesomeIcon icon={faArrowFromLeft} />,
      onClick: signOut  
    }
  ];
  const accounts = [{
      key: '0',
      label: (
        <div>
          <div className={Styles.userName}>Switch to Account</div>
        </div>
      ),
      className: Styles.userInfo
    },
    ... user.pcodes ? user.pcodes.map((el, i) => {
      return {
        key: `${i+1}`,
        label: 'p-' + el,
        onClick: () => switchAccount(el)
      };
    }) : []
  ];
  return (
    <>
      <ContentHeader
        className={Styles.globalNav}
        showLogo
        actions={[
          ...(user && !user.pcodes || user.pcodes.length == 1 ? [
            {
              component: 'span',
              children: [pcode],
              props: { className: Styles.accountHeader, }
            }
          ] : []),
          ...(user && user.pcodes && user.pcodes.length > 1 ? [
            {
              component: Dropdown,
              children: [
                {
                  component: Button,
                  children: <Space>{pcode}<FontAwesomeIcon icon={isAccountMenuOpen ? faCaretDownFilled : faCaretDown} /></Space>,
                  props: { 
                    type: 'text',
                    className: Styles.accountHeader,
                  }
                }
              ],
              props: {
                trigger: ['click'],
                menu: { items: accounts },
                overlayClassName: Styles.userDropdown,
                onOpenChange: setIsAccountMenuOpen,
                open: isAccountMenuOpen,
              }
            }
          ] : []),
          {
            component: Dropdown,
            children: [
              {
                component: Button,
                props: {
                  className: classNames(Styles.userButton, { [Styles.userButtonActive]: isUserMenuOpen }),
                  icon: <FontAwesomeIcon icon={isUserMenuOpen ? faUserFilled : faUser} />
                }
              }
            ],
            props: {
              trigger: ['click'],
              menu: { items },
              overlayClassName: Styles.userDropdown,
              onOpenChange: setIsUserMenuOpen,
              open: isUserMenuOpen,
            }
          }
        ]}
      />
      {(isAccountMenuOpen || isUserMenuOpen) && <div className={Styles.globalNavBackdrop} />}
      <Modal
        className={Styles.passwordModal}
        title="Change Password"
        open={isPasswordChangeOpen}
        onOk={() => { changePassword(); }}
        onCancel={() => { setPsswordChangeOpen(false); }}
        footer={[
          <Button type="primary" form="passwordChange" key="submit" htmlType="submit">
              Submit
          </Button>
        ]}
      >
        {passwordChangeError && (<span className={Styles.error}>{errorMessage(passwordChangeError)}</span>)}
        <Form
          id="passwordChange"
          form={passwordChangeForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          autoComplete="off"
          preserve={false}
          onFinish={() => {changePassword();}}
        >
          <Form.Item
            label="Old Password"
            name="old_password"
            rules={[{ required: true, message: 'Please input your old password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="new_password"
            rules={[{ required: true, message: 'Please input your new password!' }]}
          >
            <Input.Password />
          </Form.Item>
          
        </Form>
      </Modal>
    </>
  );
};

export default GlobalNavbar;
