// @flow
import React, { useEffect } from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/fontawesome-pro-solid';
import classNames from 'classnames';
import type { FilterInputProps } from './types';
import styles from './filter-input.module.scss';

function FilterInput({ baseData, filterBy = ['name'], onFilter, shouldCleanFilter = false }: FilterInputProps) {
  const [inputVal, setInputVal] = React.useState('');

  const cleanFilter = () => {
    if (inputVal !== '') {
      setInputVal(''); // clear input value
      onFilter(baseData); // return base data
    }
  };

  // Clean filter when switching between tabs (vendors version)
  useEffect(() => {
    if (shouldCleanFilter) {
      cleanFilter(); // clear input value
    }
  }, [shouldCleanFilter]);

  const filterByArray = (propertyProtected) => {
    const regEx = inputVal !== '' ? inputVal.replace(/[-[\]{}()*+?.,\\^$|#\\]/g, '\\$&') : '.*';
    const filterKey = filterBy.find(filterEl => propertyProtected[filterEl]);
    return new RegExp(regEx, 'i').test(propertyProtected[filterKey]);
  };

  const filterData = () => {
    // Escape all special characters while search
    onFilter(baseData.filter(filterByArray));
  };

  const shouldFilter = ({ key }: SyntheticKeyboardEvent): void => {
    if (key === 'Enter') {
      filterData();
    }
  };

  return (
    <Form.Item
      className={classNames(styles.element, { [styles.empty]: !inputVal.length })}
      size="small"
    >
      <Input
        placeholder="Search and Filter by name"
        value={inputVal}
        onChange={({ target }) => setInputVal(target.value)}
        onKeyPress={shouldFilter}
        prefix={<FontAwesomeIcon icon={faSearch} />}
        data-testid="filter-input"
        suffix={inputVal !== ''
          ? (
            <FontAwesomeIcon
              icon={faTimes}
              color="space"
              onClick={cleanFilter}
              data-testid="filter-input-clearButton"
            />
          ) : (' ')
        }
      />
    </Form.Item>
  );
}

export default FilterInput;
