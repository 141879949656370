/* eslint-disable max-len */
// @flow
import Button from 'antd/es/button';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import uid from 'uid';
import Alert from '../alert';
import * as AlertsActions from '../../../store/duck/alerts';
import * as BulkUpdateActions from '../../../store/duck/bulk-updates';
import { ALERT_IDENTIFIERS } from '../../../utils/constants';
import Space from 'antd/es/space'

type AlertType = {
  message: string;
  description: string | React.Element<any>;
  type: 'info' | 'success' | 'warning' | 'error';
  className: string;
  autoClose?: boolean;
}

type AlertsContainerProps = {
  list?: Array<AlertType>;
  customActions?: Array<Record, String>;
  actions: Object<Function>;
  bulkActions: Object<Function>;
  app: Object;
};

type AlertProps = {
  closable: Boolean | undefined;
  autoClose: Boolean | undefined;
  id: Number | undefined;
  actions: Object<Function>;
  buttons: Array<Object>;
  customActions?: Array<Record, String>;
  bulkActions: Array<Record, String>;
  callCounter: Object<Function>;
  app: Object;
}

/**
 * @param autoClose
 * @param closable
 * @param id
 * @param actions
 * @param buttons
 * @param customActions
 * @returns {*}
 * @constructor
 */
function AlertWrapper(props: AlertProps) {
  const history = useHistory();
  const { autoClose, closable, id, actions, bulkActions, callCounter, buttons, customActions, app } = props;
  const [fetchBkStatus, setFetchBkStatus] = useState(false);
  useEffect(() => {
    if (id === ALERT_IDENTIFIERS.BULK_UPDATES_INQUEUE && app && !app.loading && app.pCode) {
      if (!fetchBkStatus && callCounter.current < 4) {
        setInterval(() => setFetchBkStatus(true), 10000);
      }
    } else { setFetchBkStatus(false); }
  }, [id, app]);
  useEffect(() => {
    if (fetchBkStatus && app && !app.loading && app.pCode && id === ALERT_IDENTIFIERS.BULK_UPDATES_INQUEUE) {
      bulkActions.fetchBulkUpdateStatus({ pCode: app.pCode, page: 'sites' });
      callCounter.current += 1;
    }
  }, [fetchBkStatus]);

  const onClose = closable ? () => actions.remove(id) : undefined;
  const action = buttons && buttons.length > 0 && (
    <>
      { buttons.map((button) => {
        const { text, size, type, href, target } = button;
        const _props = { text, size, type, href, target };
        if (type === 'link' && !target) {
          _props.onClick = (e) => {
            e.preventDefault();
            history.push(href);
          };
        }
        if (customActions && button.dispatchCustomAction) {
          _props.onClick = () => {
            customActions[button.dispatchCustomAction.namespace][button.dispatchCustomAction.action](button.dispatchCustomAction.params);
          };
        }
        return <Button key={uid()} {..._props}>{_props.text}</Button>;
      })}
    </>
  );
  if (autoClose) setTimeout(() => actions.remove(id), 7000);

  return <Alert id {...{ ...props, onClose, action }} />;
}

/**
 * @param list
 * @param actions
 * @param bulkActions
 * @returns {*}
 * @constructor
 */
function AlertsContainer({ list, actions, bulkActions, customActions, app }: AlertsContainerProps) {
  const callCounter = React.useRef(0);
  useEffect(() => {
    if (list.length && window.scrollY && document.getElementsByClassName('ant-alert').length > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [list]);
  if (!list.length) return null;
  return (
    <Space direction="vertical" style={{width: '100%'}}>
      {list.map(alert => <AlertWrapper key={uid()} {...alert} actions={actions} callCounter={callCounter} bulkActions={bulkActions} customActions={customActions} app={app} />)}
    </Space>
  );
}


AlertWrapper.defaultProps = { customActions: null };
AlertsContainer.defaultProps = { list: [], customActions: {} };

export default connect(
  ({ alerts, app }) => ({ list: alerts, app }),
  dispatch => ({
    actions: bindActionCreators(AlertsActions, dispatch),
    bulkActions: bindActionCreators(BulkUpdateActions, dispatch)
  })
)(AlertsContainer);
