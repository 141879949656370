// @flow
import Modal from 'antd/es/modal';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { ANNOUNCEMENTS, ANNOUNCEMENTS_LS_PREFIX } from '../../../utils/announcements';
import { getStorage, isStorageAvailable, removeStorage } from '../../../utils/localStorage';

const AnnouncementsModal = () => {
  const [currentAnnouncementIdx, setCurrentAnnouncementIdx] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isLocalStorageAvailable = useMemo(() => isStorageAvailable(), []);
  const currentAnnouncement = ANNOUNCEMENTS && ANNOUNCEMENTS[currentAnnouncementIdx];

  const getFullAnnouncementId = id => `${ANNOUNCEMENTS_LS_PREFIX}_${id}`;

  const handleIgnore = () => {
    setIsModalOpen(false);
    setCurrentAnnouncementIdx(currIdx => currIdx + 1);
  };

  const handleDismiss = () => {
    handleIgnore();
    if (isLocalStorageAvailable) {
      const { id } = currentAnnouncement;
      localStorage.setItem(getFullAnnouncementId(id), moment().toISOString());
    }
  };

  // determine which announcement (if any) to show next
  useEffect(() => {
    if (currentAnnouncement) {
      const { id, start, end, expire, expireUnits } = currentAnnouncement;
      const now = moment();
      const fullId = getFullAnnouncementId(id);
      const lastSeen = isLocalStorageAvailable ? getStorage(fullId) : null;
      const isBeforeStart = start && now.isBefore(moment(start));
      const isAfterEnd = end && now.isAfter(moment(end));
      const isWithinExpire = (expire === null || expire === undefined)
        ? !!lastSeen
        : now.isBefore(moment(lastSeen).add(expire, expireUnits || 'ms'));
      if (isBeforeStart || isAfterEnd || isWithinExpire) {
        setCurrentAnnouncementIdx(currIdx => currIdx + 1);
        if (isAfterEnd && isLocalStorageAvailable) {
          removeStorage(fullId);
        }
      } else {
        if (isLocalStorageAvailable) {
          removeStorage(fullId);
        }
        setIsModalOpen(true);
      }
    }
  }, [ANNOUNCEMENTS, currentAnnouncement]);

  // cleanup old announcements from local storage
  useEffect(() => {
    if (!isLocalStorageAvailable) {
      return;
    }
    Object.keys(localStorage).forEach((key) => {
      const isAnnouncementKey = key.startsWith(ANNOUNCEMENTS_LS_PREFIX);
      if (
        isAnnouncementKey
        && (!ANNOUNCEMENTS || !ANNOUNCEMENTS.find(announcement => getFullAnnouncementId(announcement.id) === key))
      ) {
        removeStorage(key);
      }
    });
  }, []);

  if (!currentAnnouncement) {
    return null;
  }

  const { getModalInfo } = currentAnnouncement;
  const actions = { handleDismiss, handleIgnore };
  return (
    <Modal open={isModalOpen} onCancel={handleDismiss} onOk={handleDismiss} {...getModalInfo(actions)} />
  );
};

export default AnnouncementsModal;
