import React, { useEffect, useState } from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Radio from 'antd/es/radio';
import Space from 'antd/es/space';
import uid from 'uid';
import { CONSENT_SCOPE, USER_CONSENT_SCOPE, encodingOptions } from '../../../../../utils/constants';
// @flow

import InfoIconTooltip from '../../../../ui/infoIconTooltip';
import SITETOOLTIPS from '../../../../../utils/toolTipsMessages';
import Select from '../../../../ui/select';
import Styles from './consent.module.scss';
import validate from '../../syncValidation';
import Alert from '../../../../ui/alert';
import { isGPPSupported } from '../../../../../utils/property';

type Props = {
  validateFields: Function;
  getFieldValue: Function;
  setDataTestId: Function;
  consentError: Boolean;
  cmpVersion: String;
}

const { Option } = Select;

const Consent = ({
  validateFields,
  getFieldValue,
  setDataTestId,
  consentError,
  cmpVersion,
}: Props) => {
  const chooseHint = (scope) => {
    let hint = '';
    switch (scope) {
      case 'service':
        hint = 'Service-specific scope means a user’s preferences are applicable only to the publisher property'
          + ' on which the preference was set.';
        break;

      case 'service group':
        hint = 'Group-specific scope means a user’s preference is applicable only to a pre-defined group of'
          + ' publisher properties, which allows users to share a single set of preferences across'
          + ' all the publisher properties of your group.';
        break;
      default:
        hint = undefined;
        break;
    }
    return hint;
  };
  const [consentScopeHint, setConsentScopeHint] = useState('');
  const ampEnabled = getFieldValue('ampEnabled');
  const [scopeGroup, setScopeGroup] = useState(false);
  const [gdprEncodingMode, setGdprEncodingMode] = useState(getFieldValue('gdprEncodingMode'));

  const {
    consentScope,
    consentScopeGroupURL,
    repromtDaysAfterGvlUpdate,
    groupOfWebsitesURL
  } = validate();

  useEffect(() => {
    const consentScopeValue = getFieldValue('consentScope');
    setConsentScopeHint(chooseHint(consentScopeValue));
    setScopeGroup(consentScopeValue === 'service group');
  }, []);

  useEffect(() => {
    const hint = consentError ? undefined : chooseHint(getFieldValue('consentScope'));
    setConsentScopeHint(hint);
  }, [consentError]);

  useEffect(() => {
    validateFields(['consentScopeGroupURL']);
  }, [scopeGroup]);

  const onConsentScopeChange = (event) => {
    setConsentScopeHint(chooseHint(event));
    if (event === 'service group') {
      setScopeGroup(true);
    } else {
      setScopeGroup(false);
    }
  };

  return (
    <>
      {!isGPPSupported(cmpVersion) && (
        <Form.Item
          data-testid={setDataTestId('requireConsentFrom')}
          label={[<React.Fragment key={uid}>WHICH USERS SHOULD BE ASKED FOR CONSENT</React.Fragment>]}
          tooltip={{
            title: SITETOOLTIPS.USER_CONSENT,
            icon: (
              <span>
                <InfoIconTooltip />
              </span>
            )
          }}
          name="requireConsentFrom"
        >
          <Select>
            {USER_CONSENT_SCOPE
            && USER_CONSENT_SCOPE.map(opt => (
              <Option key={opt.value} value={opt.value} disabled={opt.disabled}>
                {opt.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        label="CONSENT SCOPE: ASK USER FOR CONSENT FOR..."
        tooltip={{
          title: SITETOOLTIPS.CONSENT_SCOPE,
          icon: (
            <span>
              <InfoIconTooltip />
            </span>
          )
        }}
        name="consentScope"
        rules={consentScope(ampEnabled)}
        help={consentScopeHint}
        data-testid={setDataTestId('consentScope')}
      >
        <Select onChange={onConsentScopeChange}>
          {CONSENT_SCOPE.map(item => (item.value === 'service' ? item : { ...item, disabled: ampEnabled })).map(opt => (
            <Option key={opt.value} value={opt.value} disabled={opt.disabled}>
              {opt.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {scopeGroup && (
        <section>
          <p>
            <strong>NOTE:</strong>
            &nbsp;By choosing a Group option, you must provide a Group Domain. This requires a deployment of a JSON API
            to read/set cookies. See&nbsp;
            <a
              target="technical_impletation"
              href="https://help.quantcast.com/hc/en-us/articles/13422563777435-Sharing-Consent-Between-Multiple-Sites-"
            >
              Technical Implementation Guide.
            </a>
          </p>
          <Form.Item
            label="CONSENT SCOPE GROUP URL"
            name="consentScopeGroupURL"
            required
            rules={consentScopeGroupURL(scopeGroup)}
            data-testid={setDataTestId('consentScopeGroupURL')}
          >
            <Input placeholder="Paste URL path here" required />
          </Form.Item>
          <Form.Item
            label="GROUP OF WEBSITES URL"
            name="groupOfWebsitesURL"
            rules={groupOfWebsitesURL}
            data-testid={setDataTestId('groupOfWebsitesURL')}
          >
            <Input placeholder="Paste URL to redirect group of websites link" />
          </Form.Item>
          <Form.Item label="Storage Mode" required name="thirdPartyStorageType" style={{ marginBottom: '5px' }}>
            <Radio.Group data-testid={setDataTestId('thirdPartyStorageType')}>
              <Radio value="iframe">Iframe</Radio>
              <Radio value="api">API</Radio>
            </Radio.Group>
          </Form.Item>
          {
            isGPPSupported(cmpVersion) && (
              <Alert
                type="warning"
                style={{ marginBottom: '24px' }}
                message={
                    `Group consent will be applicable under both GDPR and U.S
                    regulations.`
                }
                className={Styles.warningAlert}
                showIcon
              />
            )
          }
        </section>
      )}

      <div className={Styles.vendorsDays}>
        {/* // Hide it until CMP feature is complete */}
        {/* <Input
            input={this.buildInput('redirectUrl')}
            meta={buildMeta('redirectUrl')}
            label="NON-CONSENT REDIRECT URL"
            placeholder="Enter URL"
          /> */}
        <Form.Item
          label="SHOW NEW VENDORS EVERY..."
          name="repromtDaysAfterGvlUpdate"
          rules={repromtDaysAfterGvlUpdate}
          data-testid={setDataTestId('repromtDaysAfterGvlUpdate')}
        >
          <Input max={350} min={0} suffix="Days" placeholder={30} type="number" />
        </Form.Item>
      </div>

      {isGPPSupported(cmpVersion) && (
        <Space direction="vertical" size={0}>
          <Form.Item
            label={<span>What is the string format for encoding GDPR consent?</span>}
            tooltip={{
              title: SITETOOLTIPS.gdprEncodingMode,
              icon: (<span><InfoIconTooltip /></span>)
            }}
            name="gdprEncodingMode"
            onChange={e => setGdprEncodingMode(e.target.value)}
            style={{ marginBottom: '10px' }}
          >
            <Radio.Group>
              {encodingOptions.map(option => <Radio value={option.value}>{option.label}</Radio>)}
            </Radio.Group>
          </Form.Item>
          {
            gdprEncodingMode === 'TCF' && (
              <Alert
                type="warning"
                message={
                    `Some of your ad networks might not currently (or in future) be compliant with
                    TCF but instead support GPP, hence its recommended to select both GPP and TCF.`
                }
                className={Styles.warningAlert}
                showIcon
              />
            )
          }
          {
            gdprEncodingMode === 'GPP' && (
              <Alert
                type="warning"
                message={
                    `Some of your ad networks might not be compliant with GPP yet, hence
                    its recommended to select both GPP and TCF`
                }
                className={Styles.warningAlert}
                showIcon
              />
            )
          }
          {
            ampEnabled && gdprEncodingMode === 'TCF_AND_GPP' && (
              <Alert
                type="warning"
                message={
                    `"Both" option will not work for AMP sites. Only GPP string will be
                    stored. If you want TCF string format, select the "TCF" option.`
                }
                className={Styles.warningAlert}
                showIcon
              />
            )
          }
        </Space>

      )}
    </>
  );
};

export default Consent;
