export const defaultValueOptions = [
  {
    label: 'Denied',
    value: 'DENIED',
    key: 'DENIED'
  },
  {
    label: 'Granted',
    value: 'GRANTED',
    key: 'GRANTED'
  }
];

export const gbcEnabledOptions = [
  {
    label: 'Yes',
    value: true,
    key: 'YES'
  },
  {
    label: 'No',
    value: false,
    key: 'NO'
  }
];

export const GBC_FIELDS = {
  ENABLED: 'gbcEnabled',
  LOCATIONS: 'gbcLocations',
  APPLICABLE_PURPOSES: 'gbcApplicablePurposes',
  URL_PASS_THROUGH: 'urlPassThrough',
  ADS_DATA_REDACTION: 'adsDataRedaction'
};
