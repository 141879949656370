import React, { useState, useEffect } from 'react';
import CustomizeCCPA from '../../../../pages/apps/components/customize-ccpa';
import validate from '../syncValidation';
import CustomizeUI from './customize-ui';
import { autoDetectSupportedLanguage, getTranslationsForLang } from '../../../../utils/language';
import PurposesV2 from './purposes-v2';
import CustomizePersistentConsent from './customize-consent';
import UISettings from '../../../../pages/apps/components/ui-settings';

// @flow

interface IBasicProps {
  values: Object;
  form: any;
  setValues: Function;
  formInitData: Object;
  current: Object;
  onValuesChange: Function;
  gvlData: Object;
  propertyType: String;
  pCode: String;
}

const Basic = ({
  values,
  form,
  setValues,
  formInitData,
  current,
  onValuesChange,
  gvlData,
  updateTCFVersion,
  propertyType,
  pCode
}: IBasicProps) => {
  const { resetFields, validateFields, setFieldsValue } = form;

  const [language, setLanguage] = useState(autoDetectSupportedLanguage());
  const [addCustomLink, setAddCustomLink] = useState(false);

  const validations = validate();

  const onChangeTCFVersion = (gvlVersion) => {
    updateTCFVersion(gvlVersion);
  };

  useEffect(() => {
    if (formInitData.uxInitialScreenCustomLinksText1) {
      setAddCustomLink(true);
    }
    if (current && Object.keys(current).length) {
      if (formInitData.uxInitialScreenCustomLinksText1) {
        setAddCustomLink(true);
      }
      if (current.uxInitialScreenCustomLinks && Object.keys(current.uxInitialScreenCustomLinks).length === 2) {
        setAddCustomLink(true);
      }
      resetFields();
    }
  }, []);

  const normalizeHex = hex => (hex ? `#${hex.split('#').join('')}` : '');

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const appDataTest = input => `app-${input}-input`;

  const handleDisagreeButtonChange = (input) => {
    const { checked, name } = input.target;
    setValues(preValues => ({ ...preValues, [name]: checked }));
  };

  const handleUserSelectedStack = (value) => {
    setValues({ ...values, userSelectedStack: value });
  };

  const handleCustomLinks = () => {
    setAddCustomLink((prevState) => {
      if (prevState) {
        setFieldsValue({ uxInitialScreenCustomLinksText1: undefined });
        setFieldsValue({ uxInitialScreenCustomLinksUrl1: undefined });
        setValues(preValues => ({
          ...preValues,
          uxInitialScreenCustomLinksText1: undefined,
          uxInitialScreenCustomLinksUrl1: undefined
        }));
      }
      return !prevState;
    });
  };

  return (
    <>
      {values.v2HasGDPR && propertyType === 'site' && (
        <>
          <CustomizeUI
            normalizeHex={normalizeHex}
            handleLanguageChange={handleLanguageChange}
            handleDisagreeButtonChange={handleDisagreeButtonChange}
            handleCustomLinks={handleCustomLinks}
            addCustomLink={addCustomLink}
            language={language}
            values={values}
            validations={validations}
            validateFields={validateFields}
            onChange={setValues}
          />
          <PurposesV2
            purposeIds={values.vendorPurposeIds}
            purposeLegitimateIds={values.vendorPurposeLegitimateInterestIds}
            specialFeatures={values.vendorSpecialFeaturesIds}
            stackIds={values.stacks}
            specialPurposes={values.vendorSpecialPurposesIds}
            features={values.vendorFeaturesIds}
            flexiblePurposes={values.vendorFlexiblePurposesIds || []}
            publisherConsentRestrictionIds={values.publisherConsentRestrictionIds}
            publisherLIRestrictionIds={values.publisherLIRestrictionIds}
            use={values.use}
            gvlVersion={values.gvlVersion}
            onChange={(newValues) => {
              setValues(preValues => ({ ...preValues, ...newValues }));
              onValuesChange();
            }}
            vendorsData={gvlData}
            stacks={values.stacks}
            userSelectedStack={values.userSelectedStack}
            initData={values}
            onChangeTCFVersion={onChangeTCFVersion}
          />
          <CustomizePersistentConsent language={language} isSoftOptIn={values.uxSoftOptIns} validations={validations} />
        </>
      )}
      {values.v2HasGDPR && propertyType === 'app' && (
        <>
          <UISettings
            appDataTest={appDataTest}
            onChange={setValues}
            disagreeButtonTextDisabled={!values.initScreenRejectButtonShowing}
            validations={validations}
            validateFields={validateFields}
            customLinks={values.uxInitialScreenCustomLinks}
            translations={getTranslationsForLang(values.language)}
            setDataTestId={input => `app-customlink-${input}-input`}
            regulationEnabled={values.v2HasGDPR || values.v2HasCCPA}
          />
          {/* <CustomizePurposes
            onChange={(newValues, isUserChangedValues = false) => {
              setValues(prevValues => ({ ...prevValues, ...newValues }));
              if (isUserChangedValues) onValuesChange();
            }}
            values={values}
            initData={values}
            appDataTest={appDataTest}
            use={values.use}
            userSelectedStack={values.userSelectedStack}
            stackIds={values.stacks}
            setUserSelectedStack={handleUserSelectedStack}
            pCode={pCode}
            handleCustomization={handleVendorCustomization}
            vendorsData={vendorsData.data}
          /> */}
          <PurposesV2
            purposeIds={values.vendorPurposeIds}
            purposeLegitimateIds={values.vendorPurposeLegitimateInterestIds}
            specialFeatures={values.vendorSpecialFeaturesIds}
            stackIds={values.stacks}
            specialPurposes={values.vendorSpecialPurposesIds}
            features={values.vendorFeaturesIds}
            flexiblePurposes={values.vendorFlexiblePurposesIds || []}
            publisherConsentRestrictionIds={values.publisherConsentRestrictionIds}
            publisherLIRestrictionIds={values.publisherLIRestrictionIds}
            use={values.use}
            gvlVersion={values.gvlVersion}
            onChange={(newValues) => {
              setValues(preValues => ({ ...preValues, ...newValues }));
              onValuesChange();
            }}
            vendorsData={gvlData}
            stacks={values.stacks}
            userSelectedStack={values.userSelectedStack}
            initData={values}
            onChangeTCFVersion={onChangeTCFVersion}
          />
        </>
      )}
      {values && values.v2HasCCPA && (
        <CustomizeCCPA values={values} handleChange={setValues} validations={validations} setFields={setFieldsValue} />
      )}
    </>
  );
};

export default Basic;
