/* eslint-disable jsx-a11y/label-has-for */
// @flow
import React, { useState } from 'react';
import RichTextEditor from 'react-rte';
import classNames from 'classnames';
import Styles from './richText.module.scss';

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
  ]
};

type Props = {
  input: Object,
  label?: string,
  placeholder: string,
  className?: string,
  editorClassName?: string,
};

const RichText = ({ input, label, placeholder, className, editorClassName }: Props) => {
  const [state, setState] = useState(RichTextEditor.createValueFromString(input.value, 'html'));
  const onInternalChange = (value) => {
    const isEmpty = !value
      .getEditorState()
      .getCurrentContent()
      .hasText();

    if (isEmpty) {
      input.onChange('');
    } else {
      input.onChange(value.toString('html'));
    }
    setState(value);
  };

  return (
    <>
      {label && <label htmlFor={input.name}>{label}</label>}
      <RichTextEditor
        value={state}
        onChange={onInternalChange}
        id={input.name}
        placeholder={placeholder}
        toolbarConfig={toolbarConfig}
        toolbarClassName={Styles.toolbar}
        editorClassName={`${editorClassName} ${Styles.editor}`}
        className={classNames(className, [Styles.wrapper])}
      />
    </>
  );
};

RichText.defaultProps = {
  label: '',
  className: '',
  editorClassName: ''
};

export default RichText;
