import {
  MutationFunction,
  QueryFunction,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  QueryKey,
  useQuery
} from '@tanstack/react-query';
import type { iErrorObject, UseDataMutationHookReturnType } from '../../interfaces/common';
// @flow

/**
 * A wrapper for the `useQuery` hook from `react-query`.
 *
 * @param key The query key to be used for this query.
 * @param func The query function to be used for this query.
 * @param options Optional. Additional options object for the query like `enabled`, `onSuccess`, etc.
 */
export function useQueryWrapper<TApiResponse, TError = iErrorObject>(
  key: QueryKey,
  func: QueryFunction<TApiResponse, QueryKey>,
  options: UseQueryOptions<TApiResponse, TError> = {},
): UseQueryResult<TApiResponse, TError> {
  return useQuery({
    queryKey: key,
    queryFn: func,
    ...options,
  });
}

/**
 * A wrapper for the `useMutation` hook from `react-query`.
 *
 * @param func The mutation function to be used for this mutation
 * @param options Optional. Additional options object for the query like `onSuccess`, `onError` etc.
 */
export function useMutationWrapper<
  TApiResponse,
  TPayload,
  TError = iErrorObject,
>(
  func: MutationFunction<TApiResponse, TPayload>,
  options: UseMutationOptions<TApiResponse, TError, TPayload> = {},
): UseDataMutationHookReturnType<TApiResponse, TPayload, TError> {
  const mutationResult = useMutation({
    mutationFn: func,
    ...options,
  });

  return [mutationResult.mutate, mutationResult];
}
