// @flow
import React from 'react';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';

type Props = {
  enterDelay?: number;
  href: string;
  icon: any;
  leaveDelay?: number;
  placement?: string;
  text?: string;
  title: string;
  type?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default';
  className?: string;
}

function AnchorTooltipIconButton(
  { enterDelay, leaveDelay, placement, text, title, className, ...buttonProps }: Props
) {
  return (
    <Tooltip
      title={title}
      mouseEnterDelay={enterDelay}
      mouseLeaveDelay={leaveDelay}
      placement={placement}
      className={className}
    >
      <Button {...buttonProps}>
        {text && ` ${text}`}
      </Button>
    </Tooltip>
  );
}

AnchorTooltipIconButton.defaultProps = {
  enterDelay: 0,
  leaveDelay: 0,
  placement: 'top',
  text: undefined,
  type: 'primary',
  className: undefined,
};

export default AnchorTooltipIconButton;
