/* eslint-disable import/prefer-default-export */
// @flow
import type { iAnalytics } from '../interfaces/analytics';

export const mockEmptyAnalyticByURL: iAnalytics = {
  id: 0,
  updatedOn: '',
  updatedBy: '',
  url: '',
  totalConsentCookies: 0,
  totalConsentAccepted: 0,
  consentRatePercentage: 0,
  totalConsentRejected: 0,
  rejectedRatePercentage: 0,
  partialConsentAccepted: 0,
  partialRatePercentage: 0,
  averageConsentRatePercentage: 0,
  bounce: 0,
  bounceRatePercentage: 0,
  firstConsentScreenCookies: 0,
  secondConsentScreenCookies: 0,
  thirdConsentScreenCookies: 0
};

export const mockEmptyAnalyticByApp = {
  1: {
    url: 'com.app',
    pCode: 'YeqgjVvQ7Twnz',
    totalShown: 0,
    acceptAll: 0,
    partialAll: 0,
    rejectAll: 0,
    firstConsentScreenCookies: 0,
    secondConsentScreenCookies: 0,
    thirdConsentScreenCookies: 0,
    doNotSellRequestReceived: 0,
    doNotSellRequestFulfilled: 0,
    medianTimeFulfillmentMillis: 0,
    bounceForV2: 0,
    totalConsentCookies: 0,
    acceptedRatePercentage: 0.0,
    partialRatePercentage: 0.0,
    rejectedRatePercentage: 0.0,
    consentRatePercentage: 0.00,
    cookiesConsentRatePercentage: 0.0,
    bounce: 0,
    bounceRatePercentage: 0.0,
    bounceRateAltered: false
  }
};
