/* eslint-disable import/prefer-default-export */
import { QueryFunctionContext } from '@tanstack/react-query';
import getAxiosInstance from '../axiosClient';
import type { iAnalyticsDataList, } from '../../interfaces/sampleInterface';
// @flow

const requestCustomization = getAxiosInstance(process.env.REACT_APP_CHOICE_2_V2_CUSTOMIZATION_API_URL);

const url = '/site';

export async function getPremiumSites(context: QueryFunctionContext): Promise<iAnalyticsDataList> {
  const [, queryPayload] = context.queryKey;
  const { pCode } = queryPayload;
  const data = await requestCustomization.get(
    `${url}/premium/pcode/${pCode}`,
  );
  return data;
}
