// @flow
import React from 'react';
import Tooltip from 'antd/es/tooltip';
import InfoIconTooltip from '../infoIconTooltip';
import SITETOOLTIPS from '../../../utils/toolTipsMessages';

const ReportGDPRToolTip = () => (
  <Tooltip
    color="black"
    overlayInnerStyle={{ width: 415, padding: 8 }}
    title={SITETOOLTIPS.reportGDPR}
    placement="right"
  >
    <span className="info-icon-container"><InfoIconTooltip style={{ marginLeft: '5' }} /></span>
  </Tooltip>
);

export default ReportGDPRToolTip;
