import { GBC_LOCATION_DEFAULT } from '../../../utils/constants';

export const getGBCPurposesDefaultData = gbcPurposes => gbcPurposes.map(({ id }) => ({ id, enabled: false, defaultValue: 'DENIED' }));

const isValueDefined = (value) => typeof value !== 'undefined' && value !== null;

export const getGBCInitData = (current, gbcPurposes) => {
  const initGBCPurposes = getGBCPurposesDefaultData(gbcPurposes);
  const currentPurposes = current.gbcApplicablePurposes || [];

  const valMaps = {};
  currentPurposes.forEach((val) => {
    valMaps[val.id] = val;
  });
  /* gbcApplicablePurposes form format: [{ id: '1', enabled: true, defaultValue: 'DENIED' }, ...]
     So need to convert currentPurposes to the format of gbcApplicablePurposes */
  const savedPurposes = initGBCPurposes.map((val) => {
    if (valMaps[val.id]) {
      return { ...val, enabled: true, defaultValue: valMaps[val.id].defaultValue };
    }
    return val;
  });

  return {
    gbcEnabled: current.gbcEnabled || false,
    gbcLocations: (current.gbcLocations && current.gbcLocations.length > 0)
      ? current.gbcLocations[0] : GBC_LOCATION_DEFAULT,
    gbcApplicablePurposes: savedPurposes,
    urlPassThrough: isValueDefined(current.urlPassThrough) ? current.urlPassThrough : false,
    adsDataRedaction: isValueDefined(current.adsDataRedaction) ? current.adsDataRedaction : false
  };
};
