export const IFRAME_STYLES = `
  .qc-cmp-ui-container {
    overflow: auto !important;
  }

  .qc-cmp-ui-container.qc-cmp-showing {
    background:none !important;
  }
  .qc-cmp-ui-content {
    overflow: hidden !important;
  }

  .qc-cmp-ui {
    overflow: auto !important;
  }
`;

/* eslint-disable max-len */
const DELETE_COOKIES = `
  function deleteCookies() {
    ['googlepersonalization', 'eupubconsent', 'euconsent', 'euconsent-v2'].forEach(function(el) {
      document.cookie = el + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; max-age=-1; domain=' + window.location.hostname;
    });
  }
  deleteCookies();
`;

export const IFRAME_SCRIPTS_V2 = `
  ${DELETE_COOKIES}
`;

export const IFRAME_STUB_V2 = `
  (function() {
    function makeStub() {
      var TCF_LOCATOR_NAME = '__tcfapiLocator';
      var queue = [];
      var win = window;
      var cmpFrame;

      function addFrame() {
        var doc = win.document;
        var otherCMP = !!(win.frames[TCF_LOCATOR_NAME]);

        if (!otherCMP) {
          if (doc.body) {
            var iframe = doc.createElement('iframe');

            iframe.style.cssText = 'display:none';
            iframe.name = TCF_LOCATOR_NAME;
            doc.body.appendChild(iframe);
          } else {
            setTimeout(addFrame, 5);
          }
        }
        return !otherCMP;
      }

      function tcfAPIHandler() {
        var gdprApplies;
        var args = arguments;

        if (!args.length) {
          return queue;
        } else if (args[0] === 'setGdprApplies') {
          if (
            args.length > 3 &&
            args[2] === 2 &&
            typeof args[3] === 'boolean'
          ) {
            gdprApplies = args[3];
            if (typeof args[2] === 'function') {
              args[2]('set', true);
            }
          }
        } else if (args[0] === 'ping') {
          var retr = {
            gdprApplies: gdprApplies,
            cmpLoaded: false,
            cmpStatus: 'stub'
          };

          if (typeof args[2] === 'function') {
            args[2](retr);
          }
        } else {
          queue.push(args);
        }
      }

      function postMessageEventHandler(event) {
        var msgIsString = typeof event.data === 'string';
        var json = {};

        try {
          if (msgIsString) {
            json = JSON.parse(event.data);
          } else {
            json = event.data;
          }
        } catch (ignore) { }

        var payload = json.__tcfapiCall;

        if (payload) {
          window.__tcfapi(
            payload.command,
            payload.version,
            function(retValue, success) {
              var returnMsg = {
                __tcfapiReturn: {
                  returnValue: retValue,
                  success: success,
                  callId: payload.callId
                }
              };
              if (msgIsString) {
                returnMsg = JSON.stringify(returnMsg);
              }
              event.source.postMessage(returnMsg, '*');
            },
            payload.parameter
          );
        }
      }

      while (win) {
        try {
          if (win.frames[TCF_LOCATOR_NAME]) {
            cmpFrame = win;
            window.__tcfapi = tcfAPIHandler;
            break;
          }
        } catch (ignore) { }

        if (win === window.top) {
          break;
        }
        win = win.parent;
      }
      if (!cmpFrame) {
        addFrame();
        window.__tcfapi = tcfAPIHandler;
        win.addEventListener('message', postMessageEventHandler, false);
      }
    };
    if (typeof module !== 'undefined') {
      module.exports = makeStub;
    } else {
      makeStub();
    }
  })();
`;
