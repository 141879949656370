// @flow
import React, { useState } from 'react';
import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox';
import Tabs from 'antd/es/tabs';
import Alert from '../alert';
import Loader from '../loader';
import Modal from '../modal';
import { tagCodeTCFV2, tagVersionTCFV2 } from '../../../__mocks__/tagCodev2';
import { ampStylesTag, tagCodeAmp } from './amp-utils';
import { TAG_INSTRUCTIONS_LINK, TAG_AMP_INSTRUCTIONS_LINK } from '../../../utils/tag';
import Styles from './modalTag.module.scss';

type Props = {
  pCode: string;
  isPremium: boolean;
  isOpen: boolean;
  handleClose: Function;
  isSiteSpecific?: boolean;
  siteUrl?: string;
  isAmp?: boolean;
  ampConfig?: String;
  loading?: Boolean;
};

const ModalTag = ({
  pCode,
  isPremium,
  isOpen,
  handleClose,
  isSiteSpecific,
  siteUrl,
  isAmp,
  ampConfig,
  loading
}: Props) => {
  const textareaRef2 = React.createRef();
  const textareaRef3 = React.createRef();
  const textareaRef4 = React.createRef();
  const [hasBeenCopied, setHasBeenCopied] = useState(false);
  const [selectedTab, setSelectedTab] = useState(`${tagVersionTCFV2}`);
  const [persistentLinkValue, setPersistentLinkValue] = useState(true);

  const handleNavbarTabChange = (selected) => {
    setSelectedTab(selected);
  };

  const onCopy = () => {
    if (!isAmp) {
      textareaRef2.current.select();
    } else {
      textareaRef3.current.select();
    }
    document.execCommand('copy');
    setHasBeenCopied(true);
    setTimeout(() => setHasBeenCopied(false), 3000);
  };

  const onCopyStyles = () => {
    textareaRef4.current.select();
    document.execCommand('copy');
  };

  const handleOnChange = () => {
    setPersistentLinkValue(prevState => !prevState);
  };

  const footerBtns = (
    <div className={Styles.group}>
      <Button
        type="link"
        key="copy"
        onClick={onCopy}
        style={{ padding: 0 }}
        data-testid="copy_tag"
      >
        COPY TAG
      </Button>
      {(persistentLinkValue && isAmp) && (
        <Button
          type="link"
          key="copyStyles"
          onClick={onCopyStyles}
          style={{ padding: '0 10px 0' }}
          data-testid="copy_tag_styles"
        >
          COPY STYLES
        </Button>
      )}
      <Button
        type="primary"
        key="close"
        onClick={handleClose}
        data-testid="close_tag_modal"
      >
        CLOSE
      </Button>
    </div>
  );

  const v2TagCode = (
    <textarea
      readOnly
      className="textarea"
      ref={textareaRef2}
      value={tagCodeTCFV2(pCode, isSiteSpecific, siteUrl)}
    />
  );

  const ampTagCode = () => (
    <textarea
      readOnly
      className="textarea"
      ref={textareaRef3}
      value={tagCodeAmp(ampConfig, persistentLinkValue)}
    />
  );

  const ampStylesCode = () => {
    const { coreConfig } = ampConfig;

    return (
      <textarea
        readOnly
        className="textarea"
        ref={textareaRef4}
        value={ampStylesTag(coreConfig)}
      />
    );
  };

  let specificationLabel = isSiteSpecific ? 'Site' : 'Universal';
  const trigerLabel = isSiteSpecific ? 'site' : 'modal';
  const specificationForLabel = (isSiteSpecific && siteUrl)
    ? `v2.0 (for ${siteUrl})`
    : tagVersionTCFV2;

  if (isAmp) {
    specificationLabel = 'Choice AMP';
  }

  const content = !isAmp ? (
    <>
      <section>
        <p>
          {`Implement your ${specificationLabel} Tag in the website header, so that you gather users’
      consent from InMobi Choice CMP before any other tags are loaded.
      All future configurations to the Choice CMP can be managed directly in this Central Portal,
      without needing to redeploy the tag again!`}
        </p>
        {isPremium ? (
          <Tabs
            size={'large'}
            selectedTabId={selectedTab}
            onChange={handleNavbarTabChange}
            items={[{ key: '1', label: 'TCF 2.0 Tag', children: v2TagCode }]}
          />
        ) : (
          <>
            <section className={Styles.group}>
              <h5>
                INMOBI CHOICE TAG
                {` ${specificationForLabel}`}
              </h5>
            </section>
            {v2TagCode}
          </>
        )}
        {hasBeenCopied && (
          <Alert type="info" message="The tag has been copied" className={Styles.alert} />
        )}
      </section>
    </>
  )
    : (
      <section>
        {loading
          ? (
            <Loader />
          ) : (
            <>
              <p>
                {
                  `Implement your ${specificationLabel} Tag at the top of the body on your AMP-powered web page.
                  If you need to implement CCPA or you want to display GDPR only in EU,
                  check the AMP Instructions document at the top of this popup.`
                }
              </p>
              <section>
                <h5>
                  CHOICE AMP TAG
                  {` for (${siteUrl})`}
                </h5>
                <Checkbox
                  checked={persistentLinkValue}
                  onChange={handleOnChange}
                  data-testid="check-box-persistent-link"
                >
                  Display persistent consent link
                </Checkbox>
                {ampTagCode()}
                {persistentLinkValue && (
                  <>
                    <p>
                      {'Persistent consent link styles. Please paste this code inside the head tag of your page:'}
                    </p>
                    {ampStylesCode()}
                  </>
                )}
              </section>
            </>
          )}
      </section>
    );

  return (
    <Modal
      open={isOpen}
      title={
        (
          <header
            className={Styles.modalHeader}
          >
            <h3>{`Your ${specificationLabel} Tag`}</h3>
            <Button
              data-testid={`sites_tag_instructions_from_${trigerLabel}_btn`}
              type="link"
              href={!isAmp
                ? TAG_INSTRUCTIONS_LINK
                : TAG_AMP_INSTRUCTIONS_LINK}
              target="_blank"
            >
              {!isAmp ? 'TAG INSTRUCTIONS' : 'AMP INSTRUCTIONS'}
            </Button>
          </header>
        )
      }
      className={Styles.modal}
      closable={false}
      onClose={handleClose}
      footer={footerBtns}
      onCancel={handleClose}
    >
      {content}
    </Modal>
  );
};

ModalTag.defaultProps = { isSiteSpecific: false, siteUrl: '', isAmp: false, ampConfig: '', loading: false };

export default ModalTag;
