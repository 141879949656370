import { faChevronDown, faChevronRight } from '@fortawesome/fontawesome-pro-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

function renderExpandIcon() {
  return function expandIcon({ expandable, expanded, onExpand, record }) {
    const iconPrefix = 'ant-table-custom-row-expand-icon';

    return (
      <button
        className={classNames(iconPrefix, { [`${iconPrefix}-spaced`]: !expandable })}
        onClick={(e) => {
          onExpand(record, e);
          e.stopPropagation();
        }}
        type="button"
      >
        {expandable && expanded ? <FontAwesomeIcon icon={faChevronDown} /> : null}
        {expandable && !expanded ? <FontAwesomeIcon icon={faChevronRight} /> : null}
      </button>
    );
  };
}

export default renderExpandIcon;
