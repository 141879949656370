// @flow
import React, { useState, useEffect } from 'react';
import { faMinus, faPlus } from '@fortawesome/fontawesome-pro-solid';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './custom-css.module.scss';

type Props = {
  value: string;
  placeholder?: string;
  required?: boolean;
  validations: Function;
}

const CustomCss = ({
  value,
  placeholder,
  required,
  validations,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const { TextArea } = Input;

  useEffect(() => {
    if (value) {
      setVisible(true);
    }
  }, []);

  const toggleVisible = () => setVisible(prevVisible => !prevVisible);

  return (
    <div className={Styles.wrapper}>
      <button type="button" onClick={toggleVisible} className={Styles.btn}>
        <span>
          {
            visible
              ? <FontAwesomeIcon icon={faMinus} size="xs" />
              : <FontAwesomeIcon icon={faPlus} size="xs" />
          }
        </span>
        <span>Advanced: CSS Overrides</span>
      </button>
      {visible && (
        <Form.Item
          name="uxCustomizedCss"
          label="css overrides"
          required={required}
          rules={validations()}
        >
          <TextArea
            style={{ minHeight: '100px', maxHeight: '200px', resize: 'vertical' }}
            placeholder={placeholder}
          />
        </Form.Item>
      )}
    </div>
  );
};

CustomCss.defaultProps = {
  placeholder: '',
  required: false
};

export default CustomCss;
