// @flow
import React, { useState, useEffect } from 'react';
import Checkbox from 'antd/es/checkbox';
import Form from 'antd/es/form';
import Radio from 'antd/es/radio';
import Space from 'antd/es/space';
import { getLegitimateConsentIds } from '../../../../../../../utils/vendors';
import Styles from '../../purposesV2.module.scss';
import {
  PURPOSES_INFO,
  SPECIAL_FEATURES_INFO,
  SPECIAL_PURPOSES_INFO,
  FEATURES_INFO
} from '../../../../../../../utils/constants';

type Props = {
  purposeLegitimateIds: Array<number>;
  purposeIds: Array<number>;
  specialFeatures: Array<number>;
  publisherCC: String;
  specialPurposes: Array;
  features: Array;
  setFieldsValue: Function;
  setValues: Function;
  setDataTestId: Function;
  vendorsData: Array<Record<String, any>>;
}

function PurposeList({
  setValues,
  purposeIds,
  purposeLegitimateIds,
  specialFeatures,
  publisherCC,
  specialPurposes,
  features,
  setFieldsValue,
  setDataTestId,
  vendorsData,
}: Props) {
  const {
    specialFeatures: specialFeaturesData,
    specialPurposes: specialPurposesData,
    purposes: purposesList,
    features: featuresList
  } = vendorsData;
  const [legitimeValidPurposes, setLegitimeValidPurposes] = useState([]);
  const [legitimatePurposes, setLegitimatePurposes] = useState([]);
  const [purposesConsent, setpurposesConsent] = useState([]);
  const [specialFeatureConsent, setSpecialFeature] = useState([]);
  const [specialPurposesConsent, setSpecialPurposes] = useState([]);
  const [featuresConsent, setFeatures] = useState([]);
  const mergedPurposeIds = purposeIds.concat(purposeLegitimateIds).sort();

  useEffect(() => {
    setLegitimatePurposes(purposeLegitimateIds);
    setSpecialFeature(specialFeatures);
    setSpecialPurposes(specialPurposes);
    setFeatures(features);
    const purposes = [...mergedPurposeIds];
    if (!purposes.length && publisherCC !== 'DE') {
      purposes.push(1);
      setpurposesConsent(purposes);
    } else {
      setpurposesConsent(purposes);
    }
    setFieldsValue({ purposeIdsV2: purposes, features, specialFeatures, specialPurposes });
    purposes.forEach(p => setFieldsValue({ [`legitime.${p}`]: purposeLegitimateIds.includes(p) }));
  }, []);

  useEffect(() => {
    const filteredVendors = getLegitimateConsentIds(vendorsData);

    setLegitimeValidPurposes(filteredVendors.vendorPurposeLegitimateInterestIds);
  }, [vendorsData]);

  useEffect(() => {
    setValues(preValues => ({ ...preValues, purposeIdsV2: purposesConsent }));
  }, [purposesConsent]);

  useEffect(() => {
    setValues(preValues => ({ ...preValues, specialFeatures: specialFeatureConsent }));
  }, [specialFeatureConsent]);

  useEffect(() => {
    setValues(preValues => ({ ...preValues, specialPurposes: specialPurposesConsent }));
  }, [specialPurposesConsent]);

  useEffect(() => {
    setValues(preValues => ({
      ...preValues,
      purposeLegitimateIdsV2: legitimatePurposes,
    }));
  }, [legitimatePurposes]);

  useEffect(() => {
    setValues(preValues => ({ ...preValues, features: featuresConsent }));
  }, [featuresConsent]);

  const onChangeLegitime = (e, id) => {
    let newLegitimate = [...legitimatePurposes];
    if (e.target.value && !newLegitimate.includes(id)) {
      newLegitimate.push(id);
    } else {
      newLegitimate = newLegitimate.filter(legitimeId => legitimeId !== id);
    }
    setLegitimatePurposes(newLegitimate);
  };

  const getValueLegitimate = id => legitimatePurposes.includes(id);

  return (
    <section className={Styles.purposeSection}>
      <h5>SELECT ALL PURPOSES THAT APPLY TO YOUR DATA USAGE NEEDS</h5>
      <p>Purposes</p>
      <Form.Item
        noStyle
        name="purposeLegitimateIdsV2"
      >
        <Form.Item
          noStyle
          name="purposeIdsV2"
        >
          <Checkbox.Group
            onChange={purposes => setpurposesConsent(purposes)}
            data-testid={setDataTestId('purposeIdsV2')}
          >
            <Space direction="vertical">
              {(purposesList || PURPOSES_INFO).map((purpose) => {
                if (purpose.id === 1 && publisherCC !== 'DE') {
                  return (
                    <React.Fragment key={`${purpose.id}`}>
                      <Checkbox
                        disabled
                        value={purpose.id}
                        noStyle
                      >
                        {purpose.name}
                      </Checkbox>
                      {((purposesConsent.includes(purpose.id)
                        && legitimeValidPurposes.includes(purpose.id))
                        || purpose.id === 1) && (
                        <Form.Item
                          noStyle
                        >
                          <Radio.Group
                            defaultValue={false}
                            className={Styles.consentsRadios}
                            value={getValueLegitimate(purpose.id)}
                            onChange={e => onChangeLegitime(e, purpose.id)}
                            data-testid={setDataTestId(`legitime.${purpose.id}`)}
                          >
                            <Radio
                              value={false}
                              disabled
                            >
                              Consent
                            </Radio>
                            <Radio
                              value
                              disabled
                            >
                              Legitimate Interest
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      )}
                    </React.Fragment>
                  );
                }
                if (purpose.id === 1) {
                  return (
                    <React.Fragment key={`${purpose.id}`}>
                      <Checkbox
                        value={purpose.id}
                        noStyle
                      >
                        {purpose.name}
                      </Checkbox>
                      {purposesConsent.includes(purpose.id) && legitimeValidPurposes.includes(purpose.id) && (
                        <Form.Item
                          noStyle
                        >
                          <Radio.Group
                            defaultValue={false}
                            className={Styles.consentsRadios}
                            value={getValueLegitimate(purpose.id)}
                            onChange={e => onChangeLegitime(e, purpose.id)}
                            data-testid={setDataTestId(`legitime.${purpose.id}`)}
                          >
                            <Radio value={false}>
                              Consent
                            </Radio>
                            <Radio
                              value
                              disabled
                            >
                              Legitimate Interest
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      )}
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={`${purpose.id}`}>
                    <Checkbox
                      value={purpose.id}
                      noStyle
                    >
                      {purpose.name}
                    </Checkbox>
                    {purposesConsent.includes(purpose.id) && legitimeValidPurposes.includes(purpose.id) && (
                      <Form.Item
                        noStyle
                      >
                        <Radio.Group
                          defaultValue={false}
                          className={Styles.consentsRadios}
                          onChange={e => onChangeLegitime(e, purpose.id)}
                          value={getValueLegitimate(purpose.id)}
                          data-testid={setDataTestId(`legitime.${purpose.id}`)}
                        >
                          <Radio
                            value={false}
                          >
                            Consent
                          </Radio>
                          <Radio
                            value
                          >
                            Legitimate Interest
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    )}
                  </React.Fragment>
                );
              })}
            </Space>
          </Checkbox.Group>
        </Form.Item>
      </Form.Item>

      <p>Special features</p>
      <Form.Item
        noStyle
        name="specialFeatures"
      >
        <Checkbox.Group
          onChange={purposes => setSpecialFeature(purposes)}
          data-testid={setDataTestId('specialFeatures')}
        >
          {(specialFeaturesData || SPECIAL_FEATURES_INFO).map(specialFeature => (

            <Checkbox
              key={`special-feature-${specialFeature.id}`}
              value={specialFeature.id}
            >
              {specialFeature.name}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>

      <p>Special purposes</p>
      <Form.Item
        noStyle
        name="specialPurposes"
        data-testid={setDataTestId('specialPurposes')}
      >
        <Checkbox.Group
          onChange={purposes => setSpecialPurposes(purposes)}
        >
          {(specialPurposesData || SPECIAL_PURPOSES_INFO).map(specialPurpose => (

            <Checkbox
              key={`special-purpose-${specialPurpose.id}`}
              value={specialPurpose.id}
            >
              {specialPurpose.name}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>

      <p>Features</p>
      <Form.Item
        noStyle
        name="features"
      >
        <Checkbox.Group
          onChange={featuresIds => setFeatures(featuresIds)}
          data-testid={setDataTestId('features')}
        >
          {(featuresList || FEATURES_INFO).map(feature => (

            <Checkbox
              key={`feature-${feature.id}`}
              value={feature.id}
            >
              {feature.name}
            </Checkbox>

          ))}
        </Checkbox.Group>
      </Form.Item>
    </section>
  );
}

export default PurposeList;
