// @flow
import React from 'react';
import Empty from 'antd/es/empty';


type ComponentProps = {
  description?: string,
  style?: Object,
};

function NoContent({ description, style }: ComponentProps): any {
  return (
    <Empty
      description={description}
      imageStyle={style}
    />
  );
}

NoContent.defaultProps = {
  description: '',
  style: {},
};

export default NoContent;
