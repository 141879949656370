const TEXT = {
  GBC_ENABLED_LABEL: 'Enable GOOGLE basic consent',
  GBC_ENABLED_LABEL_APP: 'Enable Google Basic consent',
  GBC_ENABLED_SUB_LABEL: `Google Basic Consent allows you to take consent for using
  Google Services to track and target your users`,
  MODAL_TITLE: 'Please select the purposes for your sensitive data processing:',
  LOCATION_LABEL: 'Which users should be asked for consent?',
  ALERT: 'Please select at least one purpose',
  DEFAULT_VALUE_LABEL: 'Set a Default Value',
  EDIT: 'Edit purposes',
  GBC_ALERT: 'GBC functionality is accessible in versions 52 or higher.',
  ADDITIONAL_SETTINGS: 'Additional Settings',
  URL_PASS_THROUGH: 'Enable URL passthrough',
  ADS_DATA_REDACTION: 'Enable Data Redaction'
};

export default TEXT;
