// @flow
import React from 'react';
import Form from 'antd/es/form';
import Checkbox from 'antd/es/checkbox';
import Tooltip from 'antd/es/tooltip';
import Card from '../card';
import InfoIconTooltip from '../infoIconTooltip';
import SITETOOLTIPS from '../../../utils/toolTipsMessages';

const {
  CardHeader,
  CardContent
} = Card;

type Props = {
  pcode: string
}

function GooglePartners({ pcode }: Props) {
  return (
    <Card className="google-vendor-container">
      <CardHeader title="Google Vendors">
        <span className="regulation">GDPR</span>
      </CardHeader>
      <CardContent>
        <div style={{ display: 'flex', marginBottom: 0 }}>
          <Form.Item
            name="googleEnabled"
            valuePropName="checked"
          >
            <Checkbox
              data-testid="site-googleEnabled-input"
            >
              Enable Google Vendors
            </Checkbox>
          </Form.Item>
          <Tooltip
            title={SITETOOLTIPS.GOOGLE_TOOLTIP(pcode)}
            placement="top"
          >
            <span><InfoIconTooltip style={{ marginTop: '9' }} /></span>
          </Tooltip>

        </div>
      </CardContent>
    </Card>
  );
}

export default GooglePartners;
