import React from 'react';
import Loader from '../ui/loader';
import Unauthorized from '../ui/unauthorized';

const withPcode = Component => (props) => {
  const { app: { pCode, loading } } = props;
  let content = <Loader />;

  if (!loading) {
    if (pCode) {
      content = <Component {...props} />;
    } else {
      content = <Unauthorized />;
    }
  }
  return content;
};

export default withPcode;
