// @flow
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimes,
  faTimesCircle
} from '@fortawesome/fontawesome-pro-light';
import {
  faCheckCircle as faCheckCircleSolid,
  faExclamationCircle as faExclamationCircleSolid,
  faInfoCircle as faInfoCircleSolid,
  faTimesCircle as faTimesCircleSolid
} from '@fortawesome/fontawesome-pro-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AntAlert, { AlertProps } from 'antd/es/alert';
import React from 'react';

import './styles.scss';

const iconMapFilled = {
  error: faTimesCircleSolid,
  info: faInfoCircleSolid,
  success: faCheckCircleSolid,
  warning: faExclamationCircleSolid,
};

const iconMapOutlined = {
  error: faTimesCircle,
  info: faInfoCircle,
  success: faCheckCircle,
  warning: faExclamationCircle,
};

const Alert = ({
  closable = false,
  description,
  icon,
  prefixCls,
  showIcon,
  type,
  ...rest
}: AlertProps) => {
  const getIcon = () => {
    if (showIcon && icon) {
      return icon;
    }
    // use outline icon in alert with description
    if (showIcon && type) {
      const typeIcon = (description ? iconMapOutlined : iconMapFilled)[type] || null;
      return <FontAwesomeIcon icon={typeIcon} />;
    }
    return null;
  };

  return (
    <AntAlert
      closable={closable}
      closeText={closable ? <FontAwesomeIcon icon={faTimes} /> : null}
      description={description}
      icon={getIcon()}
      showIcon={showIcon}
      type={type}
      {...rest}
    />
  );
};

export default Alert;
