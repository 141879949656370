import { Col, Row } from 'antd/es/grid';
import { GENERAL_FORM_ERROR, GVL_VERSION_3, XSS_ERROR_TEXT } from '../../../utils/constants';
import React, { useCallback, useEffect, useState } from 'react';

import Alert from '../../ui/alert';
import Button from 'antd/es/button';
import Tag from 'antd/es/tag';
import { TabsProps } from 'antd/es/tabs';
import Tabs from 'antd/es/tabs';
import ConfigureTheme from './components/configure-theme';
import ContentHeader from '../../ui/content-header';
import CustomizeCCPA from '../../../pages/apps/components/customize-ccpa';
import CustomizePersistentConsent from './components/customize-consent';
import CustomizeUI from './components/customize-ui';
import Form from 'antd/es/form';
import Preview from '../../ui/preview';
import PurposesV2 from './components/purposes-v2';
import SITETOOLTIPS from '../../../utils/toolTipsMessages';
import Space from 'antd/es/space';
import Styles from './themesForm.module.scss';
import { autoDetectSupportedLanguage } from '../../../utils/language';
import { formatCustomLinks } from '../../../utils/themes';
import validate from './syncValidation';
import { validateUserInput } from '../../../utils/sites';
import Basic from './components/basic-configuration';
import AdvancedConfiguration from './components/advanced-configuration';
import { filterPurposesTCFVersion, getFormInitData } from './utils';
import ConsentOrPayConfig from './components/consent-or-pay';

type Props = {
  mode: string,
  stacks: Object,
  themes: Object,
  app: Object,
  location: Object,
  createTheme: Function,
  editTheme: Function,
  idParams: string,
  gvlData: Object,
  updateTCFVersion: Function,
  propertyType: string,
};

const ThemesForm = ({
  mode,
  stacks,
  themes,
  app,
  location,
  createTheme,
  editTheme,
  idParams,
  gvlData,
  updateTCFVersion,
  propertyType,
}: Props) => {
  const [form] = Form.useForm();
  const [hasChangedValues, setHasChangedValues] = useState(false);
  const { resetFields, validateFields, setFieldsValue, getFieldsValue, getFieldValue } = form;
  const { accountId, pCode, isPremium } = app;
  const { current } = themes;
  const [showPreview, setShowPreview] = useState(false);
  const [generalFormError, setGeneralError] = useState(false);
  const [generalErrorMessage, setGeneralErrorMessage] = useState(GENERAL_FORM_ERROR);
  const [values, setValues] = useState({});
  const [tabId, setTabId] = useState('basic');
  const validations = validate();

  const formInitData = getFormInitData(current, values, accountId, pCode);

  const onValuesChange = useCallback(() => {
    if (!hasChangedValues) {
      setHasChangedValues(true);
    }
  }, [hasChangedValues]);

  useEffect(() => {
    const { formError } = themes;
    if (formError && formError.message) {
      setGeneralError(true);
      /* Removed toLowerCase() so the first letter of the error messages is in Upercase, need to review error messages
      after US https://jira.quantcast.com/browse/GDPRC-4661 is finished */
      setGeneralErrorMessage(formError.message);
    }
  }, [themes.formError]);

  useEffect(() => {
    if (!hasChangedValues && values.uspDnsText && current.uspDnsText && current.uspDnsText.length !== values.uspDnsText.length) {
      setHasChangedValues(true);
    }
  }, [values.uspDnsText]);

  const getScreenCustomLinks = valuesToSend => {
    const uxInitialScreenLinks = [];
    if (valuesToSend.uxInitialScreenCustomLinksText0) {
      uxInitialScreenLinks.push({
        text: valuesToSend.uxInitialScreenCustomLinksText0,
        url: valuesToSend.uxInitialScreenCustomLinksUrl0,
      });
    }
    if (valuesToSend.uxInitialScreenCustomLinksText1) {
      uxInitialScreenLinks.push({
        text: valuesToSend.uxInitialScreenCustomLinksText1,
        url: valuesToSend.uxInitialScreenCustomLinksUrl1,
      });
    }
    return uxInitialScreenLinks;
  };

  const handleSubmitCustomLinks = (valuesToSend) => {
    const links = [];
    const {
      uxInitialScreenCustomLinksText0,
      uxInitialScreenCustomLinksUrl0,
      uxInitialScreenCustomLinksText1,
      uxInitialScreenCustomLinksUrl1,
      uxInitialScreenCustomLinksText2,
      uxInitialScreenCustomLinksUrl2
    } = valuesToSend;

    if (uxInitialScreenCustomLinksText0 && uxInitialScreenCustomLinksUrl0) {
      links.push({
        text: uxInitialScreenCustomLinksText0,
        url: uxInitialScreenCustomLinksUrl0
      });
    }
    if (uxInitialScreenCustomLinksText1 && uxInitialScreenCustomLinksUrl1) {
      links.push({
        text: uxInitialScreenCustomLinksText1,
        url: uxInitialScreenCustomLinksUrl1
      });
    }
    if (uxInitialScreenCustomLinksText2 && uxInitialScreenCustomLinksUrl2) {
      links.push({
        text: uxInitialScreenCustomLinksText2,
        url: uxInitialScreenCustomLinksUrl2
      });
    }
    return links;
  };


  const handleSubmit = e => {
    e.preventDefault();

    form
      .validateFields()
      .then(formValues => {
        const params = new URLSearchParams(location.search);
        const valuesToSend = {
          ...values,
          ...formValues,
        };

        const links = handleSubmitCustomLinks(valuesToSend);
        valuesToSend.uxInitialScreenCustomLinks = links;

        delete valuesToSend.uxInitialScreenCustomLinksText0;
        delete valuesToSend.uxInitialScreenCustomLinksUrl0;
        delete valuesToSend.uxInitialScreenCustomLinksText1;
        delete valuesToSend.uxInitialScreenCustomLinksUrl1;
        delete valuesToSend.uxInitialScreenCustomLinksText2;
        delete valuesToSend.uxInitialScreenCustomLinksUrl2;

        Object.keys(valuesToSend).forEach(key => {
          if (valuesToSend[key] === null) {
            delete valuesToSend[key];
          }
        });

        if(valuesToSend.advancedCustomizationSettings) {
          valuesToSend.advancedCustomizationSettings = valuesToSend.advancedCustomizationSettings.filter((item) => Boolean(item));
        }

        if (stacks.list.length && valuesToSend.use === 'stacks') {
          if (!valuesToSend.userSelectedStack) {
            valuesToSend.stacks = stacks.list[0].stacks;
          }
        }

        if ('publisherConsentRestrictionIds' in valuesToSend && !valuesToSend.publisherConsentRestrictionIds.length) {
          delete valuesToSend.publisherConsentRestrictionIds;
        }

        if ('publisherLIRestrictionIds' in valuesToSend && !valuesToSend.publisherLIRestrictionIds.length) {
          delete valuesToSend.publisherLIRestrictionIds;
        }

        // Validate html input for XSS
        const areValidInput = validateUserInput({ uspDnsText: valuesToSend.uspDnsText });
        if (!areValidInput) {
          throw new Error('XSS_ERROR');
        }

        if (mode === 'Create') {
          delete valuesToSend.v2HasCCPA;
          delete valuesToSend.v2HasGDPR;
          delete valuesToSend.userSelectedStack;
          delete valuesToSend.use;
          createTheme({ ...valuesToSend }, params.get('from-site'));
        } else {
          valuesToSend.privacyModes = [];

          if (valuesToSend.v2HasGDPR) {
            valuesToSend.privacyModes.push('GDPR');
          }

          if (valuesToSend.v2HasCCPA) {
            valuesToSend.privacyModes.push('USP');
          }

          if (valuesToSend.use === 'purposes') {
            valuesToSend.stacks = [];
          }

          if ('vendorFlexiblePurposesIds' in valuesToSend) {
            valuesToSend.vendorFlexiblePurposesIds.forEach(item => {
              delete valuesToSend[`CONSENT_${item}`];
              delete valuesToSend[`LI_${item}`];
            });
          }

          delete valuesToSend.vendorFlexiblePurposesIds;
          delete valuesToSend.v2HasCCPA;
          delete valuesToSend.v2HasGDPR;
          delete valuesToSend.userSelectedStack;
          delete valuesToSend.use;
          editTheme(idParams, valuesToSend);
        }
      })
      .catch(err => {
        console.error('handleSubmit', err);
        setGeneralError(true);
        if (err.message === 'XSS_ERROR') {
          setGeneralErrorMessage(XSS_ERROR_TEXT);
        } else {
          setGeneralErrorMessage(GENERAL_FORM_ERROR);
        }
      });
  };

  useEffect(() => {
    const filteredData = filterPurposesTCFVersion(gvlData);

    setValues({ ...formInitData, ...filteredData, themeType: propertyType.toUpperCase() });
  }, []);

  useEffect(() => {
    const filterPurposes = filterPurposesTCFVersion(gvlData);
    setValues(preValues => ({
      ...preValues,
      ...filterPurposes,
      gvlVersion: gvlData.gvlSpecificationVersion,
    }));
  }, [gvlData.gvlSpecificationVersion]);

  const changeRegulations = regulations => {
    setValues(preValues => ({
      ...preValues,
      v2HasGDPR: regulations.includes('GDPR'),
      v2HasCCPA: regulations.includes('USP'),
    }));
    if(!regulations.includes('GDPR')) {
      setTabId('basic');
    }
  };

  const togglePreview = () => {
    setShowPreview(prevState => !prevState);
  };

  const actionButtons = () => {
    const buttons = [
      propertyType === 'site' &&
      {
        component: Button,
        props: {
          type: 'primary',
          'data-test': 'themes_form_show_preview',
          onClick: togglePreview,
        },
        children: ['PREVIEW GDPR MODE'],
      },
      {
        component: Button,
        props: {
          type: 'primary',
          'data-test': '',
          disabled: !hasChangedValues || !(form && !('syncErrors' in form)),
          onClick: handleSubmit,
        },
        children: ['SAVE THEME'],
      },
    ];
    return [
      {
        component: Space,
        children: buttons,
      },
    ];
  };

  const renderHeader = () => (
    <ContentHeader
      title={`${mode} a theme`}
      toolTipTitle={SITETOOLTIPS.createEditTheme(location && location.pathname)}
      backBtn={{ goTo: `p-${pCode}/themes`, text: 'Back to themes' }}
      actions={actionButtons()}
    />
  );

  const getPreviewValues = () => {
    const valuesToShow = {
      ...values,
      ...getFieldsValue(),
    };
    valuesToShow.uxInitialScreenCustomLinks = getScreenCustomLinks(valuesToShow);
    return valuesToShow;
  };

  const handleTabChange = key => {
    setTabId(key);
  };

  const getTabsItems = hasGDPR => {
    const tabs = [
      {
        key: 'basic',
        label: 'Basic',
        children: (
          <Basic
            values={values}
            form={form}
            setValues={setValues}
            formInitData={formInitData}
            current={current}
            onValuesChange={onValuesChange}
            gvlData={gvlData}
            updateTCFVersion={updateTCFVersion}
            propertyType={propertyType}
            pCode={pCode}
          />
        ),
      },
    ];

    if (hasGDPR) {
      tabs.push(
        {
          key: 'advanced',
          label: (
            <Space>
              <p>Advanced Customisation</p>
              <Tag color="blue">NEW</Tag>
            </Space>
          ),
          children: (
            <AdvancedConfiguration
              validations={validations}
              getFieldValue={getFieldValue}
              setFieldsValue={setFieldsValue}
              onValuesChange={onValuesChange}
            />
          ),
        },
        {
          key: 'consentOrPay',
          label: (
            <Space>
              <p>Consent or Pay Settings</p>
              <Tag color="blue">NEW</Tag>
            </Space>
          ),
          children: (
            <ConsentOrPayConfig
              validations={validations}
              getFieldValue={getFieldValue}
              setFieldsValue={setFieldsValue}
              onValuesChange={onValuesChange}
              validations={validations}
            />
          ),
        },
      );
    }
    return tabs;
  };
  const tabItems: TabsProps = getTabsItems(values.v2HasGDPR);

  return (
    <>
      {showPreview && <Preview closeModal={togglePreview} formValues={getPreviewValues()} />}
      <div className={Styles.header}>{renderHeader()}</div>
      <div className={Styles.container}>
        <section className={Styles.content}>
          <Row gutter={[16, 16]}>
            <Col span="24">{generalFormError && <Alert type="error" message={generalErrorMessage} closable />}</Col>
            <Col span="24">
              <Form
                name="themes-control-ref"
                form={form}
                className={Styles.cardsWrapper}
                layout="vertical"
                initialValues={{ ...formInitData }}
                onValuesChange={onValuesChange}
              >
                <ConfigureTheme
                  isPremium={isPremium}
                  mode={mode}
                  validations={validations}
                  changeRegulations={changeRegulations}
                />

                <Tabs
                  size="small"
                  onTabClick={handleTabChange}
                  defaultActiveKey={tabId}
                  className={Styles.tabs}
                  items={tabItems}
                  activeKey={tabId}
                />
              </Form>
            </Col>
          </Row>
        </section>
        <aside className={Styles.aside} />
      </div>
    </>
  );
};

export default ThemesForm;
