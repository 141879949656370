// @flow
import { faSpinnerThird } from '@fortawesome/fontawesome-pro-light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AntSpin, { SpinProps } from 'antd/es/spin';
import React from 'react';
import './index.scss';

const Spin = (props: SpinProps) => (
  <AntSpin indicator={<FontAwesomeIcon icon={faSpinnerThird} />} {...props} />
);

export default Spin;
