export const consentOrPayOptions = [
  {
    label: 'Yes',
    value: true,
    key: 'YES'
  },
  {
    label: 'No',
    value: false,
    key: 'NO'
  }
];

export const callbackOptions = [
  {
    label: 'Specify re-direction URLs for user actions',
    value: 'publisher'
  },
  {
    label: "Use InMobi's consent APIs to redirect user to relevant page",
    value: 'self'
  }
];
