// @flow
import React, { useState, useEffect } from 'react';
import AntTable from 'antd/es/table';
import Styles from './table.module.scss';

type Props = {
  data: Array<Object>;
  headers: Object;
  options?: {
    maxWidth?: Number;
    alignment: {
      actions: string;
    };
  };
  paginateEvery?: number;
  isSites: boolean;
  widths?: Object;
  size?: string;
  fetchAnalyticsData?: Function;
}

const Table = ({
  data,
  headers,
  paginateEvery,
  isSites,
  widths,
  size,
  fetchAnalyticsData
}: Props) => {
  const [pages, setPages] = useState(data);
  const [pageSize, setPageSize] = useState(paginateEvery);
  const [currentPage, setCurrentPage] = useState(1);

  
  const handleAnalyticsPaginationChange = (page, pageSize) => {
    const startIdx = (page - 1) * pageSize;
    const endIdx = startIdx + pageSize;
    const pageData = pages.slice(startIdx, endIdx);
    if (fetchAnalyticsData) {
      fetchAnalyticsData(pageData.map(el => el.siteId));
    }
  }
  
  useEffect(() => {
    setPages(data);
  }, [data]);

  useEffect(() => {
    if (data.length > 0) {
      setCurrentPage(1);
      handleAnalyticsPaginationChange(currentPage, pageSize);
    }
  }, []);

  const getData = () => pages.map((site) => {
    const row = Object.keys(headers).reduce((accu, header) => (
      {
        ...accu,
        [header]: site[header]
      }
    ), {});
    return {
      ...row,
      key: site.id
    };
  });

  const getPagination = () => (pages.length > pageSize ? ['bottomCenter'] : ['none']);

  const paginationHandler = (_, noOfRows) => setPageSize(noOfRows);

  const getHeaders = () => Object.keys(headers)
    .map((header) => {
      const headerObj = {
        title: typeof headers[header] === 'string' ? headers[header].toUpperCase() : headers[header],
        dataIndex: header,
        key: headers[header],
      };
      if (widths[header]) {
        headerObj.width = widths[header];
      }
      return headerObj;
    });

  return (
      <AntTable
    columns={getHeaders()}
    pagination={{
      pageSize,
      position: getPagination(),
      onShowSizeChange: paginationHandler,
      onChange: (page, newPageSize) => {
        setCurrentPage(page);
        setPageSize(newPageSize);
        handleAnalyticsPaginationChange(page, newPageSize);
      },
      hideOnSinglePage: true,
      }}
    dataSource={getData()}
    className={`${Styles.table} ${isSites && Styles.isSites}`}
    size={size}
    />
  );
};

Table.defaultProps = {
  options: { alignment: { actions: 'right' } },
  paginateEvery: 20,
  widths: {},
  size: 'middle'
};

export default Table;
