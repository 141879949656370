import { Amplify } from "aws-amplify";
import SecureTokenStorage from './utils/secureTokenStorage';

Amplify.configure({
    Auth: {
      region: "eu-central-1",
      userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
      authenticationFlowType: 'USER_SRP_AUTH',
      signUpVerificationMethod: 'code',
      storage: process.env.REACT_APP_AUTH_SECURE_TOKEN_STORE_URL ? SecureTokenStorage : undefined,
      oauth: {
        domain: 'choicelogin-test.auth.eu-central-1.amazoncognito.com',
        scope: ['email', 'openid', 'profile'],
        responseType: 'token',
        redirectSignIn: 'http://localhost'
      }
    }
 });
