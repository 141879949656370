import React from 'react';
import Button from 'antd/es/button';
import Result from './result';

function Unauthorized() {
  return (
    <Result
      status="403"
      title="Restricted access"
      subTitle={
        `This page requires additional user permissions. Please
        contact the administrators on your account for access.`
      }
      extra={
        <Button href="/protect" type="primary">Back Home</Button>
      }
    />
  );
}

export default Unauthorized;
