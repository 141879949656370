// @flow
import React from 'react';
import classNames from 'classnames';
import styles from './content-toolbar.module.scss';

type ContentToolbarProps = {
  children: React.ElementType | Array<React.ElementType>,
};

type ContentToolbarColumnProps = {
  align?: 'left' | 'right',
  children: React.ElementType,
  flex?: string,
}

/**
 * @param {left | right} align - Optional: Text align direction
 * @param {ReactElement} children - Required: React element to be render
 * @param {CSSFlexProp} flex - Optional: CSS Flex Property
 * @returns {ReactElement}
 * @constructor
 */
function ColumnComponent({ align, children, flex }: ContentToolbarColumnProps) {
  return (
    <div className={classNames(styles.column, styles[align])} style={{ flex }}>
      {children}
    </div>
  );
}

ColumnComponent.defaultProps = {
  align: 'left',
  flex: '1 0 auto'
};

const ContentToolbar = ({ children }: ContentToolbarProps) => (
  <div className={styles.wrapper}>
    {children}
  </div>
);

ContentToolbar.Column = ColumnComponent;

export default ContentToolbar;
