// @flow
import React from 'react';
import PageHeader from 'antd/es/page-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/fontawesome-pro-light';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import uid from 'uid';
import Tooltip from 'antd/es/tooltip';

import styles from './content-header.module.scss';
import InfoIconTooltip from '../infoIconTooltip';
import logoImage from '../../../assets/images/ChoiceLogo.svg';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';

type ActionProps = {
  component: any,
  props?: { dataTest: string, },
  children?: any
}

type ComponentProps = {
  actions?: Array<ActionProps> | [],
  backBtn?: { goTo: string, text: string } | {},
  className?: string,
  description?: string | Array<any>,
  toolTipTitle?: string,
  title: string,
  showLogo?: boolean
}

/**
 * @param {Array<ActionProps>} actions - Optional: Array of actions to be render
 * @param {{ goTo: string, text: string }} backBtn - Optional: Use it to display a link above header title
 * @param {string} description - Optional: description content
 * @param {string} title - Required: Header title
 * @param {boolean} showLogo - Optional: Shows the Choice Logo
 * @returns {React.ElementType} - React Component
 * @constructor
 */
function ContentHeader({ actions, backBtn, className, description, title, showLogo, toolTipTitle }: ComponentProps) {
  const actionComponent = [];
  let backBtnComponent = null;
  const descriptionComponent = [];

  if ('goTo' in backBtn && 'text' in backBtn) {
    backBtnComponent = (
      <div className={styles.backContainer}>
        <FontAwesomeIcon size="xs" icon={faChevronLeft} style={{ marginRight: 5 }} />
        <Link to={`/protect/${backBtn.goTo}`}>{backBtn.text}</Link>
      </div>
    );
  }

  // Map description into react components
  if (Array.isArray(description)) {
    descriptionComponent.push(...description);
  } else {
    descriptionComponent.push(description);
  }
  // Map actions into react components
  if (actions.length) {
    actions.forEach((action) => {
      const childrenList = (action.children && action.children.length) ? action.children.map(
        (child) => {
          const { children } = child || {};
          if (typeof (child) === 'object') {
            return React.createElement(child.component, {
              ...child.props,
              key: uid()
            }, children && children.length === 1 ? children[0] : children);
          }
          return child;
        }
      ) : undefined;
      actionComponent.push(React.createElement(action.component, {
        ...action.props,
        key: uid()
      }, childrenList && childrenList.length === 1 ? childrenList[0] : childrenList));
    });
  }

  return (
    <PageHeader
      className={classNames(styles.wrapper, className)}
      title={[
        <div className={classNames(styles.column, styles.left)} key={uid()}>
          {showLogo ? (
            <Space split={<Divider style={{borderLeft: '1px solid rgba(255,255,255,0.6)', height: '1.9em', top: '0'}} type="vertical" />}>
              <img src={logoImage} width="130" height="49" />
              <div style={{color: 'var(--blue-1)', fontSize: '16px', fontWeight: '600'}}>Consent Management Platform (CMP)</div>
            </Space>
          ) : (
            <div className={styles.title}>
              {backBtnComponent}
              <h2>{title}</h2>
              {toolTipTitle && (
              <Tooltip
                title={toolTipTitle}
                placement="bottom"
              >
                <span><InfoIconTooltip style={{ marginBottom: '2' }} /></span>
              </Tooltip>
              )}
            </div>
          )}
        </div>
      ]}
      subTitle={descriptionComponent}
      extra={[
        actionComponent.length > 0 && (
          <div
            key={uid()}
            className={classNames(styles.column, styles.right)}
          >
            {actionComponent}
          </div>
        )
      ]}
      backIcon={false}
    />

  );
}

ContentHeader.defaultProps = {
  actions: [],
  backBtn: {},
  className: undefined,
  description: '',
  showLogo: false,
  toolTipTitle: ''
};

export default ContentHeader;
