import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PreviewActions from '../../../store/duck/preview';
import PreviewContainer from './previewContainer';

const MapStateToProps = ({ preview }) => ({ preview: preview.preview, fetching: preview.fetching });

const MapDispatchToProps = dispatch => ({ actions: { preview: bindActionCreators(PreviewActions, dispatch) } });

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(PreviewContainer);
