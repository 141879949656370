// @flow
import React, { useCallback, useEffect, useState } from "react";

import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Space from 'antd/es/space';
import uid from 'uid';
import Alert from '../../ui/alert';
import Consent from './components/consent';
import GeneralSettings from '../general_settings_site';
import GooglePartners from '../../ui/google-partners';
import ConsentScopeUSP from '../consent_scope_usp';
import { HEADER_REGULATION_TEXT } from '../../../utils/constants';
import PurposesV2 from './components/purposes-v2';
import Styles from './sitesForm.module.scss';
import type { iTheme } from '../../../interfaces/themes';
import MSPARegulationSettings from '../mspa_settings';
import { getIsNonPersonalisedAdPresent, isGPPSupported } from '../../../utils/property';
import PrivacyLink from '../../ui/privacy-link';

type Props = {
  onChanges: Function,
  initData: Object,
  themes: Array<iTheme>,
  sites: Array<any>,
  versionsV2: Array<string>,
  isNew: boolean,
  generalFormError: boolean,
  errorMessage: string,
  form: any,
  setValues: Function,
  pCode: string,
  vendors: Object
};
const SitesForm = ({
  onChanges,
  initData,
  themes,
  sites,
  versionsV2,
  isNew,
  generalFormError,
  errorMessage,
  form,
  setValues: setParentValues,
  pCode,
  vendors
}: Props) => {
  const [values, setValues] = useState(() => initData);
  const [consentError, setConsentError] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => setParentValues(values), [values]);

  const onValuesChange = useCallback(() => {
    if (!hasChanged) {
      onChanges(true);
      setHasChanged(true);
    }
  }, [hasChanged]);

  const handleInputChange = (input, value, newFields) => {
    const _handled = { ...newFields };
    if (value === undefined && input in _handled) {
      delete _handled[input];
    } else {
      _handled[input] = value;
    }
    return _handled;
  };

  const handleValuesChange = (inputs, vals) => {
    let newFields = { ...values };
    if (Array.isArray(inputs)) {
      inputs.forEach(input => {
        newFields = handleInputChange(input, vals[input], newFields);
      });
    } else {
      newFields = handleInputChange(inputs, vals, newFields);
    }
    setValues(prevValues => ({ ...prevValues, ...newFields }));
  };

  useEffect(() => {
    setValues(prevValues => ({
      ...prevValues,
      hasGDPR: initData.privacyModes.includes("GDPR"),
      hasCCPA: initData.privacyModes.includes("USP"),
      purposeLegitimateIdsV2: initData.purposeLegitimateIdsV2,
      consentScope: initData.consentScope === "global" && !initData.isDefaultConsent ? "service" : initData.consentScope
    }));
  }, []);

  const setDataTestId = input => `site-${input}-input`;

  const initDataRegulations = () => {
    let regulations = [];
    if (initData.privacyModes.includes("GDPR")) {
      regulations = [...regulations, "GDPR"];
    }
    if (initData.privacyModes.includes("USP")) {
      regulations = [...regulations, "USP"];
    }
    return regulations;
  };

  const initDataConsentConfiguration = () =>
    initData.purposeIdsV2 && initData.purposeIdsV2.length ? "customized" : "applyRecommended";

  const themesList = themes.map(({ themeId, themeName, gvlVersion }) => ({
    value: `${themeId}`,
    label: themeName,
    gvlVersion
  }));

  const themeSelected = themesList.find(theme => theme.value === values.themeId);
  const vendorsData = themeSelected && themeSelected.gvlVersion === 2 ? vendors.v2 : vendors.v3;

  const { data: { vendors: vendorsList, blockedVendors } } = vendorsData;

  const isNonPersonalisedAdPresent = getIsNonPersonalisedAdPresent(vendorsList, blockedVendors);

  return (
    <div>
      <Space direction="vertical" className={Styles.spaceForm}>
        {generalFormError && <Alert type="error" message={errorMessage} closable />}
        <Form
          name="control-ref"
          form={form}
          className={Styles.cardsWrapper}
          layout="vertical"
          initialValues={{
            ...initData,
            privacyModes: initDataRegulations(),
            radioGroup: initDataConsentConfiguration()
          }}
          onValuesChange={onValuesChange}
        >
          <GeneralSettings
            sites={sites}
            isNew={isNew}
            getFieldValue={form.getFieldValue}
            setFieldsValue={form.setFieldsValue}
            validateFields={form.validateFields}
            setValues={setValues}
            themes={themes}
            values={values}
            versionsV2={versionsV2}
            initData={initData}
            handleValuesChange={handleValuesChange}
            onValuesChange={onValuesChange}
            isNonPersonalisedAdPresent={isNonPersonalisedAdPresent}
          />
          {values.hasGDPR && (
            <Card
              title={[
                <span key={uid()}>Consent scope</span>,
                <span className={Styles.cardSubtitle} key={uid()}>
                  Who is being asked for consent, for which vendors
                </span>
              ]}
              extra={HEADER_REGULATION_TEXT.GDPR}
              className={Styles.consentCard}
            >
              <Consent
                validateFields={form.validateFields}
                getFieldValue={form.getFieldValue}
                setDataTestId={setDataTestId}
                consentError={consentError}
                cmpVersion={values.cmpVersion}
              />
            </Card>
          )}
          {values.hasGDPR && (
            <>
              <Card
                title={[
                  <span key={uid()}>Your consent configuration</span>,
                  <span className={Styles.cardSubtitle} key={uid()}>
                    Declare which legal basis you (the site host) operate under (require consent or claim legitimate
                    interest).
                  </span>
                ]}
                className={Styles.consentCard}
                extra={HEADER_REGULATION_TEXT.GDPR}
              >
                <PurposesV2
                  setFieldsValue={form.setFieldsValue}
                  validateFields={form.validateFields}
                  purposeIds={values.purposeIdsV2}
                  purposeLegitimateIds={values.purposeLegitimateIdsV2}
                  specialFeatures={values.specialFeatures}
                  specialPurposes={values.specialPurposes}
                  consentScope={values.consentScope}
                  setValues={setValues}
                  isDefaultConsent={values.isDefaultConsent}
                  stackIds={values.stackIds}
                  features={values.features}
                  initData={initData}
                  getFieldValue={form.getFieldValue}
                  setDataTestId={setDataTestId}
                  themeSelected={themeSelected}
                  pCode={pCode}
                  vendorsData={vendorsData}
                  cmpVersion={values.cmpVersion}
                />
              </Card>
              <GooglePartners pcode={pCode} />
              <div className={Styles.addTopMargin}>
                <PrivacyLink
                  gdprPrivacyLink={values.gdprPrivacyLink}
                  cmpVersion={values.cmpVersion}
                  onValuesChange={onValuesChange}
                  setFieldValue={form.setFieldValue}
                />
              </div>
            </>
          )}

          {values.hasCCPA && isGPPSupported(values.cmpVersion) && (
            <>
              <MSPARegulationSettings
                className={Styles.addTopMargin}
                setFieldsValue={form.setFieldsValue}
                getFieldValue={form.getFieldValue}
              />
              <ConsentScopeUSP
                className={Styles.addTopMargin}
                setFieldsValue={form.setFieldsValue}
                getFieldValue={form.getFieldValue}
                validateFields={form.validateFields}
              />
            </>
          )}
        </Form>
      </Space>
    </div>
  );
};

export default SitesForm;
