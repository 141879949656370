import { useQueryWrapper } from './common';
import { iSummaryPayload, iAnalyticsPayload } from '../../interfaces/analytics_v1';

import {
  getAnalyticsData,
  activateDeactivateJob,
  deleteReport,
  getAllReports,
  getAnalyticsMetaData,
  getReportsData,
  getSummaryData,
  saveAndRunJob,
  scheduleJob,
  getTrafficConsentTableData,
  getJobsData,
  getJobData,
  updateJob
} from "../services/analytics";
// @flow

const ANALYTICS_META_DATA = "analyticsMetaData";
const ANALYTICS_SUMMARY_DATA = "analyticsSummaryData";
const ANALYTICS_REPORT_DATA = "analyticsReportData";
const ANALYTICS_DATA = "analyticsData";
const ANALYTICS_SAVE_RUN_JOB = "analyticsSaveAndRunJob";
const ANALYTICS_DELETE_JOB = "analyticsDeleteJob";
const ANALYTICS_ACTIVE_DEACTIVATE_JOB = "analyticsActiveDeactivateJob";
const TRAFFIC_CONSENT_TABLE_DATA = "trafficConsentTableData";

export const useAnalyticsMetaData = () =>
  useQueryWrapper([ANALYTICS_META_DATA], getAnalyticsMetaData, {
    onError: errors => {
      console.log("Error ", errors);
    }
  });

export const useSummaryData = (queryPayload: iSummaryPayload) =>
  useQueryWrapper([ANALYTICS_SUMMARY_DATA, queryPayload], getSummaryData, {
    enabled: queryPayload.metricFields.length > 0,
    onError: errors => {
      console.log("Error ", errors);
    }
  });

export const useAnalyticsData = (queryPayload: iAnalyticsPayload) => useQueryWrapper(
  [ANALYTICS_DATA, queryPayload], getAnalyticsData, {
    onError: (errors) => {
      console.log('Error ', errors);
    }
  }
);

export const useTrafficConsentTableData = (queryPayload: iTableDataPayload) => useQueryWrapper(
  [TRAFFIC_CONSENT_TABLE_DATA, queryPayload], getTrafficConsentTableData, {
    enabled: queryPayload.enabled,
    onError: (errors) => {
      console.log('Error ', errors);
    }
  }
);
export const useGetReportsData = (pCode, type, reportName) => {
  return useQueryWrapper(
    [ANALYTICS_REPORT_DATA, pCode, type, reportName], getJobsData,
    {
      onError: errors => {
        console.log("Error ", errors);
      }
    }
  );
};

export const useGetAnalyticsData = (pCode, payload, enabled) => {
  return useQueryWrapper([ANALYTICS_DATA, pCode, payload], getReportsData, {
    onError: errors => {
      console.log("Error ", errors);
    },
    enabled
  });
};

export const useSaveAndRunJob = (pCode, payload, enabled) => {
  return useQueryWrapper([ANALYTICS_SAVE_RUN_JOB, pCode, payload], saveAndRunJob, {
    onError: errors => {
      console.log("Error ", errors);
    },
    enabled
  });
};

export const useUpdateJob = (pCode, payload, enabled, jobId) => {
  return useQueryWrapper([ANALYTICS_SAVE_RUN_JOB, pCode, payload, jobId], updateJob, {
    onError: errors => {
      console.log("Error ", errors);
    },
    enabled
  });
};

export const useScheduleJob = (pCode, payload, enabled) => {
  return useQueryWrapper([ANALYTICS_SAVE_RUN_JOB, pCode, payload], scheduleJob, {
    onError: errors => {
      console.log("Error ", errors);
    },
    enabled
  });
};

export const useDeleteReport = (pCode, jobId, enabled) => {
  return useQueryWrapper([ANALYTICS_DELETE_JOB, pCode, jobId], deleteReport, {
    onError: errors => {
      console.log("Error ", errors);
    },
    enabled
  });
};

export const useActiveDeactivateJob = (pCode, jobId, type, enabled) => {
  return useQueryWrapper([ANALYTICS_ACTIVE_DEACTIVATE_JOB, pCode, jobId, type], activateDeactivateJob, {
    onError: errors => {
      console.log("Error ", errors);
    },
    enabled
  });
};

export const useGetJobData = (pCode, jobId, enabled) => {
  return useQueryWrapper([ANALYTICS_REPORT_DATA, pCode, jobId], getJobData, {
    onError: errors => {
      console.log("Error ", errors);
    },
    enabled
  });
}
