// @flow
import React, { useState, useEffect } from 'react';
import Button from 'antd/es/button';
import Loader from '../loader';
import Modal from '../modal';
import { IFRAME_STYLES, IFRAME_SCRIPTS_V2, IFRAME_STUB_V2 } from './utils';
import Styles from './preview.module.scss';

type Props = {
  closeModal: Function;
  actions: Array<Object>;
  formValues: Object;
  preview: string;
  fetching: Boolean;
}

const PreviewContainer = ({
  closeModal,
  actions,
  formValues,
  preview,
  fetching
}: Props) => {
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    actions.preview.showPreview(formValues);
  }, []);

  useEffect(() => {
    if (Object.keys(preview).length) {
      if (!window.frames.__cmpPreview) {
        const iframe = document.createElement('iframe');
        const element = document.createElement('script');
        const customStyles = document.createElement('style');

        iframe.name = '__cmpPreview';
        iframe.setAttribute('width', '100%');
        iframe.setAttribute('height', '100%');

        element.type = 'text/javascript';

        element.textContent = `
          ${IFRAME_STUB_V2}
          ${preview}
          ${IFRAME_SCRIPTS_V2}
        `;

        element.id = 'cmp';

        customStyles.textContent = IFRAME_STYLES;

        document.getElementById('iframeContainer').appendChild(iframe);

        const iframeElement = window.frames.__cmpPreview.document.getElementsByTagName('head')[0];
        iframeElement.insertBefore(element, null);
        iframeElement.insertBefore(customStyles, null);

        setShowLoading(fetching);
      }
    }
  }, [preview]);

  const onCloseModal = () => {
    actions.preview.cleanPreview();
    closeModal();
  };

  const header = () => (
    <div className={Styles.previewHeaderContainer}>
      <h3>Preview Mode</h3>
      <Button
        type="primary"
        size="small"
        onClick={onCloseModal}
      >
        Close
      </Button>
    </div>
  );

  return (
    <Modal
      className={Styles.modalPreview}
      open
      title={header()}
      closable={false}
      width="100%"
      footer={null}
    >
      <div className={Styles.iframeContainer} id="iframeContainer">
        {preview === 'error' && (
          <div className={Styles.errorMessage}>
            There was an error showing the preview, please close and try again
          </div>
        )}
        {showLoading && (
          <Loader />
        )}
      </div>
    </Modal>
  );
};

export default PreviewContainer;
