import { defaultHexCodesV2 } from './constants';
import {
  FIELD_REQUIRED_TEXT,
  FIELD_URL_INVALID,
  URL_FIELD_REQUIRED,
  URL_TEXT_FIELD_REQUIRED,
} from '../../../utils/constants';

const validate = () => {
  const HEX_REGEX = /^#([\dA-F]{6}|[\dA-F]{3})$/i;
  const INVALID_HEX = 'Hex value not valid';
  const conflictingColor = (value1, value2) => (
    `Oops, you've selected the same ${value1} and ${value2} color.
    Please change one of the fields' color in order to proceed.`
  );
  const defaultHexCodes = defaultHexCodesV2;


  function validateCssForXSS(css) {
    if (/javascript:/i.test(css) || /expression/i.test(css) || /url/i.test(css)
      || /data:/i.test(css) || /@import/i.test(css)) {
      return true;
    }
    return false;
  }

  // Custom css validation function
  function parseCSSText(input) {
    const cssText = input.replace(/\/\*(.|\s)*?\*\//g, ' ').replace(/\s+/g, ' ');
    const style = {};
    const [, ruleName, rule] = cssText.match(/ ?(.*?) ?{([^}]*)}/) || [undefined, undefined, cssText];
    const cssToJs = string => string.replace(/\W+\w/g, match => match.slice(-1).toUpperCase());
    const properties = rule.split(';').map(o => o.split(':').map(x => x && x.trim()));

    properties.forEach((propertyArray) => {
      const [property, value] = propertyArray;
      if (value) {
        style[cssToJs(property)] = value.toLowerCase();
      }
    });

    return { input, ruleName, style };
  }

  // this regex tests for any occurence of the button class that is not
  // followed by another class because, that wouldn't be impacting the styles
  // of the primary button class, it would be a use just to increase css specificity

  function validateCustomizedCss({
    uxCustomizedCss,
    uxBackgroundColor,
    uxPrimaryButtonColor,
    uxSecondaryButtonColor,
    displayUi
  }) {
    const primaryButtonRegex = new RegExp(/.qc-cmp-button({|,| {)/, 'm');
    const primaryButton = primaryButtonRegex.exec(uxCustomizedCss);
    const secondaryButton = uxCustomizedCss.search('.qc-cmp-secondary-button');
    const backgroundColor = uxCustomizedCss.search('.qc-cmp-ui');

    if (secondaryButton !== -1) {
      let secondaryButtonStyles = uxCustomizedCss.substr(secondaryButton);
      secondaryButtonStyles = secondaryButtonStyles.substr(
        secondaryButtonStyles,
        secondaryButtonStyles.indexOf('}') + 1
      );
      const parsedCssSecondaryButton = parseCSSText(secondaryButtonStyles);

      if (!uxBackgroundColor) {
        const secondaryButtonValidationsPopup = (parsedCssSecondaryButton.style.background
           || parsedCssSecondaryButton.style.backgroundColor) === '#fff'
        || (parsedCssSecondaryButton.style.background
           || parsedCssSecondaryButton.style.backgroundColor) === '#ffffff'
        || (parsedCssSecondaryButton.style.background || parsedCssSecondaryButton.style.backgroundColor) === 'white';
        const secondaryButtonValidationsBanner = (parsedCssSecondaryButton.style.background
          || parsedCssSecondaryButton.style.backgroundColor) === '#368bd6';
        if (displayUi === 'popup' && secondaryButtonValidationsPopup) {
          return conflictingColor('background', 'button');
        } if (displayUi === 'banner' && secondaryButtonValidationsBanner) {
          return conflictingColor('background', 'button');
        }
      } if ((parsedCssSecondaryButton.style.background
        || parsedCssSecondaryButton.style.backgroundColor) === uxBackgroundColor.toLowerCase()) {
        return conflictingColor('background', 'button');
      }

      if (!uxPrimaryButtonColor) {
        const secondaryButtonValidationsPopup = (parsedCssSecondaryButton.style.background
          || parsedCssSecondaryButton.style.backgroundColor) === '#368bd6';
        const secondaryButtonValidationsBanner = (parsedCssSecondaryButton.style.background
             || parsedCssSecondaryButton.style.backgroundColor) === '#fff'
          || (parsedCssSecondaryButton.style.background
             || parsedCssSecondaryButton.style.backgroundColor) === '#ffffff'
          || (parsedCssSecondaryButton.style.background || parsedCssSecondaryButton.style.backgroundColor) === 'white';

        if (displayUi === 'popup' && secondaryButtonValidationsPopup) {
          return conflictingColor('primary', 'secondary button');
        } if (displayUi === 'banner' && secondaryButtonValidationsBanner) {
          return conflictingColor('primary', 'secondary button');
        }
      } else if ((parsedCssSecondaryButton.style.background
        || parsedCssSecondaryButton.style.backgroundColor) === uxPrimaryButtonColor.toLowerCase()) {
        return conflictingColor('primary', 'secondary button');
      }

      if (backgroundColor !== -1) {
        let backgroundColorStyles = uxCustomizedCss.substr(backgroundColor);
        backgroundColorStyles = backgroundColorStyles.substr(
          backgroundColorStyles,
          backgroundColorStyles.indexOf('}') + 1
        );
        const parsedCssBackground = parseCSSText(backgroundColorStyles);
        const validation = (parsedCssBackground.style.background
        || parsedCssBackground.style.backgroundColor) === (parsedCssSecondaryButton.style.background
          || parsedCssSecondaryButton.style.backgroundColor);

        if (validation) {
          return conflictingColor('background', 'button');
        }
      }

      if (primaryButton) {
        const primaryButtonIndex = uxCustomizedCss.indexOf(primaryButton[0]);
        let primaryButtonStyles = uxCustomizedCss.substring(primaryButtonIndex);
        primaryButtonStyles = primaryButtonStyles.substring(
          primaryButtonStyles,
          primaryButtonStyles.indexOf('}') + 1
        );
        const parsedCssPrimaryButton = parseCSSText(primaryButtonStyles);
        const customColorsConflictValidation = () => {
          const a = parsedCssPrimaryButton.style.background;
          const b = parsedCssPrimaryButton.style.backgroundColor;
          const c = parsedCssSecondaryButton.style.background;
          const d = parsedCssSecondaryButton.style.backgroundColor;
          let valid = false;

          if ((a && (a === c || a === d)) || (b && (b === c || b === d))) {
            valid = true;
          }

          return valid;
        };

        if (customColorsConflictValidation()) {
          return conflictingColor('primary', 'secondary button');
        }
      }
    } else {
      if (backgroundColor !== -1) {
        let backgroundColorStyles = uxCustomizedCss.substr(backgroundColor);
        backgroundColorStyles = backgroundColorStyles.substr(
          backgroundColorStyles,
          backgroundColorStyles.indexOf('}') + 1
        );
        const parsedCss = parseCSSText(backgroundColorStyles);
        const backgroundValidations = (parsedCss.style.background || parsedCss.style.backgroundColor) === '#eee'
        || (parsedCss.style.background || parsedCss.style.backgroundColor) === '#eeeeee';

        if (
          !uxSecondaryButtonColor
          && (backgroundValidations)) {
          return conflictingColor('background', 'button');
        } if (uxSecondaryButtonColor && (parsedCss.style.background
          || parsedCss.style.backgroundColor) === uxSecondaryButtonColor.toLowerCase()) {
          return conflictingColor('background', 'button');
        }
      }
      if (primaryButton !== -1) {
        let primaryButtonStyles = uxCustomizedCss.substr(primaryButton);
        primaryButtonStyles = primaryButtonStyles.substr(
          primaryButtonStyles,
          primaryButtonStyles.indexOf('}') + 1
        );
        const parsedCss = parseCSSText(primaryButtonStyles);
        const primaryButtonValidations = (parsedCss.style.background || parsedCss.style.backgroundColor) === '#eee'
        || (parsedCss.style.background || parsedCss.style.backgroundColor) === '#eeeeee';

        if (
          !uxSecondaryButtonColor
          && (primaryButtonValidations)) {
          return conflictingColor('primary', 'secondary button');
        } if (uxSecondaryButtonColor && (parsedCss.style.background
          || parsedCss.style.backgroundColor) === uxSecondaryButtonColor.toLowerCase()) {
          return conflictingColor('primary', 'secondary button');
        }
      }
    }
    return '';
  }

  return {
    // works for uxFontColor, uxLinkColor, uxToogleActiveColor, uxPrimaryButtonColor
    // ( it's neccesary to pass the Html element for the error message )
    uxElementColor: (uxBackgroundColor, element) => [
      {
        validator(_, value) {
          if (value) {
            if (!HEX_REGEX.test(value)) {
              return Promise.reject(new Error(INVALID_HEX));
            }
            if (uxBackgroundColor) {
              if (uxBackgroundColor.toLowerCase() === value.toLowerCase()) {
                return Promise.reject(new Error(conflictingColor('background', element)));
              }
            } else if (value.toLowerCase() === defaultHexCodes._backgroundColor.toLowerCase()
                || value.toLowerCase() === defaultHexCodes._backgroundColorShort.toLowerCase()
            ) {
              return Promise.reject(new Error(conflictingColor('background', element)));
            }
          }
          return Promise.resolve();
        }
      }
    ],
    uxBackgroundColor: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (value) {
            if (!HEX_REGEX.test(value)) {
              return Promise.reject(new Error(INVALID_HEX));
            }
            if (
              !getFieldValue('uxPrimaryButtonColor')
                && defaultHexCodes._primaryButtonColor.toLowerCase() === value.toLowerCase()
            ) {
              return Promise.reject(new Error(conflictingColor('background', 'primary')));
            }
            if (!getFieldValue('uxFontColor') && defaultHexCodes._bodyTextColor.toLowerCase() === value.toLowerCase()) {
              return Promise.reject(new Error(conflictingColor('background', 'text')));
            }
            if (!getFieldValue('uxLinkColor') && defaultHexCodes._linkColor.toLowerCase() === value.toLowerCase()) {
              return Promise.reject(new Error(conflictingColor('background', 'link')));
            }
            if (
              !getFieldValue('uxToogleActiveColor')
                && defaultHexCodes._toggleActiveColor.toLowerCase() === value.toLowerCase()
            ) {
              return Promise.reject(new Error(conflictingColor('background', 'toggle active color')));
            }
          }
          return Promise.resolve();
        }
      })
    ],
    uxPrimaryButtonTextColor: uxPrimaryButtonColor => [
      {
        validator(_, value) {
          if (value) {
            if (!HEX_REGEX.test(value)) {
              return Promise.reject(new Error(INVALID_HEX));
            }
            if (uxPrimaryButtonColor) {
              if (value.toLowerCase() === uxPrimaryButtonColor.toLowerCase()) {
                return Promise.reject(new Error(conflictingColor('button', 'button text')));
              }
            } else if (defaultHexCodes._primaryButtonColor.toLowerCase() === value.toLowerCase()) {
              return Promise.reject(new Error(conflictingColor('button', 'button text')));
            }
          }
          return Promise.resolve();
        }
      }
    ],
    uxSecondaryButtonColor: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          const uxSecondaryButtonTextColor = getFieldValue('uxSecondaryButtonTextColor');
          if (value) {
            if (!HEX_REGEX.test(value)) {
              return Promise.reject(new Error(INVALID_HEX));
            }
            if (uxSecondaryButtonTextColor) {
              if (value.toLowerCase() === uxSecondaryButtonTextColor.toLowerCase()) {
                return Promise.reject(new Error(conflictingColor('button', 'button text')));
              }
            } else if (value.toLowerCase() === defaultHexCodes._secondaryButtonTextColor.toLowerCase()) {
              return Promise.reject(new Error(conflictingColor('button', 'button text')));
            }
          }
          return Promise.resolve();
        }
      })
    ],
    uxSecondaryButtonTextColor: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          const uxSecondaryButtonColor = getFieldValue('uxSecondaryButtonColor');
          if (value) {
            if (!HEX_REGEX.test(value)) {
              return Promise.reject(new Error(INVALID_HEX));
            }
            if (uxSecondaryButtonColor) {
              if (uxSecondaryButtonColor.toLowerCase() === value.toLowerCase()) {
                return Promise.reject(new Error(conflictingColor('button', 'button text')));
              }
            } else if (defaultHexCodes._secondaryButtonColor.toLowerCase() === value.toLowerCase()
              || defaultHexCodes._secondaryButtonColorShort.toLowerCase() === value.toLowerCase()
            ) {
              return Promise.reject(new Error(conflictingColor('button', 'button text')));
            }
          }
          return Promise.resolve();
        }
      })
    ],
    consentScopeGroupURL: consentScope => [
      { type: 'url', message: FIELD_URL_INVALID },
      {
        validator(_, value) {
          if (consentScope && consentScope === 'service group') {
            if (!(value || value === '')) {
              return Promise.reject(new Error(FIELD_REQUIRED_TEXT));
            }
          }
          return Promise.resolve();
        }
      }
    ],
    regulation: [{ required: true, message: FIELD_REQUIRED_TEXT }],
    customLinksUrl: fieldName => ([
      { type: 'url', message: FIELD_URL_INVALID },
      ({ getFieldValue }) => (
        {
          validator(_, value) {
            const text = getFieldValue(fieldName);
            if (text && text !== '' && !value) {
              return Promise.reject(new Error(FIELD_REQUIRED_TEXT));
            }
            if (value && !text) {
              return Promise.reject(new Error(URL_TEXT_FIELD_REQUIRED));
            }
            return Promise.resolve();
          }
        }
      )
    ]),
    customLinksText: fieldName => ([
      ({ getFieldValue }) => (
        {
          validator(_, value) {
            const urlLink = getFieldValue(fieldName);
            if (urlLink && urlLink !== '' && !value) {
              return Promise.reject(new Error(FIELD_REQUIRED_TEXT));
            }
            if (value && !urlLink) {
              return Promise.reject(new Error(URL_FIELD_REQUIRED));
            }
            return Promise.resolve();
          }
        }
      ),
      {
        pattern: /^[^<>]*$/,
        message: "Field should not contain '<' or '>' symbols."
      },
    ]),
    uxCustomizedCss: ({
      uxBackgroundColor,
      uxPrimaryButtonColor,
      uxSecondaryButtonColor,
      displayUi
    }) => [
      {
        validator(_, value) {
          if (value && validateCustomizedCss({
            uxCustomizedCss: value,
            uxBackgroundColor,
            uxPrimaryButtonColor,
            uxSecondaryButtonColor,
            displayUi
          })) {
            return Promise.reject(new Error());
          }
          if (value && validateCssForXSS(value)) {
            return Promise.reject(new Error('CSS contains prohibited characters that could lead to an XSS attack'));
          }
          return Promise.resolve();
        }
      },
      {
        pattern: /^[^<>]*$/,
        message: "CSS should not contain '<' or '>' symbols."
      }
    ],
    themeName: [
      { required: true, message: FIELD_REQUIRED_TEXT },
      {
        pattern: /^[^<>]*$/,
        message: "Field should not contain '<' or '>' symbols."
      }
    ],
    textInput: [
      {
        pattern: /^[^<>]*$/,
        message: "Field should not contain '<' or '>' symbols."
      }
    ],
  };
};

export default validate;
