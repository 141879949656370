// @flow
import React from 'react';
import appStyles from '../../../styles.module.scss';
import Navbar from '../../ui/navbar';

type Props = {
  match: {
    path: string
  };
}

const withNavbar = Component => (props: Props) => {
  const { match: { path } } = props;

  return (
    <div className={appStyles.container}>
      <div className={appStyles.navBar}>
        <Navbar path={path} />
      </div>
      <div className={appStyles.content}>
        <Component {...props} />
      </div>
    </div>
  );
};

export default withNavbar;
