import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGBCPurposes } from '../../../store/duck/gbc';

function useGBCPurposes() {
  const purposesData = useSelector(state => state.gbcPurposes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!purposesData.pending && purposesData.purposes.length === 0
        && purposesData.error.status === 200) dispatch(fetchGBCPurposes());
  }, [purposesData]);

  return purposesData.purposes;
}


export default useGBCPurposes;
