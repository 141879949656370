// @flow
import React from 'react';
import Radio from 'antd/es/radio';
import classNames from 'classnames';
import uid from 'uid';
import Styles from './radios.module.scss';

type Props = {
  block?: boolean;
  inline?: boolean;
  input: Object;
  label: string;
  options: Array<Object>;
}

function Radios({ block, inline, input, options }: Props) {
  return (
    <Radio.Group
      className={classNames({ [Styles.radios]: block })}
      selectedValue={input.value}
      {...input}
      inline={inline}
    >
      {options.map(option => (
        <>
          <Radio key={uid()} value={option.value} disabled={option.disabled}>
            {option.label}
          </Radio>
          {option.component && (option.component)}
        </>
      ))}
    </Radio.Group>
  );
}

Radios.defaultProps = {
  block: false,
  inline: false
};

export default Radios;
