// @flow
import { faSpinnerThird } from '@fortawesome/fontawesome-pro-light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spin from 'antd/es/spin';
import React from 'react';

const styles = {
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  WebkitAnimation: 'fa-spin 700ms infinite linear',
  animation: 'fa-spin 700ms infinite linear',
  color: 'var(--blue-6)'
};

function Loader() {
  return (
    <Spin indicator={<FontAwesomeIcon icon={faSpinnerThird} />} size="large" style={styles} data-testid="loader" />
  );
}

export default Loader;
