export function calculatePercentageChange(oldValue, newValue) {
  if (oldValue === 0) {
    return newValue > 0 ? 100 : 0;
  }

  const change = newValue - oldValue;
  const percentageChange = (change / oldValue) * 100;

  return percentageChange;
}
