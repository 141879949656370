import React, { useEffect, useState } from 'react';

import { Radio, Space, Modal, Form, Checkbox, Tooltip, Button } from 'antd/es';
import classNames from 'classnames';
import SITETOOLTIPS from '../../../utils/toolTipsMessages';
import Styles from './mspaSettings.module.scss';
import TEXT from './text';
import InfoIconTooltip from '../../ui/infoIconTooltip';
import Card from '../../ui/card/lib/card';
import Alert from '../../ui/alert';

// @flow

const { CardHeader, CardContent } = Card;
type Props = {
  className: string,
  setFieldsValue: Function,
  handleChange: Function,
  getFieldValue: Function,
  isBulkUpdate: boolean,
};

const MSPARegulationSettings = (
  {
    className = '', setFieldsValue, getFieldValue, isBulkUpdate = false,
    handleChange = (() => {})
  }: Props
) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [ccpaViaUsp, setCcpaViaUsp] = useState(getFieldValue('ccpaViaUsp') || false);
  const [isCoveredTransaction, setIsCoveredTransaction] = useState(getFieldValue('isCoveredTransaction') || false);

  const booleanOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const jurisdictionOptions = [
    { label: TEXT.REGULATORY_PATH_OPTION1, value: 'NATIONAL', disable: !isCoveredTransaction },
    { label: TEXT.REGULATORY_PATH_OPTION2, value: 'STATE_AND_NATIONAL', disable: false },
  ];

  const setCoveredTransationToValue = (value) => {
    setFieldsValue({ isCoveredTransaction: value });
    setIsCoveredTransaction(value);
    const mspaJurisdiction = value ? 'NATIONAL' : 'STATE_AND_NATIONAL';
    setFieldsValue({ mspaJurisdiction });
    handleChange(['mspaJurisdiction', 'isCoveredTransaction'], {
      mspaJurisdiction,
      isCoveredTransaction: value
    });
  };

  const handleCoveredTransactionChange = (e) => {
    if (e.target.value === true) {
      // Open modal
      setModalOpen(true);
    } else {
      setCoveredTransationToValue(false);
    }
  };

  const ccpaChanged = e => (e.target.value === true ? setCcpaViaUsp(true) : setCcpaViaUsp(false));

  return (
    <>
      <Card className={classNames(className, Styles.mspaSettings)}>
        <CardHeader title={TEXT.CARD_TITLE}>
          <span className="small">{TEXT.CARD_SUBTITLE}</span>
          <span className="regulation">{TEXT.REGULATION}</span>
        </CardHeader>
        <CardContent>
          <Space direction="vertical">
            <Form.Item
              label={
              (
                <Space direction="vertical">
                  <Space>
                    <span>{TEXT.TRANSACTION_COVERED_LABEL}</span>
                    <Tooltip
                      title={SITETOOLTIPS.coveredTransaction}
                      placement="top"
                    >
                      <span><InfoIconTooltip /></span>
                    </Tooltip>
                  </Space>
                  {isBulkUpdate && (
                    <Alert
                      type="info"
                      message={TEXT.TRANSACTION_COVERED_ALERT}
                      className={Styles.alert}
                    />
                  )}
                </Space>
              )
            }
              className={Styles.radioItem}
              name="isCoveredTransaction"
            >
              <Radio.Group onChange={handleCoveredTransactionChange} options={booleanOptions} />
            </Form.Item>

            <Form.Item
              label={
              (
                <Space direction="vertical">
                  <Space>
                    <span>{TEXT.REGULATORY_PATH_LABEL}</span>
                    <Tooltip
                      title={SITETOOLTIPS.regulatoryPath}
                      placement="top"
                    >
                      <span><InfoIconTooltip /></span>
                    </Tooltip>
                  </Space>
                  {isBulkUpdate && (
                    <Alert
                      type="info"
                      message={TEXT.REGULATORY_PATH_ALERT}
                      className={Styles.alert}
                    />
                  )}
                </Space>
              )
            }
              className={Styles.radioItem}
              name="mspaJurisdiction"
            >
              <Radio.Group
                onChange={(e) => {
                  handleChange('mspaJurisdiction', e.target.value);
                }}
              >
                <Space direction="vertical">
                  {jurisdictionOptions.map(option => (
                    <Radio value={option.value} key={option.value} disabled={option.disable}>{option.label}</Radio>
                  ))}
                </Space>
              </Radio.Group>

            </Form.Item>

            <Space direction="vertical" size={0}>
              <Form.Item
                label={
                (
                  <Space direction="vertical">
                    <Space>
                      <span>{TEXT.CCPA_LABEL}</span>
                      <Tooltip
                        title={SITETOOLTIPS.ccpaViaUsp}
                        placement="top"
                      >
                        <span><InfoIconTooltip /></span>
                      </Tooltip>
                    </Space>
                    <Space>
                      <span className={Styles.subTitle}>{TEXT.CCPA_NOTE}</span>
                    </Space>
                    {isBulkUpdate && (
                      <Alert
                        type="info"
                        message={TEXT.CCPA_ALERT}
                        className={Styles.alert}
                      />
                    )}
                  </Space>
                )
              }
                className={Styles.radioItem}
                name="ccpaViaUsp"
              >
                <Radio.Group onChange={
                (e) => {
                  handleChange('ccpaViaUsp', e.target.value);
                  ccpaChanged(e);
                }}
                >
                  <Space direction="vertical">
                    <Radio value={false}>{TEXT.CCPA_OPTION1}</Radio>
                    <Radio value>{TEXT.CCPA_OPTION2}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              {
              ccpaViaUsp && (
                <Alert
                  type="warning"
                  message={TEXT.CCPA_VIA_USP_WARNING}
                  className={Styles.alert}
                  showIcon
                />
              )
            }
            </Space>


            {ccpaViaUsp && (
              <Space direction="vertical">
                <Space>
                  <Form.Item name="uspLspact" valuePropName="checked" className={Styles.checkBoxItem}>
                    <Checkbox
                      onChange={e => handleChange('uspLspact', e.target.checked)}
                      className={Styles.checkBoxLabel}
                    >
                      {TEXT.LSPA_SIGNED_LABEL}
                    </Checkbox>
                  </Form.Item>
                  <Tooltip
                    title={SITETOOLTIPS.AMP}
                    placement="top"
                  >
                    <span><InfoIconTooltip /></span>
                  </Tooltip>
                </Space>
                {isBulkUpdate && (
                <Alert
                  type="info"
                  message={TEXT.LSPA_SIGNED_ALERT}
                  className={Styles.alert}
                />
                )}
              </Space>
            )}
          </Space>
        </CardContent>
      </Card>
      <Modal
        open={modalOpen}
        closable={false}
        width={530}
        footer={null}
        style={{ textAlign: 'center' }}
      >
        <Space direction="vertical" className={Styles.modalConent}>
          {TEXT.RECONFIRM_CONTENT}
          <div className={Styles.modalButtons}>
            <Button
              type="primary"
              onClick={() => {
                setModalOpen(false);
                setCoveredTransationToValue(true);
              }}
            >
              {TEXT.OK}
            </Button>
            <Button
              onClick={() => {
                setModalOpen(false);
                setCoveredTransationToValue(false);
              }}
            >
              {TEXT.CANCEL}
            </Button>
          </div>
          <Space direction="vertical" size={2}>
            <span className={Styles.reconfirmText}>
              {TEXT.RECONFIRM_TEXT}
            </span>
            <a href="https://www.iabprivacy.com/" target="_blank" rel="noopener noreferrer">
              {TEXT.RECONFIRM_LINK}
            </a>
          </Space>
        </Space>
      </Modal>
    </>
  );
};

export default MSPARegulationSettings;
