import { getOldVersionProperties } from '../services/banner';
import { useQueryWrapper } from './common';

const OLD_VERSION_PROPERTIES_DATA = 'oldVersionPropertiesData';

export const useOldVersionProperties = (pCode: string) => useQueryWrapper([OLD_VERSION_PROPERTIES_DATA, pCode], getOldVersionProperties, {
  onError: (errors) => {
    console.log('Error ', errors);
  }
});
