/* eslint-disable */
// @flow
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import HelpCenterBubble from './components/ui/help-center-bubble';
import GlobalNavbar from './components/ui/global-navbar';
import AnnouncementsModal from './components/ui/modal-announcement';
import NotFoundPageDefault from './pages/not-found';
import Root from './pages/root';
import routes from './routes/routes';
import Login from './login/pages/Login';
import './auth';
import protectedRoute from './login/protectedRoute';
import { initAuthentication } from './store/duck/authentication';

const __Choice = ({ url }) => {
  return (
    <>
      <AnnouncementsModal />
      <GlobalNavbar />
      <Switch>
        {routes.map(route => (
          <Route
            key={route.path}
            exact={route.exact}
            path={`${url}${route.path}`}
            component={route.component}
          />
        ))}
        <Redirect from={url} exact to={`${url}/properties`} />
        <Route component={NotFoundPageDefault} />
      </Switch>
      <HelpCenterBubble />
    </>
  );
};
const Choice = protectedRoute(__Choice);
const BASE_PATH = '/protect';
const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initAuthentication())
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        {/* Top Level routes. These are routes without an account Context */}
        <Route exact path={`/`} component={Root} />
        <Route exact path={`/login`} component={Login} />
        <Route exact path={`/reset-password`} component={Login} />
        <Route exact path={BASE_PATH} component={Root} />
        <Route exact from={`${BASE_PATH}/sites`} component={Root} />
        {/* Everything that starts with /protect/:pcode */}
        <Route path={`${BASE_PATH}/:pcode(p-[a-zA-Z0-9_-]{13})`} render={({ match: { url }, history }) => {
          return (<Choice url={url} history={history} />);
        }} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
