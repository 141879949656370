// eslint-disable-next-line import/prefer-default-export
export const defaultHexCodesV2 = {
  _bodyTextColor: '#141E23',
  _bodyTextColorShort: '#141E23',
  _linkColor: '#206DC5',
  _linkColorShort: '#206DC5',
  _backgroundColor: '#FFFFFF',
  _backgroundColorShort: '#FFF',
  _toggleActiveColor: '#206DC5',
  _primaryButtonColor: '#206DC5',
  _primaryButtonTextColor: '#FFFFFF',
  _primaryButtonTextColorShort: '#FFF',
  _secondaryButtonColor: '#FFFFFF',
  _secondaryButtonColorShort: '#FFF',
  _secondaryButtonTextColor: '#206DC5'
};
